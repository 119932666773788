
// Deliveryformpopup
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import dayjs from "dayjs";
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isToday from 'dayjs/plugin/isToday';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Box,Button,Typography,Paper,Card,IconButton,ListItem,Chip,ListItemText,Grid,ListItemAvatar,Badge, Divider, AccordionSummary, AccordionDetails, Accordion} from "@mui/material";
import { styled } from '@mui/material/styles';
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore)

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const DeliveriesFormPopup=({dynamicComponent,useIsMobile,globalState,viewData,globalWrn,waveLoad,doesIDExist,dynamicMapData,queryDB,CheckID})=> {
   const mainState = useState({ 
       noListLabel: 'No Projects',
       buttonTitle: 'FETCH: End Of Day Projects',
       waitingLabel: '...Gathering your EOD Forms',
       geoMap: null
     })
   const {data} = viewData
   const [, setState, stateRef] = mainState
   const [, setEODInfo, EODInfoRef] = useState(Array)
   const [, setDeliveries, DeliveriesRef] = useState(Array)
   const [, setCompletedProj, completedProjRef] = useState(Array)
   const stagedRef = new Set()
   const [UserMap, setUserMap] = useAtom(globalState.userMapState)
   const [appState,setAppState] = useAtom(globalState.appState)
   const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
   const [inventoryMap] = useAtom(globalState.inventoryMapState)
   const [divisionMap, setDivisionMap] = useAtom(globalState.divisionMapState)
   const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
   const [productMap,setProductMap] = useAtom(globalState.productMapState)
   const tableState = useState([])
   const [, setTableState, tableStateRef] = tableState
   const mapRef = useRef(null)
   const valueTable = useState(0)
   const [, setValue, valueRef] = valueTable
   const fieldState = useState(Object)
   const [, setField, fieldRef] = fieldState
   const [reSync, setReSync] = useState(false)

   const [expanded, setExpanded] = useState(false);

   const useAndMobile = useIsMobile(560);

   const [appSocket] = useAtom(globalState.appSocketState);
   const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);
   var socketQueryIO = appSocketQuery.get('socketQueryIO');
   //console.log(Array.from([...teamMember],([k,v])=>{return v}).filter(x=>x.department === "Company")) //

   const handleChange = (panel) => (event, isExpanded) => {
     setExpanded(!expanded);

   };
   
   var [, setPopup] = useAtom(globalState.popupState)

   useEffect(()=>{
    if(UserMap.get('locTrack') !== true) setUserMap((p)=>{p.set('locTrack', true); return new Map(p)})

   },[UserMap])

   const cellColor = (Status) => {
    if(Status==='Shipping') return "#5F6A6A";
    if(Status==='Partial Received') return "#f6cd61";
    if(Status==='Warehouse Received') return "#3da4ab";
    if(Status==='Partially Received and Delivered') return "#a8e6cf";
    if(Status==='Partial to Site') return "#adcbe3";
    if(Status==='Delivered to Site') return "#64a1f4";
    if(Status==='Returned for RMA') return "#fe8a71";
    if(Status==='Stock') return "#f9caa7";
    if(Status==='Requested') return "#02d1af";
    if(Status==='Order Placed') return "#539ffc";
    if(Status==='Order Paused') return "#d68fff";
    if(Status==='Order Unavailable') return "#ff5252";
    if(Status==='Order Processing') return "#bff52c";
    if(Status==='Order Checked On') return "#ffdd00";
}


   /*function createData({TransferRequests,InventoryID,ProductID,ProjectID,DivisionID},callBK) { 
    
    var {_id, timestamp, TransferAmount, authorizor, requestor, signedName, deliverDriver, deliveryDate, Notes, Status} = TransferRequests?.find(({deliveryDate,deliverDriver,Report})=> deliverDriver===UserMap.get('avatar') && !Report && dayjs().isSameOrAfter(deliveryDate) )
   
    doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:ProjectID,modelType:divisionMap.get(DivisionID)?.teamName},(projData)=>{
   findSiteLoc(projData?.SalesState?.SiteID?.[0],(siteObj)=>{
    callBK({
      _id: _id,
      timestamp: dayjs(timestamp).format('lll'),
      name: siteObj?.name ? siteObj?.name : `No Site Name`,
      TransferAmount: TransferAmount,
      ProjectName: projectMap.get(ProjectID?.[0])?.ProjectTitle,
      authorizor: getUserAvatar(authorizor),
      requestor: getUserAvatar(requestor),
      signedName: signedName,
      deliverDriver: getUserAvatar(deliverDriver), 
      deliveryDate: dayjs(deliveryDate).format('lll'),
      deliverylocation: <Button component="label" variant="contained" startIcon={<MapIcon />} onClick={()=>{
        console.log('generateGeoMap',siteObj, ProjectID, deliverDriver)
       // generateGeoMap(siteObj, ProjectID, deliverDriver)
      }}>Start Delivery</Button>,
      Notes: Notes?Notes:`No Notes`,
      Status: Status?<Chip label={Status} sx={{backgroundColor: cellColor(Status)}}/>:<Chip label="No Status"/>,
      SiteID: getSiteID(ProjectID?.[0]),
    });
   })
   })
  }*/

  function getUserAvatar(_id){
    return  <ListItem alignItems="flex-start">
    <ListItemAvatar>
      {dynamicComponent('AvatarBubble','elements',{state:'user'+[_id],Catagory:'Team',label:'User', style:{width:'40px',height:'40px'},userID: [_id] },mainState)}
    </ListItemAvatar>
    <ListItemText
      primary={`${teamMember.get(_id)?.firstName} ${teamMember.get(_id)?.lastName}`}
      secondary={
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {`${teamMember.get(_id)?.workPhone ? teamMember.get(_id)?.workPhone : teamMember.get(_id)?.email}`}
          </Typography>
      }
    />
  </ListItem>
  }

  useEffect(()=>{
    setState(p=>({...p,...{
      deliveredForm: dynamicComponent('DeliveryFormSubmit','elements',{state:'delvForm', data: data },mainState),
      rescheduleForm: dynamicComponent('DeliveryFormReschedule','elements',{state:'rescheduleForm', data: data },mainState)
    }}))
  },[data])

  const delivered=()=>{
    setPopup(p=>({...p,...{
      open: true,
      title: `Fill out the Required Fields`,
      inputField: stateRef.current.deliveredForm,
      handleClose: ()=>{setPopup({open: false})},
    }}))
  }

  const rescheduleDelivery=()=>{
    setPopup(p=>({...p,...{
      open: true,
      zIndex: 4,
      title: `Fill out the Required Fields`,
      inputField: stateRef.current.rescheduleForm,
      width: '70%',
      handleClose: ()=>{setPopup({open: false})},
    }}))
  }

  /*const getObjectByID=(_id)=>{
    var returnObj;
    [...inventoryMap.values()].forEach(x=>{
        if(x?.TransferRequests && x.TransferRequests.some(e => e._id === _id)) returnObj = x
        return returnObj
    })
    return returnObj
  }

  const getSiteID=(_id)=>{
    return projectMap.get(_id)?.SalesState?.SiteID?.[0]
  }

  const getLocation=(_id)=>{ // driver
    return findProjectSite(findTransferReq(_id))
  }*/

  const findTransferReq = function(_id) { // get inventory project id
    return [...inventoryMap].find(entry => entry?.TransferRequests?.includes(_id))?.ProjectID?.[0];
    //return Array.from(map.values()).find(...);
  }

  const storeDataAsyncly = async(obj,map,setMap) => {
    dynamicMapData(obj, map, setMap, "Products")
  }

  function getSiteIDQuery(_id) {
    return new Promise((resolve, reject) => {
      if (projectMap.has(_id)) {
        const SiteID = projectMap.get(_id)?.SalesState?.SiteID?.[0];
        if (SiteID) {
          resolve(SiteID);
        } else {
          reject(new Error('SiteID not found in projectMap'));
        }
      } else {
        let teamName;
        let SiteID;
  
        const divisionID = data?.[0]?.DivisionID;
        
        const findProject = (teamName) => {
          return new Promise((resolve, reject) => {
            queryDB({ query: { "_id": _id }, idConvert: true, model: "Projects", modelType: teamName }, (item) => {
              SiteID = item?.SalesState?.SiteID?.[0];
              if (SiteID) {
                storeDataAsyncly({[item._id]: item}, projectMap, setProjectMap);
                resolve(SiteID);
              } else {
                reject(new Error('SiteID not found in Projects'));
              }
            });
          });
        };
  
        if (divisionMap.has(divisionID)) {
          teamName = divisionMap.get(divisionID)?.teamName;
          if (teamName) {
            findProject(teamName).then(resolve).catch(reject);
          } else {
            reject(new Error('teamName not found in divisionMap'));
          }
        } else {
          queryDB({ query: { "_id": _id }, idConvert: true, model: "DivCheck" }, (item) => {
            const divisionTeamName = item?.division?.teamName;
            if (divisionTeamName) {
              storeDataAsyncly({[item._id]: item}, divisionMap, setDivisionMap);
              findProject(divisionTeamName).then(resolve).catch(reject);
            } else {
              reject(new Error('teamName not found in DivCheck'));
            }
          });
        }
      }
    });
  }

  function getTeamMemberOrSite(SiteID) {
    return new Promise((resolve, reject) => {
      if (teamMember.has(SiteID)) {
        resolve(teamMember.get(SiteID));
      } else {
        queryDB({ query: { "_id": SiteID }, idConvert: true, model: "Sites" }, (item) => {
          if (item) {
            storeDataAsyncly({[item._id]: item}, teamMember, setTeamMember);
            resolve(item);
          } else {
            reject(new Error('Site information not found'));
          }
        });
      }
    });
  }

  const findProjectSite = function(_id,callBK) { // get site id
      // Usage example
      getSiteIDQuery(_id)
      .then(SiteID => {
        return getTeamMemberOrSite(SiteID);
      })
      .then(resp => {
        if(resp?.length && resp?.length > 0) resp = resp?.[0]
        callBK({_id:resp._id, lat: resp?.address?.location?.lat, lng: resp?.address?.location?.lng, label: resp?.address?.label, name: resp?.name||"No Site Name"})
      })
      .catch(error => {
        console.error(error.message);
        // Handle the error here
      });
  }

  /*const findSiteLoc = function(_id,callBK) { // get location from site
    doesIDExist({map:teamMember,set:setTeamMember,model:'Sites',_id:_id},(item)=>{
    callBK({
        _id:_id,
        lat: teamMember.get(_id)?.address?.location?.lat, 
        lng: teamMember.get(_id)?.address?.location?.lng, 
        label: teamMember.get(_id)?.address?.label, 
        name: teamMember.get(_id)?.name ? teamMember.get(_id)?.name : `No Site Name`
        })
      })
  }

  const setRowOpenClose=(_id)=>{
    setState(p=>({...p,...{[_id + '_row']: stateRef.current[_id + '_row'] ? !stateRef.current[_id + '_row'] : true}}))
  }*/

  useEffect(()=>{
    data.forEach(x=>{
      var totalNum = 0
      x?.TransferRequests?.forEach(y=>{
        totalNum = totalNum + y?.TransferAmount
      })
    setState(p=>({...p,...{
      [[x?.InventoryID]+'_totalAmm']: Math.abs(totalNum)
    }}))
  })
  console.log("projectMapdata:", projectMap.get(data?.[0]?.ProjectID))
    setState(p=>({...p,...{
      [[data?.[0]?.TransferRequests?.[0]?.authorizor] + '_authorizor']: dynamicComponent('AvatarBubble','elements',{state:'authorizorImg',Catagory:'Team',label:'Authorizor',userID:data?.[0]?.TransferRequests?.[0]?.authorizor},mainState),
      authorizorContact: <Card sx={{padding: '2%'}}>
        <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Work Phone: {teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.workPhone ? teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.workPhone : `None`}</Typography>
        <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Cell Phone: {teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.mobilePhone ? teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.mobilePhone : `None`}</Typography>
        <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Email: {teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.email ? teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.email : `None`}</Typography>
        </Card>,
      driverContact: <Card sx={{padding: '2%', lineHeight: 1}}>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Work Phone: {teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.workPhone ? teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.workPhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Cell Phone: {teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.mobilePhone ? teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.mobilePhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Email: {teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.email ? teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.email : `None`}</Typography>
      </Card>,
      [[data?.[0]?.TransferRequests?.[0]?.deliverDriver] + '_deliveryDriver']: dynamicComponent('AvatarBubble','elements',{state:'delDriveImg',Catagory:'Team',label:'Delivery Driver',userID:data?.[0]?.TransferRequests?.[0]?.deliverDriver},mainState),
      [[data?.[0]?.ProjectID] + "_projManager"]: projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager ? dynamicComponent('AvatarBubble','elements',{state:'projMan',Catagory:'Team',label:'Project Manager',userID:projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager},mainState) : undefined,
      projectManagerContact: <Card sx={{padding: '2%'}}>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Work Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.workPhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.workPhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Cell Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.mobilePhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.mobilePhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Email: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.email ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.email : `None`}</Typography>
      </Card>,
      [[data?.[0]?.ProjectID] + "_projLead"]: projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead ? dynamicComponent('AvatarBubble','elements',{state:'projLead',Catagory:'Team',label:'Project Lead',userID:projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead},mainState) : undefined,
      teamLeadContact: <Card sx={{padding: '2%'}}>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Work Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.workPhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.workPhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Cell Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.mobilePhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.mobilePhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Email: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.email ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.email : `None`}</Typography>
      </Card>,
      [[data?.[0]?.ProjectID] + "_client"]: projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0] ? dynamicComponent('AvatarBubble','elements',{state:'projClient',Catagory:'Clients',label:'Project Client',userID:projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0]},mainState) : undefined,
      clientContact: <Card sx={{padding: '2%'}}>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Work Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.workPhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.workPhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Cell Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.mobilePhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.mobilePhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Email: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.email ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.email : `None`}</Typography>
      </Card>,
      [[data?.[0]?.ProjectID] + "_partner"]: projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0] ? dynamicComponent('AvatarBubble','elements',{state:'projPartner',Catagory:'Partners',label:'Project Partners',userID:projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0]},mainState) : undefined,
      partnerContact: <Card sx={{padding: '2%'}}>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Work Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.workPhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.workPhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Cell Phone: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.mobilePhone ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.mobilePhone : `None`}</Typography>
      <Typography variant="subtitle2" sx={{color: '#f3e3e2',lineHeight:1.2}}>Email: {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.email ? teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.email : `None`}</Typography>
      </Card>,
    }})) 
  },[data])

  /*useEffect(()=>{
    console.log("x is:", data)
    Promise.all(
    data.flatMap(x=>new Promise((res) => createData(x,(y)=>res(y))))
    ).then((x)=>{
      setState(p=>({...p,...{
     tableInfo: x // generate array of objects sorted by "SiteID"
    }})) 
})
    
   },[data])*/

/*useEffect(()=>{
if(globalState.useIsMobile(620)) setState(p=>({...p,...{
  options: <Box sx={{display: 'flex', gap: '5px', margin: 'auto', flexWrap: 'wrap', flex: 5}}>
      { dynamicComponent('DefaultButton','elements',{state:'Location',label:'Location',type:'button', color:'#90caf9',style:{color:'#2d2d2d'},startIcon:<MapIcon/> },mainState,[()=>{ findProjectSite(data?.[0]?.ProjectID,(siteID)=>{ generateGeoMap('Location', siteID, data?.[0]?.ProjectID, UserMap.get('avatar') ) }) }]) }
      { dynamicComponent('DefaultButton','elements',{state:'Delivered',label:'Delivered',type:'button', color: '#f09d37',startIcon:<LocalShippingIcon/>},mainState,[delivered]) }
      { dynamicComponent('DefaultButton','elements',{state:'Reschedule',label:'Reschedule',type:'button',color: '#e62222',startIcon:<EventRepeatIcon/>},mainState,[rescheduleDelivery]) }
     </Box> 
  }}))   
  else setState(p=>({...p,...{
    options: dynamicComponent('DropDown','elements',{state:'optionsHeader', list:[{primary:'Location',icon:<MapIcon/>},{primary:'Delivered',icon:<LocalShippingIcon/>},{primary:'Reschedule',icon:<EventRepeatIcon/>}], replace:true },mainState,[(x)=>{
          var obj ={
            'Location': ()=>{ findProjectSite(data?.[0]?.ProjectID,(siteID)=>{ generateGeoMap('Location', siteID, data?.[0]?.ProjectID, UserMap.get('avatar') )})},
            'Delivered': delivered,
            'Reschedule': rescheduleDelivery,
          }
          obj[x.optionsHeader]()
     }]) 
    }}))
},[])*/


useEffect(()=>{
  CheckID({_id: data?.[0]?.ProjectID, key: "Projects", teamName: "ChromeBook Denver", map: projectMap, setMap: setProjectMap})
},[])

const createDeliveryMenu=({ProductID, InventoryID, productData})=>{
  var currAmount = 0

  data.forEach(x=>{
    if(x.InventoryID.filter(tmp=>tmp === InventoryID).length > 0) {
    x?.TransferRequests?.forEach(y=>{
      if (y.InventoryID === InventoryID) currAmount = currAmount + Math.abs(y?.TransferAmount)
    })
    }
  })

  setState(p=>({...p, ['item_'+[ProductID]]: <Box sx={{display: 'flex', alignContent: 'center', padding: '1%'}}><Badge badgeContent={currAmount} color="primary"><Item sx={{maxWidth:'225px',margin:'auto'}} onClick={async()=>{
    setPopup({open: true, inputField: <Box sx={{padding: '1%'}}>{waveLoad()}</Box>, handleClose: ()=>setPopup({open: false})})
  if(productMap.has(ProductID)){
    var prodExists = productMap.has(ProductID)
    setState(p=>({...p,...{
      productCard : stateRef.current?.productCard ? stateRef?.current?.productCard.push(dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists}, mainState)) : [dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists}, mainState)]
    }}))
  } else if(ProductID && !productMap.has(ProductID)) queryDB({model:'Products',idConvert:true,query:{"_id": ProductID}},(x)=>{
      if(!x || x.length === 0) {
        var prodExists = false
        setState(p=>({...p,...{
          productCard : stateRef.current?.productCard ? stateRef?.current?.productCard.push(dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists}, mainState)) : [dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists}, mainState)]
        }}))
      } 
      else{  
        var prodExists = true
        dynamicMapData({[x._id]: x}, productMap, setProductMap, "Products", (resp)=>{
          setState(p => ({
            ...p,
              productCard: stateRef.current?.productCard ? stateRef?.current?.productCard.push(dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists, fallBackProd: x?.[0]||productData}, mainState)) : [dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists, fallBackProd: x?.[0]}, mainState)]
          }));
        })
    }
    })
}} >
  <LazyLoadImage
       alt={'IMAGE'}
       effect="blur"
       loading='lazy'
       threshold={10000}
       height={'auto'}
       width={'100%'}
       style={{objectFit:'contain',maxHeight:'250px'}}
       key={'MainLogo'}
       onError={(e)=>{ e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}}
       src={productData?.IMAGE_SPOTLIGHT||productMap.get(ProductID)?.IMAGE_SPOTLIGHT||'https://www.services.ekc.app/scripts/images/AV.jpeg'}
     />
     <Typography>{productData?.DISPLAY_NAME||productMap.get(ProductID)?.DISPLAY_NAME}</Typography>
     <Typography>Total Amount to Deliver:&nbsp;{currAmount}</Typography>
     </Item></Badge></Box>
  }))
}


useEffect(() => {
  const processProducts = async () => {
    if (data && data[0] !== undefined) {
      const promises = data.map(({ InventoryID, ProductID, ProjectID, TransferRequests }) => {
        ProductID.forEach((ProductID,i)=> {
          if (!productMap.has(ProductID)) {
          return new Promise((resolve) => {
            CheckID({
              key: "Products",
              map: productMap,
              setMap: setProductMap,
              _id: ProductID
            }, (resp) => {
              resolve(createDeliveryMenu({ ProductID, InventoryID:InventoryID?.[i], productData: resp }));
            })});
          
        } else {
          createDeliveryMenu({ ProductID, InventoryID:InventoryID?.[i] });
          return Promise.resolve();
        }
      });
      });

      await Promise.all(promises);
    }
  };

  processProducts();
}, [data]);

   const generateGeoMap=(type,{name,label,lat,lng,_id}, projID, deliverDriver)=>{
       setState(p=>({...p,
            geoMap: [<Card sx={{padding: 0,width:'100%',height:'100%',position:'absolute'}}>
              <Box sx={{position:lat && lng &&'absolute',bottom:'3%',padding: '1%',display:'flex', zIndex:1}}>
              { dynamicComponent('DefaultButton','elements',{state:'Back',label:'Back',type:'button', color: '#f09d37',startIcon:<ArrowBackIcon/>},mainState,[()=>setState(p=>({...p,...{
            geoMap: null }})) ]) }
              </Box>
              </Card>,
              lat && lng && <Box sx={{position:'relative',top:0,width:'100%',height:'100%',minWidth:'85vw',minHeight:'80vh',zIndex:0}}>{dynamicComponent('geoMap','component',{loc: {lat: lat, lng:lng},offSet:{left:-35},ref: mapRef,projID, data:data, deliverDriver,SiteID:_id},mainState)}</Box>
            ]
        }))
    }

     return [stateRef.current.geoMap ? stateRef.current.geoMap : <Box sx={{zIndex: stateRef.current.geoMap ? -1 : 0,width:'100%'}}>
      <Accordion expanded={expanded === true}>
      <AccordionSummary
        expandIcon={<IconButton onClick={handleChange('panel1')}><ExpandMoreIcon /></IconButton>}
        aria-controls="panel1bh-content"
        sx={{width: '100%', gap: '6px', flexBasis: '100%'}}
      >
       <ListItemText
        primary={<Typography sx={{ flexShrink: 0 }}>
          {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.SiteID?.[0])?.name}
        </Typography>} secondary={<Typography variant="subtitle2" sx={{flexShrink: 0 }}>
          {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.SiteID?.[0])?.address?.label}
        </Typography>}>
        </ListItemText>
       { !useAndMobile ?
        <Box sx={{display: 'flex', gap: '5px', margin: 'auto', flexWrap: 'wrap'}}>
          { dynamicComponent('DefaultButton','elements',{state:'Location',label:'Start Delivery',type:'button', color:'#90caf9',style:{color:'#2d2d2d'},startIcon:<MapIcon/> },mainState,[()=>{ findProjectSite(data?.[0]?.ProjectID,(siteInfo)=>{ generateGeoMap('Location', siteInfo, data?.[0]?.ProjectID, UserMap.get('avatar') ) }) }]) }
          { dynamicComponent('DefaultButton','elements',{state:'Delivered',label:'Delivered',type:'button', color: '#f09d37',startIcon:<LocalShippingIcon/>},mainState,[delivered]) }
          { dynamicComponent('DefaultButton','elements',{state:'Reschedule',label:'Reschedule',type:'button',color: '#e62222',startIcon:<EventRepeatIcon/>},mainState,[rescheduleDelivery]) }
         </Box> : dynamicComponent('DropDown','elements',{state:'optionsHeader', list:[{primary:'Start Delivery',icon:<MapIcon/>},{primary:'Delivered',icon:<LocalShippingIcon/>},{primary:'Reschedule',icon:<EventRepeatIcon/>}], replace:true },mainState,[(x)=>{
              var obj ={
                'Start Delivery': ()=>{ findProjectSite(data?.[0]?.ProjectID,(siteInfo)=>{ generateGeoMap('Location', siteInfo, data?.[0]?.ProjectID, UserMap.get('avatar') )})},
                'Delivered': delivered,
                'Reschedule': rescheduleDelivery,
              }
              obj[x.optionsHeader]()
         }]) }
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={1}>
        <Grid item xs={12} >
       <Item sx={{display: 'flex', justifyContent: 'space-evenly'}}>
         <Typography sx={{padding: '1%'}}>Project: {projectMap.get(data?.[0]?.ProjectID)?.ProjectTitle}</Typography>
         <Divider flexItem orientation="vertical"/>
         <Typography sx={{padding: '1%'}}>Requested On: {dayjs(data?.[0]?.TransferRequests?.[0]?.timestamp).format('lll')}</Typography>
       </Item>
       </Grid>
       <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
       <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <ListItem sx={{gap:'3%'}}>
           <ListItemAvatar sx={{minWidth:'unset'}}>
             {stateRef.current?.[[data?.[0]?.TransferRequests?.[0]?.authorizor] + '_authorizor']}
           </ListItemAvatar>
           <ListItemText primary={<Typography variant="subtitle2" sx={{lineHeight:1}}>{teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.firstName} {teamMember.get(data?.[0]?.TransferRequests?.[0]?.authorizor)?.lastName}</Typography>} secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1}}>Authorizor</Typography>} />
           {stateRef.current.authorizorContact}
         </ListItem>
         </Item>
         { useAndMobile && <Divider flexItem orientation="vertical"/> }
         <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <ListItem sx={{gap:'3%'}}>
           <ListItemAvatar sx={{minWidth:'unset'}}>
             {stateRef.current?.[[data?.[0]?.TransferRequests?.[0]?.deliverDriver] + '_deliveryDriver']}
           </ListItemAvatar>
           <ListItemText primary={<Typography variant="subtitle2" sx={{lineHeight:1}}>{teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.firstName} {teamMember.get(data?.[0]?.TransferRequests?.[0]?.deliverDriver)?.lastName}</Typography>} secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1}}>Delivery Driver</Typography>} />
           {stateRef.current.driverContact}
         </ListItem>
       </Item>
       </Grid>
       <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
       <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <ListItem sx={{gap:'3%'}}>
           <ListItemAvatar sx={{minWidth:'unset'}}>
             {stateRef.current?.[[data?.[0]?.ProjectID] + '_projManager']?stateRef.current?.[[data?.[0]?.ProjectID] + '_projManager']:`No Project Manager`}
           </ListItemAvatar>
           <ListItemText primary={<Typography variant="subtitle2" sx={{lineHeight:1}}>{teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.firstName} {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager)?.lastName}</Typography>} secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1}}>{stateRef.current?.[[data?.[0]?.ProjectID] + '_projManager'] && `Project Manager`}</Typography>} />
           {stateRef.current?.[[data?.[0]?.ProjectID] + '_projManager']&&stateRef.current.projectManagerContact}
         </ListItem>
         </Item>
         { useAndMobile && <Divider flexItem orientation="vertical"/> }
         <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <ListItem sx={{gap:'3%'}}>
           <ListItemAvatar sx={{minWidth:'unset'}}>
             {stateRef.current?.[[data?.[0]?.ProjectID] + '_projLead']?stateRef.current?.[[data?.[0]?.ProjectID] + '_projLead']:`No Team Lead`}
           </ListItemAvatar>
           <ListItemText primary={<Typography variant="subtitle2" sx={{lineHeight:1}}>{teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.firstName} {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.teamLead)?.lastName}</Typography>} secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1}}>{stateRef.current?.[[data?.[0]?.ProjectID] + '_projLead'] && `Project Lead`}</Typography>} />
           {stateRef.current?.[[data?.[0]?.ProjectID] + '_projLead']&&stateRef.current.teamLeadContact}
         </ListItem>
       </Item>
       </Grid>
       <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
       <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <ListItem sx={{gap:'3%'}}>
           <ListItemAvatar sx={{minWidth:'unset'}}>
             {stateRef.current?.[[data?.[0]?.ProjectID] + "_client"]?stateRef.current?.[[data?.[0]?.ProjectID] + "_client"]:`No Project Client`}
           </ListItemAvatar>
           <ListItemText primary={<Typography variant="subtitle2" sx={{lineHeight:1}}>{teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.firstName} {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.clientID?.[0])?.lastName}</Typography>} secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1}}>{stateRef.current?.[[data?.[0]?.ProjectID] + "_client"] && `Project Client`}</Typography>} />
           {stateRef.current?.[[data?.[0]?.ProjectID] + "_client"]&&stateRef.current.clientContact}
         </ListItem>
         </Item>
         { useAndMobile && <Divider flexItem orientation="vertical"/> }
         <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <ListItem sx={{gap:'3%'}}>
           <ListItemAvatar sx={{minWidth:'unset'}}>
             {stateRef.current?.[[data?.[0]?.ProjectID] + "_partner"]?stateRef.current?.[[data?.[0]?.ProjectID] + '_projLead']:`No Project Partner`}
           </ListItemAvatar>
           <ListItemText primary={<Typography variant="subtitle2" sx={{lineHeight:1}}>{teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.firstName} {teamMember.get(projectMap.get(data?.[0]?.ProjectID)?.SalesState?.partnerID?.[0])?.lastName}</Typography>} secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1}}>{stateRef.current?.[[data?.[0]?.ProjectID] + "_partner"] && `Project Partner`}</Typography>} />
           {stateRef.current?.[[data?.[0]?.ProjectID] + "_partner"]&&stateRef.current.partnerContact}
         </ListItem>
       </Item>
       </Grid>
       <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
       <Item sx={{display: 'flex', justifyContent: 'space-evenly',padding:0}}>
         <Typography sx={{padding: '2% 1%',lineHeight:1.1}}>Signed Name: {data?.[0]?.TransferRequests?.[0]?.signedName}</Typography>
         <Divider flexItem orientation="vertical"/>
         <Typography sx={{padding: '2% 1%',lineHeight:1.1}}>Delivery Date: {dayjs(data?.[0]?.TransferRequests?.[0]?.deliveryDate).format('lll')}</Typography>
         </Item>
       <Item>
         <Card sx={{backgroundColor: '#121212', margin: 'auto', width: '70%'}}>
           <Typography sx={{justifyContent: 'start', display: 'flex', padding: '1%'}}>
           Signature:
           </Typography>
           <LazyLoadImage
             alt={'IMAGE'}
             effect="blur"
             loading='lazy'
             threshold={10000}
             height={'100%'}
             width={'100%'}
             style={{objectFit:'contain'}}
             key={'MainLogo'}
             onError={(e)=>{ e.target.src = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.123rf.com%2Fstock-photo%2Fsignature_pen.html&psig=AOvVaw13OqtfOAS-3U7rMlj6PSH5&ust=1704926789756000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCJiDrLCx0YMDFQAAAAAdAAAAABAl'}}
             src={`https://www.dev-services.ekc.app/profiles/inventory/${data?.[0]?.InventoryID}/${data?.[0]?.InventoryID}/transfer/${data?.[0]?.TransferRequests?.[0]?.requestor}${data?.[0]?.InventoryID}${dayjs(data?.[0]?.TransferRequests?.[0]?.timestamp).format()}.png`||'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.123rf.com%2Fstock-photo%2Fsignature_pen.html&psig=AOvVaw13OqtfOAS-3U7rMlj6PSH5&ust=1704926789756000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCJiDrLCx0YMDFQAAAAAdAAAAABAl'}
           />
         </Card>
       </Item>
       </Grid>
       </Grid>
      </AccordionDetails>
    </Accordion>
    <Box sx={{padding: '1%'}}>
    <Card sx={{marginTop: '2px'}}>
     <Box sx={{margin: '10px'}}>
     <Card sx={{backgroundColor: '#1A2027', margin: 'auto',lineHeight:1}}>
     <Typography variant="h4" sx={{display: 'flex', padding: '2%', justifyContent: 'center'}}>Products in Delivery</Typography>
     </Card>
     </Box>
     <Divider variant="middle" />
     <Box sx={{display:'flex',padding:'2%',flexWrap:'wrap', alignItems: 'center', justifyContent: 'center'}}>
    { data && data?.[0] !== undefined && data.flatMap(({ProductID})=>{
      if(ProductID.length > 1) return ProductID.flatMap((tmp)=>stateRef.current?.['item_'+[tmp]])
      else return stateRef.current?.['item_'+[ProductID]]
  })
   } </Box>
   </Card>
   </Box>
    </Box>,stateRef.current?.productCard?.length > 0 && stateRef.current?.productCard?.flatMap(x=>x)];

    }

    export default DeliveriesFormPopup;