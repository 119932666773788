import React from "react";
import useState from 'react-usestateref';
import { styled } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

 const Switcher=({That,viewData,action})=>{
  const {label,list,defaultValue,state,pramPass,defaultChecked,style,labelPlacement} = viewData
  const [parentState, setParentState, parentStateRef] = That
  const [checkedOld, setChecked, checkedRef] = useState(defaultValue?defaultValue:false);


  const stateChgMap=({target})=>{ 
  setChecked(target.checked)
  setParentState(p=>({...p,...{[state]:target.checked}}))
  if(action) action.forEach((x)=>x({[state]:target.checked,pramPass:pramPass})) 
  }

return <FormGroup style={style||{}}>
      
      <FormControlLabel
        control={
        <IOSSwitch 
          sx={{ m: 'auto' }} 
          defaultChecked={defaultChecked}
          checked={checkedRef.current}
          onChange={stateChgMap}
           />
        }
        label={<Typography variant="subtitle2" sx={{lineHeight:1.1}}>{label}</Typography>}
        labelPlacement={labelPlacement||"top"}
        sx={{gap:'6px'}}
      />
    </FormGroup>
  }

  export default Switcher

