import React, {useEffect,useTransition,useRef, Suspense, memo, useCallback } from "react";
import { useDebouncedCallback, useDebounce } from 'use-debounce';
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {Box,Grow, Grid, Typography, Paper, InputBase, Divider,IconButton, Badge, Collapse, Slide, ListItemAvatar, Chip } from '@mui/material';
  import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
  import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
  import ViewListIcon from '@mui/icons-material/ViewList';
  import GridViewIcon from '@mui/icons-material/GridView';
  import SearchIcon from '@mui/icons-material/Search';
  import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const Search = styled(Box)(({ theme }) => ({
  position: 'relative',
 // borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
   // 
   // backgroundColor: 'red',
  },
  paddingRight: 0,
  paddingLeft: 27, //moblie ? 0 : 27,
  width: '100%',
 // minWidth: '115px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,//theme.spacing(1),
    width: 'auto',
  },
}));

export default function SearchBar({That,globalState,globalWrn,waveLoad,useIsMobile,handlePopupClose,divisionFilterChange,dbUpdateValidate,dynamicComponent,dynamicMapData,doesIDExist}){
//const {That, globalState, viewData, action,divisionFilterChange,dbUpdateValidate,dynamicComponent} = props
const [appState, setAppState] = useAtom(globalState.appState);
const mainState = useState({secure:true});
const outputState = useState(Object);
const [, setState, {current: stateRef}] = mainState;
const [divisionMap] = useAtom(globalState.divisionMapState);
const [UserMap, setUserMap] = useAtom(globalState.userMapState);
const [,setProductMap] = useAtom(globalState.productMapState);
const [projectMap, setProjectMap] = useAtom(globalState.projectMapState);
const [isPending] = useTransition();
const [, setPopup] = useAtom(globalState.popupState)
const [, setOutput, {current: outputRef}] = outputState
const module = useRef(null)
const fieldState = useState(Object)

const [appSocket] = useAtom(globalState.appSocketState)
const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);
var socketIO = appSocket.get('socketIO')

const locationSet=({customerLoc})=>{
  setOutput(p=>({...p, customerLoc:{...customerLoc} }))
};

const projectsView=(val)=>{
  setUserMap(p=>{p.set('projectsView',val);return new Map(p)})
  dbUpdateValidate({model:'User',query:true,update:{[`local.projectsView`]:val}},(x)=>{})
}

useEffect(() =>{ 
setState(p=>({...p,
    editProj: dynamicComponent('editProj','component',{projID:{},ref:'module'},That),
    companyNameField: dynamicComponent('InputField','elements',{state:'companyName',label:'Company Name',multiline:true},outputState),
    ClientField: dynamicComponent('AutoComplete','elements',{state:'clientID',label:'Client',defaultValue: latestState('clientID'),model:'Clients',query:{},filter:{local:1}, addEntry:addPartner},outputState),
    customerLoc: dynamicComponent('geoSuggest','component',{state:'customerLoc',label: defaultValue('CustomerLoc','Client Address')?.label ,limit:4,onBlur:false},mainState,[locationSet]),
    contractNum: dynamicComponent('InputField','elements',{state:'contractNumb',label:'Contract Number',multiline:true},outputState),
    division: dynamicComponent('InputField','elements',{state:'clientDivision',label:'Client Division/District',multiline:true},outputState),
    BOMReq:<BootstrapTooltip placement="bottom" disableFocusListener disableTouchListener title="BOM Request"><IconButton key={"TwoAndAHalf"} aria-label="Add BOM" sx={{p:'1%'}} onClick={BOMOrderReq}><AddShoppingCartIcon/></IconButton></BootstrapTooltip>,
    RunAudit: <BootstrapTooltip placement="bottom" disableFocusListener disableTouchListener title="Run Audit"><IconButton key={"TwoAndThreeQuarters"} aria-label="Run Audit" onClick={()=>setAppState(p=>{
        console.log(p)
        p.set('modulePop',dynamicComponent('RunInventoryAudit','elements',{state:'runAudit'},mainState))
        return new Map(p)
        })}><AnalyticsIcon/></IconButton></BootstrapTooltip>
  }))
  },[]);

  useEffect(()=>{
  if(divisionMap.size>0) setState(p=>({...p,
      divFilt: dynamicComponent('DivisonFilter','elements',{state:'divisionFilter',label:'filter Label',DefaultIcon:PlaylistAddCheckIcon},That,[(x)=>divisionFilterChange(x)]),
      }))
  },[divisionMap.size]);

 useEffect(()=>{
  var userDiv = UserMap.get('division')
  setState(p=>({...p,
    Add: <BootstrapTooltip placement="bottom" disableFocusListener disableTouchListener title={['Inventory'].includes(UserMap.get('pageStamp').active)?"Add Inventory Item":['Tickets'].includes(UserMap.get('pageStamp').active)?"Add Ticket":"Add Project"}><IconButton key={"ThirdOne"} aria-label="Add Opportunity" sx={{p:'1%'}} onClick={()=>{ 
      if(['Projects','Sales', 'Tickets'].includes(UserMap.get('pageStamp').active)) setAppState(p=>{
          p.set('modulePop',dynamicComponent('editProj','component',{projID:{DivisionID:userDiv,modelType:divisionMap.get(userDiv)?.teamName},ref:module},That))
          return new Map(p)
          })
      if(['EKC360'].includes(UserMap.get('pageStamp').active)) addEKC360Contract()
      if(['Inventory'].includes(UserMap.get('pageStamp').active)) addInventory()
      }} >
      <AddCircleOutlinedIcon sx={{fontSize:'30px'}}/>
    </IconButton></BootstrapTooltip>
}))
},[UserMap.get('pageStamp')?.active]);

 useEffect(() =>{ 
  var list = [{primary:'List View',icon:<ViewListIcon/>},{primary:'Grid View',icon:<GridViewIcon/>},{primary:'B.O.M. Request',icon:<AddShoppingCartIcon/>}] // Calendar
  if(UserMap.get('level')<=5) list.push({primary:'Manage Forms',icon:<CheckIcon/>})
  if(['Projects','Sales','EKC360','Inventory'].includes(UserMap.get('pageStamp').active)) list.push({primary:'Add '+ UserMap.get('pageStamp').active,icon:<AddCircleOutlinedIcon/>})
  
  setState(p=>({...p,
     optionsDrp: dynamicComponent('DropDown','elements',{state:'optionsHeader', list:list, replace:true },That,[(x)=>{
          var obj ={
            'Calendar': ()=>setAppState(p=>{
              p.set('modulePop',dynamicComponent('reactCal','component',{ref:'module'},That),(k,v)=>setState(p=>({...p,[k]:v})))
              return new Map(p)
              }),
            'List View': ()=>projectsView('jsListView'),
            'Grid View': ()=>projectsView('jsGridView'),
            'Manage Forms': ()=>setState(p=>({...p,drawerState: !p.drawerState})),
            'Add Sales': ()=>setAppState(p=>{
              p.set('modulePop',dynamicComponent('editProj','component',{projID:{},ref:'module'},That),(k,v)=>setState(p=>({...p,[k]:v})))
              return new Map(p)
              }),
            'Add Projects': ()=>setAppState(p=>{
              p.set('modulePop',dynamicComponent('editProj','component',{projID:{},ref:'module'},That),(k,v)=>setState(p=>({...p,[k]:v})))
              return new Map(p)
              }),
            'Add Inventory': addInventory,
            'B.O.M. Request': BOMOrderReq,
          }
          obj[x.optionsHeader]()
        }])
    }))
   },[UserMap.get('pageStamp').active])

const defaultValue=(x,y)=> (stateRef.x) ? stateRef.x : y;

function latestState(x){
   return outputRef[x] || "Client"
 }

const addPartner=()=>{
  setState(p=>({...p,
    partnerFormBuilder : dynamicComponent('PartnerField','elements',{state:'newPart'},fieldState),
  }))

  setPopup(p=>({...p,
    open:true,
    title:`Add Partner or Client`,
    description: `Do not add Staff, they will need to create an account.`,
    inputField: stateRef.partnerFormBuilder,
    handleClose: ()=>{handlePopupClose()},
    nameInputSets: {},
}))  
}

useEffect(()=>{
  if( ['clientID', 'contractNumb', 'clientDivision', 'customerLoc','companyName'].every(x=> Object.keys(outputRef).includes(x) ) ) setPopup(p=>({...p, handleSubmit: ()=>{
    socketIO.emit('createClient360',{submitObj:{...outputRef}},({success, info})=>{ 
      if(success){ 
        handlePopupClose()
        globalWrn('success',`Support Contract was created for: ${outputRef.companyName}.`)
      } else globalWrn('error',`There was an error: ${info}.`)
    })
  } })) 
  if(outputRef.companyName) setPopup(p=>({...p,title:outputRef.companyName}))
},[outputRef]);

const addEKC360Contract=()=>{
  setPopup(p=>({...p,
    open:true,
    zIndex: 2,
    title:`Add New Company`,
    //description: 'Your Deliveries will be amazing.', {dynamicComponent('EKC360_AddCompany','elements',{state:'AddCompany'},outputState)}
    inputField: companyScripts(),
    handleClose: handlePopupClose,
    nameInputSets: {},
}))
};

const addInventory=()=>{
  setPopup(p=>({...p,
    open:true,
    zIndex: 2,
    title:`Add Inventory`,
    description: 'Your Inventory will be amazing.',
    inputField: dynamicComponent('AddInventory','elements',{state:'inventoryScripts', isStock: true},outputState),
    handleClose: handlePopupClose,

    nameInputSets: {},
}))
};

const Item = styled(Paper)(({ theme })=>({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: 0, //theme.spacing(1)
  textAlign: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  color: theme.palette.text.secondary,
}));

const useAndMobile = useIsMobile(560);

const companyScripts=()=> <Box>
<Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
    <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
    <Item sx={{'& > *':{flex:1}}}><Suspense fallback={waveLoad()}>
    {stateRef.companyNameField}
    </Suspense></Item>
    </Grid>
    <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
    <Item sx={{'& > *':{flex:1}}}><Suspense fallback={waveLoad()}>
    {stateRef.ClientField}
    </Suspense></Item>
    </Grid>
    <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
    <Item sx={{'& > *':{flex:1}}}><Suspense fallback={waveLoad()}>
    {stateRef.contractNum}
    </Suspense></Item>
    </Grid>
    <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
    <Item sx={{'& > *':{flex:1}}}><Suspense fallback={waveLoad()}>
    {stateRef.division}
    </Suspense></Item>
    </Grid>
    <Grid item xs={12}>
    <Item sx={{'& > *':{flex:1}}}><Suspense fallback={waveLoad()}>
      <Box sx={{margin:'auto', padding:'1%'}}>
    {stateRef.customerLoc}
    </Box>
    </Suspense></Item>
    </Grid>
    </Grid>
  </Box>;

useEffect(()=>{
  if(outputRef.BOMProjID) setPopup(p=>({...p,
      open:true,
      zIndex: 1,
      title:`B.O.M. Order Reqest`,
      width:'75vw',
      description:`You will need to complete all fields to submit`,
      inputField: dynamicComponent('PurchaseReq','elements',{state:'purchaseReq', ProjectID:outputRef.BOMProjID},outputState),
    }))

},[outputRef.BOMProjID]);

const BOMOrderReq=()=>{
  var socketQueryIO = appSocketQuery.get('socketQueryIO');
  Array.from([...divisionMap],([k,{teamName}])=>
    socketQueryIO.emit('queryDB',{model:'Projects',idConvert:'$nin',query:{'_id':Array.from(projectMap).flatMap(([k])=>k),'Status':{'$in':['Sales','Project']}},modelType:teamName},(items)=>
 items.filter(({_id})=>_id).forEach(x=>{
    delete x.Logg;
    x?.ProjectState?.teamLogg?.forEach(y=> delete y.logg);
    dynamicMapData({ [x._id]: x }, projectMap, setProjectMap);
  })
  ));

  if(!appState.get('ProductSet')) socketQueryIO.emit('queryDB',{model:'Products',query:{}},(items)=>
      Promise.all( items.filter(({_id})=>_id).flatMap(x=>setProductMap(p=>p.set(x._id,x))))
    );
      
  setState(p=>({...p,
    purchaseRequestField: dynamicComponent('PurchaseReq','elements',{state:'purchaseReq'},mainState),
  }))
};



const BootstrapTooltip = styled(({ className, useStyles, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

useEffect(() =>{ 
 if(divisionMap.size === 0) return
setState(p=>({...p,
   divCat: [].concat(...Array.from([...divisionMap],([k,v])=>v.category)).filter((item,index,inputArray)=>inputArray.indexOf(item) === index && item !== undefined),
   list: [].concat(...Array.from([...divisionMap],([k,v])=>({[v.teamName]:{id:k,cat:v.category}})))
  }))
 },[divisionMap]);

return [<Paper key={"firstOne"} ref={module} component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',maxWidth:'72vw', flex:1 }} >
      <Box sx={{display:'flex',gap:'8%'}}>
      <AutoComplete globalState={globalState} parentRef={module} isPending={isPending} mainState={mainState} That={That} dynamicComponent={dynamicComponent} dynamicMapData={dynamicMapData} doesIDExist={doesIDExist}/>
      </Box>
      { stateRef.divFilt }
      { !globalState.useIsMobile(620) ? [
      ['Sales','Projects'].includes(UserMap.get('pageStamp').active) && <Divider key={'k2'} sx={{ height: 28, m: 0.5 }} orientation="vertical" /> ,
      <IconButton aria-label="ListView" sx={{p:'1%'}} onClick={()=>projectsView('jsListView')}>
        <ViewListIcon sx={UserMap.get('projectsView') === 'jsListView' ? {color:'#32a7e2',fontSize:'30px'} : {fontSize:'30px'}} />
      </IconButton >,
        <IconButton aria-label="GridView" sx={{p:'1%'}} onClick={()=>projectsView('jsGridView')}>
      <GridViewIcon sx={UserMap.get('projectsView') === 'jsGridView' ? {color:'#32a7e2',fontSize:'30px'} : {fontSize:'30px'}} />
      </IconButton>,
      [0,1,2,3,4].includes(UserMap.get('level')) && [
      <Divider key={'k2.5'} sx={{ height: 28, m: 0.5 }} orientation="vertical" />,
      stateRef.BOMReq],
      ['Inventory'].includes(UserMap.get('pageStamp').active) && [0].includes(UserMap.get('level')) && [
      <Divider key={'k2.51'} sx={{ height: 28, m: 0.5 }} orientation="vertical" />,
      stateRef.RunAudit
     ],
     ['Projects','Sales','Tickets','EKC360','Inventory'].includes(UserMap.get('pageStamp').active) && [0,1,2,3,4,5,6].includes(UserMap.get('level')) && [
      <Divider key={'k3'} sx={{ height: 28, m: 0.5 }} orientation="vertical" />,
      stateRef.Add
      ]
     ] : [<Divider key={'k4'} sx={{ height: 28, m: 0.5 }} orientation="vertical" />, stateRef.optionsDrp]
    }
    </Paper>,
    <Box key={"FourthOne"}>{/*{stateRef.formBuilder}*/}</Box>, stateRef.purchaseRequestField, stateRef.runAudit ]
};

const Root = styled(Box)(
  ({ theme }) => `color: ${ theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)' }; font-size: 14px;`,
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return <Box {...other}>
      <Typography>{label}</Typography>
      <CloseIcon onClick={onDelete} />
    </Box>;
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
   // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    minWidth: '53px',
    [theme.breakpoints.up('sm')]: {
      width: '5.64ch',
      //paddingRight:'24px',
      '&:focus': {
        paddingLeft: '5%',
        width: '25ch',
       // paddingLeft: '8%'
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
  },
}));

export const AutoComplete = memo(({globalState,parentRef,mainState,dynamicComponent,dynamicMapData,isPending,doesIDExist}) => {
// const [teamMember] = useAtom(globalState.teamMemberState);
// const [inventoryMap] = useAtom(globalState.inventoryMapState);
// const [divisionMap] = useAtom(globalState.divisionMapState);
// const [projectMap] = useAtom(globalState.projectMapState);
// const { scrollStat, onScrollHandler } = globalState.useScroll();
// const [UserMap] = useAtom(globalState.userMapState);
// const [, setAvatar, {current: avatarRef}] = useState({});

  const [appState, setAppState] = useAtom(globalState.appState);
  const [, setState, {current:stateRef}] = mainState;
  const [, setOpen,{current:open}] = useState(true);
  const module = useRef();
  const resultRef = useRef();
  const [, setOptions] = useState([]);
  const [, setText,{current:textRef}] = useState(null);
  const [value] = useDebounce(textRef, 1000);


  var moblie = globalState.useIsMobile(620);

 /* const debounced = useDebouncedCallback((value) => {
    if(value.length===0) setAppState(p=>{ p.delete('search'); return new Map(p) })
    else setAppState(p=>{ p.set('search',value); return new Map(p); })
  }, 500);*/

  useEffect(()=>{
    if(value){
      if(value.length===0) setAppState(p=>{ p.delete('search'); return new Map(p) })
      else setAppState(p=>{ p.set('search',value); return new Map(p); })
    }
  },[value])

  useEffect(() => {
    if(open) globalState.closePopup({container:module.current},(v,e)=>{
      if(e.hasAttribute("role")) setTimeout(() => {
        setOpen(false); setOptions([]);
      }, 500);
       else setOpen(false); setOptions([]) 
    })
  }, [open]);

  useEffect(() => {
    if(stateRef.fade===true) globalState.closePopup({container:resultRef.current},(v,e)=>{ //console.log('closePopup::',v,e)
    if(v) setState(p=>({...p,fade: false})) 
    /*  setTimeout(() => {
        if(!stateRef.focus) setState(p=>({...p,fade: false})) 
      }, 250);*/
    })
  },[stateRef.fade]);

  const onInputChange = ({value}) => {
    if(stateRef.fade===false) setState(p=>({...p,fade: true}));
    setText(value)
   // startTransition(()=>{  debounced(value); });
  };

  /* const autoCompleteList = useCallback(()=>{
      var groupedOptions = appState.get("searchResult")?.flatMap(({data})=>data)?.filter(({_id,catagory,projID,avatar})=>{ 
        if(!avatarRef[_id+'avatar']) setAvatar(p=>({...p,[_id+'avatar']: dynamicComponent('AvatarBubble','elements',{state:'sugestUser',label:'missing person',userID:_id, isInventory: ['Inventory','Product'].includes(catagory) && UserMap.get('pageStamp').active === 'Inventory' ? true : false, Catagory:catagory,projID,AvatarLink:avatar},mainState) }))
        if(['Sales','Projects','EOS'].includes(UserMap.get('pageStamp').active) && catagory ==='Project') return true
        if(['Inventory','Product'].includes(catagory) && UserMap.get('pageStamp').active === 'Inventory' ) return true
        if(['Clients','Partners'].includes(catagory) && UserMap.get('pageStamp')[UserMap.get('pageStamp').active] == "External" && UserMap.get('pageStamp').active === 'Directory') return true
        if(['Team'].includes(catagory) && UserMap.get('pageStamp')[UserMap.get('pageStamp').active] == "Internal" && UserMap.get('pageStamp').active === 'Directory') return true
        if(['Sites'].includes(catagory) && UserMap.get('pageStamp')[UserMap.get('pageStamp').active] == "Sites" && UserMap.get('pageStamp').active === 'Directory') return true
        return false
      })?.flatMap((x, index) =>{ 
          var {_id,catagory,projID} = x
  
          var styling = {display:'flex',flexDirection:'column',margin:'.5%',padding:'.5%',flex:1,borderRadius:'8px',gap:'5px'};
          if(catagory === 'Team'){
            styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(255, 158, 66 / 65%), inset 1px 1px rgb(255, 158, 66 / 65%)',backgroundColor:'rgba(50, 167, 226,.45)'});
            if(teamMember.has(_id)){
              var {division} = teamMember.get(_id);
              if(division) var secondaryLabel = `${divisionMap.get(division)?.divisionLabel || divisionMap.get(division)?.teamName || '...pending'}` 
            }
          }
          if(catagory === 'Clients') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(255, 158, 66 / 65%), inset 1px 1px rgb(255, 158, 66 / 65%)',backgroundColor:'rgba(255, 158, 66,.45)'})
          if(catagory === 'Partners') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(255, 117, 81 / 65%), inset 1px 1px rgb(255, 117, 81 / 65%)',backgroundColor:'rgba(255, 117, 81,.45)'})
          if(catagory === 'Project'){ 
            styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(205, 144, 139,.45)'});
            if(projID.DivisionID) var secondaryLabel = `${divisionMap.get(projID.DivisionID)?.divisionLabel || divisionMap.get(projID.DivisionID)?.teamName || '..pending' }` 
          }
          if(catagory === 'Sites') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(205, 144, 139,.45)'})
          if(catagory === 'Product') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(61, 164, 171,.45)'})
          if(catagory === 'Inventory'){
             styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(254, 138, 113,.45)'})
             if(inventoryMap.has(_id)) var {DivisionID,ProjectID} = inventoryMap.get(_id)
             if(DivisionID && divisionMap.has(DivisionID)) var secondaryLabel = `${divisionMap.get(DivisionID)?.divisionLabel || divisionMap.get(DivisionID)?.teamName} (${projectMap.get(ProjectID?.[0])?.ProjectTitle||"N/A"})` 
          }
  
          return [<Divider flexItem key={`searched_${index}`}/>,
          <Grow in={true} key={`searchedGrow_${index}`}>
           <Box sx={styling} key={`searchedBox_${index}`}>
          <ListItemAvatar sx={{margin:'auto'}}>
          <Badge color="secondary" badgeContent={<Typography variant="caption">{catagory}</Typography>} anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}} sx={{"& .MuiBadge-badge": {color: "#fff"}}}>
           { avatarRef[_id+'avatar'] }
           </Badge>
           </ListItemAvatar>
          <Typography onClick={()=> setAppState(p=>{ p.set('search',x['Full Name']); return new Map(p); })} variant="subtitle2" sx={{textShadow:'1px 1px #2e2e2e', lineHeight: 1, textAlign: 'center', margin: 'auto',paddingTop:'clamp(3px,8%,8px)',
         minWidth:'150px',
         display: '-webkit-box',
         WebkitLineClamp: 2,
         WebkitBoxOrient: 'vertical',
         overflow: 'hidden',
         textOverflow: 'ellipsis' 
         }}>{ x['Full Name'] || x['fullName'] }</Typography>
          { secondaryLabel && <Chip color="primary" size="small" label={secondaryLabel} sx={{height:'unset',textShadow:'0 0 transparent'}} />}
        </Box>
          </Grow>,
          <Divider key={`lastforlist_${index}`} orientation="vertical" variant="middle" flexItem />
        ]})

  return [appState.get("searchResult")?.flatMap(({data})=>data)?.length>0 && appState.has("search") && 
    <Box key={"serch"} sx={{position:'absolute',right:0,maxWidth:'75%',backgroundColor:'rgba(0,0,0,.7)',backdropFilter:'blur(15px)',margin: '2.5% 0 0',zIndex:9999,borderRadius:'8px',padding:'.5%',display:'flex',flexDirection:'column', gap:'4px'}}>
      <Box onScroll={onScrollHandler} sx={{display:'flex',flexWrap:'wrap',maxHeight:'29vh',overflow:'auto',...scrollStat.style}}>
        {groupedOptions}
    </Box>
    <Box sx={{display:'flex',justifyContent:'center',gap:'3px'}}>
    {groupedOptions && appState.get("searchResult")?.flatMap(({catagory,data})=>
     <Chip color="warning" size="small" icon={<SearchIcon sx={{fontSize:'12px!important'}} />} sx={{textShadow:'none'}} label={ <Typography variant="caption">{data.length} {catagory}</Typography>} />
    )}
    </Box>
    </Box>,stateRef.productCard]
  },[appState.get("searchResult"),appState.get("search"),UserMap,teamMember,inventoryMap,divisionMap,projectMap,]); */


return <Root ref={module} sx={{overflow:'hidden'}}>
  <Slide direction="left" in={true} container={module.current} timeout={{ enter: 2000 }}> 
    <Search sx={moblie&&{pl:0}}>
    <StyledInputBase
        placeholder={"Search…"}
        inputProps={{'aria-label':'search'}}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault() }
        onFocus={()=> setState(p=>({...p,fade:true,focus:true})) }
        onBlur={()=> setState(p=>({...p,focus:false})) }
        onChange={({target})=>{ console.log('CHanging', target.value ); onInputChange(target); setState(p=>({...p,searchContent:target.value}))}}
        sx={{color: appState.has("search") ? '#ff9d15': 'inherit'}}
        defaultValue={appState.has("search")?appState.get("search"):''}
        startAdornment={ (appState.has("search") && appState.get("search")!==stateRef.searchContent ) || isPending ? <Typography variant="caption" sx={{pl:'3%'}}>Searching <CircularProgress color="inherit" size={20} /></Typography> : <SearchIcon sx={{color:'rgba(255,255,255,.25)',paddingLeft:'6%',transform:'translateX(25%)',fontSize:'16px'}}/>}
        endAdornment={<Box sx={{display:'flex'}}>
          { appState.has("search") && <CancelRoundedIcon sx={{cursor:'pointer',color:'#ff9d15',transform:'translateX(-20%)'}} onClick={(e)=>{ module.current.querySelector('input').value = ''; setText(null); setAppState(p=>{p.delete('searchResult');p.delete('search');return new Map(p)})}}/> }
         </Box>}
    />
  </Search>
  </Slide>
  <Box sx={{ display: 'flex' }} ref={resultRef}>
  <Collapse in={stateRef.fade||false}>
    {<AutoCompleteLists dynamicComponent={dynamicComponent} mainState={mainState} globalState={globalState} parentRef={parentRef} dynamicMapData={dynamicMapData} doesIDExist={doesIDExist}/>}
  </Collapse>
</Box>
</Root>
});

const AutoCompleteLists = ({globalState,mainState,dynamicComponent,doesIDExist}) => {
  const [teamMember] = useAtom(globalState.teamMemberState);
  const [appState, setAppState] = useAtom(globalState.appState);
  const [inventoryMap] = useAtom(globalState.inventoryMapState);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [projectMap] = useAtom(globalState.projectMapState);
  const { scrollStat, onScrollHandler } = globalState.useScroll();
  const [UserMap] = useAtom(globalState.userMapState);
 // const [, setParent] = mainState;
  const [, setState, {current: stateRef}] = useState({});
  const [, setAvatar, {current: avatarRef}] = useState({});

  useEffect(()=>{
    var organized = appState.get("searchResult")?.flatMap(({data})=>data)?.filter(({_id,catagory,projID,avatar})=>{ 
      if(!avatarRef[_id+'avatar']) setAvatar(p=>({...p,[_id+'avatar']: dynamicComponent('AvatarBubble','elements',{state:'sugestUser',label:'missing person',userID:_id, isInventory: ['Inventory','Product'].includes(catagory) && UserMap.get('pageStamp').active === 'Inventory' ? true : false, Catagory:catagory,projID,AvatarLink:avatar},mainState) }))
      if(['Sales','Projects','EOS'].includes(UserMap.get('pageStamp').active) && catagory ==='Project') return true
      if(['Inventory','Product'].includes(catagory) && UserMap.get('pageStamp').active === 'Inventory' ) return true
      if(['Clients','Partners'].includes(catagory) && UserMap.get('pageStamp')[UserMap.get('pageStamp').active] == "External" && UserMap.get('pageStamp').active === 'Directory') return true
      if(['Team'].includes(catagory) && UserMap.get('pageStamp')[UserMap.get('pageStamp').active] == "Internal" && UserMap.get('pageStamp').active === 'Directory') return true
      if(['Sites'].includes(catagory) && UserMap.get('pageStamp')[UserMap.get('pageStamp').active] == "Sites" && UserMap.get('pageStamp').active === 'Directory') return true
      return false
    });

    setState(p=>({...p,
      organized: organized 
    }));

  },[appState.get("searchResult")]);

  useEffect(()=>{
    setState(p=>({...p,
      groupedOptions: p.organized?.flatMap((x, index) =>{ 
        var {_id,catagory,avatar,projID} = x

        var styling = {display:'flex',flexDirection:'column',margin:'.5%',padding:'.5%',flex:1,borderRadius:'8px',gap:'5px'};
        if(catagory === 'Team'){
          styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(255, 158, 66 / 65%), inset 1px 1px rgb(255, 158, 66 / 65%)',backgroundColor:'rgba(50, 167, 226,.45)'});
          if(teamMember.has(_id)){
            var {division} = teamMember.get(_id);
            if(division) var secondaryLabel = `${divisionMap.get(division)?.divisionLabel || divisionMap.get(division)?.teamName || '...pending'}` 
          }
      }
        if(catagory === 'Clients') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(255, 158, 66 / 65%), inset 1px 1px rgb(255, 158, 66 / 65%)',backgroundColor:'rgba(255, 158, 66,.45)'})
        if(catagory === 'Partners') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(255, 117, 81 / 65%), inset 1px 1px rgb(255, 117, 81 / 65%)',backgroundColor:'rgba(255, 117, 81,.45)'})
        if(catagory === 'Project'){ 
          styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(205, 144, 139,.45)'});
          if(projID.DivisionID) var secondaryLabel = `${divisionMap.get(projID.DivisionID)?.divisionLabel || divisionMap.get(projID.DivisionID)?.teamName || '..pending' }` 
      }
        if(catagory === 'Sites') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(205, 144, 139,.45)'})
        if(catagory === 'Product') styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(61, 164, 171,.45)'})
        if(catagory === 'Inventory'){
           styling = Object.assign(styling,{boxShadow:'inset -1px -1px rgb(205, 144, 139 / 65%), inset 1px 1px rgb(205, 144, 139 / 65%)',backgroundColor:'rgba(254, 138, 113,.45)'})
           if(inventoryMap.has(_id)) var {DivisionID,ProjectID} = inventoryMap.get(_id)
           if(DivisionID && divisionMap.has(DivisionID)) var secondaryLabel = `${divisionMap.get(DivisionID)?.divisionLabel || divisionMap.get(DivisionID)?.teamName} (${projectMap.get(ProjectID?.[0])?.ProjectTitle||"N/A"})` 
        }

        return [<Divider flexItem key={`searched_${index}`}/>,
        <Grow in={true} key={`searchedGrow_${index}`}>
         <Box sx={styling} key={`searchedBox_${index}`}>
        <ListItemAvatar sx={{margin:'auto'}}>
        <Badge color="secondary" badgeContent={<Typography variant="caption">{catagory}</Typography>} anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}} sx={{"& .MuiBadge-badge": {color: "#fff"}}}>
         { avatarRef[_id+'avatar'] }
         </Badge>
         </ListItemAvatar>
        <Typography onClick={()=> setAppState(p=>{ p.set('search',x['Full Name']); return new Map(p); })} variant="subtitle2" sx={{textShadow:'1px 1px #2e2e2e', lineHeight: 1, textAlign: 'center', margin: 'auto',paddingTop:'clamp(3px,8%,8px)',
       minWidth:'150px',
       display: '-webkit-box',
       WebkitLineClamp: 2,
       WebkitBoxOrient: 'vertical',
       overflow: 'hidden',
       textOverflow: 'ellipsis' 
       }}>{ x['Full Name'] || x['fullName'] }</Typography>
        { secondaryLabel && <Chip color="primary" size="small" label={secondaryLabel} sx={{height:'unset',textShadow:'0 0 transparent'}} />}
      </Box>
        </Grow>,
        <Divider key={`lastforlist_${index}`} orientation="vertical" variant="middle" flexItem />
      ]})
    }))
  },[stateRef.organized]);

return [appState.get("searchResult")?.flatMap(({data})=>data)?.length>0 && appState.has("search") && 
  <Box key={"serch"} sx={{position:'absolute',right:0,maxWidth:'75%',backgroundColor:'rgba(0,0,0,.7)',backdropFilter:'blur(15px)',margin: '2.5% 0 0',zIndex:9999,borderRadius:'8px',padding:'.5%',display:'flex',flexDirection:'column', gap:'4px'}}>
    <Box onScroll={onScrollHandler} sx={{display:'flex',flexWrap:'wrap',maxHeight:'29vh',overflow:'auto',...scrollStat.style}}>
      {stateRef.groupedOptions}
  </Box>
  <Box sx={{display:'flex',justifyContent:'center',gap:'3px'}}>
  {stateRef.organized && appState.get("searchResult")?.flatMap(({catagory,data})=>
   <Chip color="warning" size="small" icon={<SearchIcon sx={{fontSize:'12px!important'}} />} sx={{textShadow:'none'}} label={ <Typography variant="caption">{data.length} {catagory}</Typography>} />
  )}
  </Box>
  
  </Box>,stateRef.productCard]
}