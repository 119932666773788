import React, {Fragment, useEffect, useRef} from "react";
import { useAtom } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faPhone, faMobile, faEnvelopeOpenText, faUser } from '@fortawesome/free-solid-svg-icons';
import { useColorScheme } from '@mui/joy/styles';
import {Component_defultAvatar} from '../../components/component_avatar';

// import "./UserProfile.css"
import AspectRatio from '@mui/joy/AspectRatio';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import PanToolIcon from '@mui/icons-material/PanTool';
import {Divider} from '@mui/material';

import DuoIcon from '@mui/icons-material/Duo';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function UserProfile({globalState,mainState,globalWrn,socketIO,dynamicMapData,setOpen,globalAdd,dynamicComponent}) {
  const [, setState, stateRef] = mainState
  const [teamMember] = useAtom(globalState.teamMemberState)  
  const [UserMap] = useAtom(globalState.userMapState)
  const [activeSession] = useAtom(globalState.activeSessionState)
  const [appState, setAppState] = useAtom(globalState.appState)
  const userID = stateRef.current?.userSel?.avatar
  const { mode, setMode } = useColorScheme();
  const socketRef = useRef();

  useEffect(()=>{ 
    if(mode!=='dark')setMode('dark')
  },[])

const emailFunc = (email) => {
if(window.cordova) window.cordova.plugins.email.open({ 
 // app: 'mailto', 
  to: email,
  subject: 'Sent with the EKCapp' 
  })
else{ 
  const subject = 'Hey there from the EKC app';
  const emailBody = "Hello ...";
  document.location =
  "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
}
} 

const callFunc = (x) => {
 var numb = x.replace(/\D+/g, "")
  if(window.cordova){ 
  window.cordova.plugins.CordovaCall.callNumber( numb, 
  function(success) { alert('Dialing succeeded') },
  function(err) {
    if (err == "empty") alert("Unknown phone number")
    else alert("Dialer Error:" + err)   
  });
  console.log('Cordova:',window?.cordova, numb)
 }
else window.location.href=`tel:${numb}`
}

const ChatMessge = () =>{
  setOpen(false)
  var roomID = socketIO.id+'_'+Date.parse(new Date())
  socketIO.emit("messageChat",{ 
    roomID: roomID,
    request: userID,
    requestor:UserMap.get('avatar'),
    reqSocketID:socketIO.id
    },({response,desc,userID})=>{
        if(response) globalWrn(response,`${desc} ${userID && teamMember.has(userID) ? `${teamMember.get(userID).firstName } ${teamMember.get(userID).lastName }`: ''}.`)
    })
  }

const videoChat =()=>{
dynamicMapData({chatter: stateRef.current?.userSel.avatar},appState,setAppState,(x)=>{ 
  setAppState(p=>new Map(p))
  setOpen(false)
})
}

const calendar=()=>{
  setOpen(false)
  globalAdd('modulePop',dynamicComponent('Agenda','elements',{state:'agendaUser',label:'missing',userID:[userID]},mainState))
}

useEffect(()=>{
  console.log("userID:", userID, "stateRef.current?.userSel:",stateRef.current?.userSel)
  setState(p=>({...p,...{
    userImage: `https://www.${process.env.REACT_APP_ENVIROMENT === "dev" ? "dev-" : ""}services.ekc.app/profiles/avatar/${stateRef.current?.userSel?.avatar}_avatar.jpg`
   }}))
},[userID])

return <Box sx={{ minHeight: 350, minWidth: 325 }}>
<Card
  variant="outlined"
  sx={(theme) => ({
    width: 'clamp(325px,50vw,100%)',
    gridColumn: 'span 2',
    flexDirection: 'row',
    flexWrap: 'wrap',
    resize: 'horizontal',
    overflow: 'hidden',
    gap: 'clamp(0px, (100% - 360px + 32px) * 999, 16px)',
    transition: 'transform 0.3s, border 0.3s',
    '&:hover': {
      borderColor: 'rgba(255,255,255,.35)',
      transform: 'translateY(-2px)',
    },
    '& > *': { minWidth: 'clamp(0px, (360px - 100%) * 999,100%)' },
  })}
>
  <AspectRatio
    variant="soft"
    sx={{
      flexGrow: 1,
      display: 'contents',
      '--AspectRatio-paddingBottom':
        'clamp(0px, (100% - 360px) * 999, min(calc(100% / (16 / 9)), 300px))',
    }}
  >
    <img
      alt="User Profile"
      src={stateRef.current.userImage}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
       // var erroImg = Component_defultAvatar()
          currentTarget.src = 'https://dev-services.ekc.app/scripts/images/blank-profile.png';
      }}
    />
  </AspectRatio>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      maxWidth: '200px',
      wordBreak: 'break-word',
    }}
  >
    <Box sx={{ display: 'flex',justifyContent:'space-between' }}>
      <Box>
        <Typography level="h2" sx={{ fontSize: 'md' }} mb={0.5}>
          {stateRef.current?.userSel?.firstName} {stateRef.current?.userSel?.lastName}
        </Typography>
        <Typography level="body2">{stateRef.current?.userSel?.department}</Typography>
      </Box>
      <Box sx={{justifyContent:'flex-end', display: 'flex',flexDirection: 'column'}}>
      <IconButton
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ ml: 'auto', alignSelf: 'flex-start' }}
      >
        { stateRef.current[stateRef.current?.userSel?.avatar+'-High'] ?
        <PanToolIcon sx={{color:'#AF8FE9'}}/> :
        <PanToolOutlinedIcon sx={{color:'#AF8FE9'}} onClick={()=>{
          setState(p=>({...p,...{[stateRef.current?.userSel?.avatar+'-High']: true}}))
          globalWrn('Success',`You have given ${stateRef.current?.userSel?.firstName} ${stateRef.current?.userSel?.lastName} a high five for their hard work!`)}}/>
     } </IconButton>
     { activeSession.has(stateRef.current?.userSel?.avatar) ? <Chip
        color="success"
        disabled={false}
        onClick={function(){}}
        size="sm"
        variant="outlined"
      >Online</Chip> : null }
      </Box>
    </Box>
    <AspectRatio
      variant="soft"
      sx={{
        '--AspectRatio-paddingBottom':
          'clamp(0px, (100% - 200px) * 999, 200px)',
        pointerEvents: 'none',
      }}
    >
      <img
        alt="UserProfile"
        src={stateRef.current.userImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
         // var erroImg = Component_defultAvatar()
           currentTarget.src= 'https://dev-services.ekc.app/scripts/images/blank-profile.png';
        }}
      />
    </AspectRatio>
    {stateRef.current?.userSel?.description ? 
    <Fragment><Box sx={{ display: 'flex', gap: 1.5, mt: 'auto' }}>
      <Avatar variant="outlined" color="neutral">
      <FontAwesomeIcon icon={faUser}/>
      </Avatar>
      <div>
        <Typography level="body2">Info:</Typography>
        <Typography fontWeight="lg" level="body3">
          {stateRef.current?.userSel?.description}
        </Typography>
      </div>
    </Box><Divider/></Fragment> : null }
    {stateRef.current?.userSel?.workPhone ? 
    <Box sx={{ display: 'flex', gap: 1.5, mt: 'auto' }}>
      <Avatar variant="outlined" color="neutral" onClick={()=>callFunc(teamMember.get(userID).workPhone)}>
      <FontAwesomeIcon icon={faPhone}/>
      </Avatar>
      <div>
        <Typography level="body2">Office:</Typography>
        <Typography fontWeight="lg" level="body2">
          {stateRef.current?.userSel?.workPhone}
        </Typography>
      </div>
    </Box> : null }
    {stateRef.current?.userSel?.mobilePhone ? 
    <Box sx={{ display: 'flex', gap: 1.5, mt: 'auto' }}>
      <Avatar variant="outlined" color="neutral" onClick={()=>callFunc(teamMember.get(userID).mobilePhone)}>
      <FontAwesomeIcon icon={faMobile}/>
      </Avatar>
      <div>
        <Typography level="body2">Mobile:</Typography>
        <Typography fontWeight="lg" level="body2">
          {stateRef.current?.userSel?.mobilePhone}
        </Typography>
      </div>
    </Box> : null }
    {stateRef.current?.userSel?.email ? 
    <Box sx={{ display: 'flex', gap: 1.5, mt: 'auto' }}>
      <Avatar variant="outlined" color="neutral" onClick={()=>{ console.log('clicked');emailFunc(teamMember.get(userID).email)}}>
      <FontAwesomeIcon icon={faEnvelopeOpenText}/>
      </Avatar>
      <div>
        <Typography level="body2">Email:</Typography>
        <Typography fontWeight="lg" level="body2">
          {stateRef.current?.userSel?.email}
        </Typography>
        </div>
    </Box> : null }

    <Divider />
        <Box sx={{display: 'flex', gap: 3, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', padding: "4px"}}>
          
          <IconButton>
        <DuoIcon onClick={videoChat}/>
        </IconButton>
        <IconButton>
        <TextsmsIcon onClick={ChatMessge}/>
        </IconButton>
          
        <IconButton>
        <CalendarMonthIcon onClick={calendar}/>
        </IconButton>
        </Box>
  </Box>
</Card>
</Box>
}