import React, {Suspense} from 'react';
import { Box,Button,Tab,IconButton,Typography,AppBar,Tabs} from '@mui/material';
import useState from 'react-usestateref';
import DeleteIcon from '@mui/icons-material/Delete';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ItemsListed({viewData,globalState,That,waveLoad}){
  const {tableState,valueTable,fieldState,bttnAction, disableDelete} = viewData
  const [,, tableStateRef] = tableState;
  const [,, fieldRef] = fieldState;
  const defaultVal = useState(0);
  const [, setValue, valueRef] = valueTable || defaultVal;
  const [,, parentRef] = That;
  const mobile520 = globalState.useWidthHeight(520);
  const theme = useTheme();

  const handleRemove = (item) => {
    //setTableState((prev) =>[...prev.filter((x) => x.ProjectID !== item)] );
    //if(tableStateRef.current.length === valueRef.current && valueRef.current!== 0 ) setValue(tableStateRef.current.length-1);
  };
  
  const handleChange = (e, v) =>{  setValue(v)};
  const handleChangeIndex = (index) => setValue(index);

  return <Box sx={{ height: '100%', flexGrow: 1, display:'flex',flexDirection: mobile520.mw ?'column':'row', overflow:'auto'}}>
  <AppBar position="static" sx={{width:'auto',flex:.34}}>
    <Button variant="contained" onClick={bttnAction} sx={{position:'sticky',top:0,left:0,zIndex:9}} >
    { parentRef.current.buttonTitle }
    </Button>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={valueRef.current}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          iconColor="primary"
          sx={{ borderRight: 1, borderColor: 'divider', height:'100%'}}
          aria-label="full width tabs example"
        >
          { tableStateRef.current.length>0 ? tableStateRef.current.map(({projLabel,ProjectID,icon},i)=>(
           <Tab disabled={false} label={projLabel} {...a11yProps(i)}
           sx={{ flexDirection:'row-reverse', justifyContent:'space-between',textAlign:'right',gap:'5%'}}
           iconPosition={ icon ? 'bottom' : 'top'}
           icon={icon ? icon : !disableDelete && <IconButton edge="end" aria-label="delete" title="Delete"onClick={()=> handleRemove(ProjectID)} >
            <DeleteIcon />
          </IconButton>
            } />
            )) : <Typography variant="button" {...a11yProps(0)} sx={{ textAlign:'right',lineHeight:3,fontSize:'12px'}}>{parentRef.current.noListLabel}</Typography> }
        </Tabs>
  </AppBar>
  <Box sx={{height:'100%',flex:1,"& > *":{height:'100%',width:'100%',"& > *":{height:'100%',width:'100%',"& > *":{height:'100%',width:'100%',display:'flex',"& > *":{margin:'auto',}}}}}}>
  <SwipeableViews axis={theme.direction === 'ltr'?'y':'y-reverse'} index={valueRef.current} onChangeIndex={handleChangeIndex} ignoreNativeScroll={true} disabled={true}>
    { tableStateRef.current.length>0 ? tableStateRef.current.map(({ProjectID,projLabel},i)=>
         valueRef.current === i ? <Suspense fallback={<Typography variant="button" sx={{margin:'auto',textAlign:'center'}}>{parentRef?.current?.waitingLabel} {waveLoad()}</Typography>}>
          {fieldRef.current?.[ProjectID]||fieldRef.current?.[projLabel]}</Suspense> : <Typography variant="button" sx={{margin:'auto',textAlign:'center'}}>...Loading</Typography>
       ) : !parentRef.current.quedReports ? <Typography variant="button" sx={{margin:'auto',textAlign:'center'}}>{parentRef?.current?.waitingLabel} {waveLoad()}</Typography> : <Typography variant="button" sx={{margin:'auto',textAlign:'center'}}>looks like your'e all caught up</Typography> }
  </SwipeableViews>
  </Box>
</Box>
}