import React, {useEffect,useRef} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import {GeoAnime} from '../components/component_geoUser'
//import Notification from 'react-web-notification'

export default function Notify({That,globalWrn,viewData,action,globalState,dynamicComponent,globalAdd,socketIO}){
    const mainState = useState({ignore: false})
    const [old, setState, stateRef] = mainState
    const [headerPopup, setHeaderPopup] = useAtom(globalState.headerPopupState)
    const [notifications, setNotifications] = useAtom(globalState.notificationState)
    const [projectMap, setProjectMap] = useAtom(globalState.projectMapState)
    const [UserMap, setUserMap] = useAtom(globalState.userMapState)
    const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState)
    const notifySound = useRef(new Audio('https://dev-services.ekc.app/scripts/audio/chatNoise.mp3'))
    //const [amount, changeAmount] = useAtom(g)
    const BackgroundFetch = window.BackgroundFetch;

  function getSubscription() {
    let promiseChain = Promise.resolve();
    var subscription = UserMap.get("NotifSub")
    return subscription;
  }
    //end of subscription code

  const handleNotificationOnShow=(e, tag)=>{   console.log(e, 'Notification shown tag:' + tag);
    playSound();
  }

  const playSound=(filename)=>{
    console.log('playSound')
    notifySound.current.play();
  }

    useEffect(( )=>{
      var arr = UserMap.get('Notifications')
      var amount = 0
      if(arr){
        for(var i = 0; i < arr?.length; i++){
          if(arr.at(i).read === 2){
            amount = amount + 1
          }
        }
        if(amount > 3){
          handleButtonClick(0, null, arr) 
        }
      //if (amount != 0){
        else{ 
        for(var i = 0; i < arr?.length; i++){
            if(arr.at(i).read === 2){
              handleButtonClick(arr.at(i), ()=> socketIO.emit("notificationUpdate",{userID:UserMap.get('avatar'),type:'changeRead',notifID:arr.at(i)._id}))
            }
        }
      }
    };
   // }
      //else{
     //   amount = amount + 1;
     // }
  },[UserMap.get('Notifications')])                                                                                                                                                                                                  
  
    const handleButtonClick =(spot, callBk, wholeArr)=> {

      if (!spot && !wholeArr)
        return
      else if(wholeArr){
        for(var i = 0; i < wholeArr?.length; i++){
          socketIO.emit("notificationUpdate",{userID:UserMap.get('avatar'),type:'changeRead',notifID:wholeArr.at(i)._id})
        }
        if(window.cordova && (window.cordova.platformId === "ios" || window.cordova.platformId === "android")){
          document.addEventListener("pause", onPause(Body, Icon, Title, Data), false);
        }
        else{
          globalWrn('info',"You have many new notifications")
        }
        return
      }

      else{

      var subscription = getSubscription() //get user's subscription

      var Title = spot.Title
      var Body = spot.Body
      var Data = spot.data
      var Avatar = spot.Avatar
      var Icon = spot.Icon
      var sender = spot.sender 

      if(spot.Title === undefined) return //makes sure it does not display empty notification
  
      if(window.cordova && window.cordova.platformId === "browser"){ //running browser on cordova
        if (Title != "New Call"){
          globalWrn('info',Body)
        }
      }

      else if(window.cordova && (window.cordova.platformId === "ios" || window.cordova.platformId === "android")){ //ios or android
          document.addEventListener("pause", onPause(Body, Icon, Title, Data), false);
        }
  
      else{ //regular browser (yarn react-scripts start)
        if(Title != "New Call" && Body != undefined){
          //createDesktopNotif(Body, Icon, Title, Data)
          let myStr = Title
          let firstWord = myStr.split(" ")[0]
          if(firstWord === 'Project'){
            globalWrn('info',Title)
          }
          else{
            globalWrn('info',Body)
          }
        }
      }
      callBk()
    }
    }

    function onPause(Body, Icon, Title, Data){
      window.cordova?.plugins.notification?.local.schedule({
        title: Title,
        text: Body,
        foreground: true,
      })
    }

const createDesktopNotif=(newTag, newIcon, name, data)=>{
        if(stateRef.current.ignore) return

        const options = {
        //  data: 'https://dev.ekc.app', //need to change to links withing the app
          body: newTag,
          icon: newIcon,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: Date.now(),
          //image: newIcon,
          badge: "https://spyna.it/icons/favicon.ico",
          actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
        }
        setState(p=>({...p,...{title:name,options:options}}))
      }
  
  function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
  }

return null
};