import React, {useEffect} from 'react';
import useState from 'react-usestateref';
import {useDropzone} from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { useAtom } from 'jotai';

import {Card,CardActions,CardContent,Button,Typography,Box,List,ListItem,IconButton,ListItemAvatar,Avatar,ListItemText, listSubheaderClasses} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as globalState from "../globalState";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    acceptedFiles:{
      fontSize: '12px',
      padding: '0px',
      margin: '0px',
    },
    container : {
        position: 'absolute',
        zIndex: '55',
        top: '-1px',
        background: '#f6f7fd',
        border: '1px solid #868DAA',
        borderTop:'none',
        borderRadius: '0px 0px 5px 5px',
        padding: '20px 40px',
        margin: '0px 0px 0px 50px',
        '& .dropzone': {
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "50px 20px",
            borderWidth: "2px",
            borderRadius: "2px",
            borderStyle: "dashed",
            backgroundColor: "#fff",
            margin:'10px 0px',
            color: "#bdbdbd",
            cursor:"pointer",
            outline: "none",
            transition: "border .24s ease-in-out",
            '&:focus':{
                borderColor: '#0492f2'
            },
            '& p':{
                padding: '0px',
                margin: '0px',
            }
        }
    },

}));

export default function UploadFiles({currentFolder,handleCancel,uploadFile,handleReload,labelConf,viewData,That,globalWrn,action}) {
 if(viewData) var {state,publisher,currentFolder,handleCancel,uploadFile,handleReload,label,labelConf,defaultValue,filePrepState,avatar} = viewData

 const [appSocket] = useAtom(globalState.appSocketState)
 var socketIO = appSocket.get('socketIO')

 if(That) var [parentState, setParentState, parentStateRef] = That
 if(filePrepState) var [, setFilePrep, filePrepRef] = filePrepState

    const classes = useStyles();
    const [files, setFiles,filesRef] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
       /* accept: 'application/* , image/*', */
       acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
        onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
        if(state) setParentState(p=>({...p,...{[state]: filesRef.current }}))
        if(action) action.forEach(x=>x(filesRef.current))
        }
    });
  
  const removeFile = index => {
    var newFiles = [...files];
    newFiles.splice(index, 1);
   // console.log(newFiles, files);
    setFiles(newFiles);
  }


  const handleSubmitUpload = () => filesRef.current.forEach((file, index) =>

    socketIO.emit('imageCompress',{image:file,writePath:`.${currentFolder}`,fileName:file.name},({sucess,err})=>{
      if(sucess===true){ 
        globalWrn('success',`Your File was Uploaded.`)
        if(handleReload) handleReload()
        if(handleCancel) handleCancel()
      } else globalWrn('error',`There was an error uploading your file(s).`)
    })
    );

  const handleCancelUpload = () =>{
    if(handleCancel) handleCancel();
    setFiles([])
  }
  
useEffect(()=>{
  if(defaultValue) defaultValue.forEach(({name})=>{
   if(filePrepRef.current[name]) setFiles(p=> [...p,filePrepRef.current[name]] )
  })

},[defaultValue])

  useEffect(() => () => {
    filesRef.current.forEach(file => URL.revokeObjectURL(file.preview));
  }, [filesRef.current]);

  return <Card sx={{width:'100%',height:'100%'}}>
  <Button sx={avatar||{margin:1,height:100,width:'97%'}} size="large" variant="outlined" {...getRootProps({className: 'dropzone'})} >
    <Typography gutterBottom variant="caption" sx={{textShadow:'1px 1px #000',color:'#fff', backgroundColor: 'rgba(0,0,0,.25)',backdropFilter:'blur(15px)', borderRadius: '8px',padding:'clamp(2px,.5%,8px)',lineHeight:1.1}} >{ label || "Drag 'n' drop some files here, or click to select files"} {
      defaultValue && [ <br/>,'- ',defaultValue.map(({name})=>{
        if(!filePrepRef.current[name]) return name
       })] }</Typography>
    <input {...getInputProps()} />
  </Button>
  {!avatar && filesRef.current.length>0 && <CardContent>
  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    { filesRef.current.map((file, index) =>  
      <ListItem key={file.path} secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon onClick={()=>removeFile(index)}/>
                    </IconButton>
                  }>
        <ListItemAvatar>
          <Avatar alt={file.name} src={file.preview} />
        </ListItemAvatar>
        <ListItemText primary={file.name} secondary={file.size} />
      </ListItem>
    )}
    </List>
  </CardContent> }
  {!publisher && <CardActions>
  {filesRef.current.length>0 && uploadFile && <Button size="small" onClick={handleSubmitUpload}>{labelConf ? labelConf : 'Send it'}</Button>}
   {handleCancel && <Button size="small" onClick={handleCancelUpload}>Cancel</Button>}
  </CardActions> }
</Card>
}