import React, {useEffect, Suspense} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Paper, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme })=>({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    color: theme.palette.text.secondary,
  }));

export default ({dynamicComponent,globalState,useIsMobile,viewData,globalWrn,That,dbUpdateValidate,waveLoad,action})=> {

  const {defaultValue, isStock, statusStock, PopupVal} = viewData
  const trashState = useState(Object)
  const outputState = useState(Object)
  const mainState = useState(Object)
  const [, setOutput, outputRef] = outputState
  const [, setState, stateRef] = mainState
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [, setPopup] = useAtom(globalState.popupState)
  const [, setPopup2] = useAtom(globalState.popupState2)
  const [, setPopup4] = useAtom(globalState.popupState4)
  const [UserMap] = useAtom(globalState.userMapState)
  const [inventoryMap] = useAtom(globalState.inventoryMapState)
  const [productMap] = useAtom(globalState.productMapState)

  const useAndMobile = useIsMobile(560)

  const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
  const latestState = (x) => outputRef.current[x] !== undefined ? outputRef.current[x] : simp(defaultValue,x)

  const createProj=()=>{
    var userDiv = outputRef.current.DivisionID || UserMap.get('division')
    var modelType = divisionMap.get(userDiv)?.teamName
    setState(p=>({...p,projectReload:undefined}))
    setPopup(p=>({...p,zIndex: 9}))
    setPopup2(p=>({...p,
      open:true,
      title:`Create Project`,
      zIndex: 10,
      inputField: <Box sx={{width:'60vw',height:'80vh'}}>{dynamicComponent('editProj','component',{DivisionLock:userDiv,projID:{DivisionID:userDiv,modelType:modelType},ref:module},That,[(x)=>{setState(p=>({...p,projectReload:true}));setOutput(p=>({...p,ProjectID:x}));setPopup2(p=>({...p,
        open:false})) }])}</Box>,
      handleClose: ()=>{setPopup2({open:false});setPopup({open:false})},
      nameInputSets: {},
  }))  
  }

useEffect(()=>{
  if(divisionMap.size>0) var list = Object.assign(...Array.from([...divisionMap],([k,v])=>({[v.teamName]:k})).sort((a, b)=> (Object.keys(a)[0] < Object.keys(b)[0]) ? -1 : (Object.keys(a)[0] > Object.keys(b)[0]) ? 1 : 0))
  if(!defaultValue) setOutput(p=>({...p,Status:'Shipping'}))
  if(isStock && isStock === true && defaultValue)
    setPopup2(p=>({...p,
      headerButton: dynamicComponent('DropDown','elements',{ state: 'DivisionID', label: 'Select A Divison', list: list, defaultValue:divisionMap.get(latestState('DivisionID'))?.teamName, replace:true },outputState),
    }))
  else setPopup(p=>({...p,
      headerButton: dynamicComponent('DropDown','elements',{ state: 'DivisionID', label: 'Select A Divison', list: list, defaultValue:divisionMap.get(latestState('DivisionID'))?.teamName, replace:true },outputState),
    }))
},[divisionMap])

useEffect(()=>{
   setState(p=>({...p,
        ProductItems: dynamicComponent('ProductSelect','elements',{state:'ProductItems', multi: false, label:'Product(s)',defaultValue:latestState('ProductItems')||[] },trashState
       ,[({ProductItems})=>{
        if(ProductItems !== undefined && ProductItems?.[0]?.id!==latestState('ProductItems')?.[0]?.id) setOutput(p=>({...p,ProductItems}))
      }]
        )
    }))
},[/*latestState('ProductItems')*/])

useEffect(()=>
    setState(p=>({...p,
        PurchaseOrder: dynamicComponent('InputField','elements',{state:'PurchaseOrder',label:'Purchase Order Number', defaultValue: latestState('PurchaseOrder')},outputState),
    }))
,[/*latestState('PurchaseOrder')*/])

useEffect(()=>
    setState(p=>({...p,
        TrackingNumber: dynamicComponent('InputField','elements',{state:'TrackingNumber',label:'Tracking Number', defaultValue: latestState('TrackingNumber')},outputState),
    }))
,[/*latestState('TrackingNumber')*/])

useEffect(()=>
    setState(p=>({...p,
        OrderNumber: dynamicComponent('InputField','elements',{state:'OrderNumber',label:'Order Number', defaultValue:latestState('OrderNumber')},outputState),
    }))
,[/*latestState('OrderNumber')*/])

useEffect(()=>
    setState(p=>({...p,
        ShelfNumber: dynamicComponent('InputField','elements',{state:'stockShelf.shelf',label:'Shelf Number', defaultValue:latestState('stockShelf.shelf')},outputState),
    }))
,[/*latestState('OrderNumber')*/])

useEffect(()=>
    setState(p=>({...p,
       AislesNumber: dynamicComponent('InputField','elements',{state:'stockShelf.aisles',label:'Aisle Number', defaultValue:latestState('stockShelf.aisles')},outputState),
    }))
,[/*latestState('OrderNumber')*/])

useEffect(()=>{
  var plnRec = [{id:'LabelValue',loc:'elements',viewData:{state:'FEN',label:'Fixed Expected Need',labelEditable:false,type:'number'}},{id:'LabelValue',loc:'elements',viewData:{state:'FENQTY',label:'Reorder Ammount',labelEditable:false,type:'number'}}]
    setState(p=>({...p,
       FENAmmount: dynamicComponent('Listable','elements',{state:'inventory',disableAdd:true,style:{padding:0,gap:'5px'},color:'#f58426',items:plnRec,defaultValue: latestState('FEN') },mainState,[({inventory})=>{ 
        inventory?.forEach(x=> setOutput(p=>({...p,...x})) )
       }])
       // dynamicComponent('LabelValue','elements',{state:'FEN',Min:0,label:'Fixed Expected Need',type:'number',labelEditable:false,Max:outputRef.current.QTYOrdered ? outputRef.current.QTYOrdered : latestState('QTYOrdered'), defaultValue:latestState('FEN')},outputState,[({FEN})=>setOutput(p=>({...p,FEN:FEN.value}))]),
    }))
},[latestState('QTYOrdered')])

useEffect(()=>
    setState(p=>({...p,
      stageZone: dynamicComponent('InputField','elements',{state:'projectStagingZone',label:'Project Staging Zone', defaultValue:latestState('projectStagingZone')},outputState),
    }))
  ,[])

useEffect(()=>{
if(latestState('ProductItems')) setState(p=>({...p,
        QTYOrdered: dynamicComponent('LabelValue','elements',{state:'QTYOrdered',Min:0,label:'QTY Ordered',type:'number',labelEditable:false, defaultValue:latestState('QTYOrdered')},outputState,[({QTYOrdered})=>setOutput(p=>({...p,QTYOrdered:QTYOrdered.value}))]),
    }))
    if(latestState('QTYOrdered') < 0)
   {
    globalWrn('error', "You cannot order less than 0 product(s)")
    setOutput(p=>({...p, 'QTYOrdered':0 }))
   }
},[latestState('QTYOrdered'),latestState('ProductItems')])

useEffect(()=>{
  if(latestState('ProductItems')) setState(p=>({...p,
       QTYReceived: dynamicComponent('LabelValue','elements',{state:'QTYReceived',Min:0,label:'QTY Received',type:'number',labelEditable:false,Max:latestState('QTYOrdered'), defaultValue:latestState('QTYReceived')},outputState,[({QTYReceived})=>setOutput(p=>({...p,QTYReceived:QTYReceived.value}))]),
   }))
   if(latestState('QTYReceived') < 0)
   {
    globalWrn('error', "You cannot recieve less than 0 product(s)")
    setOutput(p=>({...p, 'QTYReceived':0 }))
   }
},[latestState('QTYReceived'),latestState('ProductItems'),latestState('QTYOrdered')])

useEffect(()=>{
  //if(latestState('Notes')) 
  setState(p=>({...p,
       Notes: dynamicComponent('InputField','elements',{state:'Notes',label:'Notes (Optional)',defaultValue:latestState('Notes')},outputState),
   }))
},[/*latestState('Notes')*/])

useEffect(()=>{
  if(latestState('QTYReceived') < latestState('QTYOnHand')) setOutput(p=>({...p,QTYOnHand:latestState('QTYReceived')}))

if(latestState('ProductItems')) setState(p=>({...p,
        QTYOnHand: dynamicComponent('LabelValue','elements',{state:'QTYOnHand',Min:0,label:'QTY On Hand',type:'number',labelEditable:false,Max:latestState('QTYReceived'), defaultValue:latestState('QTYOnHand')},outputState,[({QTYOnHand})=>setOutput(p=>({...p,QTYOnHand:QTYOnHand.value}))]),
    }))
    if(latestState('QTYOnHand') < 0)
   {
    globalWrn('error', "You cannot have less than 0 product(s) on hand")
    setOutput(p=>({...p, 'QTYOnHand':0 }))
   }
},[latestState('QTYOnHand'),latestState('ProductItems'),latestState('QTYReceived')])

useEffect(()=>{ 
    setState(p=>({...p,
      JobNumber: dynamicComponent('AutoComplete','elements',{state:'ProjectID',label:'Job Number',defaultValue: latestState('ProjectID'),model:'Projects',query:{'Status':{$in:['Sales','Project']}},filter:{},modelType: divisionMap.get(latestState('DivisionID'))?.teamName,addEntry:createProj,single:true},outputState),
    }))
  },[latestState('DivisionID'),latestState('ProjectID'),stateRef.current.projectReload])

useEffect(()=>{ 
  if((defaultValue && outputRef.current && Object.keys(outputRef.current).length>0) || (outputRef.current && ['OrderNumber','PurchaseOrder','ProductItems','DivisionID','ProjectID','QTYOrdered'].every(x=> Object.keys(outputRef.current).includes(x))) || (outputRef.current && ['ProductItems','DivisionID','ProjectID','QTYOrdered', 'stockShelf.shelf', 'stockShelf.aisles'].every(x=> Object.keys(outputRef.current).includes(x)) && isStock && !defaultValue) ){
    if(PopupVal === 4) 
 {
  setPopup4(p=>({...p,
    submitLabel: 'Add',
    handleSubmit: create,
  }))
 }
 else if(isStock && isStock === true && defaultValue)
 {
  setPopup2(p=>({...p,
    submitLabel: 'Add',
    handleSubmit: create,
    }))
 }
 else{
    setPopup(p=>({...p,
  submitLabel: defaultValue?'Update':'Add',
  handleSubmit: create,
  }))
}
  }
 
 },[outputRef.current])

 // State for switcher
 useEffect(()=>{
  if(isStock && isStock === true && !defaultValue)
    setState(p=>({...p,
        stockSwitch: dynamicComponent('Switcher', 'elements',{state:'stockSwitchVal',label:"Stock Item",style:{flex:'unset'}},mainState)
    }))
 },[])

 useEffect(()=>{
  if(isStock && isStock === true && !defaultValue) setPopup(p=>({...p,
    helperObject: stateRef.current.stockSwitch
    }))
 },[])

 const create=()=>{
  if(stateRef.current.loading === true) return
  setState(p=>({...p,loading : true }))
  submit()
}

const closePopup=()=>{
  if(PopupVal === 4) setPopup4({open: false});
  else {
    setPopup({open: false});
    setPopup2({open: false});
  }
}

const submit=()=>{
//return console.log('Updating',  {...outputRef.current,timestamp:dayjs()} )

  const Status = defaultValue?.Status
  var QTYReceived = outputRef.current?.QTYReceived
  var QTYOrdered = outputRef.current?.QTYOrdered
  var QTYOnHand = outputRef.current?.QTYOnHand

  if(outputRef.current?.QTYReceived?.length < 1) QTYReceived = defaultValue?.QTYReceived
  if(outputRef.current?.QTYOrdered?.length < 1) QTYOrdered = defaultValue?.QTYOrdered
  if(outputRef.current?.QTYOnHand?.length < 1) QTYOnHand = defaultValue?.QTYOnHand

  if(QTYReceived === undefined || QTYReceived === null) QTYReceived = 0
  if(QTYOrdered === undefined || QTYOrdered === null) QTYOrdered = 0
  if(QTYOnHand === undefined || QTYOnHand === null) QTYOnHand = 0

  if((isStock && isStock === true && stateRef.current.stockSwitchVal === true) || statusStock === true) setOutput(p=>({...p,Status:"Stock"}))
  else if(Status === "Returned for RMA") { /* update.status does not need to change */ }
  else if (Status === "Stock") { /* update.status does not need to change */ }
  else if(QTYReceived === QTYOrdered && QTYOnHand === 0) setOutput(p=>({...p,Status:"Delivered to Site"}))
  else if(QTYOrdered > QTYReceived && QTYOnHand < QTYReceived && QTYReceived > 0) setOutput(p=>({...p,Status:"Partially Received and Delivered"}))
  else if(QTYOrdered > QTYReceived && QTYOnHand === QTYReceived && QTYReceived !== 0) setOutput(p=>({...p,Status:"Partial Received"}))
  else if(QTYOrdered === QTYReceived && QTYReceived === QTYOnHand && !['Stock','RMA'].includes(Status)) setOutput(p=>({...p,Status:"Warehouse Received"}))
  else if((QTYOrdered === QTYReceived) && (QTYOnHand > 0) && (QTYOnHand < QTYReceived)) setOutput(p=>({...p,Status:"Partial to Site"}))
  else if(QTYReceived === 0 && QTYOnHand === 0) setOutput(p=>({...p,Status:'Shipping'}))
  if(outputRef.current.FEN && outputRef.current.FEN !== 0) setOutput(p=>({...p,consumable:true}))
  if(defaultValue?.InventoryID || defaultValue?._id)
    var update = {
      InventoryID: defaultValue?.InventoryID||defaultValue?._id, 
      QTYOnHand: ![null, undefined, NaN].includes(latestState('QTYOnHand')) ? latestState('QTYOnHand') : 0,
      QTYReceived: ![null, undefined, NaN].includes(latestState('QTYReceived')) ? latestState('QTYReceived') : 0,
      QTYOrdered: ![null, undefined, NaN].includes(latestState('QTYOrdered')) ? latestState('QTYOrdered') : 0,
      Status: latestState('Status')
    }

  if(update)
  var dates = {
    type: update?.Status,
    quantityType: update?.QTYOrdered ? "QTYOrdered" : update?.QTYReceived ? "QTYReceived" : "QTYOnHand",
    quantity: update.QTYOrdered ? update.QTYOrdered : update?.QTYReceived ? update?.QTYReceived : update?.QTYOnHand ? update?.QTYOnHand : inventoryMap.get(defaultValue?.InventoryID)?.QTYOnHand ? inventoryMap.get(defaultValue?.InventoryID)?.QTYOnHand : 0,
    timestamp: new Date(),
    Notes: `No associated notes.`,
    MSRP: productMap.get(inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?.id)?.MSRP,
    userID: UserMap.get('avatar')
    }

  if(!isStock || stateRef.current.stockSwitchVal !== true && !statusStock){
    
    if(dates) dbUpdateValidate({model:'Inventory',queryID:defaultValue?true:false,query:defaultValue?defaultValue._id:{...outputRef.current,timestamp:new Date()},update:{...outputRef.current, $push: {dates:dates}, timestamp:new Date()}},({success,info,err})=>{
      setState(p=>({...p,loading : false }))
      console.log("Resp0:",success,info,err)
      if(success){
        globalWrn('success',`${latestState('PurchaseOrder')} was created.`)
        closePopup();     
      }else globalWrn('error',`We had an issue creating ${latestState('PurchaseOrder')}.`)
    })
    else dbUpdateValidate({model:'Inventory',queryID:defaultValue?true:false,query:defaultValue?defaultValue._id:{...outputRef.current,timestamp:new Date()},update:{...outputRef.current, timestamp:new Date()}},({success,info,err})=>{
      setState(p=>({...p,loading : false }))
      console.log("Resp0.5:",success,info,err)
      if(success){
        globalWrn('success',`${latestState('PurchaseOrder')} was created.`)
        closePopup();        
      }else globalWrn('error',`We had an issue creating ${latestState('PurchaseOrder')}.`)
    })
  }
  else if((isStock && isStock === true) || statusStock === true) // Create stock item when increasing QTY on hand
  {
    if(defaultValue)
    {
      // Ensure dot notation has been distributed in object -------- {
      // Safety
      if(!defaultValue.stockShelf) defaultValue.stockShelf = {}
      
      if(outputRef.current?.['stockShelf.aisles'])
      {
        defaultValue.stockShelf.aisles = outputRef.current?.['stockShelf.aisles'];
        delete outputRef.current?.['stockShelf.aisles'];
      }
      if(outputRef.current?.['stockShelf.shelf'])
      {
        defaultValue.stockShelf.shelf = outputRef.current?.['stockShelf.shelf'];
        delete outputRef.current?.['stockShelf.shelf'];
      }

        dates = [].concat(dates)
      // ------------------------- }

      // Check QTYs
      if(!defaultValue.QTYOnHand) defaultValue.QTYOnHand = 0

    delete defaultValue._id
    defaultValue?.TransferRequests?.forEach(x=>delete x?.ProductItems?.[0]?._id)
    delete defaultValue?.ProductItems?.[0]._id
    delete defaultValue?.TransferRequests
    defaultValue.ProjectID = [defaultValue.ProjectID]

    dbUpdateValidate({model:'Inventory',query:{...defaultValue, ...outputRef.current, dates:dates, timestamp:new Date()},update:{...defaultValue, ...outputRef.current, dates:dates, timestamp:new Date()}},({success,info,err})=>{
      setState(p=>({...p,loading : false }))
      console.log("Resp1:",success,info,err)
      if(success){
        globalWrn('success',`${outputRef.current.PurchaseOrder||defaultValue?.PurchaseOrder} was created.`)
        closePopup(); 
        if(action) action.forEach(x=> x(info))
      }else globalWrn('error',`We had an issue creating ${outputRef.current.PurchaseOrder}.`)
    })
    }
    else dbUpdateValidate({model:'Inventory',query:{...outputRef.current, Status: "Stock", QTYOnHand: outputRef.current.QTYOrdered, QTYReceived: outputRef.current.QTYOrdered, timestamp:new Date(), dates:dates},update:{...outputRef.current, Status: "Stock", QTYOnHand: outputRef.current.QTYOrdered, QTYReceived: outputRef.current.QTYOrdered, timestamp:new Date(), dates: dates}},({success,info,err})=>{
        setState(p=>({...p,loading : false }))
        console.log("Resp2:",success,info,err)
        if(success){
          globalWrn('success',`${outputRef.current.PurchaseOrder||defaultValue?.PurchaseOrder} was created.`)
          closePopup();
          if(action) action.forEach(x=> x(info))
        }else globalWrn('error',`We had an issue creating ${outputRef.current.PurchaseOrder}.`)
      })
      setTimeout(() => {
        if(!PopupVal) {
          setPopup2({open:false});
          setPopup({open:false}) 
        }
      }, 3500);
  }

}

useEffect(()=>{ // input field when switch stock item fields
  if(stateRef.current.stockSwitchVal !== true || defaultValue) // not stock
  setState(p=>({...p,
    threeFields :  <><Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
    <Item sx={{'& > *':{flex:1}}}>
    <Box sx={{margin:'auto', padding:'1%'}}>
    {stateRef.current.PurchaseOrder}
    </Box>
    </Item>
    </Grid>
    <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
    <Item sx={{'& > *':{flex:1}}}>
    <Box sx={{margin:'auto', padding:'1%'}}>
    {stateRef.current.OrderNumber}
    </Box>
    </Item>
    </Grid>
    <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
    <Item sx={{'& > *':{flex:1}}}>
    <Box sx={{margin:'auto', padding:'1%'}}>
    {stateRef.current.TrackingNumber}
    </Box>
    </Item>
    </Grid></>
  }))
  else if(!defaultValue) // is stock
  setState(p=>({...p,
threeFields : <>
  <Grid item xs={12} sx={{width: '50%'}} md={ useAndMobile ? 12 : 6 }>
    <Item sx={{'& > *':{flex:1}}}>
    <Box sx={{margin:'auto', padding:'1%'}}>
    {stateRef.current.ShelfNumber}
    </Box>
    </Item>
    </Grid>
    <Grid item xs={12} sx={{width: '50%'}} md={ useAndMobile ? 12 : 6 }>
    <Item sx={{'& > *':{flex:1}}}>
    <Box sx={{margin:'auto', padding:'1%'}}>
    {stateRef.current.AislesNumber}
    </Box>
    </Item>
    </Grid>
</>

}))
},[stateRef.current.stockSwitchVal])


return !latestState('DivisionID') ? <Box sx={{display:'flex',p:'2%'}}><Chip color="warning" size="small" icon={<InfoIcon />} sx={{margin:'auto'}} label="You must first Select a Division!" /></Box>
      : <Box>
  <Grid container rowSpacing={.5} sx={{padding:'0.5%'}} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
      {stateRef.current.threeFields}
      <Grid item xs={12} md={ useAndMobile ? 12 : 8 }>
      <Item sx={{'& > *':{flex:1}}}>
      <Box sx={{margin:'auto', padding:'1%'}}>
      <Suspense fallback={waveLoad()}>
      {stateRef.current.ProductItems}
      </Suspense>
      </Box>
      </Item>
      </Grid>
      <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
      <Item sx={{'& > *':{flex:1}}}>
      <Box sx={{margin:'auto', padding:'1%'}}>
      {stateRef.current.JobNumber}
      </Box>
      </Item>
      </Grid>
      <Grid item xs={12} sx={{width:'100%'}}>
      <Item sx={{'& > *':{flex:1}}}>
      <Box sx={{margin:'auto', padding:'1%',flex:1}}>
      {stateRef.current.Notes}
      </Box>
      <Box sx={{margin:'auto',flex:.5}}>
      {defaultValue?.consumable && defaultValue?.consumable === true && <Box sx={{margin:'auto', padding:'1%'}}>{stateRef.current.FENAmmount}</Box>}
      {!defaultValue && <Box sx={{margin:'auto', padding:'1%'}}>{stateRef.current.FENAmmount}</Box>}
      </Box>
      </Item>
      </Grid>
      { latestState('ProductItems') && [<Grid item xs={12} md={ (useAndMobile||!defaultValue) ? 12 : 4 }>
      {useAndMobile ? <Item sx={{'& > *':{flex:1, width: '98%'}}}>
          <Box sx={{margin:'auto', padding:'1%'}}>
            {stateRef.current.QTYOrdered}
          </Box>
        </Item>
       : <Item sx={{'& > *':{flex:1, width: '50%'}}}>
        <Box sx={{margin:'auto', padding:'1%'}}>
        {stateRef.current.QTYOrdered}
      </Box>
      </Item>}
      </Grid>,
      defaultValue && [<Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
      <Item sx={{'& > *':{flex:1}}}>
        <Box sx={{margin:'auto', padding:'1%'}}>
       {stateRef.current.QTYReceived}
      </Box>
      </Item>
      </Grid>,
      <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
      <Item sx={{'& > *':{flex:1}}}>
      <Box sx={{margin:'auto', padding:'1%'}}>
      {stateRef.current.QTYOnHand}
      </Box>
      </Item>
      </Grid>]] }
      { defaultValue && [
        <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
        <Item sx={{'& > *':{flex:1}}}>
        <Box sx={{margin:'auto', padding:'1%'}}>
         {stateRef.current.stageZone}
        </Box>
        </Item>
      </Grid>,
        <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
        <Item sx={{'& > *':{flex:1}}}>
        <Box sx={{margin:'auto', padding:'1%'}}>
         {stateRef.current.AislesNumber}
        </Box>
        </Item>
      </Grid>,
      <Grid item xs={12} md={ useAndMobile ? 12 : 4 }>
        <Item sx={{'& > *':{flex:1}}}>
        <Box sx={{margin:'auto', padding:'1%'}}>
         {stateRef.current.ShelfNumber}
        </Box>
        </Item>
      </Grid> ]}
      </Grid>
    </Box>
}