import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Checkbox, Tooltip} from '@mui/material';
import { Droppable, Draggable} from 'react-beautiful-dnd';
import clsx from "clsx";

import { toAbsoluteUrl , convertDate, formatBytes} from "../Utils/Utils";
import mainconfig from '../Data/Config';
import Styles from './Elements/Styles';
import config from './Elements/config.json';
import useClasses from "./styleConvert"
import { useIsMobile } from "../globalState";

function ViewItems(props){
    const { onContextMenuClick, addSelect, selectedFiles, bufferedItems, showImages} = props;
    //const classes = useStyles();
    const classes = useClasses(Styles)
    const getThumb = (item) => {
        try {
            if(showImages === 'thumbs' && config.imageFiles.includes(item.extension)) return `${mainconfig.serverPath}${item.path}`;
            else {
              console.warn('AbsoluteURL1', toAbsoluteUrl(config.icons[item.extension]) )
              return typeof config.icons[item.extension] !== 'undefined' ? toAbsoluteUrl(config.icons[item.extension]) : toAbsoluteUrl(config.icons.broken)
            }
        } catch (error) {
          console.warn('AbsoluteURL2', toAbsoluteUrl(config.icons[item.extension]) )
            return toAbsoluteUrl(config.icons.broken);
        }
    }

    const handleContextMenuClick = async (item,event) => {
      addSelect(item);
      onContextMenuClick(event);
    }

    const checkIsSelected = item => {
        return selectedFiles.includes(item);
    }

    const isCuted = item => {
        if(bufferedItems.type === 'cut') return bufferedItems.files.filter((file) => file.id === item.id).length > 0;
        return false;
    }

    function getStyle(style, snapshot) {
        if (!snapshot.isDraggingOver) return style;
        return {
          ...style,
           background:'#f00 !important',
        };
    }

    const FileItem = ({item,index}) => {
      let fileCuted = isCuted(item);
      let isSelected = checkIsSelected(item);

        return <Draggable 
            draggableId={item.id} 
            index={index}
            isDragDisabled={item.private}
          >
              {(provided, snapshot)=>(      
                  <Box 
                      onContextMenu={(event)=>handleContextMenuClick(item,event)} 
                      onDoubleClick={()=> props.operations.handleDownload(item.path)}
                      className={clsx(
                          classes.itemFile,
                          {
                          "selected": selectedFiles.includes(item.path),
                          "selectmode": selectedFiles.length > 0,
                          "notDragging": !snapshot.isDragging,
                          'fileCuted': fileCuted
                          })
                      }
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                  >
                        {item.private && 
                            <span className={`icon-lock ${classes.locked}`}/> || 
                            <Checkbox className={classes.checkbox} checked={isSelected} onChange={()=>addSelect(item)} value={item.id} />
                        }
                        <span className={classes.extension}>{item.extension}</span>

                        <Box className={classes.infoBox}>
                            <img src={getThumb(item)} />
                        </Box>
                        <Tooltip title={item.reName?item.reName:item.name}>
                          <Box className={classes.itemTitle}>
                              <span>{item.reName?item.reName:item.name}</span>
                          </Box>
                        </Tooltip>

                    </Box>
              )}
          </Draggable>
    }

    const FolderItem = ({item,index}) => {
     // console.warn('FolderItem:::',{item,index})
      let fileCuted = isCuted(item);
      let isSelected = checkIsSelected(item);
      return(
        <Draggable 
            index={index}
            draggableId={item.id} 
            isDragDisabled={true}
            // isDragDisabled={item.private}
        >
            {(provided, snapshot)=>( 
                <Box 
                    ref={provided.innerRef}
                    className={clsx(
                        classes.itemFile,
                        {
                        "selected": selectedFiles.includes(item.path),
                        "selectmode": selectedFiles.length > 0,
                        "notDragging": !snapshot.isDragging,
                        'fileCuted': fileCuted
                        })
                    }
                    onClick={()=>props.doubleClick(item.path)}
                    onDoubleClick={()=>props.doubleClick(item.path)}
                    onContextMenu={(event)=>handleContextMenuClick(item,event)} 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >  
                    <Droppable droppableId={item.id} type="CONTAINERITEM" isCombineEnabled>
                        {(provided, snapshot) => (
                            <Box 
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={getStyle(provided.droppableProps.style, snapshot)}
                            >
                                {item.private && 
                                    <span className={`icon-lock ${classes.locked}`}/> || 
                                    <Checkbox className={classes.checkbox} checked={isSelected} onChange={()=>addSelect(item)} value={item.id} />
                                }
                                <Box className={classes.infoBox}>
                                    <img src={snapshot.isDraggingOver ? toAbsoluteUrl(config.icons.folderopen) : toAbsoluteUrl(config.icons.folder) } />
                                </Box>
                                <Tooltip title={ <Fragment>
                                                    <b>Name :</b> {item.reName?item.reName:item.name} <br /> 
                                                    <b>Created :</b> {convertDate(item.created)}
                                                </Fragment>
                                            }>
                                    <Box className={classes.itemTitle}>
                                        <span>{item.reName?item.reName:item.name}</span>
                                    </Box>
                                </Tooltip>
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            )}
        </Draggable>
      );
    }

    const ListFolderItem = ({item,index,mobile})=>{
      let fileCuted = isCuted(item);
      let isSelected = checkIsSelected(item);

      return (
        <Draggable index={index} draggableId={item.id}>
            {(provided, snapshot)=>(  
              <TableRow 
                ref={provided.innerRef}
                className={clsx(
                    classes.tableListRow,
                    {
                      "selected": selectedFiles.includes(item.path),
                      'fileCuted': fileCuted,
                      "selectmodeTable": selectedFiles.length > 0
                    })
                }
                onDoubleClick={()=>props.doubleClick(item.path)}
                onContextMenu={(event)=>handleContextMenuClick(item,event)} 
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Droppable droppableId={item.id} type="CONTAINERITEM" isCombineEnabled>
                    {(provided, snapshot) => (
                        <Fragment>
                          <TableCell className={classes.tableCell}><Checkbox checked={isSelected} onChange={()=>addSelect(item)} value={item.id} /></TableCell>
                          <TableCell className={classes.tableCell}><img style={{"minWidth":"25px"}} src={snapshot.isDraggingOver ? toAbsoluteUrl(config.icons.folderopen) : toAbsoluteUrl(config.icons.folder) } /></TableCell>
                          <TableCell className={classes.tableCell} align="left">
                              <Box 
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  style={getStyle(provided.droppableProps.style, snapshot)}
                              >
                                {item.reName?item.reName:item.name}
                                {provided.placeholder}
                            </Box>
                          </TableCell>
                          {!mobile(420)? <TableCell className={classes.tableCell} align="left">{formatBytes(item.size)}</TableCell>:null}
                         {!mobile(560)?<TableCell className={classes.tableCell} align="left">{convertDate(item.created)}</TableCell>:null}
                        </Fragment>
                    )}
                </Droppable>
              </TableRow>
            )}
        </Draggable>
      );
    }

    const ListFileItem = ({item,index,mobile})=>{
      let fileCuted = isCuted(item);
      let isSelected = checkIsSelected(item);

      return (
        <Draggable 
            draggableId={item.id} 
            index={index}
        >
            {(provided, snapshot)=>(  
              <TableRow 
                onContextMenu={(event)=>handleContextMenuClick(item,event)} 
                onDoubleClick={()=> props.operations.handleDownload(item)}
                className={clsx(
                    classes.tableListRow,
                    {
                    "selected": selectedFiles.includes(item.path),
                    'fileCuted': fileCuted,
                    "selectmodeTable": selectedFiles.length > 0
                    })
                }
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell className={classes.tableCell}>
                  <Checkbox checked={isSelected} onChange={()=>addSelect(item)} value={item.id} />  
                </TableCell>
                <TableCell className={classes.tableCell}> 
                    <img style={{"minWidth":"20px", 'maxHeight': '30px'}} src={getThumb(item)} />
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{item.reName?item.reName:item.name}</TableCell>
                {!mobile(420)? <TableCell className={classes.tableCell} align="left">{formatBytes(item.size)}</TableCell>:null}
                {!mobile(560)?<TableCell className={classes.tableCell} align="left">{convertDate(item.created)}</TableCell>:null}
              </TableRow>
            )}
        </Draggable>
      );
    }

    const ListView = ({mobile}) => {
        return(
              <TableContainer component={Box}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      <TableCell style={{"width": '20px',paddingLeft:'2%'}}>Select</TableCell>
                      <TableCell style={{"width": '35px',paddingLeft:'2%'}} align="left">Type</TableCell>
                      <TableCell style={{paddingLeft:'2%'}}align="left">Name</TableCell>
                      {!mobile(420)? <TableCell style={{"width": '100px',paddingLeft:'2%'}} align="left">Size</TableCell>:null}
                      {!mobile(560) ? <TableCell style={{"width": '150px',paddingLeft:'2%'}} align="left">Created</TableCell>:null}
                    </TableRow>
                  </TableHead>

                  <Droppable droppableId="listDroppablContainer" type="CONTAINERITEM" isCombineEnabled>
                      {(provided, snapshot) => (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} >

                          {props.filesList.map((item,index) => (
                                item.type === 'folder' && <ListFolderItem key={index} index={index} item={item} mobile={mobile} /> 
                          ))}

                          {props.filesList.map((item,index) => (
                                item.type === 'file' && <ListFileItem key={index} index={index} item={item} mobile={mobile}/> 
                          ))}

                          {provided.placeholder}
                        </TableBody>
                      )}
                  </Droppable>

                </Table>
              </TableContainer>
        )
    }

    const GridView = () => {
      return (
        <Box className={classes.itemsList}>

          <Droppable droppableId="mainDroppablContainer" type="CONTAINERITEM" isCombineEnabled>
            {(provided, snapshot) => (
              <Box 
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.filesList.map((item,index) => (
                      item.type === 'folder' && <FolderItem  key={index} index={index} item={item} /> 
                ))}

                {props.filesList.map((item,index) => (
                      item.type === 'file' && <FileItem  key={index} index={index} item={item} /> 
                ))}

                {provided.placeholder}
              </Box>
              )}
            </Droppable>

        </Box>
      )
    }

    return (
      <Fragment>
        {props.itemsView === 'grid' ?  <GridView /> : <ListView mobile={useIsMobile} /> }
      </Fragment>
    )

}

const mapStateToProps = store => ({
    store,
    selectedFiles: store.filemanager.selectedFiles,
    selectedFolder: store.filemanager.selectedFolder,
    bufferedItems: store.filemanager.bufferedItems,
    foldersList: store.filemanager.foldersList,
    showImages:store.filemanager.showImages,
    itemsView: store.filemanager.itemsView,
    filesList: store.filemanager.filesList,
    translations : store.dashboard.translations,
    lang : store.dashboard.lang,
});
  
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ViewItems);