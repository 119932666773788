import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { Provider as JotaiProvider, createStore } from 'jotai';

import { SnackbarProvider } from 'notistack';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { APIProvider } from '@vis.gl/react-google-maps';
import './app.css';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const darkTheme = createTheme({palette:{mode:'dark'}});

// import RecoilNexus from "recoil-nexus";
// const workerAPI = new Worker(new URL("./Workers/sockets", import.meta.url));
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

/*
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });*/

const startApp = () => root.render( 
  <Provider store={store}>
  <APIProvider apiKey={process.env.REACT_APP_GOOGLEAPI} libraries={['marker',"places"]}>
  <JotaiProvider>
  <ThemeProvider theme={darkTheme}>
  <SnackbarProvider maxSnack={4} preventDuplicate>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App/> 
    </LocalizationProvider>
  </SnackbarProvider>
  </ThemeProvider>
  </JotaiProvider>
  </APIProvider>
  </Provider>
);

if(!window.cordova) startApp()
else document.addEventListener("deviceready",startApp(), false)

//reportWebVitals();