import React, { useEffect } from "react";
import { Box, Typography, List, ListItem, Checkbox, Avatar, ListItemText, Grid, Button ,Card, CardHeader, Divider } from '@mui/material';
import useState from 'react-usestateref';
import { useAtom } from 'jotai';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);
const union = (a, b) => [...a, ...not(b, a)];

const TransferList=({That,globalState,viewData:{list}})=> {
  const [productMap] = useAtom(globalState.productMapState)
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [, setParentState, parentStateRef] = That
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(list.current);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(()=>{ console.log('Right',right)
   if(right) setParentState(p=>({...p,...{
      rightList: right,
    }}));
    console.log('Parent:',parentStateRef.current)
  },[right])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    
    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    setChecked(newChecked);
    
  };
 
  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) setChecked(not(checked, items));
    else setChecked(union(checked, items));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) =>{
  return <Card sx={{height:'100%'}}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
         // width: 200,
          height: '100%',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {/*items.flatMap(({InventoryID,ProductItems,TransferAmount}) => {*/
        items.flatMap((curr)=>{
          var {InventoryID,ProductItems} = curr
          var TransferAmount = list.current?.filter(x=>x._id === InventoryID)?.[0]?.TransferAmount
          const labelId = `transfer-list-all-item-${InventoryID}-label`;
        return <Box>
            {ProductItems?.flatMap(({id,_id},i)=> {
            if(productMap.has(id)) var {IMAGE_SPOTLIGHT,DISPLAY_NAME} = productMap.get(id)
           return <ListItem
              key={InventoryID}
              role="listitem"
              button
              onClick={handleToggle(curr)}
              sx={{gap:'6%'}}
            >
               <Checkbox
                  checked={checked.indexOf(curr) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
                <Avatar src={IMAGE_SPOTLIGHT }/>
                <ListItemText key={_id} id={labelId} primary={<Typography>{DISPLAY_NAME || `No Display Name`}</Typography>} secondary={<Typography sx={{lineHeight: 1}}>Units: {TransferAmount}</Typography>} />
            </ListItem>
         }) }</Box>
        })}
      </List>
    </Card>
  }

  return <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{alignItems:'stretch'}}>
      <Grid item sx={{flex:1}}>{customList('Choices', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item sx={{flex:1}}>{customList('Chosen', right)}</Grid>
    </Grid>
}

  export default TransferList