import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import {ViewportList} from "react-viewport-list";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar,Paper,Divider,Box,Drawer,Button,Typography,Accordion,AccordionSummary,AccordionDetails,List,ListItemText,ListItemIcon,ListItem,IconButton,InputBase,ListSubheader,ListItemButton,Card,CardContent,ToggleButton,ToggleButtonGroup,ListItemAvatar} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _without from "lodash/without";
import BlurOnIcon from '@mui/icons-material/BlurOn';

import { useDebounce } from "use-debounce";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const catagory = ["Audio", "Cameras", "Cases & Bags", "Computers & Software", "Conferencing", "Content Management", "Control", "Displays", "Furniture", "Lighting & Studio", "Mounts & Rigging", "Networking & Cabling", "Power", "Presentation", "Production", "Security & Safety", "Signal Management"]

const auth = {
  username: 'EKCEnterprises',
  password: 'J5580FgftetDEG8L'
}

const getProps = (x,id) => {
  var items = {
    getcategoriesjson: {
      loadingLabel: 'Loading Categories',
      nextGet: 'getmanufacturersjson',
      params: {}
    },
    getmanufacturersjson: {
      loadingLabel: 'Loading Manufacturers',
      nextGet: 'getproductsjson',
      params: {
        catid: id,
      }
    },
    getproductsjson: {
      loadingLabel: 'Loading Products',
      nextGet: 'getproductimagesjson',
      params: {
        option: 'mfr',
        ID: id,
        pgsize: 25,
        pgnum: 1,
      }
    },
  }
  return items[x]
}

  export default function ProductSelect(props){
  const {That,viewData,action,globalState} = props
  const {label,state,projID,manualOpen,defaultValue,multi,checkOut} = viewData
  const [, setParentState, parentStateRef] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [projectMap] = useAtom(globalState.projectMapState)
  const [productMap] = useAtom(globalState.productMapState)
  if(projID) var { ProjectID } = projID
  if(ProjectID) var ProjectData = projectMap.get(ProjectID)
  const outputState = useState([])
  const [, setOutput, outputRef] = outputState
  const module = useRef(null)

  const [appSocket] = useAtom(globalState.appSocketState)
  
  useEffect(()=>{
    console.log(defaultValue)
   if(defaultValue && defaultValue.length > 0){ 
    setOutput(Object.assign({},...defaultValue?.flatMap(({id,Product})=>({[id]:Product}))))
    setState(p=>({...p,
      defaultValue: Object.assign({},...defaultValue)
    }))
  }
  },[defaultValue])

  useEffect(()=>{
    if(!multi && Object.keys(outputRef.current)?.length > 1) delete outputRef.current[Object.keys(outputRef.current)[0]];
    if(!multi && Object.keys(outputRef.current)?.length > 0) setState(p=>({...p,drawerState: false}))
    if(Object.keys(outputRef.current)?.length > 0){

      if(productMap.has(Object.keys(outputRef.current)[0])) var {IMAGE_SPOTLIGHT,DISPLAY_NAME,MODEL_NUMBER_CLEAN,MODEL_NUMBER} = productMap.get(Object.keys(outputRef.current)[0])
      
      setState(p=>({...p,
        menuBttn: <ListItem sx={{gap:'2%'}} onClick={()=> setState(p=>({...p,drawerState: !stateRef.current.drawerState}))}>
          {
          console.log(outputRef.current)}{
          Object.entries(outputRef.current).flatMap(([i,x])=>{
        
        return <Box key={"productItems"+i} sx={{display:'flex',gap:'10px'}}><ListItemAvatar>
          <Avatar sx={{ width: 56, height: 56 }}>
                <LazyLoadImage
            effect="blur"
            threshold={10000}
            // height={'100%'}
            // width={'100%'}
            style={{maxWidth:'clamp(40px,100%,10vw)',objectFit:'contain'}}
            key={'MainLogo'}
            onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
            src={x?.IMAGE_SPOTLIGHT?x?.IMAGE_SPOTLIGHT:'https://www.services.ekc.app/scripts/images/AV.jpeg'}
          />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={x?.DISPLAY_NAME} secondary={'Model #: '+(x?.MODEL_NUMBER_CLEAN ? x?.MODEL_NUMBER_CLEAN : x?.MODEL_NUMBER)} /></Box>
      })}
      </ListItem>
       }))
    }
  },[multi,outputRef.current])
  
  useEffect(()=>{
    if(outputRef.current && Object.keys(outputRef.current)?.length > 0 || defaultValue){
    if(stateRef.current.drawerState===false && (Object.keys(outputRef.current)?.length>0 || stateRef.current?.defaultValue && Object.keys(stateRef.current?.defaultValue)?.length>0 ) ){
      
      var val = Object.entries(outputRef.current).flatMap(([k,v])=>({id:k,Product:v}))
      if(stateRef.current.defaultValue && Object.keys(stateRef.current.defaultValue).length > 0) var compare = stateRef.current?.defaultValue ? Object.entries(stateRef.current?.defaultValue).flatMap(([k,v])=>({id:k,userFilter:v})) : null
      if(compare !== null && JSON.stringify(val) !== JSON.stringify(compare)){
        setParentState(p=>({...p,[state]: val }))
        action?.forEach((x)=>x({[state]:val})) 
        }
      }
    }
  },[stateRef.current.drawerState, outputRef.current])

  useEffect(()=>{
    if(manualOpen) setState(p=>({...p,drawerState: true,menuBttn:null }))
    else setState(p=>({...p,
      menuBttn: props.dynamicComponent('DefaultButton','elements',{state:'ProductSelectBttn',label:label,type:'button',startIcon:<BlurOnIcon/>,color:'#c0392b',full:true},mainState,[()=>{

        setState(p=>({...p,drawerState: !stateRef.current.drawerState }))
      } ])
     }))
  },[manualOpen])
  
return [ stateRef.current.menuBttn, <Drawed {...props} That={mainState} Output={outputState} checkOut={checkOut} /> ]
  }

function Drawed(props){
  const {That, globalState, dynamicComponent, Output, checkOut, globalWrn, waveLoad, dynamicMapData} = props
  const [, setPopup2] = useAtom(globalState.popupState2)
  const [output, setOutput, outputRef] = Output
  const [UserMap] = useAtom(globalState.userMapState)
  const [productMap,setProductMap] = useAtom(globalState.productMapState)
  const [, setParentState, parentStateRef] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [expanded, setExpanded] = useState(false)

  const [appSocket] = useAtom(globalState.appSocketState)
  const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);

  var socketIO = appSocket.get('socketIO')

  const handleChange = (panel,id)=>(event, isExpanded)=>{
    setExpanded(isExpanded ? panel : false)
  }

const closeDrawer = (e)=>{
  setParentState(p=>({...p,drawerState: false }))
}

/*  const closePopup2=()=>{ setPopup2(p=>({...p,
      open: false
  }))  } */

const createInventory=()=>{
  setPopup2(p=>({...p,
    open:true,
    zIndex: 1200,
    width: '100%',
    title:`Create Product`,
    description: 'Your new Products will be amazing.',
    inputField: dynamicComponent('CreateProduct','elements',{state:'newInventoryItem',catagory:catagory },Output,[buildReq]),
    handleClose: ()=>setPopup2({open:false}),
    nameInputSets: {},
}))
}

useEffect(()=>{
  reBuild()
},[productMap])

function reBuild(){
  var newITems = Array.from(productMap).flatMap(([k,v])=>({...v,_id:k}))
  var catagories = newITems.reduce((a,c)=> {
    var v = c['CATEGORY_DISPLAY_NAME'] || 'Uncategorized'
    a[v] = a[v] || {'CATEGORY_DISPLAY_NAME':v, data: []}
    a[v].data.push(c)
    return a },{})

    var dropList = [{primary:'All',icon: <AllInclusiveIcon/>,divider:true,dividerText:'Filters'}];
    
 if(newITems) setState(p=>({...p,
    EKCEntries: newITems,
    EKCCatagories: [catagories],
    filterDropdown: dynamicComponent('DropDown','elements',{state:'statusFilter',icon:<TuneIcon/>, list:dropList, replace:true },mainState),
   }))
  console.log('Whats-x',newITems.filter(({CATAGORY_DISPLAY_NAME})=>CATAGORY_DISPLAY_NAME))
}

function buildReq(){
  var socketQueryIO = appSocketQuery.get('socketQueryIO');
  if(productMap.size>0) reBuild()
    socketQueryIO.emit('queryDB',{model:'Products',idConvert:'$nin',query:{'_id':productMap.keys()}},(items)=>{
  Promise.all(
    items.flatMap(x=> dynamicMapData({ [x._id]: x }, productMap, setProductMap, () => setProductMap(p => new Map(p))) )
    ).then(reBuild)
})
}

// delete a product
const submitDelete=(_id)=>{
  socketIO.emit('productDelete',{productID:_id},({success,info,err})=>{ 
    if(err) globalWrn('error',`We had an issue deleting the item.`)
    else if(success){ 
      setPopup2({open:false})
      globalWrn('success',`Delete Successful.`)
    }})
  
}

const deleteItem=(_id)=>{
  setPopup2(p=>({...p,
    open:true,
    zIndex: 1200,
    width: '100%',
    title:`Delete Product`,
    description: 'Are you sure you want to delete this product? This cannot be undone.',
    handleClose: ()=>setPopup2({open:false}),
    handleSubmit: ()=>submitDelete(_id),
    nameInputSets: {},
}))
}

const UpdateItem=(x)=>{

setPopup2(p=>({...p,
    open:true,
    zIndex: 1200,
    width: '100%',
    title:`Update Product`,
    description: 'Your Products will be amazing.',
    inputField: dynamicComponent('CreateProduct','elements',{state:'newInventoryItem',catagory:catagory, defaultValue:x },Output,[buildReq]),
    handleClose: ()=>setPopup2({open:false}),
    nameInputSets: {},
}))
}

function cardBuilder(x,i){
  var {_id,MANUFACTURER_ID,MANUFACTURER,MODEL_NUMBER,PART_NUMBER,UPC_NUMBER,DISPLAY_NAME,SHORT_DESCRIPTION,IMAGE_SPOTLIGHT,T1CAT,T2CAT,T3CAT,MODEL_NUMBER_CLEAN,MSRP,ROW} = x
  if(!_id) return console.log('About to ruin your day',_id)

return <Box sx={{height:'150px',minHeight:'150px',maxHeight:'150px',overflow:'auto',display:'flex'}}><Card sx={{ display: 'inline-block',margin:'auto', flexDirection: 'column',height:'clamp(139px,82%,96%)', width:'96%',padding:'4px 6px',overflow:'hidden'}}>
  <Box sx={{ display: 'flex',justifyContent:'space-between',gap:'5px'}}>
 <Box sx={{ display: 'flex', flexDirection: 'column' }}>
   <CardContent sx={{ flex: '1 0 auto',padding:'1%!important', display:'flex',flexDirection:'column',gap:'5px'}}>
     <Typography variant="body2" sx={{overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical',lineHeight:1.1,fontSize:'14px'}}>{DISPLAY_NAME}</Typography>
     
     <Box sx={{ overflow:'auto',gap:'4px',lineHeight:1,display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
     {SHORT_DESCRIPTION && <Typography variant="caption" color="text.secondary" sx={{lineHeight:1}}>{SHORT_DESCRIPTION}</Typography>}
     {PART_NUMBER && <Typography variant="caption"> | Part #: {PART_NUMBER}</Typography>}
     {MODEL_NUMBER_CLEAN && <Typography variant="caption"> | Model #: {MODEL_NUMBER_CLEAN}</Typography>}
     {MSRP && <Typography variant="caption"> | MSRP: ${MSRP}</Typography>}
     </Box>
     <Box sx={{display:'flex',gap:'3%'}}>
     <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>{setOutput(p=>({...p,[_id]:x})); console.log("oc:", outputRef.current) }}>Select</Button>
    { UserMap.get('level')<=3 && <Box sx={{display:'flex'}}>
      <IconButton onClick={()=>UpdateItem(x)}>
        <EditIcon/>
      </IconButton>
      <IconButton onClick={()=>deleteItem(_id)}>
        <DeleteIcon/>
      </IconButton>
    </Box>
    }
    </Box>
   
   </CardContent>
   
 </Box>
 <Box sx={{ display: 'flex', flexDirection: 'column',justifyContent:'space-evenly',maxWidth:'25vw',gap:'4%' }}>
 {IMAGE_SPOTLIGHT && <Avatar variant="rounded" sx={{ maxWidth: 'clamp(25px,100%,15vh)',maxHeight:'clamp(25px,100%,15vh)', width:'140px', height:'auto',margin:'auto'}} >
   <LazyLoadImage
    alt={'IMAGE'}
    effect="blur"
    threshold={10000}
    height={'100%'}
    width={'100%'}
    style={{objectFit:'contain'}}
    key={'MainLogo'}
    onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
    src={IMAGE_SPOTLIGHT}
  /> 
  </Avatar> }
 </Box>
  </Box>
</Card>
</Box>
}

return <Drawer
          anchor={'right'}
          PaperProps={{ sx: {maxWidth:'80vw', backgroundColor:'rgba(0,0,0,.35)',backdropFilter:'blur(15px)',padding:'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)'}}}
          open={parentStateRef.current.drawerState ? parentStateRef.current.drawerState : false}
          onClose={closeDrawer}
          >
          <Box sx={{display:'flex',flexDirection:'space-evenly',position:'sticky',top:0,zIndex:9,backgroundColor:'#121212',padding:'1%',margin:'1%',borderRadius:'4px'}}>
          <Button sx={{backgroundColor:'#90caf9', color:'black',flex:1}} onClick={createInventory}>Create Product</Button>
          {stateRef.current.filterDropdown}
          </Box>
          <Box ref={module} sx={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
            { stateRef.current?.EKCEntries ? <ViewportList
                viewportRef={module}
                items={stateRef.current?.EKCEntries}
                overscan={55} 
                //initialDelay={15000}
                itemMinSize={300}
                //itemSize={150}
                >
                {cardBuilder}
                </ViewportList> 
            : waveLoad() }
             </Box>
          <SearchBar {...props} mainState={mainState} UpdateItem={UpdateItem} deleteItem={deleteItem} />
        </Drawer>
}

const AccordionSub = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummarySub = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsSub = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function SubItems(props){
  const {viewData,data,ID,Output,globalState,waveLoad,getReq,handlePopupClose,dynamicComponent,productGetAPI,mainState} = props
  const outputState = useState(Object)
  const [, setState, stateRef] = mainState
  const [expanded, setExpanded] = useState(false)
  const [UserMap] = useAtom(globalState.userMapState)
  const [popupData2, setPopup2] = useAtom(globalState.popupState2)
  const [output, setOutput, outputRef] = Output
  const accordion = useRef(null)
  const [divisionMap, setDivisionMap] = useAtom(globalState.divisionMapState)
  const theme = useTheme();

  const [appSocket] = useAtom(globalState.appSocketState)
  var socketIO = appSocket.get('socketIO')

  var list = Array.from([...divisionMap],([k,v])=>v.teamName)

  if(getProps(getReq,ID)) var {params,loadingLabel,nextGet,mapOptions} = getProps(getReq,ID)

  function axoisBuild(pgnum){
   // setState(p=>({...p, [getReq]:{[ID] :waveLoad()} }))
   /* productGetAPI({getReq,params,pgnum:pgnum},(data)=>
      setState(p=>({...p,
       [getReq]:{ [ID] : data,
        pgnum: pgnum ? pgnum : params.pgnum,
      }})) 
    ) */
  }

  useEffect(()=>{
    if(!stateRef.current?.[getReq]?.[ID]) axoisBuild()
  },[])
  
  const handleChange = (panel,id)=>(event, isExpanded)=>{
    setExpanded(isExpanded ? panel : false)
  }

  function buildReq(){
    console.log('buildReq NOOO')
   /*  var socketQueryIO = appSocketQuery.get('socketQueryIO');
   socketQueryIO.emit('queryDB',{model:'Products',query:{}},(x)=>{ 
      setState(p=>({...p,EKCEntries: x }))
     })*/
  }

  const closePopup2=()=>{
    setPopup2(p=>({...p,
        open: false
    }))
  }
  
  const UpdateItem=(x)=>{
  setPopup2(p=>({...p,
      open:true,
      zIndex: 1200,
      width: '100%',
      title:`${x?'Update':'Create'} Product`,
      description: 'Your Products will be amazing.',
      inputField: dynamicComponent('CreateProduct','elements',{state:'newInventoryItem',catagory:catagory, defaultValue:x },outputState,[buildReq]),
      handleClose: closePopup2,
      nameInputSets: {},
  }))
  }
  
  const selectCurrInventory=(x,v,DISPLAY_NAME)=>{
    const divisionName = x
    setPopup2(p=>({...p,
      open: true,
      title:`Transfer Request`,
      description: `Submit transfer request from ${x}`,
      inputField: <Box>{dynamicComponent('transferInventory','elements',{ state: 'inventoryTransf', displayName: DISPLAY_NAME, divName: divisionName, totalAmount: v },outputState)}</Box>,
      handleClose: ()=>handlePopupClose(),
      nameInputSets: {},
    }))
  }

function addMore(){
  var first = parseInt(stateRef.current?.[getReq]?.[ID]?.pop()?.ROW?.split('/')[0],10)
  var last = parseInt(stateRef.current?.[getReq]?.[ID]?.pop()?.ROW?.split('/')?.pop(),10)
  var adding = <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={()=>axoisBuild(stateRef.current?.[getReq]?.pgnum+1)} sx={{m:'2% 5% 0',width:stateRef.current?.[getReq]?.pgnum>1?'40%':'89%'}}>Next Page (Page {stateRef.current?.[getReq]?.pgnum})</Button>
  var subt = <Button variant="contained" startIcon={<AddCircleOutlineIcon />} color="warning" onClick={()=>axoisBuild(stateRef.current?.[getReq]?.pgnum-1)} sx={{m:'2% 5% 0',width:first!==last?'40%':'89%'}}>Prev Page (Page {stateRef.current?.[getReq]?.pgnum-1})</Button>
  if(first===last) return subt
  if(first<last && stateRef.current?.[getReq]?.pgnum>1) return [adding,subt]
  if(first<last) return adding
}

return <Box sx={{width:'100%'}} role="presentation">
<Box ref={accordion} style={{padding:'2%'}}> {
  stateRef.current?.[getReq]?.[ID]?.constructor===Array ? stateRef.current?.[getReq]?.[ID]?.flatMap((x,i,a)=>{
    if(getReq==='getcategoriesjson') var {CATEGORY_DISPLAY_NAME:label,CATEGORY_ID:ID,PRODUCTCOUNT:count} = x
    else if(getReq==='getmanufacturersjson') var {MANUFACTURER:label,MANUFACTURER_ID:ID,PRODUCTCOUNT:count} = x
    else if(getReq==='getproductsjson') var {PRODUCT_ID,MANUFACTURER_ID,MANUFACTURER,MODEL_NUMBER,PART_NUMBER,UPC_NUMBER,DISPLAY_NAME,SHORT_DESCRIPTION,IMAGE_SPOTLIGHT,T1CAT,T2CAT,T3CAT,MODEL_NUMBER_CLEAN,MSRP,ROW} = x
    // else console.log(getReq, 'X:::::',Object.keys(x),'NEXT HERE::', '||',nextGet )

if(getReq==='getcategoriesjson') return <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`,ID)} >
<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id={`panel${i}bh-header`} >
<ListItemText sx={{ flexShrink: 0 }} primary={label} secondary={`${count} Manufacturers`}/> 
</AccordionSummary>
<AccordionDetails sx={{padding:0}}>
{ expanded === `panel${i}` && <SubItems {...props} ID={ID} getReq={'getmanufacturersjson'}/> }
</AccordionDetails>
</Accordion>

if(getReq==='getproductsjson') return <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex',justifyContent:'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto',maxWidth:'50vw' }}>
          <Typography variant="body1">{DISPLAY_NAME}</Typography>
          {SHORT_DESCRIPTION && <Typography variant="caption" color="text.secondary" sx={{lineHeight:1}}>{SHORT_DESCRIPTION}</Typography>}
          <br/>
          {MODEL_NUMBER_CLEAN && <Typography variant="caption"> Model #: {MODEL_NUMBER_CLEAN}</Typography>}
          {PART_NUMBER && <Typography variant="caption"> | Part #: {PART_NUMBER}</Typography>}
          {MSRP && <Typography variant="caption"> | MSRP: ${MSRP}</Typography>}
          { UserMap.get('level')<=3 && <Box>
                <IconButton onClick={()=>UpdateItem(x)}>
                  <EditIcon/>
                </IconButton>
              </Box>
              }
        </CardContent>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column',justifyContent:'space-evenly' }}>
      {IMAGE_SPOTLIGHT && <Avatar variant="rounded" sx={{ maxWidth: 'clamp(25px,100%,15vh)',maxHeight:'clamp(25px,100%,15vh)', width:'100%', height:'auto',margin:'auto'}} >
   <LazyLoadImage
    alt={'IMAGE'}
    effect="blur"
    threshold={10000}
    height={'100%'}
    width={'100%'}
    style={{objectFit:'contain'}}
    key={'MainLogo'}
    onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
    src={IMAGE_SPOTLIGHT}
  /> 
  </Avatar>}
      <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>setOutput(p=>({...p,[PRODUCT_ID]:x})) } >Select</Button>
      </Box>
      </Box>
        <ToggleButtonGroup sx={{overflow:'auto',maxWidth:'65vw',p:'.5%'}}>
            {list.flatMap(x=>['ChromeBook Dallas','Sacramento A/V','Fresno Construction','Chromebook Riverside','EKC Corporate','ChromeBook Arizona','Denver A/V'].includes(x) &&
              <ToggleButton size={'small'} onClick={()=>selectCurrInventory(x,5,DISPLAY_NAME)}>
                  <Typography id={x+'023'} variant="caption" color="text.secondary" sx={{lineHeight:1}}>{x}:<br/>{`5 Available units`}</Typography>
                  </ToggleButton>
            )}
        </ToggleButtonGroup>
    </Card>
    
return <AccordionSub expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`,ID)} >
    <AccordionSummarySub expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id={`panel${i}bh-header`} >
    <ListItemText sx={{ flexShrink: 0, m:0 }} primary={label} /> 
    <Typography variant="caption" >{`${count} Products`}</Typography>
    </AccordionSummarySub>
    <AccordionDetailsSub sx={{p:0}}>
    {expanded === `panel${i}` && <SubItems {...props} ID={ID} getReq={nextGet} />}
    </AccordionDetailsSub>
  </AccordionSub>
 }) : null }
 { (stateRef.current?.[getReq]?.[ID]&&getReq==='getproductsjson'&&stateRef.current?.[getReq]?.[ID]?.constructor===Array) && addMore() }
 </Box></Box>
}

const ListItemBar = styled('li')(({ theme }) =>({
  margin: theme.spacing(0.5),
}));

/*
export function ChipsArray({Output,globalState}) {
  const [, setOutput, outputRef] = Output
  const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState)
  const [chipData, setChipData] = useState([]);

  useEffect(()=>{
  if(outputRef.current) setChipData(Object.keys(outputRef.current).flatMap((x,i)=>({ key: i, label:x})))
  },[outputRef.current])

  const handleDelete = (label) => () => {
    setChipData((chips) => chips.filter((chip) => chip.label !== label));
    setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>k!==label).map(([k,v])=>({[k]:v}))) )
  };

  return <Paper
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        maxWidth: '60vw',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map(({key,label}) =>{
        let icon;

        return (
          <ListItemBar key={key}>
            <Chip
              icon={icon}
              label={`${teamMember.get(label)?.firstName} ${teamMember.get(label)?.lastName}`}
              avatar={<Avatar alt="" src={`https://services.ekc.app/profiles/avatar/${label}_avatar.jpg`}/>}
              onDelete={handleDelete(label)}
            />
          </ListItemBar>
        );
      })}
    </Paper>
} */

export function SearchBar(props) {
  var {That,viewData,globalState,Output,waveLoad,productGetAPI,mainState,deleteItem,UpdateItem} = props
  const {list,projID} = viewData
  if(projID) var { ProjectID, DivisionID, modelType } = projID
  const [teamMember] = useAtom(globalState.teamMemberState)
  const [UserMap] = useAtom(globalState.userMapState)
  const [, setState, stateRef] = mainState
  const [, setOutput, outputRef] = Output
  const [projectStats] = useAtom(globalState.projectsStatsState)
  const [filter, setFilter] = useState(stateRef.current.search);
  const [debouncedFilter] = useDebounce(filter, 500);
  const [open, setOpen] = useState(true);
  const module = useRef(null)
  const handleClick = () => { setOpen(!open) };

  function axoisBuild(pgnum){
    setState(p=>({...p, 
      searchData : stateRef.current?.EKCEntries?.filter(x=> Object.values(x).some(y=>y?.toString().toLowerCase()?.includes(stateRef.current.search?.toString().toLowerCase())) )
    }))
  /*  productGetAPI({getReq:'getproductsjson',params:{
      option: 'global',
      keyword: stateRef.current.search,
      pgsize: 5,
      pgnum: 1,
    },pgnum:pgnum},(data)=>{
      if(data.length>0) setState(p=>({...p,
        searchData : p.searchData ? [...p.searchData,...data] : data,
        pgnum: pgnum ? pgnum : p.pgnum || 1,
      })) 
    }) */
  }

  useEffect(()=>{
    setFilter(stateRef.current.search)
  },[stateRef.current.search])

  useEffect(()=>{
    if(stateRef.current.search) axoisBuild()
  },[debouncedFilter])

 // useEffect(()=>{ console.log('searchData',stateRef.current.searchData) },[stateRef.current.searchData])

    function addMore(){
      var first = parseInt(stateRef.current.searchData?.pop()?.ROW?.split('/')[0],10)
      var last = parseInt(stateRef.current.searchData?.pop()?.ROW?.split('/')?.pop(),10)
      var adding = <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={()=>axoisBuild(stateRef.current.pgnum+1)} sx={{m:'2% 5% 0',width:stateRef.current.pgnum>1?'40%':'89%'}}>Next Page (Page {stateRef.current.pgnum})</Button>
      var subt = <Button variant="contained" startIcon={<AddCircleOutlineIcon />} color="warning" onClick={()=>axoisBuild(stateRef.current.pgnum-1)} sx={{m:'2% 5% 0',width:first!==last?'40%':'89%'}}>Prev Page (Page {stateRef.current.pgnum-1})</Button>
      if(first===last) return subt
      if(first<last && stateRef.current.pgnum>1) return [adding,subt]
      if(first<last) return adding
    }

 // console.log('List??',list)
  return <Paper component="form" variant="outlined" sx={{ p: '2px 4px',m:'1% 1% 0', display: 'flex', position:'sticky', bottom: '.5%', flexDirection:'column', alignItems: 'center', width: '97%', zIndex:998 }} >
      <Box sx={{display: 'flex',w:'100%'}}>
       <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={module}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault() }
        onChange={({target})=> setState(p=>({...p, search: target.value?.length>0 ? target.value : undefined })) }
      />
     { stateRef.current.search &&
      [<Divider variant="middle" flexItem orientation="vertical" />,
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={()=>{ module.current.querySelector('input').value = ''; setState(p=>({...p, search: undefined })) }}>
        <CancelIcon />
      </IconButton>] }
      </Box>
      { stateRef.current.search  && [<Divider />,
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader id="nested-list-subheader">
          <Typography variant="caption" >searching:  { stateRef.current.search }</Typography>
        </ListSubheader>
      }
    >
     { stateRef.current.searchData?.constructor===Array && stateRef.current.searchData
     //.filter(([k,v],i)=> v.some((userID)=> Object.values(teamMember.get(userID)).filter(v=>v?.constructor === String).map(v=>v.toLowerCase()).some(x=>x.startsWith(stateRef.current.search.toLowerCase()))))
     .flatMap((x,i)=>{
      var {PRODUCT_ID,MANUFACTURER_ID,MANUFACTURER,MODEL_NUMBER,PART_NUMBER,UPC_NUMBER,DISPLAY_NAME,SHORT_DESCRIPTION,IMAGE_SPOTLIGHT,T1CAT,T2CAT,T3CAT,MODEL_NUMBER_CLEAN,MSRP,ROW,_id} = x
    return [<ListItemButton sx={{padding:'4px 6px'}} >
        <ListItemText primary={<Typography sx={{lineHeight:1}}>{DISPLAY_NAME}</Typography>} secondary={
        [MODEL_NUMBER_CLEAN && <Typography variant="caption"> Model #: {MODEL_NUMBER_CLEAN}</Typography>,
        PART_NUMBER && <Typography variant="caption"> | Part #: {PART_NUMBER}</Typography>,
        MSRP && <Typography variant="caption"> | MSRP: ${MSRP}</Typography>]
        } />
        <Box sx={{display:'flex',flexDirection:'column'}}>
          <Box sx={{display:'flex',justifyContent:'space-between'}}>
        {IMAGE_SPOTLIGHT && <ListItemIcon>
          <Avatar variant="rounded" sx={{ maxWidth:'100%',maxHeight:'clamp(35px,100%,15vh)',width:'65px', height:'auto',margin:'auto'}} >
        <LazyLoadImage
          alt={'IMAGE'}
          effect="blur"
          threshold={10000}
          height={'100%'}
          width={'100%'}
          style={{objectFit:'contain'}}
          key={'MainLogo'}
          onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
          src={IMAGE_SPOTLIGHT}
        /> 
        </Avatar>
        </ListItemIcon>}
        { UserMap.get('level')<=3 && <Box sx={{display:'flex',justifyContent:'space-evenly',flexDirection:'column'}}>
      <IconButton onClick={()=>UpdateItem(x)}>
        <EditIcon/>
      </IconButton>
      <IconButton onClick={()=>deleteItem(_id)}>
        <DeleteIcon/>
      </IconButton>
    </Box> }
        </Box>
        <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>setOutput(p=>({...p,[_id]:x})) } >Select</Button>
        </Box>
      </ListItemButton>]
     }) }
     { stateRef.current?.searchData?.constructor===Array && addMore() }
    </List>] }
    </Paper>
}