import React from "react";
import useState from 'react-usestateref';
import { useAtom } from 'jotai';
import { Typography, ListItemText } from '@mui/material';
//import DateBox from 'devextreme-react/date-box';
//import 'devextreme/dist/css/dx.dark.css';
import dayjs from 'dayjs';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

 const DefaultButton=({That,viewData,action,dynamicComponent,globalState})=>{
  const {label,list,replace, defaultValue,state,pramPass,min,max,startIcon, full, color, style,variant,disabled} = viewData;
  const [,setParentState] = That;
  const mainState = useState(Object);
  // const [, setState, stateRef] = mainState;
  const [popupData, setPopup] = useAtom(globalState.popupState)
  const [popupData2, setPopup2] = useAtom(globalState.popupState2)
  const [popupData3, setPopup3] = useAtom(globalState.popupState3)
  const [popupData4, setPopup4] = useAtom(globalState.popupState4)

  const stateChgMap=(val)=>{ 
  setParentState(p=>({...p,...{[state]:val}}))
  if(action) action.forEach((x)=>x({[state]:val,pramPass:pramPass})) 
  }

  const popupPick = ()=>{
    if(popupData.open===false) var setMainPopup = setPopup;
    else if(popupData2.open===false) var setMainPopup = setPopup2;
    else if(popupData3.open===false) var setMainPopup = setPopup3;
    else if(popupData4.open===false) var setMainPopup = setPopup4;

    setMainPopup(p=>({...p,
      open:true,
      title:label,
      inputField: <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateTimePicker 
          defaultValue={dayjs(defaultValue)} 
          onAccept={(x)=>{
            stateChgMap(dayjs(x).toISOString())
            setMainPopup({open:false})
          }}
          onClose={()=>setMainPopup({open:false})}
          />
    </LocalizationProvider>
  }))

  }


return dynamicComponent('DefaultButton','elements',{state:'datePickerBttn',
label: <ListItemText 
          primary={<Typography variant="body1" sx={{ display: '-webkit-box',WebkitLineClamp: 1, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis',lineHeight:1.1,fontSize:'13px'}}>{label}</Typography>} 
          secondary={<Typography variant="caption" sx={{ display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis',lineHeight:1,fontSize:'12px'}}>{defaultValue ? dayjs(defaultValue).format('MMM Do YY  h:ma') : 'Date Needed'}</Typography>}
 />,type:'button',startIcon:<MoreTimeIcon/>,full:true,style:{backgroundColor:state.includes('startDate')?'#6FC276': state.includes('endDate') ? '#ff2c2c': '#90caf9',color:state.includes('startDate')?'#fff': state.includes('endDate') ? '#fff': '#2e2e2e'}},mainState,[popupPick])
  }

export default DefaultButton