import React, { useEffect} from 'react';
import { Box,Button,Collapse,IconButton,List,ListItem,ListItemText,Divider} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionGroup } from 'react-transition-group';
import useState from 'react-usestateref';

function renderItem({ item, removeItem }) {
  return [ <ListItem sx={{p:'0 33px 0 1%'}}
        secondaryAction={
        removeItem && <IconButton edge="end" aria-label="delete" title="Delete" onClick={() => removeItem(item.key)} sx={{p:0}} >
        <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText primary={item} />
    </ListItem>,
    <Divider variant="middle" /> ]
}

export default function Listable({That, viewData:{label, state, defaultValue, style, pramPass, items, required, disableAdd,layout}, action, dynamicComponent}) {
  const outputState = useState(Object)
  const [, setOutput, {current: outputRef}] = outputState
  const [, setParentState] = That
  const [, setItems, {current: itemRef}] = useState(Array)

  const addNewItem = (def,callBK) =>{ var genID = makeKey()
   setItems((prev) => [...prev,<Collapse key={genID}>{renderItem({item:<Box key={genID} sx={{display:'flex',gap:'5px',flexDirection:layout?layout:'column',flexWrap:'wrap'}}>{items.map(({viewData,id,loc,flex,minWidth,action},i)=>{
    if(def && def[viewData.state]) viewData.defaultValue = def[viewData.state]
    else if(viewData.defaultValue) delete viewData.defaultValue
    return <Box sx={{flex:flex||1,margin:'auto',minWidth:minWidth||'unset',width:'100%'}}>{dynamicComponent(id,loc,{...viewData,index:prev.length,state:viewData.state+'_'+i+'-'+genID},outputState,action)}</Box>
  })}</Box>,removeItem})}</Collapse>])
  if(callBK) callBK(genID)
  }

  const removeItem = (k) =>{ 
    setOutput(Object.assign({},...Object.entries(outputRef).filter(([k2,v2])=>!k2.endsWith(k)).map(([k2,v2])=>({[k2]:v2}))))
    setItems(itemRef.filter(y=>y.key!==k))
  }

  function makeKey(){
    var newKey= Math.random()
    if(itemRef?.some(x=>x.key===newKey)) return makeKey()
    return newKey
  }

useEffect(()=>{
var preOut = Object.entries(outputRef).filter(([k,v])=>v!=="").reduce((newObj,[k,v]) => {
  if (k) {
  var c = k.split('-')[1]
  var c2 = k.split('_')[0] 
  newObj[c] = {...newObj[c],[c2]:v};
  }
  return newObj;
},{})


if(outputRef){
var outReady = Object.assign([],...Object.entries(preOut).filter(([k,v])=>required && items.length!==Object.keys(v).length ?false:true).flatMap(([k,v],i)=>({[i]:v})))
  setParentState(p=>({...p,...{[state]:outReady}}))
  if(action) action.forEach((x)=>x({[state]:outReady,pramPass:pramPass?pramPass:false})) 
}
},[outputRef]) 

useEffect(()=>{
  if(defaultValue && defaultValue?.constructor === Array){
    defaultValue?.forEach(x=>{
      if(x._id) delete x._id
      addNewItem(x,(y)=>{ setOutput(p=>({...p,...Object.assign({},...Object.entries(x).map(([k,v],i)=>({[k+'_'+i+'-'+y]:v}))) })) })
    })
  }
},[])

useEffect(()=>{
 if(itemRef.length===0) addNewItem()
},[itemRef.length])

  return <Box style={{display:'flex',flexDirection:'column',border:'1px solid',borderRadius:'8px',padding:'1%',paddingTop:0,zIndex:1}}>
        <List sx={style||{padding:0}}>
          <TransitionGroup>
            {itemRef}
          </TransitionGroup>
        </List>
      {!disableAdd && <Button variant={"contained"} sx={{position:'sticky',bottom:'1%'}} onClick={addNewItem}>{label}</Button>}
    </Box>
}
