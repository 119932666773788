  import React, { useEffect } from "react";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
	import mainLogo from'../public/scripts/images/ekc-logo-sml.png';
  import useState from 'react-usestateref';
  import { useAtom } from 'jotai';
  import Carousel from 'react-material-ui-carousel'
  import { useTheme } from '@mui/material/styles';

  import { LazyLoadImage } from 'react-lazy-load-image-component';
  import { Card,CardContent,Box,Divider,Typography,Chip,List,ListItem,ListItemText,ListItemButton,Checkbox,FormControl,Paper,Button,IconButton, CardMedia, Avatar, Stack } from '@mui/material';
  import StartIcon from '@mui/icons-material/Start';
  import FaceIcon from '@mui/icons-material/Face';
  import KeyIcon from '@mui/icons-material/Key';
  import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
  import InfoIcon from '@mui/icons-material/Info';

const avatarStyle = {
    width: 115,
    aspectRatio: '1/1',
    objectFit: 'cover',
    margin: '2% 0'
  }

export const View_SignUp = ({membersList,globalState,dynamicComponent,viewAnimate,objectItems,updateUser,pnlAminte,globalWrn,inputAlert,checker}) => {

  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const outputState = useState(Object)
  const [output, setOutput, outputRef] = outputState
  const [, setAppState] = useAtom(globalState.appState)
  const [UserMap, setUserMap] = useAtom(globalState.userMapState)
  const [appSocket] = useAtom(globalState.appSocketState)

  const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);
  var socketIO = appSocket.get('socketIO');

  const avatarStyle2 = {backgroundSize:'cover',backgroundPosition:'center',width:'105px',height:'105px',borderRadius:'50%',backgroundImage:`url(https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${UserMap.get('avatar')}_avatar.jpg)`}

  function imageSet(x){
    x.forEach((data)=>{ 
      setOutput(p=>({...p,avatar:data})) 
      setState(p=>({...p,
        avatarOptions: dynamicComponent('Dropzone','elements',{state:"avatar",label:'Drag/Click to add profile image',avatar:avatarStyle2},mainState,[imageSet])
      }))
    })
  }

  const startBuilder =()=> setState(p=>({...p,
    submit: false,
    submitBttn : undefined,
    loginOptions: dynamicComponent('DropDown','elements',{state:'pageID',label: <FontAwesomeIcon icon={faUsersCog}/>, list: {'Sign In':'SignIn','Forgot':'Forgot'}, replace:true },outputState,[returnBack]),
    firstNameField: dynamicComponent('InputField','elements',{state:'firstName',label:'1. First Name',variant:'standard',defaultValue:outputRef.current.firstName},outputState),
    lastNameField: dynamicComponent('InputField','elements',{state:'lastName',label:'2. Last Name',variant:'standard',defaultValue:outputRef.current.lastName},outputState),
    emailField: dynamicComponent('InputField','elements',{state:'email',label:'3. Email',variant:'standard',type:'email',defaultValue:outputRef.current.email},outputState),
    userNameField: dynamicComponent('InputField','elements',{state:'userName',label:'2. Username',variant:'standard',defaultValue:outputRef.current.userName},outputState),
    avatarOptions: dynamicComponent('Dropzone','elements',{state:"avatar",label:'Drag/Click to add profile image',avatar:avatarStyle2},mainState,[imageSet])
    //dynamicComponent('avatar','component',{stateChg:['updateLocal'],state:'avatar', maxWidthOrHeight: 300},outputState)
  }))


  useEffect(() =>{
    startBuilder()
    setAppState(p => { p.set('pageHold', true); return new Map(p)}) 
  },[]);

  useEffect(()=>{
    if(UserMap.get('type')==="inviter") setState(p=>({...p,submit: true, userID: UserMap.get('userID'), requestorID:UserMap.get('requestor') }))
  },[UserMap])

  useEffect(()=>{
    if(socketIO?.connected){
       depatmentOptions()
       membersOptions({'local.level':{ $lte: 5 }});
    }
  },[socketIO?.connected])

  useEffect(() =>{
    if(!Object.entries(outputRef.current).some(([k,v])=>v==='') && !stateRef.current.submit && checker(Object.keys(outputRef.current), ['firstName', 'lastName', 'email']) ) setState(p=>({...p,
      submitBttn : dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:'Next',type:'save',startIcon:<StartIcon/>,full:true,style:{borderTopLeftRadius:'unset', borderTopRightRadius:'unset'}},mainState,[validate])
    }))
    else if(!Object.entries(outputRef.current).some(([k,v])=>v==='') && stateRef.current.submit && checker(Object.keys(outputRef.current),['firstName', 'lastName', 'email', 'userName', 'password']) ) setState(p=>({...p,
      submitBttn : dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:'Submit',type:'save',startIcon:<LibraryAddCheckIcon/>,full:true,style:{borderTopLeftRadius:'unset', borderTopRightRadius:'unset'}},mainState,[submit])
    }))
    else if(!Object.entries(outputRef.current).some(([k,v])=>v==='') && stateRef.current.userID && stateRef.current.submit && checker(Object.keys(outputRef.current),['userName', 'password']) ) setState(p=>({...p,
      submitBttn : dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:'Submit',type:'save',startIcon:<LibraryAddCheckIcon/>,full:true,style:{borderTopLeftRadius:'unset', borderTopRightRadius:'unset'}},mainState,[submit])
    }))
    else setState(p=>({...p,submitBttn : undefined }))
  },[outputRef.current]);

/*useEffect(() =>{
if(Object.entries(state).length > 0 && !state.mounted){
  panelSelect(mainState,signup,true)

  if(signup.current){
    viewAnimate(signup.current);
    objectItems( signup.current,'li','.2')
  }
  else console.log('NOPE',signup)
  setState(p=>({...p,mounted:true}))
}
//console.log('SIGNUP STATE CHANGED',state)
},[state]);*/

const membersOptions=(query)=>{ 
  var socketQueryIO = appSocketQuery.get('socketQueryIO');
  socketQueryIO.emit('queryDB',{model:'User',query:query,filter:{'local.firstName':1,'local.lastName':1}},async(items,filter)=>{
  if(!items ||items.length <= 0) membersOptions({'local.level':1})
  else if(items.length <= 0 && query == ({'local.level':1}) ){
    var items = [{local:{firstName: "No Members",lastName: "Found"},_id: false}]; 
    delete output.manager
  }else{
 var list = items.map((x)=>({[x.local.firstName+' '+x.local.lastName]: x._id}) )


 setState(p=>({...p,
  managementOptions: dynamicComponent('DropDown','elements',{
    state: 'manager',
    label: 'Select A Supervisor',
    defaultValue: 'Select A Supervisor',
    list: Object.assign(...list), 
    replace:true 
    },outputState) 
 }))
 }})
}

const depatmentOptions=()=>{
  var socketQueryIO = appSocketQuery.get('socketQueryIO');
  socketQueryIO.emit('queryDB',{model:'DivCheck',query:{},filter:{'division.teamName':1}},(x,y)=> 
    setState(p=>({...p,
    departmentOptions: dynamicComponent('DropDown','elements',{
      state: 'division',
      label: 'Select A Divison',
      defaultValue: 'Department',
      list: Object.assign(...x.map(z=>( { [z.division.teamName] : z._id} ) ) ), 
      replace:true 
      },outputState,[optionDrop]) 
  }))
  )
},

 validateEmail=(email)=>{
const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
return re.test(String(email).toLowerCase());
},

optionDrop=({division})=> membersOptions({$or:[{'local.level':{$lte:5},'local.division':division},{'local.SupervisorOf':division}]}),
//membersList(['updateLocal'], {$or:[{'local.level':{$lte:5},'local.division':x},{'local.SupervisorOf':x}]},signup,mainState),

returnBack=(x)=>{

 setAppState(p => { p.set('pageHold', false); return new Map(p)}) 
 Object.entries(x).forEach(([k,v])=>{
   if(v!=='PageBack') updateUser({state:k,value:v})
    else startBuilder()
  }) 
},

validate=()=>{
  if(checker(Object.keys(outputRef.current),['firstName', 'lastName', 'email']) && validateEmail(outputRef.current.email)) socketIO.emit('validSignup01',{email:outputRef.current.email},(x)=>{ 
    if(x === true) setState(p=>({...p,
      submit: true,
     // loginOptions: dynamicComponent('DropDown','elements',{state:'pageID',label: <FontAwesomeIcon icon={faUsersCog}/>, list: {'Page Back':'PageBack','Sign In':'SignIn','Forgot':'Forgot'}, replace:true },outputState,[returnBack]),
      submitBttn : undefined
    }))
    if(x === 'found') globalWrn('error',"Your Email Already Exists")
  })
if(!outputRef.current.email || !validateEmail(outputRef.current.email)) globalWrn('error',"A valid Email is required")
if(!outputRef.current.lastName || outputRef.current.lastName==='') globalWrn('error',"A valid Last Name is required")
if(!outputRef.current.firstName || outputRef.current.firstName==='') globalWrn('error',"A valid First Name is required")
},

submit=()=>{
  setState(p=>({...p,loading: true }))
  if(stateRef.current.userID && checker(Object.keys(outputRef.current),['userName', 'password']) && stateRef.current.validPW === true && outputRef.current.password === outputRef.current.passwordConf) return socketIO.emit('validPartner', {...outputRef.current,userID:stateRef.current.userID} ,({success})=>{
    setState(p=>({...p,loading: false }))
    if(success === false){
      globalWrn('error',`Sorry the UserName "${outputRef.current.userName}" is already in use.`)
      setState(p=>({...p,loading: false }))
    }
    if(success===true){ 
      globalWrn('success',`"${outputRef.current.userName}" has now been created.`)
      updateUser({state:'pageID',value:'SignIn'})
    }
  })
  else if( checker(Object.keys(outputRef.current),['firstName', 'lastName', 'email', 'userName', 'password','avatar']) && stateRef.current.validPW === true && outputRef.current.password === outputRef.current.passwordConf) 
  socketIO.emit('validSignup02', outputRef.current ,(x)=>{
    setState(p=>({...p,loading: false }))
    if(x === 'failed') globalWrn('error',`Sorry the UserName "${outputRef.current.userName}" is already in use.`)
    if(x===true){ 
      setState(p=>({...p,nextSteps: true }))
     /* globalWrn('success',`"${outputRef.current.userName}" has now been created.`)
      updateUser({state:'pageID',value:'SignIn'})
      setTimeout(() => {globalWrn('info',`Please check your email "${outputRef.current.email}" to validate this account.`) }, 2000);
      setTimeout(() => { globalWrn('info',`Our team will be approving your account, until then you can login with limited access to modify your profile.`)}, 5000);*/
    }
  })
  if(!outputRef.current.avatar){
     globalWrn('error','A Profile Picture is required')
     setState(p=>({...p,loading: false }))
    }
  if(!outputRef.current.userName){
     globalWrn('error','A Username is required')
     setState(p=>({...p,loading: false }))
    }
  if(!outputRef.current.password){
     globalWrn('error', 'Cannot leave Password Blank')
     setState(p=>({...p,loading: false }))
    }
  if(!outputRef.current.passwordConf){ 
    globalWrn('error','You must retype your Password.')
    setState(p=>({...p,loading: false }))
    }
  if(outputRef.current.password !== outputRef.current.passwordConf) {
    globalWrn('error','Passwords Do Not Match.')
    setState(p=>({...p,loading: false }))
    }
  if(stateRef.current.validPW !== true){
     globalWrn('error','Your Password must meet all requirements.')
     setState(p=>({...p,loading: false }))
    }
}

var items = [
  {
      name: "Verify Your Email",
      description: `You will be sent an email asking you to verify your email address, please note that you may receive a popup "You are submitting information to an external page. Are you sure?", Please select OK to verify your account. Additionally email clients like Outlook may block you from verifying your account.`,
      image: "https://dev-services.ekc.app/scripts/images/Account-Verification.jpg",
      buttons: [
       // {label: 'Next Step',action:'next'},
        {label: 'Skip to Sign In',action:'SignUp'}
      ]
  },
  {
      name: "Manament Approvail Period",
      description: "The manager that you set (or if you didn't select a manager, the HR team will assign one for you) will approve you and select your level of access. This can take up to 24 hours.",
      image: "https://dev-services.ekc.app/scripts/images/Administrator-Approval.jpg",
      buttons: [
      //  {label: 'Next Step',action:'next'},
        {label: 'Skip to Sign In',action:'SignUp'}
      ]
  },
  {
      name: "While Waiting For Access",
      description: "Once you have verified your email from step 01, you will be granted temporary limited access to modify/update your profile account.",
      image: "https://dev-services.ekc.app/scripts/images/Sign-Up.jpg",
      buttons: [
        {label: 'Sign In',action:'SignUp'}
      ]
  }
]

return stateRef.current.nextSteps ? <Carousel sx={{minWidth:'85vw'}} animation={'slide'} interval={"8500"} >
      { items.map( (item, i) => <Item key={i} {...item} index={i+1} updateUser={updateUser} globalState={globalState} /> ) }
  </Carousel> 
  : <Card>
    <Box sx={{display:'flex',gap:'3%',padding:'3%'}}>
    <LazyLoadImage
              alt={'IMAGE'}
              effect="blur"
              threshold={10000}
              height={40.83}
              width={70}
              key={'MainLogo'}
              onError={(e)=>e.target.src = 'https://services.ekc.app/scripts/images/ekc-logo-sml.png'}
              src={mainLogo}
              />
            <Typography variant="h6" sx={{lineHeight:1,margin:'auto'}}>Sign Up</Typography>
          <Box className={"loginDrop"} style={{height:45,display:'flex'}}>{stateRef.current.loginOptions}</Box>
    </Box>
    <Box sx={{padding:'0 2% 2%'}}>
    <Chip icon={<InfoIcon/>}  sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }} label={<Typography style={{whiteSpace: 'normal',lineHeight:1.1,padding:'2% 0'}}>Once all fields are properly filled, the {!stateRef.current.submit?"NEXT":"SUBMIT"} button will appear.</Typography>}/>

    </Box>
  
       <Divider />
       <CardContent sx={{padding:'1% 4%', display:'flex', flexDirection:'column', gap:'5px'}}>
       { !stateRef.current.submit ? [stateRef.current.firstNameField, stateRef.current.lastNameField,stateRef.current.emailField,
       <Box sx={{display: 'flex',alignItems:'center',justifyContent:'space-between',padding:'unset',columnGap:'3%'}}>
         <Typography gutterBottom variant="subtitle1">
         4. Select Division:
         <Typography variant="caption">(Optional)</Typography>
         </Typography>
         {stateRef.current.departmentOptions}
       </Box>] : [<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'unset',columnGap:'3%'}}>
          <Box sx={avatarStyle}>{stateRef.current.avatarOptions}</Box>
          <Box sx={{display: 'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between',padding:'unset',columnGap:'3%'}}>
          <Chip icon={<FaceIcon/>} label={<Typography style={{whiteSpace: 'normal',lineHeight:1.1,padding:'3% 0'}}>1. Click or Drag & Drop a profile picture to the Avatar.</Typography>}
          sx={{height:"100%",margin:'1% auto'}}/>
          {stateRef.current.userNameField}
          </Box>
        </Box>,<Divider/>,<PasswordCheck globalState={globalState} dynamicComponent={dynamicComponent} outputState={outputState} That={mainState}/>,<Divider/>,
         !stateRef.current.userID ? <Box sx={{display: 'flex',alignItems:'center',justifyContent:'space-between',padding:'unset',columnGap:'3%'}}>
         <Typography variant="subtitle1" sx={{lineHeight:1.1}}>
          5. Select Manager:
          <Typography variant="caption">(Optional)</Typography>
         </Typography>
         { stateRef.current.managementOptions }
       </Box>: null]}
       </CardContent>
       <Box >
       { stateRef.current.submitBttn }
      </Box>
  </Card>
}

const Item = ({name,description,image,buttons,index,updateUser,globalState}) =>{
  const [, setAppState] = useAtom(globalState.appState)
  const theme = useTheme();

    return <Card sx={{ display: 'flex',height:'clamp(45vh,400px,65vh)',m:'.75%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column',position:'absolute', backgroundColor:'rgba(0,0,0,.5)', height:'100%',maxWidth:'clamp(250px,45%,300px)', backgroundColor: 'rgba(0,0,0,.35)', backdropFilter: 'blur(8px)', textShadow: '1px 1px 2px rgba(0,0,0,.65)' }}>
      <CardContent sx={{ flex: '1 0 auto',display:'flex',flexDirection:'column',gap:'10px' }}>
        <Chip color="primary" avatar={<Avatar>0{index}</Avatar>} label={'NEXT STEPS'} />
        <Typography variant="h5" sx={{lineHeight:1}}>
          {name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{lineHeight:1.2}}>
          {description}
        </Typography>
      </CardContent>
      <Stack direction="row" spacing={2} sx={{justifyContent:'space-evenly',m:'2% 0'}}>
      { buttons && buttons.flatMap(({label,action})=>
           <Button variant="contained" onClick={()=>{
            if(action==='next') console.log('Next',action)
            else{
            setAppState(p => { p.set('pageHold', false); return new Map(p)}) 
           updateUser({state:'pageID',value:'SignIn'})
            }
          }}>{label}</Button>
       )}
    </Stack>
    </Box>
    <CardMedia
      component="img"
      //sx={{ width: 151 }}
      image={image}
      alt={name}
    />
  </Card>
}



export function PasswordCheck({dynamicComponent,outputState,That}) {
  const [, setState, stateRef] = That
  const [, setOutput, outputRef] = outputState

  useEffect(() =>{
    if(outputRef.current.password===undefined)return
    var val = outputRef.current.password 
    var upperCase= new RegExp('[A-Z]');
    var lowerCase= new RegExp('[a-z]');
    var numbers = new RegExp('[0-9]');
    var special = /[!@#$%\^&*(){}[\]<>?/|\-]/;
    
    if(val.length < 8) setState(p=>({...p,'Min. Length: 8' : false }))
    else setState(p=>({...p,'Min. Length: 8' : true }))
    
    if(val.match(upperCase) && val.match(lowerCase)) setState(p=>({...p,'Uppercase & Lowercase' : true }))
    else setState(p=>({...p,'Uppercase & Lowercase' : false }))
    
    if(val.match(numbers)) setState(p=>({...p,'A Number' : true }))
    else setState(p=>({...p,'A Number' : false }))
    
    if(val.match(special)) setState(p=>({...p,'A Special Character' : true }))
    else setState(p=>({...p,'A Special Character' : false }))
    
    if( val.length >= 8 && val.match(upperCase) && val.match(lowerCase) && val.match(numbers) && val.match(special) ){
      setState(p=>({...p,validPW:true}))
      setOutput(p=>({...p,validPW:true}))
    }else{
      setState(p=>({...p,validPW:false})) 
      setOutput(p=>({...p,validPW:false}))
    }
   
  },[outputRef.current.password]);

  useEffect(() =>{
    setState(p=>({...p,
      password: dynamicComponent('InputField','elements',{state:'password',label:'3. Password',variant:'standard',type:'password',defaultValue:outputRef.current.password},outputState),
      passwordConf: dynamicComponent('InputField','elements',{state:'passwordConf',label:'4. Retype Password',variant:'standard',type:'password',defaultValue:outputRef.current.passwordConf},outputState)
    })) 
  },[]) 

return <FormControl>
  <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'unset',columnGap:'3%'}}>
<Box sx={{flex:1}}>
<CheckboxList manual={true} list={['Uppercase & Lowercase','A Special Character','A Number','Min. Length: 8']} parentState={That} />
</Box>
<Box sx={{display:'flex',flex:1.3,flexDirection:'column',alignItems:'center',justifyContent:'space-between',padding:'unset',columnGap:'3%'}}>
<Chip icon={<KeyIcon />} label={<Typography style={{whiteSpace: 'normal',lineHeight:1.1,padding:'3% 0'}}>Create a password by meeting all the requirements.</Typography>} sx={{height:"100%",margin:'1% auto'}}/>
{ stateRef.current.password }
{ stateRef.current.passwordConf }
</Box>
</Box>
</FormControl>
}

function CheckboxList({list,manual,parentState}) {
  const [checked, setChecked] = useState([]);
  const [output, setOutput, outputRef] = parentState
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    setChecked(newChecked);
  };

  useEffect(() =>{
  list.forEach(x=>{
   if(outputRef.current[x] && outputRef.current[x]===true && !checked.some(y=> x===y)) setChecked(p=>[...p,x])
   else if( outputRef.current[x]===false && checked.some(y=> x===y)) setChecked(p=>[...p].filter(y=>x!==y))
  })
  },[outputRef.current])

return <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding:0 }}>
      {list.map((value,i) => {
      const labelId = `checkbox-list-secondary-label-${value}`;
      return [ i!==0 && <Divider variant="middle" />,
           <ListItem
            key={value}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={manual ? null : handleToggle(value)}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            }
            disablePadding >
            <ListItemButton>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          </ListItem>]

      })}
    </List>
}