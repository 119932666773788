import React, { Fragment, useEffect } from "react";
import { createNewFolder,getFilesList,uploadFile } from '../Redux/actions';
import { connect, Provider } from 'react-redux';


const TaskManager=({That,uploadFile,createNewFolder,getFilesList})=>{
    const [, setParentState, parentStateRef] = That
useEffect(()=>{
    setParentState(p=>({...p,...{
        uploadFile:uploadFile,
        createNewFolder:createNewFolder,
        getFilesList:getFilesList,
    }}))  
},[])

return null
}

const mapStateToProps = (store) => store 
const mapDispatchToProps = dispatch => ({
    uploadFile: (files, path) => dispatch(uploadFile(files, path)),
    createNewFolder: (destination, folderName) => dispatch(createNewFolder(destination, folderName)),
    getFilesList: (path) => dispatch(getFilesList(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskManager);