    import React, { useEffect } from "react";
    import { useAtom } from 'jotai';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
    import { faBriefcase, faHardHat, faFolderTree, faUsers, faUserShield, faProjectDiagram, faTruck, faCompass, faChartLine, faAddressCard, faHeading, faClipboardList, faGraduationCap, faUserClock, faCommentsDollar, faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
    import { faCloudscale } from '@fortawesome/free-brands-svg-icons';
    import useState from 'react-usestateref';

    import {Button,List,ListItem,ListItemText,ListItemAvatar,ListSubheader,Divider,Grow} from '@mui/material';

    const iconSet = {
      Discover: <FontAwesomeIcon icon={faCompass}/>,
      Trends: <FontAwesomeIcon icon={faChartLine}/>,
      Projects: <FontAwesomeIcon icon={faProjectDiagram}/>,
      EOD: <FontAwesomeIcon icon={faUserClock}/>,
      EOS : <FontAwesomeIcon icon={faCloudscale}/>,
      Directory: <FontAwesomeIcon icon={faAddressCard}/>,
      EKC360: <FontAwesomeIcon icon={faUserShield}/>,
      Teams: <FontAwesomeIcon icon={faUsers}/>,
      'HR/Payroll': <FontAwesomeIcon icon={faHeading}/>,
      Roadside: <FontAwesomeIcon icon={faTruck}/>,
      Safety : <FontAwesomeIcon icon={faHardHat}/>,
      "EKC University": <FontAwesomeIcon icon={faGraduationCap}/>,
      Sales : <FontAwesomeIcon icon={faCommentsDollar}/>,
      Operations : <FontAwesomeIcon icon={faBriefcase}/>,
      Chromebook : <FontAwesomeIcon icon={faFolderTree}/>,
      'AV Files' : <FontAwesomeIcon icon={faFolderTree}/>,
      'Inventory': <FontAwesomeIcon icon={faBoxesStacked}/>,
      'Direct Sales Files': <FontAwesomeIcon icon={faFolderTree}/>,
      'Tickets': <FontAwesomeIcon icon={faClipboardList} />
    };

export const Component_verticleMenu = ({That,globalState,dbUpdateValidate,updateUser,useIsMobile}) => {
//const [state, setState, stateRef] = useState(Object)
const [menus, setMenus, menusRef] = useState(Object)
const [parent, setParent, parentRef] = That
const [UserMap,setUserMap] = useAtom(globalState.userMapState)
const [globalAppState, setGlobalAppState] = useAtom(globalState.appState)
const [menuItems] = useAtom(globalState.menuItemState)
var menuFocus = globalAppState.get('menuFocus')
var isMobile = useIsMobile(560)


const handleClick=(k,m,v)=>{
 // updateUser({state:'active',value:k})
 // updateUser({state:'moduleID',value:m+'_'+v})
 setUserMap(p=>{p.set('pageStamp',{...UserMap.get('pageStamp'),active:k,moduleID:m+'_'+v});return new Map(p)})
 dbUpdateValidate({model:'User',query:true,update:{"local.pageStamp.active":k,"local.pageStamp.moduleID":m+'_'+v}},(x)=>{ })
  if(globalAppState.has('modulePop')) setGlobalAppState(p=>{ p.delete('modulePop'); return new Map(p) })
}

const vertStyles =(i)=>({
  ListSubheader:{
    paddingLeft:0,
    paddingRight:0,
    textAlign:'center',
    top: isMobile ? 58 : 40,
    textShadow:'1px 1px #2e2e2e',
    lineHeight:2,
    background:'transparent',
    // transition: 'all .5s',
    zIndex: menuFocus ? 999 : 25,
  },
  ListItem:{
    padding:'3%',
    borderRadius:'4px',
    margin: '4% auto auto auto', //parentRef.current.menuFocus||parentRef.current.mobileMenu?'4% auto auto auto':'18% auto auto auto',
    marginLeft: menuFocus ?'5px':'1px',
    "&:hover": {
      background:'rgba(255,255,255,.05)'
    }
  },
  Button:{
    minWidth:'unset',
    width:'55px',
    height:'55px',
    fontSize:'28px',
    boxShadow:'-0.5px -0.5px 0 hsl(0deg 0% 100% / 15%)'
  },
  ListItemText:{
    margin: menuFocus||!parentRef.current.mobileMenu?'auto 6px auto 10px':0,
    textShadow:'1px 1px #2e2e2e',
    color:'#fff',
    pointerEvents:'none',
    maxWidth: menuFocus||!parentRef.current.mobileMenu ? '100%' : 0,
    opacity: menuFocus||!parentRef.current.mobileMenu ? 1 : 0,
    transition: menuFocus?`max-width .75s ease-in,opacity 1s ease-in ${i?i/4:.5}s`:'max-width .04s ease-in',
  }
})

const itemList = (k,v,i) => 
setMenus(p=>({...p,...{
  [k]: <List key={k+i} sx={{ bgcolor:'transparent' }}>
  
  <ListSubheader sx={vertStyles().ListSubheader}> 
    <Divider variant="middle" sx={!menuFocus?{gap:'10px', margin: 0,'& span':{padding:0}}:null} >{k}</Divider>
  </ListSubheader>
  
  { Object.entries(v).map(([k2,v2],i)=> 
  <Grow key={k2+i} in={true} timeout={{ enter: (i)*500 }}>
   <ListItem sx={vertStyles().ListItem} onClick={()=>handleClick(k2,k,v2)}>
    <ListItemAvatar sx={{margin:'auto'}}>
    <Button variant="contained"  aria-label={k2} sx={{...vertStyles().Button,...{color: UserMap.get('pageStamp')?.active === k2 ? '#fff' : '#808191', backgroundColor: UserMap.get('pageStamp')?.active === k2 ? '#ff7551':'#21364E', "&:hover": { backgroundColor: i % 2 === 0 ? "#6c5ecf" : "#32a7e2", color: '#fff'
    }}}} >
    { iconSet[k2] }
    </Button>
    </ListItemAvatar>
   <ListItemText 
    primary={k2} 
   // secondary="Jan 9, 2014" 
    sx={vertStyles(i).ListItemText}/>
  </ListItem>
  </Grow>
   )}
   </List>
  }}))

useEffect(()=>{
  if(menuItems.size>0) Array.from([...menuItems],([k,v],i)=> itemList(k,v,i) )
 },[menuItems,menuFocus,parentRef.current.mobileMenu,UserMap.get('pageStamp')?.active]);

return Object.values(menusRef.current)
  }
