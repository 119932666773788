 /* eslint-disable no-unused-expressions */
 import React, { useEffect } from "react";
 import { useAtom } from 'jotai';
 import useState from 'react-usestateref';
 import AvTimerIcon from '@mui/icons-material/AvTimer';
 import VerifiedIcon from '@mui/icons-material/Verified';
 import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
 import SnoozeIcon from '@mui/icons-material/Snooze';
 import dayjs from "dayjs";
 import { LazyLoadImage } from 'react-lazy-load-image-component';
 import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
 import * as localizedFormat from 'dayjs/plugin/localizedFormat';
 import * as isToday from 'dayjs/plugin/isToday';

 import MenuIcon from '@mui/icons-material/Menu';
 import LogoutIcon from '@mui/icons-material/Logout';

 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faProjectDiagram, faUserClock } from '@fortawesome/free-solid-svg-icons';
 import LocalShippingIcon from '@mui/icons-material/LocalShipping';

 import {Box,Typography,Toolbar,Paper,AppBar,IconButton,ListItem,Chip,ListItemText,Avatar,Divider} from "@mui/material";
 import { styled } from '@mui/material/styles';
 dayjs.extend(localizedFormat);
 dayjs.extend(isToday);
 dayjs.extend(isSameOrBefore)

 const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));


const FinishPopup=({dynamicMapData,dbUpdateValidate,dynamicComponent,globalState,viewData,globalWrn,waveLoad,doesIDExist,CheckID,queryDB})=> {
  const mainState = useState({ 
      noListLabel: 'No Projects',
      buttonTitle: 'FETCH: End Of Day Projects',
      waitingLabel: '...Gathering your EOD Forms'
    })
  const [, setState, stateRef] = mainState
  const [, setEODInfo, EODInfoRef] = useState(Array)
  const [, setDeliveries, DeliveriesRef] = useState(Array)
  const [, setRemote, RemoteRef] = useState(Array)
  const [, setCompletedProj, completedProjRef] = useState(Array)
 // const stagedRef = new Set()
  const [appState,setAppState] = useAtom(globalState.appState)
  const [UserMap, setUserMap] = useAtom(globalState.userMapState)
  const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
  const [inventoryMap,setInventoryMap] = useAtom(globalState.inventoryMapState)
  const [productMap, setProductMap] = useAtom(globalState.productMapState)
  const [divisionMap,setDivisionMap] = useAtom(globalState.divisionMapState)
  const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
  const tableState = useState([])
  const [, setTableState, tableStateRef] = tableState
  const valueTable = useState(0)
 // const [, setValue, valueRef] = valueTable;
  const fieldState = useState(Object);
  const [, setField, fieldRef] = fieldState;
  const [reSync, setReSync] = useState(false);

  var [, setPopup] = useAtom(globalState.popupState);

  const [appSocket] = useAtom(globalState.appSocketState);
  var socketIO = appSocket.get('socketIO');

  useEffect(()=>{
   queryDB({ model: 'Inventory', query: {'TransferRequests.deliverDriver': UserMap.get('avatar'),
      'TransferRequests.deliveryDate': {  $lt: dayjs().endOf('day').toISOString()}}, filter:{} }, (x) => {
        x?.forEach(y=>{
        dynamicMapData({[y._id]: y}, inventoryMap, setInventoryMap);
        var {TransferRequests,DivisionID,ProjectID,ProductItems:[{id:ProductID}]} = y;
       // if(ProductID) doesIDExist({map:productMap,set:setProductMap,model:'Products',_id:ProductID},(x)=>console.log("Finished",x))
       // if(DivisionID) doesIDExist({map:divisionMap,set:setDivisionMap,model:'DivCheck',_id:DivisionID},({division})=>{
         // if(ProjectID) ProjectID.forEach(pID=> doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:pID,modelType: division?.teamName || divisionMap.get(DivisionID)?.teamName},({SalesState:{SiteID}})=> {
           // SiteID?.forEach(z=>{ 
               //   console.log("Tester:", z)
               //     doesIDExist({map:teamMember,set:setTeamMember,model:'Sites',_id:z})
              
               // console.log("InventoryID:", y._id, "ProjectName:", projectMap.get(ProjectID), "TransferRequest:", TransferRequests.filter(x=>x?.TransferType && (["Delivery", "Remote"].includes(x?.TransferType) && x?.Completed !== true)).filter(x=>x?.Reschedule === undefined || x?.Reschedule?.filter(z=>dayjs().isBefore(z.date) && z?.itemsReturned.filter(y=>y.ProductID === ProductID)?.[0]?.amount === Math.abs(x?.TransferAmount) ).length === 0))
               var currRequests = TransferRequests.filter(x=>x?.TransferType && (["Delivery"].includes(x?.TransferType) && x?.Completed !== true && x?.deliveryDate)).flatMap(x=>{return {...x, ProductID, InventoryID: y._id}})
               //currRequests.filter(x=>x?.Reschedule === undefined || x?.Reschedule?.filter(z=>dayjs().isBefore(z.date) && z?.itemsReturned.filter(y=>x.ProductID === ProductID)?.[0]?.amount === Math.abs(x?.TransferAmount) ).length === 0)

               if(currRequests?.length > 0) {
               let finalObj = {
                ProjectID: ProjectID?.[0],
                DivisionID,
                ProductID: [ProductID],
                InventoryID: [y?._id],
                TransferRequests: currRequests
               }
               setDeliveries(p=> {
                if(p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length === 0)
                  return [...p, finalObj]
                else if (p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length > 0) return p.flatMap(tmp=>{ 
                  if(tmp.ProjectID === y.ProjectID?.[0]) 
                    return {
                        ProjectID:tmp.ProjectID,
                        DivisionID:tmp.DivisionID,
                        ProductID:tmp.ProductID.concat(ProductID),
                        InventoryID:tmp.InventoryID.concat(y._id),
                        TransferRequests:tmp.TransferRequests.concat(currRequests)
                      };
                  else return tmp;
                    })
                else if (!p) return [finalObj]
               })}
               })
      }
      )
  },[]);

  useEffect(()=>{
    queryDB({ model: 'Inventory', query: {'TransferRequests.requestor': UserMap.get('avatar'),
      'TransferRequests.deliveryDate': {  $lt: dayjs().endOf('day').toISOString()}}, filter:{} }, (x) => {
        x?.forEach(y=>{
        dynamicMapData({[y._id]: y}, inventoryMap, setInventoryMap);
        var {TransferRequests,DivisionID,ProjectID,ProductItems:[{id:ProductID}]} = y;
               var currRequests = TransferRequests.filter(x=>x?.TransferType && (["Remote"].includes(x?.TransferType) && x?.Completed !== true && x?.deliveryDate)).flatMap(x=>{return {...x, ProductID, InventoryID: y._id}})

               if(currRequests?.length > 0) {
               let finalObj = {
                ProjectID: ProjectID?.[0],
                DivisionID,
                ProductID: [ProductID],
                InventoryID: [y?._id],
                TransferRequests: currRequests
               }
               setRemote(p=> {
                if(p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length === 0)
                  return [...p, finalObj]
                else if (p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length > 0) return p.flatMap(tmp=>{ 
                  if(tmp.ProjectID === y.ProjectID?.[0]) 
                    return {
                        ProjectID:tmp.ProjectID,
                        DivisionID:tmp.DivisionID,
                        ProductID:tmp.ProductID.concat(ProductID),
                        InventoryID:tmp.InventoryID.concat(y._id),
                        TransferRequests:tmp.TransferRequests.concat(currRequests)
                      };
                  else return tmp;
                    })
                else if (!p) return [finalObj]
               })}
               })
      }
      )
  },[])

  useEffect(()=>{  
    var userID = UserMap.get('avatar')

    const missingEODs = [].concat(...Array.from([...projectMap],([k,v])=> [v]
      .filter(({ProjectState,DivisionID})=> 
      ProjectState?.EODLogg?.some(({Warned,WarnedTo})=>{ 
          if(Warned === true){
            var category = divisionMap.get(DivisionID)?.category
            if(WarnedTo === userID) return true
            if( category === 'ChromeBook' && ProjectState?.projManager === userID ) return true
            if( ['Construction',' AV'].includes(category) && ProjectState?.teamLead === userID ) return true
            return false
          }
          return false
        })
      ) ))
      setEODInfo(missingEODs)

      const completedProj = [].concat(...Array.from([...projectMap],([k,v])=> [v].filter(x=>x?.snooze?.alerts ? dayjs(x?.snooze.alerts)?.isSameOrBefore(dayjs()) : x).filter(x=>x?.ProjectState?.Status === "Active").filter(({ProjectState})=>{
        if(ProjectState?.Calendar?.endDate && dayjs().isSameOrAfter(ProjectState?.Calendar?.endDate) && ProjectState?.projManager === userID) return true
      })))
      setCompletedProj(completedProj)


  if(missingEODs.length > 0 || completedProj.length > 0 || DeliveriesRef.current?.length > 0) setAppState(p => { p.set('requiredPopup', true); return new Map(p)}) 
  else if((missingEODs.length === 0 && completedProj.length === 0 && DeliveriesRef.current?.length === 0)){
      setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
      setState(p=>({...p,...{ FinishPopup: undefined }}))
    }
},[projectMap,reSync,UserMap.get('avatar')]);


useEffect(()=>{
  if(EODInfoRef.current) EODInfoRef.current.flatMap(({ProjectTitle,DivisionID,ProjectState,_id:ProjectID})=> ProjectState?.EODLogg?.flatMap(({date, _id})=>({
      projLabel: `${ProjectTitle} (Missing EOD on ${dayjs(date).format('l')})`,
      DivisionID: DivisionID,
      modelType: divisionMap.get(DivisionID)?.teamName,
      ProjectID: ProjectID,
      reSubmission: date,
      icon: <FontAwesomeIcon icon={faUserClock}/>,
      eodID: _id
      }))).forEach(items=>{
       if(!tableStateRef.current.some((x)=>items.projLabel===x.projLabel)){
         setTableState(p=>[].concat(p,items));
         missingEOD(items);
       } 
      })

},[EODInfoRef.current]);

useEffect(()=>{
  if(completedProjRef.current) completedProjRef.current.flatMap(({ProjectTitle,DivisionID,ProjectState,_id:ProjectID, PoNumber})=>({
    projLabel: `${ProjectTitle} (Job # ${PoNumber})`,
    DivisionID: DivisionID,
    modelType: divisionMap.get(DivisionID)?.teamName,
    ProjectID: ProjectID,
    icon: <FontAwesomeIcon icon={faProjectDiagram}/>,
    completion: ProjectState?.Calendar?.endDate || dayjs()
    })).forEach(items=>{
     if(!tableStateRef.current.some((x)=>items.projLabel===x.projLabel)){
       setTableState(p=>[].concat(p,items)); 
       projectComplete(items);
     }
    })
},[completedProjRef.current])

const buildDeliveryTab = ({remote, ProjectTitle, delivery, DivisionID, ProjectID, InventoryID}) => {
  if(remote && remote.length>0) return {
    projLabel: `${ProjectTitle} (Remote Transfer on ${dayjs(delivery.deliveryDate).format('l')})`,
    DivisionID,
    modelType: divisionMap.get(DivisionID)?.teamName,
    ProjectID,
    icon: <LocalShippingIcon />,
    InventoryID,
    Deliveries: remote,
  };

  return {
    projLabel: `${ProjectTitle} (Missing Delivery Report on ${dayjs(delivery.deliveryDate).format('l')})`,
    DivisionID,
    modelType: divisionMap.get(DivisionID)?.teamName,
    ProjectID,
    icon: <LocalShippingIcon />,
    InventoryID,
    Deliveries: delivery,
    }
  
}

useEffect(() => {
  const processDeliveries = async () => {
    if (DeliveriesRef.current) {
      const promises = DeliveriesRef.current.flatMap(({ InventoryID, DivisionID, ProjectID, ProductID, TransferRequests }) => {
        const delivery = TransferRequests?.filter(({ deliveryDate, deliverDriver, Report, Reschedule, TransferAmount }) => (deliverDriver && deliverDriver === UserMap.get('avatar') && !Report && dayjs().isSameOrAfter(deliveryDate))
        )?.flatMap(x => ({ ...x, ProductID }));

        if (!projectMap.has(ProjectID)) {
          return new Promise((resolve) => {
            CheckID({ 
              key: "Projects", 
              teamName: divisionMap.get(DivisionID)?.teamName, 
              map: projectMap, 
              setMap: setProjectMap, 
              _id: ProjectID 
            }, (resp) => {
              const { ProjectTitle } = resp;
              resolve(buildDeliveryTab({ ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
            });
          });
        } else {
          const { ProjectTitle } = projectMap.get(ProjectID);
          return Promise.resolve(buildDeliveryTab({ ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
        }
      });

      Promise.all(promises).then(results => {
        results.forEach(items => {
          if (!tableStateRef.current.some(x => items.projLabel === x.projLabel)) {
            setTableState(p => [].concat(p, items));
            DeliveriesBuildout(items);
          }
        });
      });
    }
    if(RemoteRef.current)
      {
        const promises = RemoteRef.current.flatMap(({ InventoryID, DivisionID, ProjectID, ProductID, TransferRequests }) => {
          const delivery = TransferRequests?.filter(({ deliveryDate, requestor, Report }) => (requestor === UserMap.get('avatar') && !Report && dayjs().isSameOrAfter(deliveryDate))
          )?.flatMap(x => ({ ...x, ProductID }));
  
          const remote = TransferRequests?.filter(({ TransferType, deliveryDate, Report }) => 
            TransferType && TransferType === "Remote" && !Report && dayjs().isSameOrAfter(deliveryDate)
          )?.flatMap(x => ({ ...x, ProductID }));
  
          if (!projectMap.has(ProjectID)) {
            return new Promise((resolve) => {
              CheckID({ 
                key: "Projects", 
                teamName: divisionMap.get(DivisionID)?.teamName, 
                map: projectMap, 
                setMap: setProjectMap, 
                _id: ProjectID 
              }, (resp) => {
                const { ProjectTitle } = resp;
                resolve(buildDeliveryTab({ remote, ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
              });
            });
          } else {
            const { ProjectTitle } = projectMap.get(ProjectID);
            return Promise.resolve(buildDeliveryTab({ remote, ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
          }
        });
  
        Promise.all(promises).then(results => {
          results.forEach(items => {
            if (!tableStateRef.current.some(x => items.projLabel === x.projLabel)) {
              setTableState(p => [].concat(p, items));
              DeliveriesBuildout(items);
            }
          });
        });
      }
  };

  processDeliveries();
}, [DeliveriesRef.current, RemoteRef.current]);


const gatherList = async(userFilter,projFilter,category, socketIO) => await new Promise((resolve) => queryDB({model:'AdminOrg',query:{'fieldSet':[`${category}Services`,`${category}Production`]},filter:{'_id':0,'fieldSet':0,'Number':0}},(items,err)=>{ 
  var projectFilter = projFilter ? projFilter : []
  return resolve( Object.assign({},...items.reverse().flatMap(x=>Object.entries(x)
  .filter(([k, v],i) => projectFilter ? Object.keys(projectFilter).includes(k) : false )
  .map(([k, v],i) =>({[k]: Object.entries(v)
  .filter(([k2, v2],i2) => projectFilter ? projectFilter[k].flatMap(x=>Object.values(x)).includes(v2.label) : false)
  .map(([k2, v2],i2) =>({...projectFilter[k][0],...v2})) 
  }) 
  ))) )
}) );


const handleRemove = (item) => {
  setTableState((prev)=>[...prev.filter(({projLabel})=>projLabel !== item)]);
  setField(p=>{delete p[item];return p})
  if(tableStateRef.current.length===0){
    setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
    setPopup({open:false})
  }
};

const projectComplete=({DivisionID,ProjectID,modelType,projLabel,completion})=>{
  if (projectMap.has( ProjectID )) var {ProjectState,SalesState} = projectMap.get( ProjectID );
  var projID = { ProjectID, DivisionID, modelType } 
  var defaultEnd = stateRef.current['ProjectState.Calendar.endDate'] || ProjectState?.Calendar?.endDate || 'End Date Needed';
var item = <AppBar position="static">
<Toolbar variant="dense" sx={{pl:'0px!important',flexWrap:'wrap',gap:'12px',p:'1%!important'}} >
<ListItem alignItems="flex-start" sx={{gap:'5px',p:0,width:'unset'}}>
<AvTimerIcon sx={{fontSize:'50px',margin:'auto'}} />
<ListItemText sx={{display:'flex',flexDirection:'column',gap:'4px'}}
primary={<Typography variant="body1" color="inherit" sx={{display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1}}>{projLabel}</Typography>}
secondary={
[ <Typography sx={{ display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1 }} component="span" variant="body2" color="text.primary">Schedule to be completed:</Typography>,
` ${dayjs(completion).format('LLLL')} `]
}
/>
</ListItem>
<Box sx={{flex:1,display:'flex',gap:'8px',justifyContent:'space-evenly',flexWrap:'wrap'}}>
  {dynamicComponent('DefaultButton','elements',{state:'marked',label:'Mark as Complete',type:'button',startIcon:<VerifiedIcon/>, color:'#A0E77D',style:{color:'#2e2e2e'}},mainState,[()=>
  socketIO.emit('projectBuilder',{projID:projID,submitObj:{'SalesState.Status':'Complete','ProjectState.Status':'Complete',Status:'Completed'}},(x)=>{
    globalWrn('info',`Project: ${projLabel} has been marked "Completed".`); 
    handleRemove(projLabel);
    setCompletedProj(completedProjRef.current.filter(x=>{if(x?._id === projID) return x})); 
    setReSync(!reSync);
  })
  ])
  }
  <Item elevation={8}>
  { dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.endDate',label:'Extend Out End Date', defaultValue: defaultEnd, min: ProjectState?.Calendar?.startDate, max: SalesState?.projectDue?.calendar },mainState,[(x)=>
  socketIO.emit('projectBuilder',{projID:projID,submitObj:x},(x)=>{
    globalWrn('info',`Project: ${projLabel} has been Extend Out to: ${dayjs(x['ProjectState.Calendar.endDate'])}.`).format('LLLL');
    setCompletedProj(completedProjRef.current.filter(x=>{if(x?._id === projID) return x})); 
    handleRemove(projLabel);
    } )]) }
  </Item>

  { dynamicComponent('DropDown','elements',{
    state: 'snoozeDrp',
    icon: <SnoozeIcon sx={{ minWidth: '14px' }} />,
    buttonStyles: {backgroundColor:'rgb(18,18,18)'},
    label: 'Snooze',
    buttonStyles: {color:'#fff',backgroundColor:'transparent'},
    list: [
      {primary:'1 minute',secondary:dayjs().add(1, 'minute').format('LT'), icon: <SnoozeIcon/> },
      {primary:'2 hours',secondary:dayjs().add(2, 'hour').format('LT'), icon: <SnoozeIcon/> },
      {primary:'4 hours',secondary:dayjs().add(4, 'hour').format('LT'), icon: <SnoozeIcon/> },
      {primary:'6 hours',secondary:dayjs().add(6, 'hour').format('LT'), icon: <SnoozeIcon/> },
    ],
    replace:true, 
    },mainState,[({snoozeDrp})=>{
    var socketIO = appSocket.get('socketIO')
    var newSnooze = Object({
      '1 minute': ()=> dayjs().add(1,'minute'),
      '2 hours': ()=> dayjs().add(2,'hour'),
      '4 hours': ()=> dayjs().add(4,'hour'),
      '6 hours': ()=> dayjs().add(6,'hour')
    })[snoozeDrp]()
    socketIO.emit('projectBuilder',{projID:projID,submitObj:{
      'snooze.alerts': newSnooze.toDate()
  }},()=>{
    handleRemove(projLabel);
    globalWrn('info',`Project: ${projLabel} has been Snoozed till: ${snoozeDrp}.`)?.format('LT')} );
    }
    ]) }
</Box>
</Toolbar>
</AppBar>

setField(p=>({...p,[projLabel]: item})) 
};

const DeliveriesBuildout = ({DivisionID,ProjectID,modelType,projLabel,reSubmission,completion,Deliveries,InventoryID,eodID,_id}) => {
    const regex = /\(Remote/;
    var item = projLabel && regex.test(projLabel) ? 
    RemoteRef.current.filter(x=>x.ProjectID === ProjectID).flatMap(y=>dynamicComponent('RemoteFormPopup', 'elements',{state:'RemoteFormPopup',data: [y]},mainState))
 : DeliveriesRef.current.filter(x=>x.ProjectID === ProjectID).flatMap(y=>dynamicComponent('DeliveriesFormPopup', 'elements',{state:'DeliveriesFormPopupState',data: [y]},mainState));
setField(p=>({...p,[projLabel]: item}))
};

const missingEOD=({DivisionID,ProjectID,modelType,projLabel,reSubmission,completion,Deliveries,eodID,_id})=>{
  var socketIO = appSocket.get('socketIO')
  if (projectMap.has( ProjectID )) var {SalesState} = projectMap.get( ProjectID )
  if (divisionMap.has( DivisionID ) ) var {category} = divisionMap.get( DivisionID )
  var userFilter = SalesState?.PhaseOption ? SalesState?.PhaseFilter : SalesState?.OptionsFilter
      if(SalesState?.PhaseOption && SalesState?.PhaseFilter ) Promise.all(
        SalesState?.PhaseFilter.map(({label,Options})=>({[projLabel]:{label,Options: gatherList(userFilter,Options,category, socketIO)}}))
      ).then(([x])=> setField(p=>({...p,...{ 
        [projLabel]: x?.dynamicComponent('EODform', 'elements',{state:'saveForm',projID:{ProjectID,DivisionID,modelType},ProjData:x,projLabel,reSubmission,Deliveries,eodID,handleRemove:handleRemove},mainState,[(x)=> console.log('Returning ProjEOD', x)])
       }})) )
      else  {
        Promise.all([gatherList(userFilter,SalesState?.OptionsFilter,category, socketIO)]).then(([x])=>
        setField(p=>({...p,...{ [projLabel]: dynamicComponent('EODform', 'elements',{state:'saveForm',projID:{ProjectID,DivisionID,modelType,eodID:eodID},ProjData:x,projLabel,reSubmission,eodID:eodID,handleRemove:handleRemove},mainState,[(x)=> console.log('Returning ProjEOD', x)]) }}))
       )
      }
};

useEffect(()=>{
  if( fieldRef.current ) setState(p=>({...p,...{
   FinishPopup: appState.get('requiredPopup') && <Box sx={{ display: 'flex', flexDirection:'column', width:'100%', gap:'1%' }}>
   <AppBar position="static">
     <Toolbar sx={{gap:'2%', paddingLeft:'1%'}}>
       <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
         <ReportProblemOutlinedIcon />
       </IconButton>
       <Typography variant="h6" sx={{ flexGrow: 1, }} noWrap> {stateRef.current.title} </Typography>
       <Box sx={{display:'flex',overflow:'auto'}}>
       { DeliveriesRef.current?.length > 0 && <Chip avatar={<Avatar>{DeliveriesRef.current?.length}</Avatar>} label="Delivery(s)" /> }
       { completedProjRef.current.length > 0 && <Chip avatar={<Avatar>{completedProjRef.current.length}</Avatar>} label="Project(s) Ending" /> }
       { EODInfoRef.current.length > 0 && <Chip avatar={<Avatar>{EODInfoRef.current.length}</Avatar>} label="Missing EOD(s)" /> }
       </Box>
       <Divider orientation="vertical" variant="middle" flexItem />
       {dynamicComponent('DefaultButton','elements',{state:'scanEnable',label:'Logout',type:'button',startIcon:<LogoutIcon/>},mainState,[()=>{
         setPopup(p=>({...p,...{
           open: true,
           title:'Are you sure?',
           description: 'You are about to logout of the EKC App, are you sure you meant to do this?',
           handleSubmit: ()=>{ 
             setAppState(p => { p.set('pageHold', false); return new Map(p)}) 
             dbUpdateValidate({model:'User',query:true,update:{[`local.pageStamp.pageID`]:'SignIn'}},(x)=>{if(x===true) globalWrn('info',"You have now been logged out."); })
             setUserMap(p=>{ p.set('pageStamp',{pageID:'SignIn'}); return new Map(p)});
             setPopup({open:false})
           },
           handleClose: ()=> setPopup({open:false}),
           submitLabel: 'LOGOUT',
         }}))
       }])}
     </Toolbar>
   </AppBar>
     { dynamicComponent('ItemsListed','elements',{tableState,fieldState,bttnAction:()=>setReSync(true), disableDelete: true},mainState) }
   </Box>
 }}))

},[ fieldRef.current, DeliveriesRef.current ])




return [stateRef.current.FinishPopup,DeliveriesRef.current?.length > 0 && DeliveriesRef.current.flatMap(x=>x?.ProductID?.flatMap(y=>stateRef.current?.[y+'productCard']))]
}

/*
 const FinishPopup2=({dynamicMapData,dbUpdateValidate,dynamicComponent,globalState,viewData,globalWrn,waveLoad,doesIDExist})=> {
    const mainState = useState({ 
        noListLabel: 'No Projects',
        buttonTitle: 'FETCH: End Of Day Projects',
        waitingLabel: '...Gathering your EOD Forms'
      })
    const [, setState, stateRef] = mainState
    const [, setEODInfo, EODInfoRef] = useState(Array)
    const [, setDeliveries, DeliveriesRef] = useState(Array)
    const [, setCompletedProj, completedProjRef] = useState(Array)
    const stagedRef = new Set()
    const [appState,setAppState] = useAtom(globalState.appState)
    const [UserMap, setUserMap] = useAtom(globalState.userMapState)
    const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
    const [inventoryMap,setInventoryMap] = useAtom(globalState.inventoryMapState)
    const [productMap, setProductMap] = useAtom(globalState.productMapState)
    const [divisionMap,setDivisionMap] = useAtom(globalState.divisionMapState)
    const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
    const tableState = useState([])
    const [, setTableState, tableStateRef] = tableState
    const valueTable = useState(0)
    const [, setValue, valueRef] = valueTable
    const fieldState = useState(Object)
    const [, setField, fieldRef] = fieldState
    const [reSync, setReSync] = useState(false)

    var [, setPopup] = useAtom(globalState.popupState)

    const [appSocket] = useAtom(globalState.appSocketState)
    var socketIO = appSocket.get('socketIO')
    const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);
    var socketQueryIO = appSocketQuery.get('socketQueryIO');

  // STEP 01 !!!!!!!!!!!!! Build Missing EOD and Incomplete Projects
  useEffect(()=>{  
    var userID = UserMap.get('avatar')

    const missingEODs = [].concat(...Array.from([...projectMap],([k,v])=> [v]
      .filter(({ProjectState,DivisionID})=> 
      ProjectState?.EODLogg?.some(({Warned,WarnedTo})=>{ 
          if(Warned === true){
            var category = divisionMap.get(DivisionID)?.category
            if(WarnedTo === userID) return true
            if( category === 'ChromeBook' && ProjectState?.projManager === userID ) return true
            if( ['Construction','AV'].includes(category) && ProjectState?.teamLead === userID ) return true
            return false
          }
          return false
        })
      ) ))
      setEODInfo(missingEODs)

      const completedProj = [].concat(...Array.from([...projectMap],([k,v])=> [v].filter(x=>x?.snooze?.alerts ? dayjs(x?.snooze.alerts)?.isSameOrBefore(dayjs()) : x).filter(x=>x?.ProjectState?.Status === "Active").filter(({ProjectState})=>{
        if(ProjectState?.Calendar?.endDate && dayjs().isSameOrAfter(ProjectState?.Calendar?.endDate) && ProjectState?.projManager === userID) return true
      })))
      setCompletedProj(completedProj)

      console.log('FinishPOP::' , missingEODs.length, completedProj.length, DeliveriesRef.current.length  )

  if (missingEODs.length > 0 || completedProj.length > 0 || DeliveriesRef.current.length > 0){ 
    // Build Different Categories
    if(missingEODs.length > 0) buildEOD()
    if(completedProj.length > 0) buildIncompleteProj()

    setAppState(p => { p.set('requiredPopup', true); return new Map(p)}) 
    } else if((missingEODs.length === 0 && completedProj.length === 0 && DeliveriesRef.current.length === 0)){
      setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
    //setAppState(p => { p.delete('requiredPopup'); return new Map(p)}) 
    setState(p=>({...p,...{ FinishPopup: undefined }}))
    }

},[projectMap,reSync,UserMap.get('avatar')]);




const buildIncompleteProj=()=>{
  if(completedProjRef.current) completedProjRef.current.flatMap(({ProjectTitle,DivisionID,ProjectState,_id:ProjectID, PoNumber})=>({
     projLabel: `${ProjectTitle} (Job # ${PoNumber})`,
     DivisionID: DivisionID,
     modelType: divisionMap.get(DivisionID).teamName,
     ProjectID: ProjectID,
     icon: <FontAwesomeIcon icon={faProjectDiagram}/>,
     completion: ProjectState?.Calendar?.endDate || dayjs()
     })).forEach(items=>{
      // if(setContainsProperty(stagedRef, items.projLabel) === false)
        stagedRef.add(items)
     })
 
 console.log("StagedRef2:", stagedRef)
}















    // Set Project Map and Inventory Map
    useEffect(()=>{
      var socketQueryIO = appSocketQuery.get('socketQueryIO');
      if(UserMap.get('level')>4&&socketQueryIO?.connected) socketQueryIO.emit('queryDB',{model:'Inventory',query:{'TransferRequests.deliverDriver':UserMap.get('avatar'),'TransferRequests.Status':{ $nin:['Delivered']},'TransferRequests.deliveryDate': { $lt: dayjs().endOf('day')}}},(items)=>{ // $gte: dayjs().startOf('day'),

        items?.forEach(x=>{  console.log(projectMap.has(x.ProjectID[0]))
       if(!projectMap.has(x.ProjectID[0])) socketQueryIO.emit('queryDB',{model:'Projects',idConvert:true,modelType:divisionMap.get(x.DivisionID)?.teamName,query:{'_id':x.ProjectID[0]}},([items2])=>{
        if(items2){ 
          items2?.SalesState?.SiteID?.forEach(x=>{ console.log('has SIte::',x,teamMember.has(x) )
            if(!teamMember.has(x)) socketQueryIO.emit('queryDB',{model:'Sites',idConvert:true,query:{'_id':x}},([items3])=>{
            if(items3) dynamicMapData({ [items3._id]: items3},teamMember, setTeamMember)
          }) })
          dynamicMapData({ [items2._id]: items2}, projectMap, setProjectMap )
        }
        })
       dynamicMapData({ [x._id]: x}, inventoryMap, setInventoryMap)
      }) })
    },[UserMap.get('level'),socketIO?.connected])









    const gatherList = async(userFilter,projFilter,category, socketIO) => await new Promise((resolve) => socketQueryIO.emit('queryDB',{model:'AdminOrg',query:{'fieldSet':[`${category}Services`,`${category}Production`]},filter:{'_id':0,'fieldSet':0,'Number':0}},(items,err)=>{ 
        var projectFilter = projFilter ? projFilter : []
      
        return resolve( Object.assign({},...items.reverse().flatMap(x=>Object.entries(x)
        .filter(([k, v],i) => projectFilter ? Object.keys(projectFilter).includes(k) : false )
        .map(([k, v],i) =>({[k]: Object.entries(v)
        .filter(([k2, v2],i2) => projectFilter ? projectFilter[k].flatMap(x=>Object.values(x)).includes(v2.label) : false)
        .map(([k2, v2],i2) =>({...projectFilter[k][0],...v2})) 
        }) 
        ))) )
      }) )

      const setContainsProperty=(set,property)=>{
        for (let obj of set) {
          if (obj?.projLabel === property)
          {
            return true
          }
        }
        return false
      }

        const buildEOD=()=>{
       if(EODInfoRef.current){
        EODInfoRef.current.flatMap(({ProjectTitle,DivisionID,ProjectState,_id:ProjectID})=> ProjectState?.EODLogg?.flatMap(({date, _id})=>({
          projLabel: `${ProjectTitle} (Missing EOD on ${dayjs(date).format('l')})`,
          DivisionID: DivisionID,
          modelType: divisionMap.get(DivisionID).teamName,
          ProjectID: ProjectID,
          reSubmission: date,
          icon: <FontAwesomeIcon icon={faUserClock}/>,
          eodID: _id
          }))).forEach(items=>{
            if(setContainsProperty(stagedRef, items.projLabel) === false){
              stagedRef.add(items)
          }})}
          console.log("StagedRef1:", stagedRef)
        }

         


    const buildDeliveries=()=>{
      if(DeliveriesRef.current && DeliveriesRef.current?.length > 0){ 
       // console.log(projectMap.get(DeliveriesRef?.current?.[valueTable?.[0]]?.ProjectID))
        var data = [DeliveriesRef?.current?.[valueTable?.[0]]]
          if(data?.[0]) {
            var {InventoryID,DivisionID,ProjectID,ProductID} = data[0]
            var delivery = data.flatMap(x=> x?.TransferRequests?.filter(({deliveryDate,deliverDriver,Report})=> deliverDriver&&deliverDriver===UserMap.get('avatar') && !Report && dayjs().isSameOrAfter(deliveryDate)).flatMap(x=>({...x,ProductID})))
            var remote = data.flatMap(x=> x?.TransferRequests?.filter(({TransferType, deliveryDate, Report})=> TransferType&&TransferType==="Remote" && !Report && dayjs().isSameOrAfter(deliveryDate)).flatMap(x=>({...x,ProductID})))
            if(projectMap.has(ProjectID)) var {ProjectTitle} = projectMap.get(ProjectID)
            const response = {
            projLabel: `${ProjectTitle} (Missing Delivery Report on ${dayjs(delivery.deliveryDate).format('l')})`,
            DivisionID,
            modelType: divisionMap.get(DivisionID)?.teamName,
            ProjectID,
            icon: <LocalShippingIcon />,
            InventoryID,
            Deliveries: delivery,
            }
            if(remote) var responseRemote = {
              projLabel: `${ProjectTitle} (Remote Transfer on ${dayjs(delivery.deliveryDate).format('l')})`,
              DivisionID,
              modelType: divisionMap.get(DivisionID)?.teamName,
              ProjectID,
              icon: <LocalShippingIcon />,
              InventoryID,
              Deliveries: remote,
            }

            if(setContainsProperty(stagedRef, response.projLabel) === false){
              stagedRef.add(response)
            if(remote && setContainsProperty(stagedRef, responseRemote?.projLabel) === false){
              console.log("Before:", stagedRef)
              stagedRef.add(responseRemote)
              console.log("After:", stagedRef)
          // DeliveriesRef.current.forEach(items=> stagedRef.add(items))
          }
        }
      }
    }
}

      const handleRemove = (item) => {
        setTableState((prev)=>[...prev.filter(({projLabel})=>projLabel !== item)]);
        setField(p=>{delete p[item];return p})
        if(tableStateRef.current.length===0){
          setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
          setPopup({open:false})
        }
      };
      
      // Get Deliveries and Remote Delivers
      useEffect(()=>{
        var socketQueryIO = appSocketQuery.get('socketQueryIO');
       if(socketQueryIO?.connected === true) socketQueryIO.emit('queryDB', { model: 'Inventory', query: {'TransferRequests.deliverDriver': UserMap.get('avatar'),
          'TransferRequests.deliveryDate': {  $lt: dayjs().endOf('day').toISOString()}}, filter:{} }, (x) => {     console.log("X is:", x)
          x?.forEach(y=>{
            dynamicMapData({[y._id]: y}, inventoryMap, setInventoryMap);
            var {TransferRequests,DivisionID,ProjectID,ProductItems:[{id:ProductID}]} = y;
            if(ProductID) doesIDExist({map:productMap,set:setProductMap,model:'Products',_id:ProductID})
            if(DivisionID) doesIDExist({map:divisionMap,set:setDivisionMap,model:'DivCheck',_id:DivisionID},({division})=>{
              if(ProjectID) ProjectID.forEach(pID=> doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:pID,modelType: division?.teamName || divisionMap.get(DivisionID)?.teamName},({SalesState:{SiteID}})=>{ console.log('ProjectID',pID)
                    if(SiteID) SiteID.forEach(z=>{ 
                        doesIDExist({map:teamMember,set:setTeamMember,model:'Sites',_id:z})
                        setDeliveries(p=>[...p,{TransferRequests:TransferRequests.filter(x=>x?.TransferType && (x?.TransferType === "Delivery" || x?.TransferType === "Remote") && x?.Completed !== true),InventoryID:y._id,DivisionID,ProjectID:ProjectID[0],ProductID}].filter(obj => obj.TransferRequests.length > 0))
                        buildDeliveries()
                    }) 
                }))
            })
            })
          })
        
      },[socketIO?.connected]);

  
useEffect(()=>{

   if( stateRef.current.ItemsListedField ) setState(p=>({...p,...{
    FinishPopup: appState.get('requiredPopup') && <Box sx={{ display: 'flex', flexDirection:'column', width:'100%', gap:'1%' }}>
    <AppBar position="static">
      <Toolbar sx={{gap:'2%', paddingLeft:'1%'}}>
        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <ReportProblemOutlinedIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1, }} noWrap> {stateRef.current.title} </Typography>
        { DeliveriesRef.current.length > 0 && <Chip avatar={<Avatar>{DeliveriesRef.current.length}</Avatar>} label="Delivery(s)" /> }
        { completedProjRef.current.length > 0 && <Chip avatar={<Avatar>{completedProjRef.current.length}</Avatar>} label="Project(s) Ending" /> }
        { EODInfoRef.current.length > 0 && <Chip avatar={<Avatar>{EODInfoRef.current.length}</Avatar>} label="Missing EOD(s)" /> }
        <Divider orientation="vertical" variant="middle" flexItem />
        {dynamicComponent('DefaultButton','elements',{state:'scanEnable',label:'Logout',type:'button',startIcon:<LogoutIcon/>},mainState,[()=>{
          setPopup(p=>({...p,...{
            open: true,
            title:'Are you sure?',
            description: 'You are about to logout of the EKC App, are you sure you meant to do this?',
            handleSubmit: ()=>{ 
              setAppState(p => { p.set('pageHold', false); return new Map(p)}) 
              dbUpdateValidate({model:'User',query:true,update:{[`local.pageStamp.pageID`]:'SignIn'}},(x)=>{if(x===true) globalWrn('info',"You have now been logged out."); })
              setUserMap(p=>{ p.set('pageStamp',{pageID:'SignIn'}); return new Map(p)});
              setPopup({open:false})
            },
            handleClose: ()=>{setPopup({open:false})},
            submitLabel: 'LOGOUT',
          }}))
        }])}
      </Toolbar>
    </AppBar>
      { [ stateRef.current.ItemsListedField ] }
    </Box>
  }}))

},[ stateRef.current.ItemsListedField,DeliveriesRef.current ])

       useEffect(()=>{ 
        if(stagedRef?.size>0) stagedRef.forEach((x)=>{
            console.log("x is:", x)
            var {DivisionID,ProjectID,modelType,projLabel,reSubmission,completion,Deliveries,eodID,_id} = x
            if (ProjectID && projectMap.get( ProjectID )?.ProjectState) var {ProjectState} = projectMap.get( ProjectID )
            if (ProjectID && projectMap.get(ProjectID)?.SalesState) var {SalesState} = projectMap.get( ProjectID )
            if (DivisionID && divisionMap.has( DivisionID ) ) var {category} = divisionMap.get( DivisionID )
            var projID = { ProjectID, DivisionID, modelType } 
            var defaultEnd = stateRef.current['ProjectState.Calendar.endDate'] || ProjectState?.Calendar?.endDate || 'End Date Needed';

            if((completion||eodID||Deliveries) && !tableStateRef.current.some((x)=>projLabel===x.projLabel) ) {
              setTableState(y=>[...y,x]); 
              console.log("added setTableState:", x)
            };

            if(completion) setField(p=>({...p,...{ 
              [projLabel]: <AppBar position="static">
              <Toolbar variant="dense">
              <ListItem alignItems="flex-start" sx={{flex:.65}}>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
      <AvTimerIcon sx={{fontSize:'50px'}} />
    </IconButton>
        <ListItemText
          primary={<Typography variant="h6" color="inherit">{projLabel}</Typography>}
          secondary={
             [ <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">Schedule to be completed:</Typography>,
              ` ${dayjs(completion).format('LLLL')} `]
          }
        />
      </ListItem>
          <Box sx={{flex:1,display:'flex',gap:'3%'}}>
                {dynamicComponent('DefaultButton','elements',{state:'marked',label:'Mark as Complete',type:'button',startIcon:<VerifiedIcon/>, color:'#A0E77D',style:{color:'#2e2e2e'}},mainState,[()=>
                socketIO.emit('projectBuilder',{projID:projID,submitObj:{'SalesState.Status':'Complete','ProjectState.Status':'Complete',Status:'Completed'}},(x)=>{globalWrn('info',`Project: ${projLabel} has been marked "Completed".`); setCompletedProj(completedProjRef.current.filter(x=>{if(x?._id === projID) return x})); setReSync(!reSync)} )
                ])
                }
                <Item elevation={8}>
                { dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.endDate',label:'Extend Out End Date', defaultValue: defaultEnd, min: ProjectState?.Calendar?.startDate, max: SalesState?.projectDue?.calendar },mainState,[(x)=>
                socketIO.emit('projectBuilder',{projID:projID,submitObj:x},(x)=>{globalWrn('info',`Project: ${projLabel} has been Extend Out to: ${dayjs(x['ProjectState.Calendar.endDate'])}.`).format('LLLL')} )]) }
                </Item>
   
                { dynamicComponent('DropDown','elements',{
                  state: 'snoozeDrp',
                  icon: <SnoozeIcon sx={{ minWidth: '14px' }} />,
                  buttonStyles: {backgroundColor:'rgb(18,18,18)'},
                  label: 'Snooze',
                  buttonStyles: {color:'#fff',backgroundColor:'transparent'},
                  list: [
                    {primary:'2 hours',secondary:dayjs().add(2, 'hour').format('LT'), icon: <SnoozeIcon/> },
                    {primary:'4 hours',secondary:dayjs().add(4, 'hour').format('LT'), icon: <SnoozeIcon/> },
                    {primary:'6 hours',secondary:dayjs().add(6, 'hour').format('LT'), icon: <SnoozeIcon/> },
                  ],
                  replace:true, 
                  },mainState,[({snoozeDrp})=>{
                  var socketIO = appSocket.get('socketIO')
                  var newSnooze = Object({
                    '2 hours': ()=> dayjs().add(2,'hour'),
                    '4 hours': ()=> dayjs().add(4,'hour'),
                    '6 hours': ()=> dayjs().add(6,'hour')
                  })[snoozeDrp]()
                  socketIO.emit('projectBuilder',{projID:projID,submitObj:{
                    'snooze.alerts': newSnooze.toDate()
                }},(x)=>{
                  stagedRef.forEach((x)=>{
                    if(x.projLabel === projLabel) {
                      stagedRef.delete(x)
                      //const newRef = stagedRef.delete(x)
                     // stagedRef = newRef; // Reset
                      setState(p=>({...p,...{
                        flag: stateRef.current.flag?!stateRef.current.flag:true
                      }}))
                    }
                  })
                  globalWrn('info',`Project: ${projLabel} has been Snoozed till: ${snoozeDrp}.`)?.format('LT')} )
                  }
                  ]) }
          </Box>
              </Toolbar>
            </AppBar>
             }})) 

            if(DeliveriesRef.current && DeliveriesRef.current.length > 0 && x?.InventoryID) {
              const regex = /\(Remote/; // Determine if Remote Transfer or Delivery by project label
              setField(p=>({...p,...{ 
                [projLabel]: x?.projLabel && regex.test(x?.projLabel) ? 
                <Box>
                  <Box sx={{display:'flex',padding:'2%',flexWrap:'wrap'}}>
    { x?.Deliveries && x?.Deliveries !== undefined && x?.Deliveries.flatMap(({ProductID,TransferAmount})=>
          <Box><Item sx={{maxWidth:'225px',margin:'auto'}} onClick={async()=>{
            var socketQueryIO = appSocketQuery.get('socketQueryIO');
            if(productMap.has(ProductID)){
              var prodExists = productMap.has(ProductID)
            } else if(ProductID && !productMap.has(ProductID)) socketQueryIO.emit('queryDB',{model:'Products',query:{"_id": ProductID}},([productData])=>{
              if(!productData) var prodExists = false
                else{  
                  var prodExists = true
                  dynamicMapData({[productData._id]: productData}, productMap, setProductMap);
              }
              setState(p=>({...p,...{
                productCard : dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:x?.InventoryID, productExists:prodExists}, mainState)
              }}))
              })
             else var prodExists = false
             console.log("test so far")  

              setState(p=>({...p,...{
                productCard : dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:x?.InventoryID, productExists:prodExists}, mainState)
              }}))

          }} >
          <LazyLoadImage
           alt={'IMAGE'}
           effect="blur"
           loading='lazy'
           threshold={10000}
           height={'auto'}
           width={'100%'}
           style={{objectFit:'contain'}}
           key={'MainLogo'}
           onError={(e)=>{ e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}}
           src={productMap.get(ProductID)?.IMAGE_SPOTLIGHT||'https://www.services.ekc.app/scripts/images/AV.jpeg'}
         />
         </Item>
         <Item>
         <Typography>{productMap.get(ProductID)?.DISPLAY_NAME}</Typography>
         <Typography>Total Amount to Transfer:&nbsp;{x?.Deliveries?.flatMap(x=>x?.TransferAmount).reduce((partialSum, a) => partialSum + a, 0)}</Typography>
         </Item>
         </Box>
     )
   } </Box>
                  Remote Transfer Here!
                  </Box> 
                  : 
                  dynamicComponent('DeliveriesFormPopup', 'elements',{state:'DeliveriesFormPopupState',data: [DeliveriesRef.current?.[valueTable?.[0]]]},mainState),
              //[projLabel]: dynamicComponent('Deliveryform', 'elements',{state:'saveDeliveryForm',projID:{ProjectID,DivisionID,modelType},Deliveries,projLabel,reSubmission,handleRemove:handleRemove},mainState,[(x)=> console.log('Returning saveDeliveryForm', x)]) 
            }}))
            console.log(stagedRef)
          }

          var socketIO = appSocket.get('socketIO')
            if(eodID){
              var userFilter = SalesState?.PhaseOption ? SalesState?.PhaseFilter : SalesState?.OptionsFilter
            if(SalesState?.PhaseOption && SalesState?.PhaseFilter ) Promise.all(
              SalesState?.PhaseFilter.map(({label,Options})=>({[projLabel]:{label,Options: gatherList(userFilter,Options,category, socketIO)}}))
            ).then(([x])=> setField(p=>({...p,...{ 
              [projLabel]: x?.dynamicComponent('EODform', 'elements',{state:'saveForm',projID:{ProjectID,DivisionID,modelType},ProjData:x,projLabel,reSubmission,Deliveries,eodID,handleRemove:handleRemove},mainState,[(x)=> console.log('Returning ProjEOD', x)])
             }})) )
            else {
              Promise.all([gatherList(userFilter,SalesState?.OptionsFilter,category, socketIO)]).then(([x])=>
              setField(p=>({...p,...{ [projLabel]: dynamicComponent('EODform', 'elements',{state:'saveForm',projID:{ProjectID,DivisionID,modelType,eodID:eodID},ProjData:x,projLabel,reSubmission,eodID:eodID,handleRemove:handleRemove},mainState,[(x)=> console.log('Returning ProjEOD', x)]) }}))
             )
            }}
      })
      
      },[stagedRef.size])


      useEffect(()=>{

        console.log("Run Now:", fieldRef.current, stagedRef)
        if(Object.keys(fieldRef.current).length>0 && stagedRef.size > 0) {
          setTableState(Array.from(stagedRef));
          setAppState(p => { p.set('requiredPopup', true); return new Map(p)}) 
          console.log("testr:", appState.get('requiredPopup'))
          setState(p=>({...p,...{ 
          ItemsListedField: dynamicComponent('ItemsListed', 'elements',{tableState,valueTable,fieldState,bttnAction:()=>{buildDeliveries(); buildEOD(); buildIncompleteProj()}, disableDelete: true},mainState)
        }}))
        }
        else {
          setState(p=>({...p,...{FinishPopup: null}}))
          setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
        }
      },[stagedRef.size, fieldRef.current]) 




    return [stateRef.current.FinishPopup,stateRef.current.productCard]
 }*/
 export default FinishPopup;