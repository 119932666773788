import React, {useEffect} from "react";
import {Box} from '@mui/material';
import { useAtom } from 'jotai';
import { connect } from 'react-redux';
import { getTranslations, langChange } from '../Redux/actions';
import FileManager from '../FileManager/FileManager';
import { makeStyles } from '@mui/styles';
import * as globalState from "../globalState";

import { createNewFolder,getFilesList } from '../Redux/actions';
var unitID = {}

function folderQuery(children,folderID){
    if(children?.some(x=>x.id === folderID)){ 
        if(unitID.reName) children.find(x=>x.id === folderID).reName = unitID.reName
        return children.filter(x=>x.id === folderID)
    }
    else return children?.flatMap(x=>{ 
        if(x.children) return [].concat(...folderQuery(x.children,folderID))
        else return x
    })
}

function folderFilter(folderID,store){
    if(store?.filemanager?.selectedFolder==='/EKC' || (unitID.ProjectID && !store?.filemanager?.selectedFolder?.split('/')?.includes(unitID.ProjectID))) store.filemanager.filesList = store.filemanager.filesList.filter(x=>x.id === folderID)
    //console.warn('Test 01', store?.filemanager?.selectedFolder==='/EKC' || (unitID.ProjectID && !store?.filemanager?.selectedFolder?.split('/')?.includes(unitID.ProjectID)) )
    if(store?.filemanager?.filesList.some(x=>x.id === folderID) && unitID.reName) store.filemanager.filesList.find(x=>x.id === folderID).reName = unitID.reName
    //console.warn('Test 01', store?.filemanager?.filesList.some(x=>x.id === folderID) && unitID.reName)
    if(store?.filemanager?.foldersList?.children) store.filemanager.foldersList.children = folderQuery(store.filemanager.foldersList.children,folderID)
    //console.warn('Test 03',folderID, store?.filemanager?.foldersList?.children )
    return store   
}

const storeFilter = async(store) => {
  store = await folderFilter(unitID.folderID,store)
 // console.log('ListRep:',store,' || ',unitID )
return store
}

const useStyles = makeStyles(theme => ({
    root: {}
}));

function Repository(props){
  var {viewData,createNewFolder,globalWrn,translations,lang,getTranslations,memberQuery} = props
  const [projectMap] = useAtom(globalState.projectMapState)
  //const [teamMember] = useAtom(globalState.teamMemberState)
  const [appSocket] = useAtom(globalState.appSocketState)
  var socketIO = appSocket.get('socketIO')
  var {folderID,projID,compID} = viewData
  unitID.folderID = folderID

    if(projID?.ProjectID){
        unitID.ProjectID = projID?.ProjectID
        unitID.reName = projectMap.get(unitID.ProjectID).ProjectTitle
    }
    if(compID){
        unitID.ProjectID = compID
        //unitID.reName = teamMember.get(compID)?.name  
        memberQuery('61c0d633f259151fc4f829f6',(x)=>{
            if(x) unitID.reName = x
        })
    }

     if(!folderID){
        if(projID?.ProjectID){ 
        createNewFolder(`/EKC/Projects`,projID.ProjectID)
        .then(({data})=>
        socketIO.emit('projectBuilder',{projID:projID,submitObj:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }},({success,info})=>{
        console.log('updated FolderID',success,info)
        if(success) globalWrn('success',`You have successfully created a repository`)
        unitID.folderID = data.folderInfo.id
        })
        ).catch((error)=>{console.log('Error creating project folder',error)})
    }
    if(compID){ 
        unitID.ProjectID = compID
       // unitID.reName = teamMember.get(compID)?.name   
       memberQuery('61c0d633f259151fc4f829f6',(x)=>{ 
        if(x) unitID.reName = x
        createNewFolder(`/EKC/Companies`,compID).then(({data})=> 
        socketIO.emit('companyBuilder',{companyID:compID, submitObj:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }},({success,info})=>{
       if(success) globalWrn('success',`You have successfully created a repository`)
       unitID.folderID = data.folderInfo.id
      }) )
    })
    }
    }

useEffect(()=>{
    return ()=> unitID = {}
},[])

  const classes = useStyles();

    if(translations?.lang !== lang) import(`../Data/Languages/${lang}`)
        .then(result => getTranslations({
                lang: lang,
                data: result.default
            }) ).catch(e =>
            getTranslations({
                lang: lang,
                data: {}
            }) )

    const handleCallBack = (filePath)=> console.log('Image Path Returend', filePath)
    return <Box className={classes.root} style={{display:'flex',flex:1,width:'100%'}}><FileManager style={{display:'flex',flex:1}} callback={handleCallBack} {...props}/></Box>
}

const mapStateToProps = (store) => {
  store = storeFilter(store)
  //console.log('STORE??',store)
  return ({
    store,
    translations : store?.dashboard?.translations,
    lang : store?.dashboard?.lang,
})
}

const mapDispatchToProps = dispatch => ({
    langChange: (lang) => dispatch(langChange(lang)),
    getTranslations: (data) => dispatch(getTranslations(data)),
    createNewFolder: (destination, folderName) => dispatch(createNewFolder(destination, folderName)),
    getFilesList: (path) => dispatch(getFilesList(path)),
})

export const Component_repository = connect(mapStateToProps, mapDispatchToProps)(Repository);
