import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Avatar,Paper,Divider,Box,Drawer,Grid,Checkbox,InputLabel,MenuItem,FormControl,Button,Chip,Typography,Accordion,AccordionSummary,AccordionDetails,List,ListItemText,ListItemIcon,ListItem,IconButton,InputBase,ListSubheader,ListItemButton,Collapse,Card,CardContent,CardMedia,ToggleButton,ToggleButtonGroup,ListItemAvatar} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Slide from '@mui/material/Slide';

const RunAudit=({viewData,globalState,useIsMobile,waveLoad,globalWrn,getReq,dbUpdateValidate,dynamicComponent,action})=>{
     const {totalAmount, divName, defaultValue, displayName,categoryLabel, state,catagory} = viewData
      const outputState = useState(Object)
      const mainState = useState(Object)
      const checkState = useState(Object)
      const [, setState, stateRef] = mainState
      const [, setCheckState, checkRef] = checkState
      const [expanded, setExpanded] = React.useState(false)
      
      const [popupData3, setPopup3] = useAtom(globalState.popupState3)
      const [popupData2, setPopup2] = useAtom(globalState.popupState2)
      const [popupData4, setPopup4] = useAtom(globalState.popupState4)
      const [, setOutput, outputRef] = outputState
      const [UserMap] = useAtom(globalState.userMapState)
      const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState)
      const [divisionMap, setDivisionMap] = useAtom(globalState.divisionMapState)
      const [projectMap] = useAtom(globalState.projectMapState)
      const [inventoryMap] = useAtom(globalState.inventoryMapState)
      const [productMap] = useAtom(globalState.productMapState)

      const [appSocket] = useAtom(globalState.appSocketState)
      const uploadFilesState = useState(Object)
      const [appState, setAppState] = useAtom(globalState.appState)
      const [old3, setUploadFiles, uploadFilesRef] = uploadFilesState
      const [old4, setUploadTempFiles, uploadTempFilesRef] = useState(Object)
      const [file, setFile] = useState()
      const trashState = useState(Object)
      const containerRef = useRef(null);
      const alignments = useState(UserMap.get('pageStamp')?.[UserMap.get('pageStamp')?.active]);
      var gridRef = useRef(null)

      var socketIO = appSocket.get('socketIO')

      function latestState(x){
        return defaultValue?.x || outputRef.current?.x
      }

      const sendUserBack=()=>{
        if(stateRef.current.graphData !== undefined)
        {
          setState(p=>({...p,...{
            graphData: undefined,
            graphsStatus: undefined,
            graphsPrice: undefined,
            loadingPage: undefined,
            backBttn: dynamicComponent('DefaultButton','elements',{state:'backBttn',label:'Back',type:'button',startIcon:<KeyboardBackspaceIcon/>,full:true, style:{maxWidth: '25%', flex: 1, backgroundColor: '#c12a2a'}},mainState,[sendUserBack]),
          }}))
        }
        else{
          setAppState(p=>{ p.delete('modulePop'); return new Map(p) })
        }
      }

      useEffect(()=>{
        setState(p=>({...p,...{
            backBttn: dynamicComponent('DefaultButton','elements',{state:'backBttn',label:'Back',type:'button',startIcon:<KeyboardBackspaceIcon/>,full:true, style:{maxWidth: '25%', flex: 1, backgroundColor: '#c12a2a'}},mainState,[sendUserBack]),
        }}))
      },[])

      const handleChange = (event) => {
        const name = event.target.name

        if(checkRef.current[name]&& checkRef.current[name] === true) {
          setCheckState(p=>({...p,...{
            [name]: false
          }}))}
        else
        setCheckState(p=>({...p,...{
            [name]: true
          }}))

      }

      useEffect(()=>{
        if(stateRef.current.graphData && stateRef.current.graphData?.info?.Statuses)
        {
          setState(p=>({...p,...{
            graphsStatus: dynamicComponent('GenerateStatusReport', 'elements', {graphData: stateRef.current.graphData}, mainState)
          }}))
        }
        if(stateRef.current.graphData && stateRef.current.graphData?.info?.Prices)
        {
          setState(p=>({...p,...{
            graphsPrice: dynamicComponent('GeneratePriceReport', 'elements', {graphData: stateRef.current.graphData}, mainState)
          }}))
        }
      },[stateRef.current.graphData])

      const submit=(amountChecked)=>{
        const start1 = true
        setState(p=>({...p,...{
          loadingPage: <Slide in={start1} timeout={{
            enter: 2000,
            exit: 6000,
          }} direction="up" container={containerRef.current}><Box><Typography variant="h2" sx={{display: 'flex', justifyContent: 'center', padding: '2%'}}>Loading Results...</Typography><Avatar sx={{height: '80%', width: '70%', margin: 'auto'}} src="https://i.pinimg.com/originals/17/44/1e/17441ef826077986a1ee601f45e6bdfa.gif"></Avatar></Box></Slide>
        }}))
        var allDivisionsArr = [...divisionMap]

        var inventoryArr = JSON.stringify([...inventoryMap])
        var divisionArr = JSON.stringify([...divisionMap])
        var projectArr = JSON.stringify([...projectMap])
        var productArr = JSON.stringify([...productMap])
        var stockItems = [...inventoryMap].filter(([k,v]) => ["Stock"].includes(v?.Status))

        console.log(checkRef.current, amountChecked, outputRef.current.typePriceInWarehouse)
        if(checkRef.current.MissingMSRP)
        {
          var filteredInventoryMap = ([...inventoryMap].filter(([k,v]) => 1 >= (v?.ProductItems?.[0]?.Product?.MSRP)).flatMap(x=>{return x[1]}))
          console.log(filteredInventoryMap)
          setState(p=>({...p,...{
            dataGrid: <Box>
              <Typography sx={{padding: '1%', display: 'flex', justifyContent: 'center'}}>{`There is ${filteredInventoryMap.length} Inventory Items in need of a MSRP`}</Typography>
              <Box sx={{flex:1,overflow:'auto',display:'flex'}}>{dynamicComponent('InventoryGrid','elements',{state:'DataGridBuilder',data:filteredInventoryMap,alignments:alignments,gridRef:gridRef},mainState) || waveLoad()} </Box>
              </Box>
          }}))
        }
        socketIO.emit('auditInventory',{data:checkRef.current,amountChecked:amountChecked,product:outputRef.current.ProductItems,maps:{inventoryArr,productArr,divisionArr,projectArr},typeInWarehouse:outputRef.current.typePriceInWarehouse},(x)=>{
          setState(p=>({...p,...{
            graphData: x
          }}))
          console.log("Response is:", x)
        })
      }

      useEffect(()=>{
        setState(p=>({...p,...{
          productSelection: dynamicComponent('ProductSelect','elements',{state:'ProductItems',label:'Select A Product',defaultValue:latestState('ProductItems')||[] },trashState
          ,[({ProductItems})=>{
           if(ProductItems !== undefined && ProductItems?.[0]?.id!==latestState('ProductItems')?.[0]?.id) setOutput(p=>({...p,...{ProductItems}}))
         }]
           )
        }}))
      },[])

      useEffect(()=>{      // submit button
        var amountChecked = 0
        const checkType = ['Metrics','Spreadsheet','ProjectInventoryReport']
        const checkContent = ['Statuses','Price','Savings','SignOuts','Product']
        const checkSpecifics = ['ItemsInRMA','ItemsInOrderProcessing','ItemsInShipping','ItemsInRequested','ItemsInStock', 'AllItems', 'AmountAtSite','AmountInWarehouse','AmountToPurchase','MissingMSRP','AmountSavedByRMAs','AmountSavedByStockReduction','SignOutReports','ProductStatistics']
        // make sure that values are actually checked
        for (const key of checkType)
        {
          if (checkRef.current[key] === true) {
            amountChecked = 1;
            break;
          }
        }
          // if not checked then return
          if(amountChecked === 0)
          {
            setState(p=>({...p,...{submitBttn:undefined}}))
            return
          }
          amountChecked = 0
            // make sure that values are actually checked
            for (const key of checkContent)
            {
              if (checkRef.current[key] === true) {
                amountChecked = 1;
                break;
              }
            }
            // if not checked then return
            if(amountChecked === 0) 
            {
              setState(p=>({...p,...{submitBttn:undefined}}))
              return
            }
            amountChecked = 0
              // make sure that values are actually checked
              for (const key of checkSpecifics)
              {
                if (checkRef.current[key] === true) {
                  amountChecked = amountChecked + 1;
                }
              }
              // if not checked then return
              if(amountChecked === 0) 
              {
                setState(p=>({...p,...{submitBttn:undefined}}))
                return
              }
              console.log(outputRef.current)
              if(checkRef.current.ProductStatistics && checkRef.current.ProductStatistics === true && !outputRef.current.ProductItems){
                if(stateRef.current.submitBttn) 
                {
                  setState(p=>({...p,...{submitBttn:undefined}}))
                }
              }
              else if(checkRef.current.AmountInWarehouse && checkRef.current.AmountInWarehouse === true && !outputRef.current.typePriceInWarehouse){
                if(stateRef.current.submitBttn) 
                {
                  setState(p=>({...p,...{submitBttn:undefined}}))
                }
              }
              else {
                setState(p=>({...p,...{
                  submitBttn:dynamicComponent('DefaultButton','elements',{state:'submitBttn',label:'Submit',type:'button',full:true, style:{flex: 1, backgroundColor: '#c12a2a'}},mainState,[()=>submit(amountChecked)])
                }}))
              }

      },[checkRef.current, outputRef.current.ProductItems, outputRef.current.typePriceInWarehouse])

      useEffect(()=>{
        setState(p=>({...p,...{
          warehouseTypeDropdown: dynamicComponent('DropDown','elements',{ state: 'typePriceInWarehouse', label: 'Select A Category', list: ['Stock', 'Project Inventory', 'All'], replace:true },outputState) 
        }}))
      },[])



      return <Box sx={{padding: '1%', height: '90vh', opacity: 1, borderRadius: '4px'}} key={"audit1"}>
        <Box sx={{padding: '0.5%', opacity: 1, height: '99%', borderRadius: '4px'}}>
            <Box sx={{width: '100%', textAlign:'center', boxShadow:'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', borderRadius: '4px', display: 'flex', backgroundColor: '#474646'}}>
            {stateRef.current.backBttn}
            </Box>
            <Box sx={{backgroundColor: '#474646', width: '100%', height: '100%'}} ref={containerRef}>{!stateRef.current.loadingPage ? <>
            <Typography variant="h2" sx={{color: 'white', display: 'flex', justifyContent:'center'}}>Run Audit</Typography>
            <Box sx={{width: '98%', height:'89%', margin:'auto', textAlign:'center', boxShadow:'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', borderRadius: '4px', backgroundColor:'#21364E'}}>
            <Typography sx={{justifyContent: 'center', padding: '1%'}}>
                Type of Report
              </Typography>
              <Divider/>
              <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
              <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.Metrics === true} name="Metrics"/>} label="Metrics" />
              <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.Spreadsheet === true} name="Spreadsheet"/>} label = "Spreadsheet"/>
              <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ProjectInventoryReport === true} name="ProjectInventoryReport"/>} label="Project Inventory Report"/>
            </FormGroup>
            <Divider/> 
              <Typography sx={{justifyContent: 'center', padding: '1%'}}>
                Content
              </Typography>
            <Divider/>
              <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
                <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.Statuses === true} name="Statuses"/>} label="Statuses" />
                <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.Price === true} name="Price"/>} label = "Price"/>
                <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.Savings === true} name="Savings"/>} label="Savings"/>
                <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.SignOuts === true} name="SignOuts"/>} label="Sign-Outs"/>
                <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.Product === true} name="Product"/>} label="Product"/>
              </FormGroup> 
            <Divider/>
              <Typography sx={{justifyContent: 'center', padding: '1%'}}>
                Specifics
              </Typography>
              <Divider/>
              {
                checkRef.current.Statuses && checkRef.current.Statuses === true &&               
                <>
                  <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ItemsInRMA === true} name="ItemsInRMA"/>} label="Items In RMA" />
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ItemsInOrderProcessing === true} name="ItemsInOrderProcessing"/>} label = "Items In Order Processing"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ItemsInShipping === true} name="ItemsInShipping"/>} label="Items In Shipping"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ItemsInStock === true} name="ItemsInStock"/>} label="Items In Stock"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ItemsInRequested === true} name="ItemsInRequested"/>} label="Items In Requested"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.AllItems === true} name="AllItems"/>} label="All Statuses"/>
                  </FormGroup> 
                  <Divider/>
                </>
              }
              {
                checkRef.current.Price && checkRef.current.Price === true &&
                <>
                  <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.AmountAtSite === true} name="AmountAtSite"/>} label="Amount At Site"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.AmountInWarehouse === true} name="AmountInWarehouse"/>} label="Amount In Warehouse"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.AmountToPurchase === true} name="AmountToPurchase"/>} label="Amount To Purchase"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.MissingMSRP === true} name="MissingMSRP"/>} label="Missing MSRP"/>
                  </FormGroup> 
                  <Divider/>
              </>
              }
              {
                checkRef.current.Savings && checkRef.current.Savings === true &&
                <>
                  <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.AmountSavedByRMAs === true} name="AmountSavedByRMAs"/>} label="Amount Saved By RMAs"/>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.AmountSavedByStockReduction === true} name="AmountSavedByStockReduction"/>} label="Amount Saved By Stock Reduction"/>
                  </FormGroup>
                  <Divider/>
                </> 
              }
              {
                checkRef.current.SignOuts && checkRef.current.SignOuts === true &&
                <>
                  <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.SignOutReports === true} name="SignOutReports"/>} label="Sign Out Reports"/>
                  </FormGroup> 
                  <Divider/>
                </>
              }
              {
                checkRef.current.Product && checkRef.current.Product === true &&
                <>
                  <FormGroup sx={{flexDirection:'row',justifyContent:'space-between', padding: '1%'}}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checkRef.current.ProductStatistics === true} name="ProductStatistics"/>} label="Product Statistics"/>
                  </FormGroup> 
                  <Divider/>
                </>
              }
              {
                checkRef.current.ProductStatistics && checkRef.current.ProductStatistics === true &&
                <Box sx={{justifyContent: 'center'}}>{stateRef.current.productSelection}</Box>
              }
              {
                checkRef.current.AmountInWarehouse && checkRef.current.AmountInWarehouse === true &&
                <Box sx={{width: '100%'}}>{stateRef.current.warehouseTypeDropdown}</Box>
              }
              <Box>
              {stateRef.current.submitBttn}
              </Box>
          </Box> </> : <><Box>{stateRef.current.graphsStatus && stateRef.current.graphsStatus}</Box>
                       {stateRef.current.dataGrid && <Box>{stateRef.current.dataGrid}</Box>}
                       <Box>{stateRef.current.graphsPrice && stateRef.current.graphsPrice}</Box>
                       <Box>{!stateRef.current.graphsStatus && !stateRef.current.graphsPrice && stateRef.current.loadingPage}</Box>
                        </>}
        </Box>
      </Box>
    </Box>


    }
    export default RunAudit;