import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import TaskManager from '../FileManager/TaskManager';

import { Box } from '@mui/material';

const AddSite=({dynamicComponent,globalState,handlePopupClose,viewData,globalWrn,memberQuery})=> {
  const {compID, popupNum} = viewData
  const outputState = useState(Object)
  const fieldState = useState(Object)
  const mainState = useState(Object)
  const [, setOutput, outputRef] = outputState
  const [, setState, stateRef] = mainState
  const [teamMember] = useAtom(globalState.teamMemberState)
  
  var [popupData3, setPopup3] = useAtom(globalState.popupState3)
  var [popupData2, setPopup2] = useAtom(globalState.popupState2)
  var [popupData, setPopup] = useAtom(globalState.popupState)


  /*if(compID) var companyData = memberQuery(compID,(x)=>{
    return x
  })*/
  
  if(compID) var companyData = teamMember.get(compID)
  
  const [appSocket] = useAtom(globalState.appSocketState)
  var socketIO = appSocket.get('socketIO')

  const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
  function latestState(x){
    return outputRef.current[x] || simp(companyData,x)
  }

  useEffect(()=>{
    console.log(popupNum)
  },[popupNum])

 /* const newSite=()=>{
    setState(p=>({...p,...{
      newSt: dynamicComponent('AddSites','elements',{state:'newSite'},mainState),
    }}))
  } 

  const closePopup2=()=>{
    setPopup2(p=>({...p,...{
      open:false,
    }}))
  }

  const addSites=()=>{
    newSite()
    setPopup2(p=>({...p,...{
      open:true,
      title:`Add District/Company Site`,
      description: `Do not add entire company, government bureau/agency, or school district.`,
      inputField: stateRef.current.newSt,
      handleClose: closePopup2,
      nameInputSets: {},
  }}))  
  }
  */

  const userAdd =() =>{
    setState(p=>({...p,...{
    partnerFormBuilder : dynamicComponent('PartnerField','elements',{state:'newPart'},fieldState),
  }}))
  
  setPopup(p=>({...p,...{
                  open:true,
                  title:`Add Partner or Client`,
                  description: `Do not add Staff, they will need to create an account.`,
                  inputField: stateRef.current.partnerFormBuilder,
                  handleClose: handlePopupClose,
                  nameInputSets: {},
  }}))
  }
  useEffect(()=>{
    if(teamMember.has(compID)) var {firstName,lastName,workPhone,fax,email,department,company,POnumber} = companyData
    /*if(memberQuery(compID,(x)=>{
      if(x)
        return true
      else
        return false
    })) var {firstName,lastName,workPhone,fax,email,department,company,POnumber} = companyData*/
    setState(p=>({...p,...{ 
      TypeField: dynamicComponent('DropDown','elements',{state: 'type', replace:true,list:['Education','Government', 'Healthcare', 'Residential', 'Religious', 'Commercial', 'Industrial'],label:'Type',defaultValue: company },outputState),
      NameField: dynamicComponent('InputField','elements',{state:'name',label:'Name',type:'text',defaultValue: firstName},outputState),
      PhoneField: dynamicComponent('InputField','elements',{state:'phone',label:'Phone #',type:'tel', defaultValue: workPhone},outputState),
      FaxField: dynamicComponent('InputField','elements',{state:'fax',label:'Fax # (Optional)',type:'tel', defaultValue: fax},outputState),
      EmailField: dynamicComponent('InputField','elements',{state:'email',label:'Email',type:'email', defaultValue: email},outputState),
      AddressField: dynamicComponent('GeoSuggest','elements',{state:'address',label:department || 'Headquarters Address',limit:4},outputState),
      DescriptionField: dynamicComponent('InputField','elements',{state:'description',label:'Notes (Optional)',type:'text',multiline:true, defaultValue: lastName},outputState),
      Sites: dynamicComponent('AutoComplete','elements',{state:'SiteID',label:'Site(s)',defaultValue: latestState('SiteID'),model:'Sites',query:{}},outputState),
      Client: dynamicComponent('AutoComplete','elements',{state:'ClientID',label:'Client(s)',defaultValue: latestState('ClientID'),model:'Clients',query:{},filter:{local:1}, addEntry:userAdd},outputState),
      POnumber: dynamicComponent('InputField','elements',{state:'POnumber',label:'PO number',type:'text', defaultValue: POnumber},outputState),
    }}))
  },[teamMember.get(compID)])


    const submitItem=()=>{
      delete outputRef.current.map
      socketIO.emit('companyBuilder',{submitObj:{...outputRef.current}},({success,info,err})=>{ 
        if(success===true){ 
          globalWrn('success',`You have successfully created ${outputRef.current['name']}`)
          if(popupNum===1 || !popupNum)setPopup({open:false})
          if(popupNum===2) setPopup2({open:false})
          if(popupNum===3) setPopup3({open:false})
          if(!compID) stateRef.current.createNewFolder(`/EKC/Companies`, info.insertedId).then(({data})=> 
          socketIO.emit('companyBuilder',{companyID:info.insertedId, submitObj:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }},({success,info})=>{
         if(success) globalWrn('success',`You have successfully created a repository for ${outputRef.current['name']}`)
        }) )
        }else globalWrn('error',`There was an error updating company ${outputRef.current['name']}.`)
        setState(p=>({...p,...{loading : false }}))
      })
    }
    
    const submitUpdateItem=()=>{
      delete outputRef.current.map
      socketIO.emit('companyBuilder',{submitObj:{...outputRef.current}, companyID:compID},({success,info})=>{ //update existing company
        if(success===true){ 
          globalWrn('success',`You have successfully updated ${outputRef.current['name']}`)
          if(popupNum===1 || !popupNum)setPopup({open:false})
          if(popupNum===2) setPopup2({open:false})
          if(popupNum===3) setPopup3({open:false})
          //if(!CompanyID) stateRef.current.createNewFolder(`/EKC/Companies`, comp._id)
        }else globalWrn('error',`There was an error updating company ${outputRef.current['name']}.`)
        setState(p=>({...p,...{loading : false }}))
      })
    }
    
    useEffect(()=>{
      console.log('outputRef.current',outputRef.current)

      if(!compID && outputRef.current && ['type','name','address'].every(x=> Object.keys(outputRef.current).includes(x)) && outputRef.current.name !== "")
      {
        if(popupNum && popupNum === 3) {setPopup3(p=>({...p,...{
        handleSubmit: submitItem,
        submitLabel: 'Create',
      }}))
    }
      else{
        setPopup(p=>({...p,...{
          handleSubmit: submitItem,
          submitLabel: 'Create'
        }}))
      console.log(popupNum)
      }
    }
      else if(compID && outputRef.current && Object.keys(outputRef.current).length > 0) //if output ref exists and has items in it
      {
        setPopup(p=>({...p,...{
          handleSubmit: submitUpdateItem,
          submitLabel: 'Update'
        }}))
      }
      else
      {
        if(popupNum === 1) setPopup(p=>({...p,...{handleSubmit: undefined}}))
        if(popupNum === 2) setPopup2(p=>({...p,...{handleSubmit: undefined}}))
        if(popupNum === 3) setPopup3(p=>({...p,...{handleSubmit: undefined}}))
      }
    },[outputRef.current,compID])

   return <Box sx={{ display: 'flex', flexWrap: 'wrap',flexDirection:'column',p:'1%', gap:'5px'}}>
          <Box style={{display:'flex',flex:1, overflow:'auto', gap:'1%' }}>
          <Box sx={{m:'auto'}}>{stateRef.current.TypeField}</Box>
          {stateRef.current.NameField}
          </Box>
          <Box style={{display:'flex',flex:1,gap:'1%' }}>
          {stateRef.current.PhoneField}
          {stateRef.current.FaxField}
          {stateRef.current.EmailField}
          </Box>
          <Box style={{display:'inline-block',flex:1,gap:'1%', width:'100%' }}>
          {stateRef.current.AddressField}
          </Box>
          <Box style={{display:'flex',flex:1}}>
          {stateRef.current.POnumber}
          </Box>
          <Box style={{display:'flex',flex:1}}>
          {stateRef.current.Sites}
          </Box>
          <Box style={{display:'flex',flex:1}}>
          {stateRef.current.Client}
          </Box>
          <Box style={{display:'flex',flex:1}}>
          {stateRef.current.DescriptionField}
          </Box>
          <TaskManager That={mainState}/>
          </Box>
}
export default AddSite