    import React, { useEffect, useRef } from "react";
    import { useAtom } from 'jotai';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faFileContract } from '@fortawesome/free-solid-svg-icons';
    import useState from 'react-usestateref';
    import CloseIcon from '@mui/icons-material/Close';
    import Draggable from 'react-draggable';
    import SaveIcon from '@mui/icons-material/Save';
    //import { styled } from '@mui/material/styles';
    import { Box, Grid, Card, CardMedia, CardContent, CardActions, Typography, Divider, Fab } from '@mui/material';

    const PopStyle = {
      close: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'unset',
        height: 'unset',
        aspectRatio: '1/1',
        zIndex: 1000,
        borderRadius: 'unset',
        background: 'var(--primary-color)',
        borderBottomRightRadius: '8px'
      }
      }
  
    export const Component_editDivsn = ({globalState,That,viewData,dynamicComponent,waveLoad,globalWrn,handlePopupClose,useIsMobile,dbUpdateValidate}) =>{
      const {DivisionID,divisionName} = viewData
      const mainState = useState(Object)
      const [, setState, stateRef] = mainState
      const outputState = useState(Object)
      const [, setOutput, outputRef] = outputState
      const [, setPopup] = useAtom(globalState.popupState)
      const [, setPopup2] = useAtom(globalState.popupState2)
      const [UserMap] = useAtom(globalState.userMapState)
      const [, setGlobalAppState] = useAtom(globalState.appState)
      const [divisionMap] = useAtom(globalState.divisionMapState)
      const {DivLoc,calendar,category,description,log,projectID,geoLog,mobilePhone,teamLogg,teamName,divisionLabel,Costs,workPhone} = divisionMap.get(DivisionID)
      const useAndMobile = useIsMobile(560)
      const mapRef = useRef(null)

      const [appSocket] = useAtom(globalState.appSocketState)
      var socketIO = appSocket.get('socketIO')

      const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
      const latestState =(x)=> outputRef.current[x] || simp(divisionMap.get(DivisionID),x)

      const pnl =()=> {
        var plnRec = [{id:'LabelValue',loc:'elements',viewData:{state:'inventory',label:'Costs',type:'number',labelEditable:true}}]
        setPopup(p=>({...p,
        open: true,
        title:`Set Your Division Costs.`,
        description: 'Your costs will be amazing.',
        /*<Box style={{background:'rgba(0,0,0,0.35)',padding:'4%',color:'#fff',borderRadius:'8px',minWidth:'500px'}}></Box>*/
        inputField: dynamicComponent('Listable','elements',{state:'division.Costs',label:'Add Cost Item',items:plnRec,defaultValue: outputRef.current['division.Costs'] ? outputRef.current['division.Costs'] : divisionMap.get(DivisionID)?.Costs ? divisionMap.get(DivisionID)?.Costs : null },outputState),
        //inputField: dynamicComponent('lineItem','component',{stateChg:['updateLocal'],state:'division.Costs',items:[{label:'Additional Costs',type:'number',icon:<FontAwesomeIcon icon={faDollarSign}/>},{label:'Description',type:'text'}], plus:true, defaultValue: latestState('Costs')  },outputState),
        handleClose: handlePopupClose,
        nameInputSets: {},
      }))}

    useEffect(()=>{ 
        setState(p=>({...p,
          picture: dynamicComponent('avatar','component',{stateChg:['dbUpdate'],state:'avatar',className:'ProfileCircle',avatar: DivisionID, maxWidthOrHeight: 300},mainState),
        }))
    },[])

    useEffect(()=>{
      if(Object.keys(outputRef.current).length>0 && !stateRef.current.updateBttn) setState(p=>({...p,
        updateBttn: <Box sx={{m:'1%'}}>{dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:"UPDATE",type:'save',startIcon:<SaveIcon/>,full:true},mainState,[save])}</Box>
      }))
    },[outputRef.current])

    const save=()=>{ console.log('OUT:',DivisionID, outputRef.current )
      dbUpdateValidate({model:'Divisions',modelType:divisionMap.get(DivisionID)?.teamName,queryID:true,query:DivisionID,update:outputRef.current},({success,info,err})=>{ console.log('Save',success,info,err)
        if(success){
          globalWrn('success',`Division item for ${divisionLabel || teamName} was updated.`)
          setGlobalAppState(p=>{ p.delete('modulePop'); return new Map(p) })
       } else globalWrn('error',`We had an issue updating Division ${divisionLabel || teamName}.`)
       })
    }

    const createProj=()=>{
      var userDiv = outputRef.current.DivisionID || UserMap.get('division')
      var modelType = divisionMap.get(userDiv)?.teamName
      setState(p=>({...p,projectReload:undefined}))
      setPopup2(p=>({...p,
        open:true,
        title:`Create Project`,
        zIndex: 2,
        inputField: <Box sx={{width:'60vw',height:'80vh'}}>{dynamicComponent('editProj','component',{projID:{DivisionID:userDiv,modelType:modelType},DivisionLock:userDiv,ref:module},That,[(x)=>{setState(p=>({...p,projectReload:true}));setOutput(p=>({...p,ProjectID:x}));setPopup2(p=>({...p,
          open:false})) }])}</Box>,
        handleClose: ()=>setPopup2({open:false}),
        nameInputSets: {},
    }))  
    }

    useEffect(()=>{
      console.log("Latest:", latestState('logManager') ,DivLoc?.location)
      setState(p=>({...p, 
        geoMap: dynamicComponent('geoMap','component',{loc: DivLoc?.location, ref: mapRef,projID:{ DivisionID, modelType:teamName }},mainState),
        followField: dynamicComponent('AutoComplete','elements',{state:'division.projectFollow',label:'Follow All Projects',defaultValue: latestState('projectFollow'),model:'User',query:{},filter:{local:1}},outputState),
        divisionName: dynamicComponent('InputField','elements',{state:'division.divisionLabel',label:'Division Name/Label', defaultValue: divisionMap.get(DivisionID)?.divisionLabel || divisionMap.get(DivisionID)?.teamName, type:'text', style:{margin:0},multiline:true},outputState),
        divisionDescription: dynamicComponent('InputField','elements',{state:'division.description',label:'Division Notes', defaultValue: divisionMap.get(DivisionID)?.description, type:'text', style:{margin:0},multiline:true},outputState),
        phoneNumber: dynamicComponent('InputField','elements',{state:'division.workPhone',label:'Office Phone#', defaultValue: divisionMap.get(DivisionID)?.workPhone, type:'phone', style:{margin:0},multiline:true},outputState),
        costsBttn: dynamicComponent('DefaultButton','elements',{state:'costs',color:'#f58426',label:'Division Costs',startIcon:<FontAwesomeIcon icon={faFileContract}/>},mainState,[pnl]),
        membersList: latestState('Members') ? dynamicComponent('AvatarBubble','elements',{state:'teams',label:'missing Lead', style:{width:'20px',height:'20px'},userID: [...new Set(latestState('Members').flatMap(x=> x.id))] },mainState) : <Typography variant="caption" >No Members Listed</Typography>,
        projManager: dynamicComponent('UserButton','elements',{state:'division.divsionManager',label:'Division Director',defaultValue: latestState('divsionManager'), divisionName:divisionName},outputState),
        logManager: dynamicComponent('UserButton','elements',{state:'division.logManager',defaultValue: divisionMap.get(DivisionID)?.logManager, label:'Logistics Manager', divisionName:divisionName},outputState),
        warehouseManager: dynamicComponent('UserButton','elements',{state:'division.warehouseManager',label:'Warehouse Manager', divisionName:divisionName},outputState),
        //logManager: dynamicComponent('UserButton','elements',{state:'division.logManager',label:'Logistics Manager',defaultValue: latestState('logManager'),disabled:true, divisionName:divisionName},outputState),
        geoSug: dynamicComponent('GeoSuggest','elements',{state:'DivLoc',label: latestState('DivLoc.label') || "Enter Delivery Location",limit:4},mainState,[({DivLoc})=>{ console.log('DivLoc',DivLoc); setOutput(p=>({...p, 'division.DivLoc':{...DivLoc} })) }]),
        // dynamicComponent('geoSuggest','component',{state:'DivLoc',label: (latestState('DivLoc.label') || "Enter Delivery Location") ,limit:4,onBlur:false},mainState,[({DivLoc})=> setOutput(p=>({...p, 'division.DivLoc':{...DivLoc} })) ]),
        defProj: dynamicComponent('AutoComplete','elements',{state:'division.projectID',label:'Default Division Stock',defaultValue: projectID?.[0],model:'Projects',query:{'Status':{$in:['Sales','Project']}},filter:{},modelType: teamName,addEntry:createProj,single:true},outputState),
        //fullfillment: <FulfillmentInventory dynamicComponent={dynamicComponent} dbUpdateValidate={dbUpdateValidate} globalState={globalState} latestState={latestState} globalWrn={globalWrn} handlePopupClose={handlePopupClose} useAndMobile={useAndMobile} DivisionID={DivisionID} socketIO={socketIO} />
      }))
      console.log("Test:", divisionMap.get(DivisionID))
    },[divisionMap.get(DivisionID)])

    useEffect(()=>{
      setState(p=>({...p, 
        geoMap: dynamicComponent('geoMap','component',{loc: DivLoc?.location, ref: mapRef,projID:{ ProjectID:null, DivisionID, modelType:teamName }},mainState),
      }))
    },[])

      return [ <Box sx={{opacity:.65,position:'absolute',width:'100%',height:'100%'}} key={'someVal'}>
               { stateRef.current.geoMap }
              </Box>,
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Card sx={{ maxWidth: 'clamp(40%,445px,86vw)', m:'auto 3% auto auto',display:'flex',flexDirection:'column' }}>
      <Fab onClick={()=>{ setGlobalAppState(p=>{ p.delete('modulePop'); return new Map(p) }) }} style={PopStyle.close}>
      <CloseIcon/>
    </Fab>
      <CardMedia sx={{maxHeight:'150px',display:'flex'}}>
        { stateRef.current.picture}
      </CardMedia>
      <CardContent id="draggable-dialog-title" sx={{p:'1.25% 2%'}} >
        <Typography gutterBottom variant="h5" sx={{m:'auto',lineHeight:1}}>
          {teamName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         {description}
        </Typography>
     </CardContent>
     <CardActions sx={{overflow:'auto',flexDirection:'column'}}>
     

      <Box sx={{width:'100%'}}>
      <Divider variant="middle"><Typography gutterBottom variant="caption" component="div">DIVISION SETTINGS</Typography></Divider>
            <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.divisionName }
            </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.divisionDescription }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.phoneNumber }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            <Box sx={{display:'flex',height:'90%',m:'1%',justifyContent:'center'}}>{ stateRef.current.costsBttn }</Box>
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.projManager }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.logManager }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.warehouseManager }
            </Grid>
            <Grid sx={{padding:'1%'}} item xs={12} md={ useAndMobile ? 12 : 6 }>
              {stateRef.current.defProj}
            </Grid>
            <Grid sx={{padding:'1%'}} item xs={12} md={ useAndMobile ? 12 : 12 }>
            { stateRef.current.geoSug }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            <Box sx={{m:'auto',flex:1,flexDirection:'column'}}>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',flex:1,p:'3px 2% 2%'}}>
                <Typography variant="body2">Project Managers</Typography>
                { stateRef.current.membersList }
              </Box>{stateRef.current.teamAdd}</Box>
            </Grid>
            <Grid sx={{padding:'1%'}} item xs={12} md={ useAndMobile ? 12 : 6 }>
            <Box sx={{m:'auto',flex:1,flexDirection:'column'}}>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',flex:1,p:'3px 2% 2%'}}>
                <Typography variant="body2">Team Members</Typography>
                { stateRef.current.membersList }
              </Box>{stateRef.current.teamAdd}</Box>
            </Grid>
            </Grid>
            </Box>
          {/*<Box sx={{width:'100%'}}>
          <Divider variant="middle"><Typography gutterBottom variant="caption" component="div">Project Fulfullment Inventory</Typography></Divider>
            <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 12 }>
            { stateRef.current.fullfillment }
            </Grid>
          </Grid>
  </Box>*/}
        <Box sx={{width:'100%'}}>
          <Divider variant="middle"><Typography gutterBottom variant="caption" component="div">YOUR SETTINGS</Typography></Divider>
            <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 12 }>
            { stateRef.current.followField }
              </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 12 }>
            {/* dynamicComponent('ToggleBttnGroups','elements',{state:'userOpt',Items:[{type:'single',label:'Text 01',subState:'label01',items:[{label:'Item 01'},{label:'Item 02'},{label:'Item 03'},{label:'Item 04'},{label:'Item 05'}]},{type:'multi',label:'Text 02',subState:'label02',items:[{label:'Item A'},{label:'Item B'},{label:'Item C'},{label:'Item D'},{label:'Item E'}]}]},mainState) */}
            </Grid>
          </Grid>
        </Box>
        </CardActions>
    { stateRef.current.updateBttn }
    </Card>
    </Draggable>] 
    }

    /*function FulfillmentInventory({dynamicComponent,dbUpdateValidate,globalState,latestState,handlePopupClose,DivisionID,useAndMobile,globalWrn}) {
      const [popupData, setPopup] = useAtom(globalState.popupState)
      const mainState = useState({ 
        noListLabel: 'No Fulfillments',
        buttonTitle: '+Fulfillment',
        waitingLabel: '...Gathering Fulfillments'
       })
      const [old1, setState, stateRef] = mainState
      const outputState = useState(Object)
      const [output, setOutput, outputRef] = outputState
      const tableState = useState([])
      const [old2, setTableState, tableStateRef] = tableState
      const valueTable = useState(0)
      const [value, setValue, valueRef] = valueTable
      const fieldState = useState(Object)
      const [old3, setField, fieldRef] = fieldState

      const [appSocket] = useAtom(globalState.appSocketState)
      var socketIO = appSocket.get('socketIO')

      const [divisionMap, setDivisionMap] = useAtom(globalState.divisionMapState)
      const {teamName,FulfillmentInventory} = divisionMap.get(DivisionID)

      const addPartner=()=>{
        setPopup(p=>({...p,
          open:true,
          title:`Add Partner or Client`,
          description: `Do not add Staff, they will need to create an account.`,
          inputField: dynamicComponent('PartnerField','elements',{state:'newPart'},fieldState),
          handleClose: ()=>{handlePopupClose()},
          nameInputSets: {},
      }))  
      }

      useEffect(()=>{
      if( outputRef.current && ['labelFulfillment','POFulfillment','descFulfillment','clientID','projManager'].every(x=> Object.keys(outputRef.current).includes(x)) ) setPopup(p=>({...p,
      submitLabel: 'Create',
      handleSubmit: ()=> socketIO.emit('updateFulfillment',{data:outputRef.current,projID:{DivisionID:DivisionID}},({sucess,info,err})=>{
        if(sucess) {globalWrn('success',`${outputRef.current.labelFulfillment} was created.`)
        handlePopupClose()}
        else globalWrn('error',`We had an issue creating ${outputRef.current.labelFulfillment}.`)
      })
      }))
      },[outputRef.current])

     /* function create(){
        var items = <Box sx={{p:'1%'}}><Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('InputField','elements',{state:'labelFulfillment',label:'Fulfillment Label', defaultValue:null, type:'text'},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('InputField','elements',{state:'POFulfillment',label:'Fulfillment PO:', defaultValue:null, type:'text'},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('InputField','elements',{state:'descFulfillment',label:'Fulfillment Notes', defaultValue:null, type:'text'},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('AutoComplete','elements',{state:'clientID',label:'Client',defaultValue:[],model:'Clients',query:{},filter:{local:1}, addEntry:addPartner},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          <Box sx={{width:'100%'}}>{ dynamicComponent('UserButton','elements',{state:'projManager',label:'Assigned Project Manager',defaultValue: latestState('projManager'),disabled:true, divisionName:teamName,bttnStyle:{width:'100%'}},outputState) }</Box>
        </Grid>
      </Grid>
      </Box>

        setPopup(p=>({...p,
          open:true,
          zIndex: 2,
          title:`Create Fulfillment Project`,
          description: 'Your Fulfillment will be amazing.',
          inputField: items,
          handleClose: handlePopupClose,
          nameInputSets: {},
      }))
      }

      /*useEffect(()=>{
       // console.log('FulfillmentInventory',FulfillmentInventory)
        if(FulfillmentInventory){ 
          setTableState(FulfillmentInventory.flatMap(x=>({...x,projLabel:x.labelFulfillment,ProjectID:x._id})))
          FulfillmentInventory.forEach(x => setField(p=>({...p, [x._id]:<Slider socketIO={socketIO} globalWrn={globalWrn} addPartner={addPartner} fulfillmentID={x._id} outputState={outputState} dbUpdateValidate={dbUpdateValidate} dynamicComponent={dynamicComponent} globalState={globalState} useAndMobile={useAndMobile} DivisionID={DivisionID} latestState={latestState} handlePopupClose={handlePopupClose} {...x}/> })) )
        }
      },[FulfillmentInventory])

      useEffect(()=>{
        if(fieldRef.current) setState(p=>({...p, 
          ItemsListed: dynamicComponent('ItemsListed', 'elements',{tableState,valueTable,fieldState/*,bttnAction:create},mainState)
        }))
      },[fieldRef.current,valueRef.current]) 

      return stateRef.current.ItemsListed
    }*/

    /*function Slider({dynamicComponent,addPartner,globalWrn,fulfillmentID,globalState,useAndMobile,DivisionID,latestState,handlePopupClose,dbUpdateValidate}){
      const mainState = useState(Object)
      const [popupData, setPopup] = useAtom(globalState.popupState)
      const [old1, setState, stateRef] = mainState
      const [divisionMap, setDivisionMap] = useAtom(globalState.divisionMapState)
      const {teamName,FulfillmentInventory} = divisionMap.get(DivisionID)
      const outputState = useState(Object)
      const [output, setOutput, outputRef] = outputState
      if(FulfillmentInventory) var {Inventory,labelFulfillment,POFulfillment,descFulfillment,clientID,partnerID,projManager} = FulfillmentInventory?.find(({_id})=>_id===fulfillmentID)
      const [appSocket] = useAtom(globalState.appSocketState)
      var socketIO = appSocket.get('socketIO')

    useEffect(()=>{
      var plnRec = [{id:'LabelValue',loc:'elements',viewData:{state:'inventory',label:'Costs',type:'currency',labelEditable:true}}]
      setState(p=>({...p, 
           listed: dynamicComponent('Listable','elements',{state:'inventory',label:'ADD INVENTORY',color:'#f58426',items:plnRec,defaultValue: Inventory },mainState,[({inventory})=>{ 
            if(!stateRef.current.listedReady) return setState(p=>({...p, listedReady:true }))
            if(stateRef.current.listedReady) socketIO.emit('updateFulfillment',{data:{Inventory:inventory},projID:{DivisionID:DivisionID,fulfillmentID:fulfillmentID}},({sucess,info,err})=>{
              if(sucess) {globalWrn('success',`${labelFulfillment} was updated.`)
              handlePopupClose()}
              else globalWrn('error',`We had an issue creating ${outputRef.current.labelFulfillment}.`)
            })
          }])
        }))
      },[Inventory])

      /*useEffect(()=>{
        if( Object.keys(outputRef.current).length > 0 ) setPopup(p=>({...p,
        submitLabel: 'Update',
        handleSubmit: ()=> socketIO.emit('updateFulfillment',{data:outputRef.current,projID:{DivisionID:DivisionID,fulfillmentID:fulfillmentID}},({sucess,info,err})=>{
          if(sucess) {globalWrn('success',`${outputRef.current.labelFulfillment} was updated.`)
          handlePopupClose()}
          else globalWrn('error',`We had an issue creating ${outputRef.current.labelFulfillment}.`)
        })
        }))
        },[outputRef.current])*/

      /*const update=()=>{
        
       // console.log('fulfillmentID)',FulfillmentInventory.find(({_id})=>_id===fulfillmentID))
        var items = <Box sx={{p:'1%'}}><Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('InputField','elements',{state:'labelFulfillment',label:'Fulfillment Label', defaultValue: labelFulfillment || null, type:'text'},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('InputField','elements',{state:'POFulfillment',label:'Fulfillment PO:', defaultValue: POFulfillment || null, type:'text'},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('InputField','elements',{state:'descFulfillment',label:'Fulfillment Notes', defaultValue: descFulfillment || null, type:'text'},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('AutoComplete','elements',{state:'clientID',label:'Client',defaultValue: clientID || [],model:'Clients',query:{},filter:{local:1}, addEntry:addPartner},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          { dynamicComponent('AutoComplete','elements',{state:'partnerID',label:'Partner',defaultValue: partnerID || [],model:'Partners',query:{},filter:{local:1}, addEntry:addPartner},outputState) }
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
          <Box sx={{width:'100%'}}>{ dynamicComponent('UserButton','elements',{state:'projManager',label:'Assigned Project Manager',defaultValue: projManager,disabled:true, divisionName:teamName,bttnStyle:{width:'100%'}},outputState) }</Box>
        </Grid>
      </Grid>
      </Box>

        setPopup(p=>({...p,
          open:true,
          zIndex: 2,
          title:`Update Fullfillment Project`,
          description: 'Your Fulfillment will be amazing.',
          inputField: items,
          handleClose: handlePopupClose,
          nameInputSets: {},
      }))
      
      }

      return <Paper elevation={8} variant="outlined" sx={{ display: 'flex',flexDirection:'column',flex:1,height:'100%',p:'2%', border: (theme) => `1px solid ${theme.palette.divider}`, flexWrap: 'wrap',flexWrap:'nowrap' }} >
      <Box sx={{display:'flex',flexDirection:'column',flex:1,gap:'3%'}}>
        <Typography variant="body2">{labelFulfillment}</Typography>
        <Divider/>
        { stateRef.current.listed }
      </Box>
    {/*<Box sx={{display:'flex',gap:'1%',justifyContent:'space-between',position:'sticky',bottom:'1%'}}>
      { dynamicComponent('DefaultButton','elements',{state:'editFillment',label:`EDIT ${labelFulfillment}`,startIcon:<FontAwesomeIcon icon={faFileContract}/>},mainState,[update]) }
    </Box>}
    </Paper>
    }*/
