import React from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Box from '@mui/material/Box';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

const Timeline_Field=({That, viewData, action, formatPhoneNumber,handlePopupClose,globalState})=>{
const {defaultValue, state, alternate, outline, icons, labels, colors, oppositeColors, avatarContent, dotTypes, connectorOptions, secondaryTitle, title, description, titleOptions, descriptionOptions, missing } = viewData
const [old, setState, stateRef] = useState(Object)
const [popupData, setPopup] = useAtom(globalState.popupState)
const [output, setOutput, outputRef] = useState(defaultValue? defaultValue: '')
const [parentState, setParentState, parentStateRef] = That
const [values, setValues] = React.useState(Object);

const openImage=(x)=>{
  setPopup(p=>({...p,...{
    open:true,
    zIndex: 1,
    title:`Signature`,
    width:'75vw',
    inputField: <Box component="img" src={x}></Box>,
    handleClose: handlePopupClose,
  }}))
}

return [<Timeline position={alternate && alternate}>
        { labels && labels.length > 0 && labels.flatMap((v,i)=>{
       return <TimelineItem key={i+1}>
        {secondaryTitle?.[i] && 
            <TimelineOppositeContent>
              <Typography variant="caption">{secondaryTitle?.[i]}</Typography>
          </TimelineOppositeContent>
            }
         <TimelineSeparator>
           <TimelineDot variant={dotTypes && dotTypes?.length > 1 ? dotTypes?.[i] : dotTypes?.length === 1 ? dotTypes?.[0] : outline ? "outlined" : null} color={colors?.[i] ? colors?.[i] : "#000000"}>
              {(icons && icons?.[i]) && icons?.[i]}
            </TimelineDot>
           <TimelineConnector sx={connectorOptions && connectorOptions} />
         </TimelineSeparator>  
         <TimelineContent>

         <ListItem alignItems="flex-start" sx={{gap:'3%', flexDirection: i % 2 === 0 ? "row" : "row-reverse"}}>
        <ListItemAvatar>
          <Avatar alt="Travis Howard" style={{border: '0.1px solid lightgray', margin:'auto', objectFit:'fill'}} src={avatarContent?.[i]} onClick={()=>openImage(avatarContent?.[i])}/>
        </ListItemAvatar>
        <ListItemText 
        sx={i % 2 > 0 && {textAlign: "end"}}
          primary={<Typography variant="subtitle1" sx={titleOptions && titleOptions}>
          {v}
        </Typography>}
          secondary={<Typography variant="subtitle2" sx={descriptionOptions ? descriptionOptions : null}>
          {description?.[i]}
        </Typography>}
        />
      </ListItem>
        {/* v && 
         <TimelineContent>
            <Typography variant="subtitle1" sx={titleOptions && titleOptions}>
              {v}
            </Typography>
            {description?.[i] &&
              <Typography variant="subtitle2" sx={descriptionOptions ? descriptionOptions : null}>
                {description?.[i]}
              </Typography>
            }*/}
          </TimelineContent>
         
       </TimelineItem>
          })}
     </Timeline>,
     (!labels || labels.length === 0 ) && missing && <Typography variant="body1" sx={{textAlign:'center',color:'#fff'}}>
     {missing}
   </Typography>]
}

export default Timeline_Field;
