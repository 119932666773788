import React, {useEffect,useRef} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { detailedDiff } from 'deep-object-diff';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import SettingsIcon from '@mui/icons-material/Settings';

import {Accordion,AccordionDetails,AccordionSummary,List,ListItem,ListItemAvatar,ListItemText,Divider,Box,Typography,AppBar,Tabs,Tab} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SwipeableViews from 'react-swipeable-views';
import Checkbox from '@mui/material/Checkbox';

const MenuButton = ({viewData:{label,DefaultIcon},DFdrpDwnBttn,chkBxStateRef,open,handleClick})=>[
  <Divider key={'k1_2'} sx={{ height: 28, m: 0.5 }} orientation="vertical" />,
  DefaultIcon ? 
  <IconButton key={'k1_3'} sx={Object.entries(chkBxStateRef).some(([k,v])=>v===false) ? {color:'#ff9d15',p:'1%'}:{p:'1%'}}
  ref={DFdrpDwnBttn}
  id={"fade-button"}
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
  variant={"contained"}
  onClick={handleClick}
  >
  <DefaultIcon key={"k1_4"} sx={{p:'1%',fontSize:'30px'}} />
</IconButton>
: <Button
key={"k1_5"}
ref={DFdrpDwnBttn}
id={"fade-button"}
aria-controls={open ? 'fade-menu' : undefined}
aria-haspopup="true"
aria-expanded={open ? 'true' : undefined}
variant={"contained"}
onClick={handleClick}
aria-label={label}
endIcon={<KeyboardArrowDownIcon
sx={{ m:'auto', minWidth: '3ch',fontSize:'30px',p:'1%' }} />}
>
{ label }
</Button>]

export default function DivisonFilter(props){
const {viewData:{preItem,listItems,replace},dbUpdateValidate,globalState,divisionFilterChange} = props
const DFdrpDwnBttn = useRef(null)
const mainState = useState({	  
        preItem: preItem,
        listItems: listItems,
        replace: replace,
        category: false,
        DvDrpOpen: false,
        haveText: <FontAwesomeIcon icon={faTasks}/>,
        cBoxToggle: true
        })
const [, setState, {current:stateRef}] = mainState
const secondState = useState(Object)
const [, setChkBxState, {current: chkBxStateRef}] = secondState
const [UserMap,setUserMap] = useAtom(globalState.userMapState)
const [globalAppState] = useAtom(globalState.appState)
const [divisionMap] = useAtom(globalState.divisionMapState)
const [, setAnchorMenu2, {current: anchorMenuRef}] = useState(null);
const open = Boolean(anchorMenuRef);
const handleClick = (e) => setAnchorMenu2(DFdrpDwnBttn.current)

useEffect(() => {
if(UserMap.has('divisionFilterMap')) var userDefined = UserMap.get('divisionFilterMap')
Array.from([...divisionMap],([k,{category}])=>{
  var selector = userDefined?.[k] ? true : false
  setChkBxState(p=>({...p, [k]: selector }))
  if(!chkBxStateRef[category]) setChkBxState(p=>({...p, [category]:selector })) 
})
},[divisionMap,UserMap.has('divisionFilterMap')])

useEffect(()=>{ 
 if(Object.keys(chkBxStateRef).length>0){ 
 if(stateRef.PrevChkBxState){
  var { updated } = detailedDiff(stateRef.PrevChkBxState,chkBxStateRef)
  if(updated && Object.keys(updated).length>0){
    divisionFilterChange(Object.entries(chkBxStateRef).flatMap(([k,v])=>({[k]:v})))
    setUserMap(p=>{p.set('divisionFilterMap',chkBxStateRef);return new Map(p)})
    console.log('DBVALIDATE DIV FILTER', updated)
    dbUpdateValidate({model:'User',query:true,update:{[`local.divisionFilterMap`]:chkBxStateRef}},(x)=>{ console.log('Div Filt Upadted',x)})
    setState(p=>({...p,
      PrevChkBxState: chkBxStateRef
     }))
  }
 }else{ 
  setState(p=>({...p,
  PrevChkBxState: chkBxStateRef
 }))
 divisionFilterChange(Object.entries(chkBxStateRef).flatMap(([k,v])=>({[k]:v})))
}
/*  var stateDefined = Object.entries(chkBxStateRef).flatMap(([k,v])=>({[k]:v})) 
  if(divisionFilterMap.size>0) var divisnArray = Object.assign(...[].concat(...Array.from([...divisionFilterMap],([k,v])=>({[k]:v}))))
  if( (divisnArray && !Object.entries(divisnArray).some(([k,v])=> Object.entries(Object.assign(...stateDefined)).some(([k2,v2])=>k2===k&&v2===v))) || (!divisnArray && stateDefined ) ){
    divisionFilterChange(stateDefined)
    setUserMap(p=>{p.set('divisionFilterMap',chkBxStateRef);return new Map(p)})
   if(divisnArray) dbUpdateValidate({model:'User',query:true,update:{[`local.divisionFilterMap`]:chkBxStateRef}},(x)=>{ console.log('Upadted',x)})
   else console.log('Division not updated')
  }
  else{
  }
 // if( JSON.stringify(stateDefined)!==JSON.stringify(divisnArray)) stateChgMap(Object.entries(chkBxStateRef).flatMap(([k,v])=>({[k]:v})))
 */
}
},[chkBxStateRef])

// const stateChgMap=(val)=>{ divisionFilterChange(val) }

const handleClose = (x) => {
  setAnchorMenu2(null)
//  var stateDefined = Object.assign(...Object.entries(chkBxStateRef).flatMap(([k,v])=>({[k]:v}))) 
//  if(divisionFilterMap.size>0) var divisnArray = Object.assign(...[].concat(...Array.from([...divisionFilterMap],([k,v])=>({[k]:v}))))
//  if( JSON.stringify(stateDefined)!==JSON.stringify(divisnArray)) stateChgMap(Object.entries(chkBxStateRef).flatMap(([k,v])=>({[k]:v})))
  }

useEffect(() =>{
var accordArray = [...new Set(Array.from([...divisionMap],([k,v])=>v.category))]
 setState(p=>({...p,
  accordArray: accordArray,
  menu : <Menu
  id={"divisonFilter"}
  MenuListProps={{'aria-labelledby': 'fade-button'}}
  anchorEl={DFdrpDwnBttn.current}
  getContentAnchorEl={null}
  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
  transformOrigin={{vertical: 'top', horizontal: 'center'}}
  open={open}
  onClose={handleClose}
  TransitionComponent={Fade}
>
<Accod {...props} accordArray={accordArray} secondState={secondState} /> 
</Menu>
 }))
},[divisionMap])

return [<MenuButton key={"k1"} {...props} DFdrpDwnBttn={DFdrpDwnBttn} chkBxStateRef={chkBxStateRef} open={open} handleClick={handleClick}/>,
      <Menu
        id={"divisonFilter"}
        MenuListProps={{'aria-labelledby': 'fade-button'}}
        anchorEl={DFdrpDwnBttn.current}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        key={"k2_1"}
      >
      <Accod key={"k3_1"} {...props} accordArray={stateRef.accordArray} secondState={secondState} handleClose={handleClose} /> 
      </Menu>]
}

export function Accod(props){
  //const [, setState, stateRef] = useState(Object)
  const {accordArray, globalState, secondState} = props
  const [teamMember] = useAtom(globalState.teamMemberState)
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [projectMap] = useAtom(globalState.projectMapState)
  const accordion = useRef(null)
  const [expanded, setExpanded] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [, setChkBxState, {current: chkBxStateRef}] = secondState
 const handleChange = (panel) => (e, isExpanded) => setExpanded(isExpanded ? panel : false);
  const handleToggle = (value,child) => () => {
    child.forEach(x=> setChkBxState(p=>({...p,[x]:!chkBxStateRef[value]})) )
    setChkBxState(p=>({...p,[value]:!chkBxStateRef[value]}))
  }
const actionSel=(x)=>{
const {type, data} = x

const action = {
  Select: ()=> console.log('selected',data),
 // Edit: ()=> formSelect({formData:data.formData,name:data.name}),
  Duplicate: ()=> console.log('Duplicate',data),
  Preview: ()=> console.log('Preview',data),
  Send: ()=> console.log('Send',data),
  Delete: ()=> console.log('Delete',data),
}
action[type]()
}

var Divis = Array.from([...divisionMap],([k,v])=>({id:v.id,label:v.teamName,category:v.category}))
/*var Proj = Array.from([...projectMap],([k,v])=>({id:k,label: v.ProjectTitle, category:divisionMap.get(v.DivisionID)?.category}))
var Staff = Array.from([...teamMember],([k,v])=>({id:k,label: `${v.firstName} ${v.lastName}`, category:divisionMap.get(v.division)?.category}))*/

return <Box ref={accordion} style={{padding:'2%'}}> { accordArray &&
accordArray.flatMap((x,i)=>{
        var catagories = ['Location'/*,'Projects','Staff','Partners','Clients'*/]
        var child = Array.from([...divisionMap],([k,v])=>{ v.id=k; return v}).filter(z=>z.category===x).flatMap(z=>z.id)
        var items = {
          Location: Divis.filter(z=>z.category===x),
         /* Projects: Proj.filter(z=>z.category===x),
          Staff: Staff.filter(z=>z.category===x)*/
        }

// console.log('ProjMap', Array.from([...projectMap],([k,v])=>({id:k,label: v.ProjectTitle, category:divisionMap.get(v.DivisionID)?.category})).filter(z=>z.category===x) )

     return <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id={`panel${i}bh-header`}
        >
        <Checkbox
            edge="start"
            checked={chkBxStateRef[x]}
            tabIndex={-1}
            disableRipple
            onClick={handleToggle(x,child)}
            inputProps={{ 'aria-labelledby':  `checkbox-list-label-${x}` }}
          />
       
       {<Typography sx={{ width: '33%', flexShrink: 0, m:'auto 2%' }}> {x?.toUpperCase()}{ (child.some(y=>chkBxStateRef[y]===false)&&!child.every(y=>chkBxStateRef[y]===false)) && ` (${child.filter(y=>chkBxStateRef[y]===true).length}/${child.length})` } </Typography> }
        </AccordionSummary>
        <AccordionDetails sx={{padding:0}}>
         <FullWidthTabs {...props} catagories={catagories} items={items} action={(x)=>actionSel(x)} />
      <Divider />
        </AccordionDetails>
      </Accordion>
}) }</Box>
}


export function FullWidthTabs({catagories,items,secondState,dynamicComponent,globalState,That,handleClose}) {
  const {direction} = useTheme();
  const [,setValue,{current: valueRef}] = useState(0);
  const [, setChkBxState, {current: chkBxStateRef}] = secondState;
  const [, setGlobalAppState] = useAtom(globalState.appState);
  const [UserMap] = useAtom(globalState.userMapState);
  const handleChange = (event, newValue) => setValue(newValue);
  const handleChangeIndex = (i) => setValue(i);

  const handleToggle = (target,value) => {
    if(target.type==='checkbox') setChkBxState(p=>({...p,[value]:!chkBxStateRef[value]}))
  }

const TabPanel = ({ children, value, index, ...other }) =>
    <Box
      role="tabpanel"
      hidden={valueRef !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {valueRef === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>

/*TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}; */

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

return [<AppBar position="static">
        <Tabs
          value={valueRef}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
        { catagories.flatMap((x,i)=>
        <Tab key={"test"+i} label={x} {...a11yProps(i)} />) }
        </Tabs>
        </AppBar>,
      <SwipeableViews
        axis={direction === 'rtl' ? 'x-reverse' : 'x'}
        index={valueRef}
        onChangeIndex={handleChangeIndex}
      >
      { catagories.flatMap((x,i)=> 
          <TabPanel key={"tabpanel"+i} value={valueRef} index={i} dir={direction} >
        { items[x] ? items[x]?.flatMap((y,i)=>{
        const val = y.id
        const labelId = `checkbox-list-label-${val}`;
        return <List key={"List"+i} sx={{padding:0}}>
            <ListItem key={y.label} onClick={({target})=> handleToggle(target,val) } sx={{padding:'3% 0'}} >
             <Checkbox
                  edge="start"
                  checked={chkBxStateRef[val]}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
           
              <ListItemAvatar>
              { ['Location'].includes(x) && <PinDropIcon/> }
              { ['Projects'].includes(x) && <ArtTrackIcon/> }
              { ['Staff','Partners','Clients'].includes(x) ? dynamicComponent('AvatarBubble','elements',{state:'teams',label:'missing Lead', style:{width:'30px',height:'30px'},userID: y.id }) : null}
              </ListItemAvatar>
            
             <ListItemText primary={<Typography sx={{textAlign:'left',lineHeight:1.1}}>{y.label}</Typography> }/>

            { UserMap.get('level')<=5 && <ListItemAvatar sx={{display:'flex',justifyContent:'flex-end'}}>
              <SettingsIcon fontSize="small" color="secondary" onClick={()=>{ setGlobalAppState(p=>{ p.set('modulePop', dynamicComponent('editDivsn','component',{type:'admin',ref:module,DivisionID:val}, That)); return new Map(p)}); handleClose()}}/>
             </ListItemAvatar> }
          </ListItem>
          </List>
          }) : <Typography sx={{textAlign:'center'}}>Coming Soon!</Typography> }
        </TabPanel>
      ) }
      </SwipeableViews>]
}

