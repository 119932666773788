import React, { useEffect, useRef, useMemo } from 'react';
import useState from 'react-usestateref'; 
import { useAtom } from 'jotai';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
/*import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Workbook } from 'exceljs';

const TableData = ({doesIDExist,dynamicComponent,dbUpdateValidate,globalState,globalWrn,multiProject,isIterable,viewData:{projID},That,dynamicMapData}) => {
    const [state, setState, stateRef] = useState({expanded:true,grouping:"date",focused:false,defaultTB:true})
    const dataGridRef = useRef(null);
    const { ProjectID, DivisionID, modelType } = projID
    const [divisionMap] = useAtom(globalState.divisionMapState)
    const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
    const [projectLoggMap, setProjectLoggMap] = useAtom(globalState.projectLoggMapState)
    const [teamMember] = useAtom(globalState.teamMemberState)
    const [UserMap] = useAtom(globalState.userMapState)
    const ProjectData = projectMap.has(ProjectID) && projectMap.get(ProjectID)
    const projFollow = ProjectData?.ProjectState?.teamLogg?.flatMap(({_id})=>_id) || []
    const [appSocket] = useAtom(globalState.appSocketState)
    var socketIO = appSocket.get('socketIO')

    useEffect(()=>{
    if(projFollow.length>0) doesIDExist({modelType,model:'ProjectLogg',_id:ProjectID,projectLogg:projFollow})
  },[])
  
  useEffect(()=>{
   //doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:ProjectID,modelType:modelType},(item)=>{})
    projGatherData()
  },[projectLoggMap,ProjectData])
  
  useEffect(() =>{ 
  if(stateRef.current.minDate){
    var newDate = new Date(stateRef.current.minDate)
    var newRows = stateRef.current.rows.filter(x=> new Date(x.date).getTime() >= newDate.getTime())
  setState(p=>({...p,...{
    rows: newRows,
    deviceFocusOrders: stateRef.current.deviceFocusOrders.filter(x=> new Date(x.date).getTime() >= newDate.getTime()),
    totalCount: newRows.length+1
  }}))
    }else projGatherData()
  },[stateRef.current.minDate])
  
  useEffect(() =>{ 
  if(stateRef.current.maxDate){
    var newDate = new Date(stateRef.current.maxDate)
    var newRows = stateRef.current.rows.filter(x=> new Date(x.date).getTime() <= newDate.getTime())
  setState(p=>({...p,...{
    rows: newRows,
    deviceFocusOrders: stateRef.current.deviceFocusOrders.filter(x=> new Date(x.date).getTime() <= newDate.getTime()),
    totalCount: newRows.length+1
  }}))
    }else projGatherData()
  },[stateRef.current.maxDate])
  

useEffect(()=>{ // console.log('PreTest:', projectMap.get(ProjectID)?.ProjectState?.teamLogg?.some(({_id})=> !projectLoggMap.has(_id) ) )
   // if( projectMap.get(ProjectID)?.ProjectState?.teamLogg?.some(({_id})=> !projectLoggMap.has(_id) ) ){
     if(!modelType) var modelType = divisionMap.get(projectMap.get(ProjectID)?.DivisionID)?.teamName
     socketIO?.emit('queryDB',{model:'Projects',modelType:modelType,idConvert:true,query:{"_id":ProjectID},filter:{'ProjectState.teamLogg':1}},([x],y)=>{ 
       if(x?.ProjectState?.teamLogg) Promise.all(
        x.ProjectState.teamLogg?.flatMap((x)=>{
         if(x) var {_id,logg} = x
         if(logg) logg.ProjectID = ProjectID
         else var logg = {ProjectID:ProjectID}
         return dynamicMapData({[_id]:logg},projectLoggMap,setProjectLoggMap,(x)=>{ setProjectLoggMap(p=>new Map(p))}) 
       })
       ).then(()=>projGatherData())
       else globalWrn('error',`We could not find the correct Database items.`)
     })
   // }
    },[ projectMap.get(ProjectID)?.ProjectState?.teamLogg?.some(({_id})=> !projectLoggMap.has(_id) ) ])
     

  const projGatherData = () => {
  //try{
  if(multiProject) var grouppedProj = multiProject
  else grouppedProj = projFollow

 // console.log('grouppedProj:',projectLoggMap)

  
  var orders = grouppedProj.filter(x=>projectLoggMap.has(x)).flatMap((x,i)=>
     projectLoggMap.get(x)?.flatMap((y,i2)=>
      y.user.flatMap((z,i3)=>{
      var userID = teamMember.get(z)
      if(isIterable(y.userFilter)) var userFilt = Object.assign(...y.userFilter)[z]
      var userFilter = userFilt?.options?.filter(x=>x!=='SerialNumber')?.flatMap(x=>({[x]:y.data[x]}))
      if(userFilter && userFilter.length>0) userFilter = Object.assign(...userFilter)
      return { 
        id: i+'-'+i2+'-'+i3,
        _id: y._id,
        userID:z,
        user:`${userID?.firstName} ${userID?.lastName}`,
        numberSet: y.numberSet ? y.numberSet:'Missing',
        time:dayjs(userFilt?.timestamp).format('h:mm a'),
        date:dayjs(userFilt?.timestamp).format('MMMM DD YYYY'),
        week:dayjs(userFilt?.timestamp).week(),
        month:dayjs(userFilt?.timestamp).format('MMMM YYYY'),
        mulitUserProject: y.user.length>1 ? true : false,
        serialNumber:y.data?.SerialNumber? y.data.SerialNumber : 'Missing',
        ...userFilter}
    })
    )
  )

 // console.log( 'orders',orders)

  var deviceFocusOrders = grouppedProj.map((x,i)=>
   projectLoggMap.get(x)?.flatMap((y,i2)=>{
      var numberSet = y.numberSet ? y.numberSet:'Missing'
      var serialNum = y.data?.SerialNumber? y.data.SerialNumber : 'Missing'
      var preUserFilter = y.userFilter.length>0 ? Object.entries(Object.assign(...y.userFilter)).flatMap(([k,v])=> v?.options?.filter(x=>x!=='SerialNumber')?.flatMap(x=>({[x]:y.data[x]}))).filter(x=>x!==undefined) : []
      var userArray = y.userFilter.length>0 ? Object.entries(Object.assign(...y.userFilter)).flatMap(([k,v])=>k) :[]
      var userFilt = y.userFilter.length>0 ? Object.assign(...y.userFilter)[userArray.slice(-1)[0]]:[]
      var userID = teamMember.get(userArray.slice(-1)[0])
      var userFilter = preUserFilter.length>0 ? Object.assign(...preUserFilter) : preUserFilter
      return { 
        id: i+'-'+i2,
        _id: y._id,
        userID: userArray.slice(-1)[0],
        serialNumber: y.data?.SerialNumber? y.data.SerialNumber : 'Missing',
        user: userArray.length>1?'Multiple Users':`${userID?.firstName} ${userID?.lastName}`,
        numberSet: y.numberSet ? y.numberSet:'Missing',
        time:dayjs(userFilt.timestamp).format('h:mm a'),
        date:dayjs(userFilt.timestamp).format('MMMM DD YYYY'),
        week:dayjs(userFilt.timestamp).week(),
        month:dayjs(userFilt.timestamp).format('MMMM YYYY'),
        mulitUserProject: userArray.length>1 ? true : false,
        ...userFilter}
    })
  )
  
  // console.log('orders:', orders)
  
  var removedColumns = ['id','week','month','userID','mulitUserProject']
  if(orders) var columns = [...new Set(orders.filter(x=> x !== undefined).flatMap(x=>Object.keys(x)))].filter(x=>!removedColumns.includes(x)).flatMap(x=>{
     if(x==='user') var title = 'Employee'
     else if(x==='numberSet') title = 'Device #'
     else if(x==='serialNumber') title = 'Serial #'
     else title = x?.charAt(0).toUpperCase() + x?.slice(1).replace(/([A-Z])/g, ' $1').trim()
     return {name:x,title:title}
     })
  
  
//  if(orders) var columns = [...new Set(orders.filter(x=> x !== undefined).flatMap(x=>
//   Object.entries(x).flatMap(([k,v])=>Object.keys(v)))
//   )].filter(x=>!['id','week','month','userID','mulitUserProject'].includes(x)).flatMap(x=>{
//    if(x==='user') var title = 'Employee'
//    else if(x==='numberSet') title = 'Device #'
//    else if(x==='serialNumber') title = 'Serial #'
//    else title = x?.charAt(0).toUpperCase() + x?.slice(1).replace(/([A-Z])/g, ' $1').trim()
//    return {name:x,title:title}
//    })
  
  setState(p=>({...p,...{
    rows: orders,
    columns: columns,
    deviceFocusOrders: [].concat(...deviceFocusOrders),
    totalCount: [].concat(...orders).length
  }})) 
  //}catch(e){ setState(p=>({...p,...{error:true}})) }
  }
  
  const groupingValues = [{
        value: 'all',
        text: 'No Grouping',
      },{
        value: 'date',
        text: 'Grouping by Day',
      },{
        value: 'week',
        text: 'Grouping by Week',
      },{
        value: 'month',
        text: 'Grouping by Month',
      },{
        value: 'user',
        text: 'Grouping by Employee',
      }];
  
   const onExporting=(e)=> {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Main sheet');
      
       
          e.cancel = true;
      }
  
   const collapseAllClick =()=> setState(p=>({...p,...{
        expanded: !stateRef.current.expanded,
   }}))
  
  const getGroupCount=(groupField)=> {
      return 
    }
  const groupChanged=(e)=> {
      const grouping = e.value;
      dataGridRef.current.instance.clearGrouping();
      dataGridRef.current.instance.columnOption(grouping, 'groupIndex', 0);
      setState(p=>({...p,...{
        totalCount: getGroupCount(grouping),
        grouping: grouping,
      }}))
    }
  
  const searchChange =(e)=> console.log('searchChange',e)
  
  const textFormating =(x)=> {
    //console.log('textFormating',x)
  if(x.value===undefined) return <div className="dx-fieldset-label" style={x.data.mulitUserProject?{color:'rgb(255, 157, 21)'}:null}> -- </div>
  return <div className="dx-fieldset-label" style={x.data.mulitUserProject?{color:'rgb(255, 157, 21)'}:null}>{x.value}</div>  //.replace(/[A-Z]/g, ' $&').trim()
  }
  
  const onSelectionChanged=(e)=> {
      e.component.refresh(true);
    }
  
  const onSaving=({changes})=>{ 
    changes.forEach(({data,type,key},i)=>{
       var keySet = Array.from([...projectLoggMap],([k,v])=>{ if(v.some(({_id})=>_id===key)) return k }).filter(x=>x!==undefined)[0]
       var orig =  projectLoggMap.get(keySet).find(({_id})=>_id===key).data
       var arrayFilters =  [{"el._id": keySet }]
  
       if(type==='remove'){
        var output = {"$pull":{'ProjectState.teamLogg.$[el].logg':{'_id': key}}}
         return dbUpdateValidate({model:'Projects',modelType: modelType || divisionMap.get(DivisionID)?.teamName,idConvert:true,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
          console.log('PULL:',{success,info,err})
          if(success){
             globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`);
             doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:ProjectID,modelType:divisionMap.get(DivisionID)?.teamName})
          }
          if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
          })
       }
       
    //arrayFilters.push({"el2._id": {$in: [key]}})  
   // var output = Object.entries(data).map(([k,v])=>({ "$set": {[`ProjectState.teamLogg.$.logg.$.data.${k}`]:v} } ))
   arrayFilters = [...arrayFilters,{"el2._id": key }]
   var output = {'ProjectState.teamLogg.$[el].logg.$[el2].data':{...orig,...data},  "$push": {'ProjectState.teamLogg.$[el].logg.$[el2].userFilter': {[UserMap.get('avatar')]:{timestamp: dayjs().toISOString(),options:Object.keys(data)} }}
  }
  console.log('UPdateing', {arrayFilters})
   dbUpdateValidate({model:'Projects',modelType: modelType || divisionMap.get(DivisionID)?.teamName,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
    console.log('Returned',success,info,err )
    if(success) globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
    if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
    })
    })
    return
  }

  const userRender=(x)=>{
    if(x.data.user==="Multiple Users") return <div className="dx-fieldset-label" style={{margin:'auto 1%'}}>{x.data.user}</div>
    return [<div style={{display:'flex'}}>
    { dynamicComponent('AvatarBubble','elements',{state:'projLead',label:'missing Lead',userID:x.data.userID},That) }
    <div className="dx-fieldset-label" style={{margin:'auto 1%'}}>{x.data.user}</div></div>]
  }
  
  return !stateRef.current.error ? {/*<DataGrid
        ref={dataGridRef}
        id="gridContainer"
        dataSource={stateRef.current.focused ? stateRef.current.rows : stateRef.current.deviceFocusOrders }
        keyExpr={"_id"}
        onSelectionChanged={onSelectionChanged}
        onExporting={onExporting}
        allowColumnResizing={true}
        allowColumnReordering={true}
        showBorders={true}
        onSaving={onSaving}
        >
        <Export enabled={true} allowExportSelectedData={true} />
        <Selection mode="sinlge" />
        <GroupPanel visible={true} />
        <SearchPanel visible={true} onValueChanged={searchChange} />
        <Grouping autoExpandAll={stateRef.current.expanded } />
        <ColumnChooser enabled={true} />
        <Editing
              mode={"form"}
              useIcons={true}
              allowUpdating={true}
              confirmDelete={true} 
              allowAdding={true}
              allowDeleting={true} >
                <Form>
                <Item itemType="group" colCount={3} colSpan={2}>
                {stateRef.current.columns?.filter(({name})=>name!=='_id').flatMap(({name})=> <Item dataKey={"Frank"} dataField={name}/> )}
                </Item>
              </Form>
            </Editing>
        <Paging defaultPageSize={15} />
        {stateRef.current.columns ? stateRef.current.columns.flatMap((x)=> 
        <Column key={x.title} dataField={x.name} caption={x.title} width={x.title==="Device #"?70:x.title==="Date"?125:x.title==="Serial #"?225:x.title==="Time"?80:x.title==="Employee"?125:225} cellRender={x=> x.title==="Employee"?userRender(x):textFormating(x)} visible={x.title==="_id" ? false : true} />
        ):null}
        <Column
            dataField={stateRef.current.grouping}
            groupIndex={0}
        />
        <Summary>
        <GroupItem
          column="serialNumber"
          summaryType="count"
          displayFormat="{0} units"
          showInGroupFooter={false}
          alignByColumn={true}/>
        </Summary>
        <SortByGroupSummaryInfo summaryItem="count" />
        {stateRef.current.defaultTB ? <Toolbar>
            <Item location="before">
              <div className="informer">
                <h2 className="count" style={{margin:'unset',lineHeight:1}}>{stateRef.current.totalCount}</h2>
                <span className="name"> Scanned Units</span>
              </div>
            </Item>
             <Item location="before">
             {stateRef.current.grouping!=='all'? <Button
                text={!stateRef.current.focused ? 'Device Focus' : 'Employee Focus'}
                width='145'
                onClick={()=> setState(p=>({...p,...{focused:!stateRef.current.focused}})) }/>:null}
            </Item>
            <Item location="before">
             {stateRef.current.grouping!=='all'? <Button
                text={stateRef.current.expanded ? 'Collapse All' : 'Expand All'}
                width='136'
                onClick={collapseAllClick}/>:null}
            </Item>
            <Item location="before">
              <Button
                text={'Filters'}
                width='85'
                onClick={()=>setState(p=>({...p,...{defaultTB:!stateRef.current.defaultTB}}))}/>
            </Item>
            <Item name="exportButton"/>
            <Item name="searchPanel"/>
          </Toolbar> : 
          <Toolbar>
            {} <Item location="before">
             <div className="informer">
                <span className="name">Extented Filters</span>
              
        : <Typography gutterBottom variant="body1" sx={{margin:'auto'}}>...Well this is Awkward, your Database is not available at this time.</Typography>
  };

  export default TableData;*/




import dayjs from 'dayjs';
  import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
//Material UI Imports
import { Box, Button, ListItemIcon, MenuItem, Typography,Tooltip, IconButton } from '@mui/material';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//Icons Imports
import { AccountCircle, Description, Send } from '@mui/icons-material';
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const TableData = ({doesIDExist,dynamicComponent,waveLoad,dbUpdateValidate,globalState,globalWrn,multiProject,isIterable,viewData:{projID},That,dynamicMapData}) => {
  const [state, setState, stateRef] = useState({expanded:true,grouping:"date",focused:false,defaultTB:true})
  const dataGridRef = useRef(null);
  const mainState = useState(Object);
  const { ProjectID, DivisionID, modelType } = projID
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
  const [projectLoggMap, setProjectLoggMap] = useAtom(globalState.projectLoggMapState)
  const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState);
  const [UserMap] = useAtom(globalState.userMapState)
  const [popupData2, setPopup2] = useAtom(globalState.popupState2);
  const ProjectData = projectMap.has(ProjectID) && projectMap.get(ProjectID)
  const projFollow = ProjectData?.ProjectState?.teamLogg?.flatMap(({_id})=>_id) || []
  // State for managing filename
  // ProjectData && projectStats.has(DivisionID) ? projectStats.get(DivisionID)?.assign?.[ProjectID] : null


    useEffect(()=>{// console.log('Starter',projFollow.length, ProjectData )
    if(projFollow.length>0) doesIDExist({modelType,model:'ProjectLogg',_id:ProjectID,projectLogg:projFollow},(x)=>{console.log('Responded x',x)})
  },[])

  function updateField(key, dataID, update) {
    var arrIndex = projectLoggMap.get(key)
    if (arrIndex) {
      var updatedArr = arrIndex.map(obj => {
        if(obj._id === dataID) {
          return {data:update, _id: obj._id};
        }
        else return obj;
      })
      dynamicMapData({[key]: updatedArr}, projectLoggMap, setProjectLoggMap)
      //setProjectLoggMap(p => p.set(key, updatedArr), new Map(p))
    }
  }

  const onSaving=(table, changes, type, row)=>{ 
    var data = changes
    var id = row?.original?._id;
    var keySet = Array.from([...projectLoggMap],([k,v])=>{ if(v.some(({_id})=>_id===id)) return k }).filter(x=>x!==undefined)[0]
    var orig =  projectLoggMap.get(keySet)?.find(({_id})=>_id===id).data
    var arrayFilters
    if(keySet) arrayFilters =  [{"el._id": keySet }]

    if(type && type==='remove'){
    var output = {"$pull":{'ProjectState.teamLogg.$[el].logg':{'_id': id}}}
      return dbUpdateValidate({model:'Projects',modelType: modelType || divisionMap.get(DivisionID)?.teamName,idConvert:true,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
      if(success){
          setPopup2({open: false})
          globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`);
          doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:ProjectID,modelType:divisionMap.get(DivisionID)?.teamName})
      }
      if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
      })
    }
    else {
       
    //arrayFilters.push({"el2._id": {$in: [id]}})  
   // var output = Object.entries(data).map(([k,v])=>({ "$set": {[`ProjectState.teamLogg.$.logg.$.data.${k}`]:v} } ))
   //id = row?.original

   if(id && arrayFilters?.length > 0) arrayFilters = [...arrayFilters,{"el2._id": id }]
   else if (id) arrayFilters = [{ "el2._id": id }]

   //delete data._id
   //console.log(ProjectState.teamLogg.$[el].logg.$[el2].data)
  // Ensure _id is correct
  //data._id = row?.original?._id;
  delete data._id
  delete data.date
  delete data.time
  delete data.numberSet


   var output = {'ProjectState.teamLogg.$[el].logg.$[el2].data':{...orig,...data},  "$push": {'ProjectState.teamLogg.$[el].logg.$[el2].userFilter': {[UserMap.get('avatar')]:{timestamp: dayjs().toISOString(),options:Object.keys(data)} }}
  }
  
   dbUpdateValidate({model:'Projects',modelType: modelType || divisionMap.get(DivisionID)?.teamName,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
    if(success) {
      globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
      //updateField(keySet, id, changes)
      table.setEditingRow(null); //exit editing mode
    }
      if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
    })
    }
    return
  }

    //DELETE action
  const openDeleteConfirmModal = (table, row) => {
    setPopup2(p=>({...p,
      open: true,
      title: "Delete Entry",
      description: "Are you sure you want to delete this row? This cannot be undone",
      handleSubmit: ()=>onSaving(table,null,"remove",row),
      handleClose: ()=>{setPopup2({open: false})}
    }))
    }  

  const projGatherData = () => {
    //try{
   // console.log("pre:", multiProject, projFollow)
    if(multiProject) var grouppedProj = multiProject
    else grouppedProj = projFollow

    if(grouppedProj.filter(x=>projectLoggMap.has(x))) var orders = grouppedProj.filter(x=>projectLoggMap.has(x))?.flatMap((x,i)=>
       projectLoggMap.get(x)?.flatMap((y,i2)=>
        y.user && y.user.flatMap((z,i3)=>{
          
        var userID = teamMember.get(z)
        if(isIterable(y.userFilter)) var userFilt = Object.assign(...y.userFilter)[z]
        var userFilter = userFilt?.options?.filter(x=>x!=='SerialNumber')?.flatMap(x=>({[x]:y.data[x]}))
        if(userFilter && userFilter?.length>0) userFilter = Object.assign(...userFilter)
        return { 
          id: i+'-'+i2+'-'+i3,
          _id: y._id,
          userID:z,
          user:`${userID?.firstName} ${userID?.lastName}`,
          numberSet: y.numberSet ? y.numberSet:'Missing',
          time:dayjs(userFilt?.timestamp).format('h:mm a'),
          date:dayjs(userFilt?.timestamp).format('MMMM DD YYYY'),
          week:dayjs(userFilt?.timestamp)?.week(),
          month:dayjs(userFilt?.timestamp).format('MMMM YYYY'),
          mulitUserProject: y.user?.length>1 ? true : false,
          SerialNumber:y.data?.SerialNumber? y.data.SerialNumber : 'Missing',
          ...userFilter}
      })
      )
    )

    //console.log("Orders is:", orders)
    //console.log("projectLoggmap:", projectLoggMap)
  
    var deviceFocusOrders = grouppedProj.map((x,i)=>
     projectLoggMap.get(x)?.flatMap((y,i2)=>{
        var numberSet = y.numberSet ? y.numberSet:'Missing'
        var serialNum = y.data?.SerialNumber? y.data.SerialNumber : 'Missing'
        var preUserFilter = y.userFilter?.length>0 ? Object.entries(Object.assign(...y.userFilter)).flatMap(([k,v])=> v?.options?.filter(x=>x!=='SerialNumber')?.flatMap(x=>({[x]:y.data[x]}))).filter(x=>x!==undefined) : []
        var userArray = y.userFilter?.length>0 ? Object.entries(Object.assign(...y.userFilter)).flatMap(([k,v])=>k) :[]
        var userFilt = y.userFilter?.length>0 ? Object.assign(...y.userFilter)[userArray.slice(-1)[0]]:[]
        var userID = teamMember.get(userArray.slice(-1)[0])
        var userFilter = preUserFilter?.length>0 ? Object.assign(...preUserFilter) : preUserFilter
        return { 
          id: i+'-'+i2,
          _id: y._id,
          userID: userArray.slice(-1)[0],
          SerialNumber: y.data?.SerialNumber? y.data.SerialNumber : 'Missing',
          user: userArray?.length>1?'Multiple Users':`${userID?.firstName} ${userID?.lastName}`,
          numberSet: y.numberSet ? y.numberSet:'Missing',
          time:dayjs(userFilt.timestamp).format('h:mm a'),
          date:dayjs(userFilt.timestamp).format('MMMM DD YYYY'),
          week:dayjs(userFilt.timestamp).week(),
          month:dayjs(userFilt.timestamp).format('MMMM YYYY'),
          mulitUserProject: userArray?.length>1 ? true : false,
          ...userFilter}
      })
    )

   // console.log("deviceFocusOrders", deviceFocusOrders)

    var tempNum = 0;
    
    var removedColumns = ['id','week','month','userID','mulitUserProject']
    if(orders) var columns = [...new Set(orders.filter(x=> x !== undefined).flatMap(x=>Object.keys(x)))].filter(x=>!removedColumns.includes(x)).flatMap(x=>{
      if(x==='user') var title = 'Employee'
       else if(x==='numberSet') title = 'Device #'
       else if(x==='SerialNumber') title = 'Serial #'
       else title = x?.charAt(0).toUpperCase() + x?.slice(1).replace(/([A-Z])/g, ' $1').trim()
       tempNum = tempNum + 1;
       return {name:x,title:title, id:tempNum}
       })
       console.log("rows and columns:", orders, columns)
    setState(p=>({...p,...{
      rows: orders,
      columns: columns,
      deviceFocusOrders: [].concat(...deviceFocusOrders),
      totalCount: [].concat(...orders)?.length
    }})) 
    //}catch(e){ setState(p=>({...p,...{error:true}})) }
    }

  useEffect(()=>{
    projGatherData()
  },[projectLoggMap])

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `${ProjectData?.ProjectTitle}_${ProjectData?.PoNumber}_${dayjs().format('llll')}`
  });

    const handleExportRows = (rows) => {
      const rowData = rows.map((row) => row.original);
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    };
  
    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(stateRef.current?.rows);
      download(csvConfig)(csv);
    };

    const handleExportRowsPDF = (rows) => {
      const doc = new jsPDF();
      const tableData = rows.map((row) => Object.values(row.original));
      const tableHeaders = columnsVal.map((c) => c.header);
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
  
      doc.save(`${ProjectData?.ProjectTitle}_Scans.pdf`);
    };

    useEffect(()=>{
      if(ProjectData?.ProjectState?.teamMembers?.length > 0) ProjectData?.ProjectState?.teamMembers.forEach(x=>doesIDExist({map:teamMember,set:setTeamMember,model:'Team',_id:x?._id,modelType:divisionMap.get(DivisionID)?.teamName}))
      if(ProjectData?.ProjectState?.projManager) doesIDExist({map:teamMember,set:setTeamMember,model:'Team',_id:ProjectData?.ProjectState?.projManager,modelType:divisionMap.get(DivisionID)?.teamName})
      if(ProjectData?.ProjectState?.teamLead) doesIDExist({map:teamMember,set:setTeamMember,model:'Team',_id:ProjectData?.ProjectState?.teamLead,modelType:divisionMap.get(DivisionID)?.teamName})

      },[ProjectData?.ProjectState?.teamMembers, ProjectData?.ProjectState?.projManager, ProjectData?.ProjectState?.teamLead])

  const columnsVal = useMemo(()=>
   // if(!stateRef?.current?.columns) return []; // Safety
      stateRef?.current?.columns?.flatMap(x=>{
        if (x?.name === "date") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
          accessorFn: (x) => new Date(x?.date),
          filterVariant: 'date-range',
          Cell: ({ cell }) => `${cell?.row?.original?.date}`, // convert back to string for display
        }
        else if (x?.name === "time") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
        }
        else if (x?.name === "numberSet") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
        }
        else if (x?.name === "_id") return {
          id: x?.name,
          header: 'Employee',
          enableEditing: false,
          accessorFn: (x) => x?.user,
          filterVariant: "autocomplete",
          filterSelectOptions: (multiProject ? multiProject : projFollow)?.filter(x=>projectLoggMap.has(x))?.flatMap((x,i)=>
          projectLoggMap.get(x)?.flatMap((y,i2)=>
           y.user && y.user
              ?.filter(x=>x!==undefined)
              ?.flatMap((x)=>{
                return `${teamMember.get(x)?.firstName} ${teamMember.get(x)?.lastName}`
              })
            ))?.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []),
          accessorKey: x?.name,
          Cell: ({ renderedCellValue, row }) => (
            <Box sx={{ display: 'flex', alignItems: 'center',gap:'5%' }} >
            <Box>{dynamicComponent('AvatarBubble','elements',{state:'teams',label:'Employee', isProject: true, style:{width:'30px',height:'30px',margin:'unset'},userID: row.original.userID },mainState)}</Box>
            <Typography variant='caption' >
              {row.original.user}
            </Typography>
          </Box>
          ),
        }
        else return {
          accessorKey: x?.name,
          header: x?.title,
        }
  }).filter(x=>x?.accessorKey !== 'user'), [stateRef.current.columns],);

  return ( <Box sx={{overflow: 'auto', width: '100%'}}>{ columnsVal && stateRef.current?.rows &&
    <MaterialReactTable
      columns={columnsVal}
      data={stateRef.current?.rows}
      enableColumnFilterModes
      enableColumnOrdering
      enableEditing
      enableGrouping
      enablePinning
      //editDisplayMode='row'
      onEditingRowSave={ ({ table, values, row }) => {
        onSaving(table, values, null, row);
      }}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Edit">
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => openDeleteConfirmModal(table,row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      initialState={{ showColumnFilters: true,density: 'compact' }}
      positionToolbarAlertBanner="bottom"
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          { dynamicComponent('DropDown','elements',{ state: 'Export', defaultValue: 'Select An Export Option', label: 'Export PDF/CSV', list: ['Export All Data (CSV)', 'Export All Rows (CSV)', 'Export Page Rows (CSV)', 'Export Selected Rows (CSV)', 'Export All Rows (PDF)', 'Export Page Rows (PDF)', 'Export Selected Rows (PDF)'], replace:true },mainState,[({Export})=>{
            Object({ 
              'Export All Data (CSV)': handleExportData,
              'Export All Rows (CSV)': ()=>handleExportRows(table.getPrePaginationRowModel().rows),
              'Export Page Rows (CSV)': ()=> handleExportRows(table.getRowModel().rows),
              'Export Selected Rows (CSV)': ()=> handleExportRows(table.getSelectedRowModel().rows),
              'Export All Rows (PDF)': ()=> handleExportRowsPDF(table.getPrePaginationRowModel().rows),
              'Export Page Rows (PDF)': ()=> handleExportRowsPDF(table.getRowModel().rows),
              'Export Selected Rows (PDF)': ()=> handleExportRowsPDF(table.getSelectedRowModel().rows),
              })[Export]()
      }])}
         
      </Box>
      )}
      sx={{width: '100%', overflow: 'auto', overflowX: 'auto', position: 'relative'}}
    />
  }
  </Box> || waveLoad()
  );
};

export default TableData;
