import React,{useEffect} from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DialogTitle, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText,AppBar,Toolbar ,Typography,IconButton,Divider,Paper,ListItem,ListItemText, CircularProgress} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import useState from 'react-usestateref';
import Draggable from 'react-draggable';
import Translate from '../Utils/Translate';
//import InputField from './InputField';
import useStyles from './Styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in={props.open} ref={ref} {...props} />;
});

const darkTheme = createTheme({palette: {mode:'dark'}});

function PaperComponent(props) {
  return <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
}

export default function PopupDialog({viewData, globalState,globalWrn, dynamicComponent,That}) {
  const { open, title, icon, description, enableSubmitButton, handleClose, handleSubmit, inputField, nameInputSets, submitLabel, cancelLabel, pramPass, width,height,minWidth, zIndex, maxWidth, fullWidth, button03, helper, helperObject, headerButton, disableEnter } = viewData
  const classes = useStyles();

  const nameValue = nameInputSets && typeof nameInputSets.value !== undefined ? nameInputSets.value : '';
  const [renameText, setRenameText] = useState(nameValue);
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const enterPress = globalState.useKeyPress('Enter')
  const [clicked, setClicked] = useState(false)

  const styles = {
    paperD : { width: width ? width : "auto", 
              minWidth: minWidth ? minWidth : "auto",
              maxWidth: maxWidth ? maxWidth : 'unset',
              height: height ? height: "auto"
            }
  }
  
  const handleNameChange = value => {
    setRenameText(value);
    nameInputSets.callBack(value);
  }

  const submitButton=(pramPass)=>{
    setClicked(true)
    handleSubmit(pramPass)
    setState(p=>({...p,
      clickedInterval: true
    }))
  }

  useEffect(()=>{
    setTimeout(()=>{
      if(stateRef.current.clickedInterval && stateRef.current.clickedInterval === true && open === true) setClicked(false)
    },10000)
  },[stateRef.current.clickedInterval])

  useEffect(()=>{
    if(!disableEnter && enterPress && handleSubmit) submitButton(pramPass)
  },[enterPress])

  useEffect(()=>{
    if(enableSubmitButton === true) // stop circular progress animation and set disabled to false
    {
      setClicked(false)
    }
  },[enableSubmitButton])

 return <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        scroll={'paper'}
       classes={{ paper : classes.dialogPaper}}
       fullWidth={fullWidth? fullWidth : false}
       sx={zIndex ? {zIndex: zIndex} : null}
       PaperProps={{ sx: styles.paperD }}
       PaperComponent={PaperComponent}
      >

      {(icon||title||description||headerButton) && <AppBar sx={{ position: 'relative',backgroundColor:'rgba(0,0,0,.35)'}}>
          <Toolbar sx={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',pl:'5px!important',columnGap:'12px'}} >
            { icon && <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            { icon }
            </IconButton> }
            <Box sx={{display:'flex',gap:'3px'}} id="draggable-dialog-title" >
            <DragIndicatorIcon sx={{m:'auto'}} />
            <ListItemText 
               sx={{overflow:'hidden',lineHeight:1}}
               primary={<Typography sx={{ flex: 1, lineHeight:1 }} variant="h6"> {title} </Typography>}
               secondary={ <Typography sx={{ flex: 1, lineHeight:1 }} variant="body2"> {description} </Typography>}
              />
            </Box>
            {headerButton && <Box sx={{display:'flex',flex:1,justifyContent:'flex-end'}}>{headerButton}</Box> }
            </Toolbar>
        </AppBar>}

        <DialogContent sx={{p:'unset'}} >
          {inputField}
          {nameInputSets?.value && 
                <Box className="form-group">
                    { dynamicComponent('InputField','elements',{state:'input',label:<Translate>{nameInputSets.label}</Translate>, defaultValue:renameText, style:{margin:'3% 2%',width:'96%'},type:'text',multiline:true},That,[({input})=>handleNameChange(input)]) }
                </Box> 
          }
        </DialogContent>
        <Divider />
        {(helper||handleSubmit||button03||handleClose||helperObject) && <DialogActions className="dialogButtons" sx={{zIndex:99999,alignItems:'stretch',flexWrap:'wrap',gap:'3px'}}>
        { helperObject && <ListItem sx={{gap:'3%',p:0,alignItems:'stretch',justifyContent:'end',width:'unset'}}>{helperObject}</ListItem> }
        {helper && <ListItem sx={{gap:'3%',p:0,alignItems:'stretch',justifyContent:'end',width:'unset'}}>
                    {helper.action && <Button onClick={()=>helper.action(pramPass)} variant="contained" sx={{lineHeight:1}}><Translate>{helper.label || 'Next'}</Translate></Button> }
                    {helper.primary && <ListItemText primary={helper.primary} sx={{overflow:'hidden',lineHeight:1}} color={helper.color} secondary={helper.secondary}/>}
                    </ListItem> }
          {handleSubmit && <Button disabled={clicked} onClick={()=>submitButton(pramPass)} variant="contained" color="primary" sx={{lineHeight:1}}><Translate>{submitLabel?submitLabel:'Submit'}</Translate>{clicked && clicked === true && <CircularProgress/>}</Button>}
          <Button onClick={handleClose} variant="contained" color="secondary" sx={{lineHeight:1}}><Translate>{cancelLabel?cancelLabel:'Cancel'}</Translate></Button>
           {button03 && Object.keys(button03).length > 0 && <Button onClick={()=>button03?.action(pramPass)} variant="contained" color="warning" sx={{lineHeight:1,}}><Translate>{button03.label?button03.label:'Next'}</Translate></Button>}
        </DialogActions> }
      </Dialog>
}
