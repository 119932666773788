import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks,faCommentDots, faBell, faVideo, faEdit, faClipboardList, faTrash, faPlus, faMagnifyingGlass, faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { CssVarsProvider } from '@mui/joy/styles';
import UserProfile from "../contacts/UserProfile";
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
import {  styled } from '@mui/material/styles';
import { Box, Badge, Avatar, AvatarGroup} from '@mui/material';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})); 

export default function AvatarBubble(props){
  var {globalWrn,That,viewData,action,globalState,dynamicComponent,dynamicMapData,globalAdd,memberQuery,doesIDExist} = props;
  var {label,replace,list2,Catagory,projID,defaultValue,state,userID,subUserID,style,disable,notActive,border,maxAvatar,AvatarLink,isInventory,isProject} = viewData
  const mainState = useState(Object)
  const [, setState, {current:stateRef}] = mainState
  const [productMap] = useAtom(globalState.productMapState)
  const [inventoryMap] = useAtom(globalState.inventoryMapState)
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [projectMap] = useAtom(globalState.projectMapState)
  const [UserMap] = useAtom(globalState.userMapState)
  const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
  const [activeSession] = useAtom(globalState.activeSessionState)
  const [appState, setAppState] = useAtom(globalState.appState)
  const [, setPopup] = useAtom(globalState.popupState)
  const [, setPopup2] = useAtom(globalState.popupState2)
  const [, setOpen,{current:open}] = useState(false)

  const [appSocket] = useAtom(globalState.appSocketState)
  var socketIO = appSocket.get('socketIO')

  useEffect(()=>{
   if(userID && isInventory !== true) doesIDExist({model:Catagory||'User',_id:userID})
  },[userID])


/*if(UserMap.get('avatar')===userID) var list = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}]
else if(!Catagory&&UserMap.get('avatar')!==userID) list = [{label:'Get Schedule',icon:faClipboardList},{label:'Chat With',icon:faCommentDots},{label:'Video Call',icon:faVideo},{label:'Active Projects',icon:faBarsProgress}]
else if(Catagory==='Division') list = [{label:"Get Divisions Schedule",icon:faClipboardList},{label:'Chat With Division Admin',icon:faCommentDots},{label:'Conferance With Division',icon:faCommentDots},{label:'Active Projects',icon:faBarsProgress}]
else if(Catagory==='Project'){ 
  list = [{label:'Get Project Stats',icon:faClipboardList},{label:'Get Project Schedule',icon:faClipboardList},{label:'Chat With Project Manager',icon:faCommentDots},{label:'Project Progress',icon:faBarsProgress}]
  if(UserMap.get('level')<=5) list.unshift({label:'Edit Project',icon:faClipboardList},{label:'Conferance With Team',icon:faCommentDots},{label:'Chat With Project Lead',icon:faCommentDots})
}else if(Catagory==='Team'&&UserMap.get('avatar')!==userID) list = [{label:'Get Schedule',icon:faClipboardList},{label:'Chat With',icon:faCommentDots},{label:'Video Call',icon:faVideo},{label:'Active Projects',icon:faBarsProgress}]
*/

/* useEffect(()=>{
  var list = []
  if(UserMap.get('avatar')===userID) list = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}]
  if(Catagory) Object({
    Division: ()=>{   
      list = [{label:"Get Divisions Schedule",icon:faClipboardList},{label:'Conferance With Division',icon:faCommentDots},{label:'Active Projects',icon:faBarsProgress}]
      if(divisionMap.has(userID)) var {divsionManager,logManager} = divisionMap.get(userID)
      if(divsionManager&&activeSession.has(divsionManager)) list.push({label:'Chat With Division Admin',icon:faCommentDots})
      if(logManager&&activeSession.has(logManager)) list.push({label:'Chat With Logistics Admin',icon:faCommentDots})
    },
    Project: ()=>{ 
      list = [{label:'Get Project Stats',icon:faClipboardList},{label:'Get Project Schedule',icon:faClipboardList},{label:'Project Progress',icon:faBarsProgress}]
      if(UserMap.get('level')<=5) list.unshift({label:'Edit Project',icon:faClipboardList},{label:'Conferance With Team',icon:faCommentDots})
      var {ProjectState} = projectMap.get(userID)
      if(ProjectState?.projManager&&activeSession.has(ProjectState.projManager)) list.push({label:'Chat With Project Manager',icon:faCommentDots})
      if(ProjectState?.teamLead&&activeSession.has(ProjectState.teamLead)) list.push({label:'Chat With Project Lead',icon:faCommentDots})
    },
    Clients: ()=> list = [{label:'Client Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Partners: ()=> list = [{label:'Partner Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Sites: ()=> list = [{label:'Site Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Team: ()=>  list = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Inventory: ()=>{ list = [{label:'General Details',icon:faClipboardList},{label:'Inventory Details',icon:faBarsProgress}] },
    Product: ()=> list = [{label:'General Details',icon:faClipboardList},{label:'Product Details',icon:faBarsProgress}],
  })[Catagory]()
  if(UserMap.get('avatar')!==userID&&activeSession.has(userID)) list.push({label:'Chat With',icon:faCommentDots},{label:'Video Call',icon:faVideo})
  
  console.log('Catagory:',Catagory, list)

  if(list) setState(p=>({...p,
    list:list
  }))
},[Catagory,userID]) */

const avatrBuild = <Box sx={{margin:'auto', width: 47, height: 47, ...style }}><LazyLoadImage
alt={'IMAGE'}
height={'100%'}
width={`100%`}
effect={"blur"}
threshold={10000}
style={{borderRadius:'50%',objectFit:'cover'}}
onError={(e)=>e.target.src = `https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
src={AvatarLink||`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${userID}_avatar.jpg`}
title={teamMember.has(userID) ? `${teamMember.get(userID).firstName} ${teamMember.get(userID).lastName}`:'No Name'}
/></Box>

const actionHandler=(x,y,z)=>
 Object({
    'Edit Project': ()=>setAppState(p =>{ p.set('modulePop',dynamicComponent('editProj','component',{projID:z},mainState)); return new Map(p)}),
    'Get Schedule': ()=>setAppState(p =>{ p.set('modulePop',dynamicComponent('Agenda','elements',{state:'agendaUser',label:'missing',userID:[y]},mainState)); return new Map(p)}),
    'Get Project Stats': ()=>setAppState(p =>{ p.set('modulePop',dynamicComponent('PartnerPanel','elements',{state:'AnaliticData',projID:z},mainState)); return new Map(p)}),  
    'Video Call': ()=> dynamicMapData({chatter:y},appState,setAppState,(x)=>{ setAppState(p=>new Map(p)) }),
    'Chat With': ()=> ChatMessge(y),
    'General Details': ()=>{
    if(Catagory === 'Inventory'){
      var InventoryID = userID;
      var { ProductItems } = inventoryMap.get(userID);
      var ProductID = ProductItems?.[0]?.id
      var productExists = productMap.has(ProductID)
    }
    if(Catagory === 'Product'){
      var ProductID = userID;
      var productExists = productMap.has(userID)
    }
    setState(p=>({...p,
      productCard : dynamicComponent('ProductCard', 'elements', {ProductID : ProductID, InventoryID:InventoryID, productExists:productExists}, mainState)
    })) 
    },
    'Product Details': ()=>{editProduct(userID)},
    'Inventory Details': ()=>{editInventoryItem(userID)},
    'Get Divisions Schedule': ()=> console.log("Coming Soon!"),
    'Active Projects': ()=> console.log("Coming Soon!"),
  })[x]()


const editProduct=(ProductID)=>{
  const labels = Object.values(Catagory).map(({label}) => label);
  setPopup2(p=>({...p,
    title: `Edit Product`,
    zIndex: 3,
    open:true,
    inputField:<Box>{dynamicComponent('CreateProduct','elements',{state:'inventoryScripts', defaultValue:productMap.get(ProductID), categoryLabel:labels })}</Box>, //send default value (inventory item)
    // handleSubmit: submitChanges,
    button03: undefined,
    helper: undefined,
    handleClose: ()=> setPopup2({open:false}),
  }))
}

const editInventoryItem=(InventoryID)=>{
  setPopup(p=>({...p,
    title: `Edit Inventory Item`,
    zIndex: 3,
    open:true,
    inputField:<Box>{dynamicComponent('AddInventory','elements',{state:'inventoryScripts', defaultValue:inventoryMap.get(InventoryID) })}</Box>, //send default value (inventory item)
    // handleSubmit: submitChanges,
    button03: undefined,
    helper: undefined,
    handleClose: ()=> setPopup({open:false}),
  }))
}

const ChatMessge = (userID) =>{
  var roomID = socketIO.id+'_'+Date.parse(new Date())
  socketIO.emit("messageChat",{ 
    roomID: roomID,
    request: userID,
    requestor: UserMap.get('avatar'),
    reqSocketID:socketIO.id
    },({response,desc,userID})=>{
        if(response) globalWrn(response,`${desc} ${userID && teamMember.has(userID) ? `${teamMember.get(userID).firstName } ${teamMember.get(userID).lastName }`: ''}.`)
    })
  //  globalAdd('modulePop', dynamicComponent('ChatMessages','socketIO',{parentState:That,roomID:roomID},mainState)) 
}


useEffect(() =>{ 
  var menuList = []
  if(UserMap.get('avatar')===userID) menuList = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}]
  if(Catagory) Object({
    Division: ()=>{   
      menuList = [{label:"Get Divisions Schedule",icon:faClipboardList},{label:'Conferance With Division',icon:faCommentDots},{label:'Active Projects',icon:faBarsProgress}]
      if(divisionMap.has(userID)) var {divsionManager,logManager} = divisionMap.get(userID)
      if(divsionManager&&activeSession.has(divsionManager)) menuList.push({label:'Chat With Division Admin',icon:faCommentDots})
      if(logManager&&activeSession.has(logManager)) menuList.push({label:'Chat With Logistics Admin',icon:faCommentDots})
    },
    Project: ()=>{ 
      menuList = [{label:'Get Project Stats',icon:faClipboardList},{label:'Get Project Schedule',icon:faClipboardList},{label:'Project Progress',icon:faBarsProgress}]
      if(UserMap.get('level')<=5) menuList.unshift({label:'Edit Project',icon:faClipboardList},{label:'Conferance With Team',icon:faCommentDots})
      var {ProjectState} = projectMap.get(userID)
      if(ProjectState?.projManager&&activeSession.has(ProjectState.projManager)) menuList.push({label:'Chat With Project Manager',icon:faCommentDots})
      if(ProjectState?.teamLead&&activeSession.has(ProjectState.teamLead)) menuList.push({label:'Chat With Project Lead',icon:faCommentDots})
    },
    Clients: ()=> menuList = [{label:'Client Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Partners: ()=> menuList = [{label:'Partner Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Sites: ()=> menuList = [{label:'Site Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Team: ()=>  menuList = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Inventory: ()=>{ menuList = [{label:'General Details',icon:faClipboardList},{label:'Inventory Details',icon:faBarsProgress}] },
    Product: ()=> menuList = [{label:'General Details',icon:faClipboardList},{label:'Product Details',icon:faBarsProgress}],
  })[Catagory||'Team']()
  if(UserMap.get('avatar')!==userID&&activeSession.has(userID)) menuList.push({label:'Chat With',icon:faCommentDots},{label:'Video Call',icon:faVideo})

  setState(p=>({...p, 
    PopMenu : dynamicComponent('PopMenu','elements',{state:'subMenu',label:'Select A Menu..',list:menuList,userID:userID,disable,bttnIcon: <Badge
        sx={{border:border? border:'1px solid #fff',borderRadius:'50%',aspectRatio:'1/1','& hover':{background:'red'},...style}}
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
         subUserID && 
         <SmallAvatar key={subUserID+'subUser'}>
            <LazyLoadImage
              alt={'...loading'}
              loading='lazy'
              effect="blur"
              threshold={10000}
              height={'100%'}
              width={'100%'}
              style={{objectFit:'contain'}}
              key={'MainLogo'}
              onError={(e)=>e.target.src = `https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
              src={subUserID?`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${subUserID}_avatar.jpg`:`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
            /> 
            </SmallAvatar>
        }
        >
        { activeSession.has(userID) && !notActive ?
        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" >
        {avatrBuild}
        </StyledBadge>
        : avatrBuild }
      </Badge>
     },mainState,[(x)=>{ 
      if(action) action.forEach(y=> y(x))
      else actionHandler(x,userID,projID)
    }])
}))
},[userID,subUserID])

useEffect(() =>{ 

  var menuList = []
  if(UserMap.get('avatar')===userID) menuList = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}]
  if(Catagory) Object({
    Division: ()=>{   
      menuList = [{label:"Get Divisions Schedule",icon:faClipboardList},{label:'Conferance With Division',icon:faCommentDots},{label:'Active Projects',icon:faBarsProgress}]
      if(divisionMap.has(userID)) var {divsionManager,logManager} = divisionMap.get(userID)
      if(divsionManager&&activeSession.has(divsionManager)) menuList.push({label:'Chat With Division Admin',icon:faCommentDots})
      if(logManager&&activeSession.has(logManager)) menuList.push({label:'Chat With Logistics Admin',icon:faCommentDots})
    },
    Project: ()=>{ 
      menuList = [{label:'Get Project Stats',icon:faClipboardList},{label:'Get Project Schedule',icon:faClipboardList},{label:'Project Progress',icon:faBarsProgress}]
      if(UserMap.get('level')<=5) menuList.unshift({label:'Edit Project',icon:faClipboardList},{label:'Conferance With Team',icon:faCommentDots})
      var {ProjectState} = projectMap.get(userID)
      if(ProjectState?.projManager&&activeSession.has(ProjectState.projManager)) menuList.push({label:'Chat With Project Manager',icon:faCommentDots})
      if(ProjectState?.teamLead&&activeSession.has(ProjectState.teamLead)) menuList.push({label:'Chat With Project Lead',icon:faCommentDots})
    },
    Clients: ()=> menuList = [{label:'Client Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Partners: ()=> menuList = [{label:'Partner Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Sites: ()=> menuList = [{label:'Site Details',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Team: ()=>  menuList = [{label:'Get Schedule',icon:faClipboardList},{label:'Active Projects',icon:faBarsProgress}],
    Inventory: ()=>{ menuList = [{label:'General Details',icon:faClipboardList},{label:'Inventory Details',icon:faBarsProgress}] },
    Product: ()=> menuList = [{label:'General Details',icon:faClipboardList},{label:'Product Details',icon:faBarsProgress}],
  })[Catagory||'Team']()
  if(UserMap.get('avatar')!==userID&&activeSession.has(userID)) menuList.push({label:'Chat With',icon:faCommentDots},{label:'Video Call',icon:faVideo})
  
if(userID && userID?.constructor !== String && menuList) setState(p=>({...p,
  avatGrp: <AvatarGroup 
    total={userID.length}
    max={maxAvatar||userID.length}
    FabProps={{
    sx: {
      width: 27,
      height: 27,
      flexWrap:'wrap',
      bgcolor: 'secondary.main',
      '&:hover': {
      bgcolor: 'secondary.main',
      }
    }
  }} >
   { userID.flatMap((x,i)=> dynamicComponent('PopMenu','elements',{state:'subMenu',label:'Select A Menu',list:menuList,menuList:menuList,userID:x,bttnIcon:
          activeSession.has(x) && !notActive ? <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" key={'f'+x+i}>
            <Avatar sx={style? style:{ width: 27, height: 27 }} key={x+i}>
            <LazyLoadImage
              alt={'...loading'}
              loading='lazy'
              effect="blur"
              threshold={10000}
              height={'100%'}
              width={'100%'}
              style={{objectFit:'contain'}}
              key={'MainLogo'}
              onError={(e)=>e.target.src = `https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
              src={x?`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${x}_avatar.jpg`:`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
            /> 
            </Avatar>
          {/*<Avatar 
            sx={style? style:{ width: 27, height: 27 }}
            key={x+i}
            alt={ teamMember.has(x) ? `${teamMember.get(x).firstName} ${teamMember.get(x).lastName}`:'No Name' }
            title={ teamMember.has(x) ? `${teamMember.get(x).firstName} ${teamMember.get(x).lastName}`:'No Name' }
            src={`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${x}_avatar.jpg`}
            onError={({target,onerror})=>{ onerror = null; target.src =`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}}  
            />*/}
          </StyledBadge> :
          <Avatar sx={style? style:{ width: 27, height: 27 }} key={x+i}>
            <LazyLoadImage
              alt={'...loading'}
              loading='lazy'
              effect="blur"
              threshold={10000}
              height={'100%'}
              width={'100%'}
              style={{objectFit:'contain'}}
              key={'MainLogo'}
              onError={(e)=>e.target.src = `https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
              src={x?`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${x}_avatar.jpg`:`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
            /> 
            </Avatar>
          /*<Avatar sx={style? style:{ width: 27, height: 27 }} key={x}
          alt={ teamMember.has(x) ? `${teamMember.get(x).firstName} ${teamMember.get(x).lastName}`:'No Name' }
          title={ teamMember.has(x) ? `${teamMember.get(x).firstName} ${teamMember.get(x).lastName}`:'No Name' }
          src={`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${x}_avatar.jpg`}
          onError={({target,onerror})=>{ onerror = null; target.src =`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}}  
          /> */
      },mainState,[(event)=>{
        if(action) action.forEach(y=> y(event))
        else actionHandler(event,x)
      }]) 
     )}
  </AvatarGroup>
}))
},[Catagory,userID])

return [userID && userID?.constructor === String
  ? stateRef.PopMenu  
  : stateRef.avatGrp,
  stateRef.productCard,
  /* <CssVarsProvider>
  <Box className={'userProfile'} style={{minWidth: stateRef.userSel && 320}}> <UserProfile {...props} mainState={mainState} setOpen={setOpen}/> </Box>
  </CssVarsProvider> */
  ]
}