import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import dayjs from "dayjs";
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isToday from 'dayjs/plugin/isToday';

import SignatureCanvas from 'react-signature-canvas'

import {Box,Typography,Grid} from "@mui/material";
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore)


const DeliveryForm=({dynamicComponent,dbUpdateValidate,useIsMobile,globalState,viewData,globalWrn,waveLoad,CheckID,newNotification})=> {
   const mainState = useState(Object)
   const outputState = useState(Object)
   const {data} = viewData
   const [, setState, stateRef] = mainState
   const [, setOutput, outputRef] = outputState
   const [, setEODInfo, EODInfoRef] = useState(Array)
   const [, setDeliveries, DeliveriesRef] = useState(Array)
   const [, setCompletedProj, completedProjRef] = useState(Array)
   const stagedRef = new Set()
   const [UserMap] = useAtom(globalState.userMapState)
   const [appState,setAppState] = useAtom(globalState.appState)
   const [projectMap] = useAtom(globalState.projectMapState)
   const [inventoryMap] = useAtom(globalState.inventoryMapState)
   const [divisionMap, ] = useAtom(globalState.divisionMapState)
   const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState)
   const [productMap,setProductMap] = useAtom(globalState.productMapState)
   const tableState = useState([])
   const [, setTableState, tableStateRef] = tableState
   const mapRef = useRef(null)
   const valueTable = useState(0)
   const [, setValue, valueRef] = valueTable
   const fieldState = useState(Object)
   const [, setField, fieldRef] = fieldState
   const [reSync, setReSync] = useState(false)
   var sig = useRef(null)
   var sig2 = useRef(null)

   const [appSocket] = useAtom(globalState.appSocketState)
   var socketIO = appSocket.get('socketIO')

   const [expanded, setExpanded] = useState(false);

   const useAndMobile = useIsMobile(560)
   const timestamp = dayjs()

   //console.log(Array.from([...teamMember],([k,v])=>{return v}).filter(x=>x.department === "Company")) //

   const handleChange = (panel) => (event, isExpanded) => {
     setExpanded(isExpanded ? panel : false);
   };
   
   var [, setPopup] = useAtom(globalState.popupState)

   useEffect(()=>{
    console.log("Data is:", data)
   },[data])

   useEffect(()=>{
    const siteID = projectMap.get(data?.[0]?.ProjectID)?.SalesState?.SiteID?.[0]
    var siteName
    if(teamMember.has(siteID)) siteName = teamMember.get(siteID)?.address?.label
    else CheckID({_id: siteID, key: "Sites", map: teamMember, setMap: setTeamMember},(res)=>{
      siteName = res?.address?.label
    })

    setState(p=>({...p,
      siteName: siteName
    }))
   },[])

   useEffect(()=>{
    setState(p=>({...p,...{
        timestamp: timestamp,
        notes: <Box ref={sig2}>{dynamicComponent('InputField','elements',{state:'notesField',label:'Notes',type:'text',style:{margin:0}},outputState)}</Box>,
        nameField: dynamicComponent('InputField','elements',{state:'signedNameField',label: `Have the receiver write their full name:` ,type:'text'},outputState),
        sigTyp: <Box><Typography>I acknowledge that the item was delivered to {stateRef.current?.siteName} on {dayjs().format('lll')}</Typography></Box>,
        sigCanvas: <Box sx={{borderStyle: 'solid', borderColor: 'white', borderWidth: '2px'}}>{sig2.current?.offsetWidth && <SignatureCanvas penColor='white' ref={sig}
        canvasProps={{height: 200, width: `${sig2.current?.offsetWidth}px` , className: 'sigCanvas'}} onEnd={()=>{
        setState(p=>({...p,...{sign:true}}))
        setTimeout(() => {
        setPopup(p=>({...p,...{
          helper: {
            label: `Clear Signature`,
            action: ()=>{
              sig.current.clear()
              setState(p=>({...p,...{sign:undefined}}))
              setPopup(p=>({...p,...{
                helper: undefined,
                handleSubmit: undefined
              }}))
            }
          }
          }}))
        }, "100");
        }} />}</Box>,
    }}))

   },[data, sig2.current?.offsetWidth, outputRef.current, stateRef.current?.siteName])

   useEffect(()=>{
    data?.forEach(x=>{
      if(x?.InventoryID.filter(y=>y === x?.InventoryID).length > 0) x?.TransferRequests.forEach(vals=>{if(vals?.InventoryID === x?.InventoryID) setState(p=>({...p, ["QTYDelivered"+[x._id]]: Math.abs(vals?.TransferAmount)}))})
        })
    //<Box sx={{marginTop: '1%'}}>{dynamicComponent('LabelValue','elements',{state:['amount_'+x?._id],Min:0,Max: max,label:`Amount for: ${x?.ProductItems?.[0]?.Product?.DISPLAY_NAME}`,type:'number',labelEditable:false, defaultValue:outputRef?.current?.amount},outputState,[({amount})=>{if(amount?.value) setOutput(p=>({...p,['amount_'+x._id]:amount?.value}))}])}</Box>,
  },[data])

   useEffect(()=>{
      setState(p=>({...p,...{
        uploadFile: dynamicComponent('UploadFile','elements',{state:'addFiles1',ProjectID:data?.[0]?.ProjectID, timestamp:stateRef.current.timestamp, required: submitReady, func: submitItems, itemsToSubmit:{data:data, sig:sig, sign:  stateRef.current.sign, notes: outputRef.current.notesField}},mainState),
      }}))
   },[outputRef.current.signedNameField,sig, stateRef.current.sign, outputRef.current.notesField])

   const submitItems=({data,sig, notest})=>{

    /*//items to be delivered
    const rightList = stateRef.current.rightList.flatMap(x=>{return x?._id})
    
    //complete data of items to be delivered
    const TransferableItems = data?.filter((x,i)=>{
      if(rightList.includes(x?.InventoryID?.[i])) return x
    })*/

    //for each item to be delivered, submit to the corresponding projects and inventory items
    data.forEach(x => {
      var numEmailer = 0
      x.InventoryID.forEach(id=>{
      const submitObj = {
        timestamp: stateRef.current.timestamp,
        notes: outputRef.current.notesField,
        name: outputRef.current.signedNameField,
        InventoryID: id
      }

      var projIDtemp = { DivisionID: x?.DivisionID,modelType: divisionMap.has(x?.DivisionID) ? divisionMap.get(x?.DivisionID).teamName : divisionMap.get(UserMap.get('division')).teamName }
      if(x?.ProjectID) projIDtemp.ProjectID = x?.ProjectID

      //project submit
      socketIO.emit('projectBuilder',{projID:projIDtemp,submitObj:{$set:{}, $push:{Deliveries:submitObj}}},(resp)=>{ 
        var {success,proj,error} = resp    
        if(success===true){ 
            //inventory submit for each delivery
            var itemsArr = [];

            return new Promise((resolve, reject) => { x.TransferRequests.forEach(req => {
              // Determine if active delivery
              if(!req?.deliveryDate && dayjs()?.isBefore(req?.deliveryDate)) {/*Do Nothing*/}
              else{
             var arrayFilters = [{"el.timestamp": req.timestamp}]
             // Array for email to project manager
             itemsArr.push({name:productMap.get(req?.ProductID)?.DISPLAY_NAME, value: Math.abs(req?.TransferAmount)})

             var output = {'TransferRequests.$[el].Completed':true, 'TransferRequests.$[el].Notes': (req?.Notes ? (req?.Notes + " + ") : "") + outputRef?.current?.notesField, 'TransferRequests.$[el].ReceiverName': outputRef?.current?.signedNameField}
             dbUpdateValidate({model:'Inventory',queryID:true,query:id,update:output,arrayFilters:arrayFilters},(x)=>{ 
              if(x?.success){ 
                }else {console.log("DELIVERY NOT SAVED!!!", x?.err); reject(x?.err);}
              })
            }
            })
            resolve(itemsArr);
          }).then((itemsArr)=>{

              setAppState(p=>{ 
              p.delete('modulePop')
              return new Map(p)
              })

              // Delivery Email Functions -----------------------------------------------------------
              if(numEmailer === 0) {
                numEmailer = 1
                const prjManID = projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager
                var prjManEmail
                var prjManName
        
                if(teamMember.has(prjManID)) {
                  prjManEmail = teamMember.get(prjManID)?.email
                  prjManName = `${teamMember.get(prjManID)?.firstName} ${teamMember.get(prjManID)?.lastName}`
                }
                else CheckID({_id: prjManID, key: "User", map: teamMember, setMap: setTeamMember},(res)=>{
                  prjManEmail = res?.email
                  prjManName = `${res?.firstName} ${res?.lastName}`
                })
        
                var bodyField = itemsArr.flatMap(y=>{return `<li>${y.name}: <b>${y.value}</b></li>`}).join('')
                const body = `${UserMap.get('firstName')} ${UserMap.get('lastName')} finished a delivery. Go to your project to review it now.\n\nNotes Provided: ${outputRef?.current?.notesField || 'No notes added.'}\n\nItem(s) Delivered:\n<ul>${bodyField}</ul>`
                var name_u = prjManName
                newNotification({Title:`A delivery to ${projectMap.get(data?.[0]?.ProjectID)?.ProjectTitle} was delivered`,Body:body,Data:null,avatar:prjManID,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},Email:prjManEmail,EmailForm:"Warning",name:name_u,sender:UserMap.get('avatar'),receiver:prjManID})
              }
              // End Of Delivery Email Functions ----------------------------------------------------
            })

            }else {
              globalWrn('error',`There was an error updating project ${projectMap.get(x?.ProjectID)?.ProjectTitle}.`)
            }
          })
      })
    })
      }

   

   function submitReady({data, sig, sign}){
    console.log(data, sig, sign)
      if(data && sig && sign)
        return true
      else 
        return false
   }

   return <Box sx={{padding: '2%'}}><Grid>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.nameField}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.notes}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.sigTyp}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.sigCanvas}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.uploadFile}
    </Box>
    </Grid></Box>
}
export default DeliveryForm;
