import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import useState from 'react-usestateref';



 const DefaultButton=({That,viewData,action})=>{
  const {label,state,pramPass ,startIcon,size, full, color,colorStyle, style,variant,disabled} = viewData
  const [parentState, setParentState, parentStateRef] = That
  const [, setState, stateRef] = useState(Object)

  const stateChgMap=(val)=>{ 
  setParentState(p=>({...p,...{[state]:val}}))
  if(action) action.forEach((x)=>{if(x) x({[state]:val,pramPass:pramPass}); else console.log("x is not defined!", x)}) 
  }

  const styling=()=>{
    let obj = {lineHeight:1.1}
    if(full){ obj.width='100%'; obj.height='100%'; obj.flex=1}
    if(color){ obj.bgcolor = color; obj.justifyContent= "space-evenly"}
    if(style) obj = Object.assign({},obj,style)
    return obj
  }

return <LoadingButton
            loading={ parentStateRef.current.loading!==undefined ? parentStateRef.current.loading : false}
            loadingPosition="start"
            startIcon={ startIcon ? startIcon : null }
            color={colorStyle||'inherit'}
            disabled={disabled?disabled:false}
            size={size?size:'medium'}
            variant={variant?variant:"contained"}
            onClick={()=>stateChgMap(true)}
            aria-label={label}
            sx={styling()}
          >
          { label ? label : "Submit" }
          </LoadingButton>
  }

  export default DefaultButton

