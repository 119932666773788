import React, { useState,useMemo } from "react";
import { ListItem, List} from "@mui/material";
//import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faQuestion, faCheck, faChevronRight, faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import useClasses from "./styleConvert"

const styles = theme => ({
    root: {
        padding: '10px 0px',
        '& .folderItem':{
            display:'block !important',
            width: '100%',
            margin:'0px !important',
            padding:'0px',
            fontSize: "13px",

            '& .folderTitle':{
                position: 'relative',
                '& .iconArrow':{
                    position: 'absolute',
                    left:'0px',
                    top: '0px',
                    fontSize: '10px',
                    lineHeight: '17px',
                    padding: '6px 5px'
                },
                '& .titleWrap':{
                    overflow: 'hidden',
                    width: '100%',
                    display: 'block',
                    padding: '3% 5%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                },
                '& .title':{
                    padding: '0px 0px 0px 4%'
                },
            },
            '& .MuiButtonBase-root': {
                padding: '0px 0px 0px 4%',
                borderRadius: '3px'
            },
            '& .folderSubmenu': {
                display: 'none',
                width: '95%',
                padding:'0px 0px 0px 5% !important',
                margin:'0px !important'
            },
            '&.active > .MuiButtonBase-root': {
                background: '#0492f2',
                color: '#fff'
            },
            '&.open > .folderSubmenu': {
                display: 'block',
            },
            '&.open > .MuiButtonBase-root > svg': {
                transform: 'rotate(90deg)'
            }
        }
    }
})

export default function FolderList(props){
  
   const classes = useClasses(styles)
    const { foldersList, onFolderClick, selectedFolder } = props;
    return (  
            <div className={classes.root} key={`folderRoot`} >
                {foldersList.name && (
                    <MenuItem item={foldersList} onFolderClick={onFolderClick} currentUrl={selectedFolder} />
                )}
            </div>
    )
   
}


function MenuSubmenu(props){
    const { item, currentUrl, onFolderClick } = props;

    return (
        <List className='folderSubmenu'>
        {item.children.map((child, index) => (
          <React.Fragment key={index}>
            {child.name && (
              <MenuItem item={child} onFolderClick={onFolderClick} parentItem={item} currentUrl={currentUrl} />
            )}
          </React.Fragment>
        ))}
      </List>
    );

}


function MenuItem(props) {
    const asideLeftLIRef = React.createRef();
    const { item, currentUrl, onFolderClick} = props;
    const [expand, setExpand] = useState(false);

    const mouseClick = () => {
        onFolderClick(item.path);
    }
    const handleExpand = () => {
        setExpand(!expand);
    }

    const isMenuItemIsActive = item => {
        if (item.children && item.children.length > 0) {
        isMenuRootItemIsActive(item);
        }
        return currentUrl.indexOf(item.path) !== -1;
    };

    const isMenuRootItemIsActive = item => {
        for (const subItem of item.children) {
        if (isMenuItemIsActive(subItem)) {
            return true;
        }
        }
        return false;
    };

    const isActive = isMenuItemIsActive(item);

    return (
      <ListItem
        ref={asideLeftLIRef}
        className={clsx(
          'folderItem',
          {
            "open": isActive && item.children || expand,
            "active": item.path === currentUrl
          }
        )}
      >
        <ListItem button className="folderTitle" >
            {item.children.length > 0 && <FontAwesomeIcon icon={faChevronRight} onClick={handleExpand} />} {/*<i className="icon-next iconArrow" onClick={handleExpand} />*/}
            <span className="titleWrap"  onClick={mouseClick}>
                {isActive && item.children ? <FontAwesomeIcon icon={faFolderOpen}/> : <FontAwesomeIcon icon={faFolder}/> } {/*<i className='icon-folder' /> : <i className='icon-folder-1' />*/}
                <span className="title">{item.reName?item.reName:item.name}</span>
            </span>
        </ListItem>

        {item.children.length > 0 && <MenuSubmenu item={item} onFolderClick={onFolderClick} parentItem={item} currentUrl={currentUrl}  /> }
        
      </ListItem>
    );
}