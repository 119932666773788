import React from "react";
import useState from 'react-usestateref';
import { useTheme } from '@mui/material/styles';
import { Paper, Box, MobileStepper, Typography, Button, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import FileViewer from "react-file-viewer";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper({viewData,That}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [old, setFilePrep, filePrepRef] = That

  const {images,projID} = viewData
  const maxSteps = images ? images?.length : 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const downloadFile =()=>{
    console.log('Tester', filePrepRef.current[images[activeStep].name])
   
    if(filePrepRef.current[images[activeStep].name]) var url = window.URL.createObjectURL( new Blob([filePrepRef.current[images[activeStep].name]]) )
    else url = `https://www.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/projects/${projID.ProjectID}/${images[activeStep].name}`

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${images[activeStep].name}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  return <Box sx={{maxWidth:400,flexGrow:1,mr:'1%'}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          pl: 2,
          bgcolor: 'background.default',
          justifyContent: 'flex-start',
          gap: '5%'
        }}
      >{ images ? [ <IconButton onClick={downloadFile}> <DownloadIcon sx={{display: 'flex-end', maxHeight: 'auto'}}/></IconButton>,
        <Typography>{images[activeStep].name}</Typography>] : <Typography>Insert Files</Typography> }
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={images ? handleStepChange : null }
        enableMouseEvents
      >
      {images ? images.map(({name,type}, i) => {

      //const url = window.URL.createObjectURL( new Blob([filePrepRef.current[name]]) );
      if(filePrepRef.current[name]) var url = window.URL.createObjectURL( new Blob([filePrepRef.current[name]]) )
      else var url = `https://www.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/projects/${projID.ProjectID}/${name}`
      
      return <Box key={name}>
            {Math.abs(activeStep - i) <= 2 ? (
           <FileViewer fileType={type.split('/').pop()} filePath={url} onError={(e)=>console.log(e, "error in file-viewer") } style={{width:'100%',height:'100%',objectFit:'cover'}}/>
            ) : null}
          </Box>
      }) :null}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1 || maxSteps ===0}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
 { images ?     
 [<Divider />,
 <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="files">
      {images.map(({name,type}, i) => 
        <ListItemButton
          selected={images[activeStep].name === name}
          onClick={(event) =>console.log('click')}
          onMouseEnter={()=> setActiveStep(i) }
        >
          <ListItemIcon>
           { filePrepRef.current[name] ? <UploadFileIcon /> : <InsertDriveFileIcon/> }
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      )}
      </List>
    </Box>] :null }
    </Box>
}

export default SwipeableTextMobileStepper;