import React from 'react';
import {Tooltip, Button, ButtonGroup} from '@mui/material';
//import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClasses from "../styleConvert"

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: '6px'//theme.spacing(1),
    },
  },
  button: {
      fontSize:'28px',
      minWidth: '50px',
      minHeight: '47px',
      background: '#32a7e2',
      color:'#fff',
      border: '1px solid #1f2937',
      '& span':{

      }
  },
  buttonTitle: {
    fontSize: '12px',
    textTransform: 'none',
    lineHeight: '11px',
    padding: '0px 5px'

  }
})

export default function ButtonGroupSimple(props){
 // const classes = useStyles();
 const classes = useClasses(styles)
  const { buttons, index } = props;
  return (
    <div className={classes.root}>
      <ButtonGroup key={index} color="primary" aria-label="outlined primary button group">
        {buttons.map((button, index)=>{
            return button.disable ? null
                          /*<Button key={index} className={classes.button} disabled={true} aria-haspopup="true">
                            { <span className={`${button.icon}`}></span> }
                            { <FontAwesomeIcon icon={button.icon}/> }
                          </Button>*/
                          : 
                          <Tooltip key={index} title={button.title} aria-label={button.title}>
                            <Button  className={classes.button} onClick={button.onClick} disabled={button.disable} aria-haspopup="true">
                              { <span className={`${button.icon}`}></span>}
                              { <FontAwesomeIcon icon={button.icon}/> }
                              {/* <span className={classes.buttonTitle}>{button.title}</span> */}
                            </Button>
                          </Tooltip>
                  
        })}
      </ButtonGroup>
    </div>
  );
}
