import React, {useEffect} from "react";
import useState from 'react-usestateref';
import { Divider, Paper, IconButton, InputBase, InputAdornment } from '@mui/material'; 
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default function LabelValue({That,viewData,action}) { 
const { state,label,type,pramPass,labelEditable,defaultValue,Max,Min,onBlur } = viewData
const outputState = useState(Object)
const [, setOutput, outputRef] = outputState
const [, setParentState] = That

useEffect(()=>{
if(defaultValue) setOutput(p=>({...p,...{value:parseInt(defaultValue,10)}}))
},[defaultValue])

useEffect(() =>{
if( outputRef.current && ['label','value'].filter(x=>{if(labelEditable===false&&x==='label')return false; else return true}).every(x=> Object.keys(outputRef.current).includes(x)) ){ 
  setParentState(p=>({...p,...{[state]:outputRef.current.value ? parseInt(outputRef.current.value,10) : 0}}))
    action?.forEach((x)=>x({[state]:outputRef.current,pramPass:pramPass})) 
  }
},[outputRef.current])

const handleChange =({value})=>{ 
  if(Max && Max < value) value = Max 
  if(Min && Min > value) value = Min 
  if(!outputRef.current.maxValue || outputRef.current.value<=outputRef.current.maxValue) setOutput(p=>({...p,...{value:value?parseInt(value,10):0}}))
}

useEffect(()=>{
  if((Max||Min)&&outputRef.current.value) handleChange({value:outputRef.current.value})
},[Max,Min])

return <Paper component="form" sx={{display: 'flex', alignItems: 'center', width: '100%',flexWrap:'wrap',backgroundColor:'#2a2a2a' }} onBlur={onBlur} >
      <InputBase
        type={'text'}
        multiline={true}
        sx={{ ml: 1, flex: 1,fontSize:'14px',minHeight:'unset!important', lineHeight:1.1}}
        color={'info'}
        onChange={({target})=>setOutput(p=>({...p,...{label:target.value}}))}
        placeholder={label||'Label'}
        defaultValue={outputRef.current.label || label || null}
        readOnly={!labelEditable?true:false}
        inputProps={{ 'aria-label': 'Inventory Label' }}
      />
      <InputBase
        type={type}
        multiline={true}
        sx={{ ml: 1, flex: .6,fontSize:'14px',textAlign:'right',lineHeight:1}}
        placeholder={'value'}
        value={outputRef.current.value || 0}
        onChange={({target})=>handleChange(target)}
        autoFocus
        inputProps={{ 
          'aria-label': 'Inventory Ammount',
          startAdornment : type==='currency' ? <InputAdornment position="start">$</InputAdornment> : null
         }}
      />
      <IconButton type="button" aria-label="Add" sx={{p:'2%'}} onClick={()=>{handleChange({value:outputRef.current.value+1||1})}}>
        <AddCircleIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton aria-label="Minus" sx={{p:'2%'}} onClick={()=>{handleChange({value:outputRef.current.value-1>=0?outputRef.current.value-1:0}) }}> 
        <RemoveCircleIcon />
      </IconButton>
    </Paper>
}