import React from "react";
import useState from 'react-usestateref';

import { createTheme } from '@mui/material/styles';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioGroups({That,viewData,action}){
  const {label,list,replace, defaultValue,state} = viewData
  const [old, setState, stateRef] = useState(Object)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [parentState, setParentState, parentStateRef] = That
  
  const handleClick = (x) => {
  var val = x.target.value
  setParentState(p=>({...p,...{[state]:val}}))
  if(action) action.forEach((x)=>x({[viewData.state]:val,pramPass:viewData.pramPass})) 
  };
return <FormControl>
      {label ? <FormLabel id={state}>{label}</FormLabel> : null}
      <RadioGroup
        row={true}
        aria-labelledby={state}
        name={"row-radio-buttons-group"}
        onChange={handleClick}
      >

      { list?.constructor === Array ?
        list.flatMap(x=><FormControlLabel key={x} value={x} control={<Radio />} label={x}/>)
        : list?.constructor === Object ?
         Object.entries(list).map(([k,v])=><FormControlLabel key={k} value={v} control={<Radio />}  label={k}/>)
        : null }

      </RadioGroup>
    </FormControl>
}
