import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { setSorting, filterSorting, setImagesSettings } from '../Redux/actions';
//import { makeStyles } from '@material-ui/core/styles';
import TopBarButtonGroups from './Elements/TopBarButtonGroups';
import { Grid } from '@mui/material';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { faSortAmountUpAlt, faCog} from '@fortawesome/free-solid-svg-icons';
import useClasses from "./styleConvert"

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
          main: '#0061AD',
        },
      secondary: {
          main: '#fff',
        },
    }
    });

//const useStyles = makeStyles(theme => ( console.log('theme.spacing(2)', theme.spacing(2), ' theme.palette.text.secondary',  theme.palette.text.secondary) & {
const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 16, //theme.spacing(2),
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)', //theme.palette.text.secondary,
    },
    container: {
        flexWrap: 'unset',
        overflow: 'auto',
        borderRadius:'6px',
        boxShadow: '2px 2px rgb(0 0 0 / 35%)',
        minHeight: '60px',
        borderBottom: '1px solid #868DAA',
        background: '#1f2937'
    },
    menuItem:{
        padding:'0px',
        fontSize: '13px',
        width: '250px',
        display:'block',
        '& span':{
            fontSize: '13px'
        },
        '& label':{
            margin:'0px'
        },
        '& svg':{
            width: '15px'
        }
    }
})


function TopBar(props){
    const classes = useClasses(styles)
   // const classes = useStyles();
    const { buttons , orderFiles, showImages} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState({sorting:false,search:false,settings:false});

    const handleOpenMenu = (event, name) => {
        switch (name) {
            case 'sorting':
                setOpen({sorting: true})
                break;
            case 'search':
                setOpen({search: true})
                break;
            case 'settings':
                setOpen({settings: true})
                break;
        
            default:
                break;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleSetOrderBy = (orderBy) =>{
        props.setSorting(orderBy, orderFiles.field);
        props.filterSorting();
    }

    const handleSetOrderField = (field) =>{
        props.setSorting(orderFiles.orderBy, field);
        props.filterSorting();
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen({sorting:false,search:false,settings:false});
    };

    const handleSetSettings = (imagePreview) =>{
        props.setImagesSettings(imagePreview);
    }


    const options = [
        {
            name: 'By Name',
            value:'name'
        },
        {
            name: 'By Size',
            value:'size'
        },
        {
            name: 'By Create Date',
            value:'date'
        }
    ];

    const additionalButtons = [
        {
            title: 'Sorting',
            icon: faSortAmountUpAlt,
            onClick: (e)=> handleOpenMenu(e,'sorting'),
            disable: false
        },
        {
            title: 'Settings',
            icon: faCog,
            onClick: (e)=> handleOpenMenu(e,'settings'),
            disable: false
        }
    ]

    return (
        <Fragment>
            <ThemeProvider theme={darkTheme}>
              <Grid container className={classes.container} >
                {buttons.topbar.map((groups, index)=> 
                    <Grid item key={index}>
                     <TopBarButtonGroups buttons={groups} index={index} />
                    </Grid>
                )}
            </Grid>
            </ThemeProvider>
        </Fragment>
    )
}


const mapStateToProps = store => ({
    store,
    selectedFiles: store.filemanager.selectedFiles,
    selectedFolder: store.filemanager.selectedFolder,
    bufferedItems: store.filemanager.bufferedItems,
    foldersList: store.filemanager.foldersList,
    filesList: store.filemanager.filesList,
    itemsView: store.filemanager.itemsView,
    showImages: store.filemanager.showImages,
    orderFiles: store.filemanager.orderFiles,
    history: store.filemanager.history,
    translations : store.dashboard.translations,
    lang : store.dashboard.lang,
});

const mapDispatchToProps = dispatch => ({
    setSorting: (orderBy, field) => dispatch(setSorting(orderBy, field)),
    filterSorting: () => dispatch(filterSorting()),
    setImagesSettings:(imagePreview)=>dispatch(setImagesSettings(imagePreview)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
