  import { useEffect } from "react";
  import { atom, useAtom } from 'jotai';
  import { atomWithStorage, createJSONStorage } from 'jotai/utils';

  import { MMKV } from 'react-native-mmkv'

  import useState from 'react-usestateref';
  import localForage from 'localforage';
  export var userStore = localForage.createInstance({ name: "GeneralEKC" });

  export const storage = new MMKV();


  /*const LocalStorageEffect = (k) => async({setSelf, onSet, trigger}) => {
   onSet((nV, _, isReset) => //console.log('LocalStorageEffect::', k,nV )
    isReset ? userStore.removeItem(k) : userStore.setItem(k,nV) 
  );
   userStore.getItem(k).then(v=> v && setSelf(new Map(v)) );
  };

 
  const atomWithMMKV = (key, initialValue) => {

    const baseAtom = atom(storage.contains(key) ? new Map(Object.entries(JSON.parse(storage.getString(key)))) : initialValue);

    const derivedAtom = atom(
      (get) => get(baseAtom),
      (get, set, update) => {
        const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update
        set(baseAtom, nextValue)
        storage.set(key, JSON.stringify(Object.fromEntries(nextValue)))
      },
    );

    return derivedAtom;
  }

export const atomWithLocalForage = (key, initialValue) =>
  atomWithStorage(
    key,
    initialValue,
    createJSONStorage(() => ({
      getItem,
      setItem,
      removeItem,
      clearAll
    }))
  )*/

  const atomWithLocalForage = (k, iV) => {
    const baseAtom = atom(iV)
    baseAtom.onMount = (setValue) => { 
    userStore.getItem(k)
    .then((item)=> setValue(new Map(item).size > 0 ? new Map(item) : iV)) 
    .catch((err)=> console.log('atomWithLocalForage ERR',err));
    };

    const derivedAtom = atom(
      (get) => get(baseAtom),
      (get, set, update) => {
        const nV = typeof update === 'function' ? update(get(baseAtom)) : update;
        //console.log('updating::', k, nV )
        set(baseAtom, nV);
        userStore.setItem(k,nV);
      },
    )
    return derivedAtom
  }









export const userMapState = // atom(new Map());
atomWithLocalForage("userMap",new Map()); 
/* atom({
  key: "userMap",
  default: new Map(),
  effects: [LocalStorageEffect('userMap')],
}); */

export const appSocketState = atom(new Map()); 
/* atom({
  key: "appSocketStates",
  default: new Map(),
})*/

export const appSocketValidateState = atom(new Map()); 
export const appSocketQueryState = atom(new Map()); 
export const appSocketEOSState = atom(new Map()); 


export const appState = atom(new Map()); 
/* atom({
  key: "appState",
  default: new Map(),
}); */

export const listColumnsState = // atom(new Map());
atomWithLocalForage("listColumns",new Map()); 
 /* atom({
  key: "listColumns",
  default: new Map(),
  effects: [LocalStorageEffect('listColumns')],
}); */

export const projectKeysState = // atom(new Map()); 
atomWithLocalForage("projectKeys",new Map()); 
/* atom({
  key: "projectKeys",
  default: new Map(),
  effects: [LocalStorageEffect('projectKeys')],
}); */

export const projectServicesState = // atom(new Map());
 atomWithLocalForage("projectServices",new Map()); 
/* atom({
  key: "projectServices",
  default: new Map(),
  effects: [LocalStorageEffect('projectServices')],
}); */

export const CellFocus = // atom(new Map());
 atomWithLocalForage("cellFocus",new Map());  
/*atom({
  key: "cellFocus",
  default: new Map(),
});*/

export const aMarker = // atom(new Map());
 atomWithLocalForage("aMarker",new Map()); 
/*atom({
  key: "aMarker",
  default: new Map(),
}); */

export const perfLablesMap = // atom(new Map());
 atomWithLocalForage("perfLables",new Map()); 
/* atom({
  key: "perfLables",
  default: new Map(),
}); */

export const popupState = atom({open:false}); 
/* atom({
  key: "popup",
  default: {open:false},
}); */

export const popupState2 = atom({open:false}); 
/* atom({
  key: "popup2",
  default: {open:false},
}); */

export const popupState3 = atom({open:false}); 
/* atom({
  key: "popup3",
  default: {open:false},
}); */

export const popupState4 = atom({open:false}); 
/* atom({
  key: "popup4",
  default: {open:false},
}); */

export const menuItemState = // atom(new Map());
 atomWithLocalForage("menuItems",new Map()); 
/* atom({
  key: "menuItems",
  default: new Map(),
  effects: [LocalStorageEffect('menuItems')],
}); */

export const headerPopupState = atom(new Set()); 
/* atom({
  key: "headerPopup",
  default: new Set(),
});*/

export const chatterState = // atom(new Map());
 atomWithLocalForage("chatter",new Map());
 /* atom({
  key: "chatter",
  default: new Map(),
  effects: [LocalStorageEffect('chatter')],
}); */

export const scheduleState = // atom(new Map());
 atomWithLocalForage("schedule",new Map()); 
/* atom({
  key: "schedule",
  default: new Map(),
  effects: [LocalStorageEffect('schedule')],
}); */

export const divisionMapState = //atom(new Map()); 
 atomWithLocalForage("divisionMap",new Map()); 
/* atom({
  key: "divisionMap",
  default: new Map(),
  effects: [LocalStorageEffect('divisionMap')],
}); */
 
export const divisionFilterMapState = // atom(new Map()); 
 atomWithLocalForage("divisionMapFilter",new Map()); 
/* atom({
  key: "divisionMapFilter",
  default: new Map(),
  effects: [LocalStorageEffect('divisionMapFilter')],
}); */

export const projectFilterMapState = // atom(new Map()); 
 atomWithLocalForage("projectMapFilter",new Map()); 
/* atom({
  key: "projectMapFilter",
  default: new Map(),
  effects: [LocalStorageEffect('projectMapFilter')],
}); */

export const projectMapState = // atom(new Map()); 
atom(new Map()) 
//atomWithLocalForage("projectMap",new Map());
 /*  atom({
  key: "projectMap",
  default: new Map(),
  effects: [LocalStorageEffect('projectMap')],
}); */

export const projectsStatsState = // atom(new Map());
 atomWithLocalForage("projectStats",new Map());
 /* atom({
  key: "projectStats",
  default: new Map(),
  effects: [LocalStorageEffect('projectStats')],
});*/

export const teamLoggState = atom(new Set());
/* atom({
  key: "teamLogg",
  default: new Set()
});*/

export const teamMemberState = // atom(new Map());
 atomWithLocalForage("teamMemeber",new Map()); 
/* atom({
  key: "teamMemebers",
  default: new Map(),
  effects: [LocalStorageEffect('teamMemeber')],
}); */

export const teamMemberMap = // atom(new Map());
atomWithLocalForage("teamMemeberMap",new Map()); 
/* atom({
  key: "teamMemeberMap",
  default: new Map(),
  effects: [LocalStorageEffect('teamMemeber')],
}); */

export const teamFollowState = // atom(new Map());
 atomWithLocalForage("teamFollow",new Map());
/* atom({
  key: "teamFollow",
  default: new Map(),
}); */

export const projFollowState = atom(new Set()); 
/* atom({
  key: "projFollow",
  default: new Set(),
}); */

export const projectLoggMapState = // atom(new Map());
 atomWithLocalForage("projectLoggMap",new Map()); 
/* atom({
  key: "projectLoggMap",
  default: new Map(),
  effects: [LocalStorageEffect('projectLoggMap')],
}); */

export const productMapState = atom(new Map());
 //atomWithLocalForage("productMap",new Map()); 
/* atom({
  key: "productMap",
  default: new Map(),
  effects: [LocalStorageEffect('productMap')],
}); */

export const vendorMapState = // atom(new Map());
 atomWithLocalForage("vendorMap",new Map()); 
/* atom({
  key: "vendorMap",
  default: new Map(),
  effects: [LocalStorageEffect('vendorMap')],
});  */

export const inventoryMapState = atom(new Map());
// atomWithLocalForage("inventoryMap",new Map()); 
/* atom({
  key: "inventoryMap",
  default: new Map(),
  effects: [LocalStorageEffect('inventoryMap')],
});*/

export const leaderBoardState = // atom(new Map());
 atomWithLocalForage("leaderBoard",new Map()); 
/* atom({
  key: "leaderBoard",
  default: new Map(),
  effects: [LocalStorageEffect('leaderBoard')],
}); */

export const messagesState = // atom(new Map());
 atomWithLocalForage("messages",new Map()); 
/* atom({
  key: "messages",
  default: new Map(),
  effects: [LocalStorageEffect('messages')],
}); */

export const googleEventState = atom(new Set()); 
/* atom({
  key: "googleEvents",
  default: new Set()
}); */

export const activeSessionState = // atom(new Map());
 atomWithLocalForage("activeSession",new Map()); 
/* atom({
  key: "activeSession",
  default: new Map(),
  effects: [LocalStorageEffect('activeSession')],
}); */

export const notificationState = // atom(new Map());
 atomWithLocalForage("notifications",new Map());
/* atom({
  key: "notifications",
  default: new Map(),
  effects: [LocalStorageEffect('notifications')],
}); */

// OTHER ITEMS ///

export const useKeyPress = targetKey => {
  const [, setKeyPressed, keyPressedRef] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) setKeyPressed(true);
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) setKeyPressed(false);
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressedRef.current;
};

export const setSessionStorage=(data)=>{
  const {localStorage} = window
  if(data === 'clear'){
    localStorage.clear();
     userStore.removeItem('session'); // sessionStorage.clear()
  }else localStorage.setItem("session",data.secret)
};

export const Sessions=()=> new Promise((resolve) => {
const {location,history,localStorage} = window
var query = {}
var urlPass = Object.fromEntries(new URLSearchParams(location.search))
if(Object.keys(urlPass).length>0) query.query = urlPass

var x = localStorage.getItem('sessionID');
resolve( {...query,sessionID:x||false,sessionType:x?'session':false})
history.pushState({}, document.title, "/")

 /*userStore.getItem('sessionID').then(async(x)=>{
 if(query.sessionID) return
 if(!x) x = await sessionStorage.getItem('sessionID');
 console.log('userStore get SESSION ID:',x)
 query = {...query,sessionID:x||false,sessionType:x?'session':false}
 resolve(query)
 history.pushState({}, document.title, "/")
 }).catch(function (err) {
  console.log('Whats localforage ERR:',err)
})
setTimeout(()=>{ 
  if(query.sessionID) return
  var x = sessionStorage.getItem('sessionID');
  console.log('SESSION ID TOOK OVER 1.5SEC',x)
  query = {...query,sessionID:x||false,sessionType:x?'session':false}
  resolve(query)
},1500) */
});

export const useIsMobile =(x)=> {
  const getIsMobile = () => window.innerWidth <= x;
  const [isMobile, setIsMobile] = useState(getIsMobile())
    useEffect(() => {
        const onResize = () => setIsMobile(getIsMobile())
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize)
    }, [])
   return isMobile
};

export function useWidthHeight(x){
  const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

  const getHeight = () => window.innerHeight 
  || document.documentElement.clientHeight 
  || document.body.clientHeight;

 const IsMobileWidth = () => widthRef.current <= x;
 const IsMobileHeight = () => heightRef.current <= x;

  let [width, setWidth, widthRef] = useState(getWidth());
  let [height, setHeight, heightRef] = useState(getHeight());
  let [mWidth, setMWidth, mWidthRef] = useState(IsMobileWidth());
  let [mHeight, setMHeight, mHeightRef] = useState(IsMobileHeight());

  useEffect(() => {
    const resizeListener = () => { 
      setWidth(getWidth());
      setHeight(getHeight());
      setMWidth(IsMobileWidth());
      setMHeight(IsMobileHeight());
       };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])
return {width:widthRef.current,height:heightRef.current,mw:mWidthRef.current,mh:mHeightRef.current};
};

export const useIsMobileVert =(x)=> {
  const getIsMobile = () => window.innerHeight <= x;
  const [isMobile, setIsMobile] = useState(getIsMobile())
    useEffect(() => {
        const onResize = () => setIsMobile(getIsMobile())
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize)
    }, [])
   return isMobile
};

export const closePopup =({container:x,target:e},callBK)=>{
    if(e!==undefined && x!==null && x!==undefined && !x.contains(e)){
      window.removeEventListener('mousedown', closePopup)
      callBK(true,e)
    }else if(x!==null && e===undefined) window.addEventListener('mousedown', (e)=>closePopup({container:x,target:e.target},callBK))
}

export function useScroll() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const onScrollHandler = (event) => {
    setScrollTop(event.target.scrollTop);
    setScrollHeight(event.target.scrollHeight);
    setClientHeight(event.target.clientHeight);

    setScrollLeft(event.target.scrollLeft);
    setScrollWidth(event.target.scrollWidth);
    setClientWidth(event.target.clientWidth);
  };

  function getBoxShadow() {
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
    const isBottom = clientHeight <= (scrollHeight - scrollTop)+10;
    var percent = parseInt(((((clientHeight / scrollHeight - scrollTop)*-1)/(scrollHeight-clientHeight))*100).toFixed(0),10)
    var fadeFallPre = percent < 10 ? percent : 10
    var fadeFallPost = percent > 90 ? percent : 90

    if(scrollHeight===0&&clientHeight===0&&scrollTop===0) return {percent:false,style:{boxShadow:"unset",WebkitMaskImage:"unset"}}
    let boxShadow = {percent:100,style:{boxShadow:"inset 0 50px 25px -25px rgb(0 0 0 / 25%)"}}
    if (isTop) boxShadow = {percent:0,style:{boxShadow:"inset 0 -50px 25px -25px rgb(0 0 0 / 25%)",'WebkitMaskImage':'linear-gradient(180deg,#000 95%,transparent 100%)'}}
    else if (isBetween) boxShadow = {percent:percent,style:{boxShadow:"inset 0 -50px 25px -25px rgb(0 0 0 / 25%),inset 0 50px 25px -25px rgb(0 0 0 / 25%)",WebkitMaskImage:`linear-gradient(180deg,#000 ${fadeFallPost}%,transparent 100%)`}} // transparent 0%,#000 ${fadeFallPre}%,
    else if (isBottom) boxShadow = {percent:100,style:{boxShadow:"inset 0 50px 25px -25px rgb(0 0 0 / 25%)"}}
    return boxShadow;
  }

  function getBoxShadowHoz() {
    const isLeft = scrollLeft === 0;
    const isHorzBetween = scrollLeft > 0 && clientWidth < scrollWidth - scrollLeft;
    const isRight = clientWidth <= (scrollWidth - scrollLeft)+10;
    // console.log('scrollLeft',scrollLeft,'scrollWidth',scrollWidth,'clientWidth',clientWidth)
    var percent = parseInt(((((clientWidth / scrollWidth - scrollLeft)*-1)/(scrollWidth-clientWidth))*100).toFixed(0),10)
    var fadeFallPre = percent < 25 ? percent : 25
    var fadeFallPost = percent > 75 ? percent : 75
    if (scrollLeft===0&&clientWidth===0&&scrollWidth===0) return {percent:false,style:{boxShadow:"unset",WebkitMaskImage:"unset"}}
    let boxShadow = {percent:100,style:{boxShadow:"inset -50px 0 50px -50px rgb(0 0 0 / 25%)"}}
    if (isLeft) boxShadow = {percent:0,style:{boxShadow:"inset -50px 0 50px -50px rgb(0 0 0 / 25%)",'WebkitMaskImage':'linear-gradient(70deg,#000 70%,transparent 100%)'}}
    else if (isHorzBetween) boxShadow = {percent:percent,style:{pl:'0!important',boxShadow:"inset -50px 0 50px -50px rgb(0 0 0 / 25%),inset 50px 0 50px -50px rgb(0 0 0 / 25%)",WebkitMaskImage:`linear-gradient(90deg,transparent 0%,#000 ${fadeFallPre}%,#000 ${fadeFallPost}%,transparent 100%)`}}
    else if (isRight) boxShadow = {percent:100,style:{pl:'0!important',boxShadow:"inset 50px 0 50px -50px rgb(0 0 0 / 25%)",WebkitMaskImage:'linear-gradient(90deg,transparent 0%,#000 20%)'}} //inset -50px 0 50px -50px rgb(0 0 0 / 25%)
    return boxShadow;
  }

  return { scrollStat: getBoxShadow(), scrollStatHorz: getBoxShadowHoz(), onScrollHandler };
}


export function memorySizeOf(obj) {
  var bytes = 0;

  function sizeOf(obj) {
      if(obj !== null && obj !== undefined) {
          switch(typeof obj) {
          case 'number':
              bytes += 8;
              break;
          case 'string':
              bytes += obj.length * 2;
              break;
          case 'boolean':
              bytes += 4;
              break;
          case 'object':
              var objClass = Object.prototype.toString.call(obj).slice(8, -1);
              if(objClass === 'Object' || objClass === 'Array') {
                  for(var key in obj) {
                      if(!obj.hasOwnProperty(key)) continue;
                      sizeOf(obj[key]);
                  }
              } else bytes += obj.toString().length * 2;
              break;
          }
      }
      return bytes;
  };

  return {fb: formatByteSize(sizeOf(obj)),val:sizeOf(obj)};
};

export function formatByteSize(bytes) {
  if(bytes < 1024) return bytes + " bytes";
  else if(bytes < 1048576) return(bytes / 1024).toFixed(2) + " KiB";
  else if(bytes < 1073741824) return(bytes / 1048576).toFixed(2) + " MiB";
  else return(bytes / 1073741824).toFixed(2) + " GiB";
};

export function objectDeepKeys(obj){
  return Object.keys(obj)
    .filter(key => obj[key] instanceof Object)
    .map(key => objectDeepKeys(obj[key]).map(k => `${key}.${k}`))
    .reduce((x, y) => x.concat(y), Object.keys(obj))
};


export const groupByKey = (data, key) => Object.values(
  data.reduce((res, item) => {
   const value = item?.[key]
   const existing = res[value] || {[key]: value, _id:item?._id, data:[]}
   return {...res,[value] : {...existing,data: [...existing.data, item] }
   } 
  }, {})
);

export const textColor = (color)=> {
  if (color.length == 7) {
    const rgb = [ parseInt(color.substring(1, 3), 16), parseInt(color.substring(3, 5), 16), parseInt(color.substring(5), 16)];
    const luminance = (0.2126 * rgb[0]) / 255 + (0.7152 * rgb[1]) / 255 + (0.0722 * rgb[2]) / 255;
    return luminance > 0.5;
  }
  return false;
};

export const Searcher = ()=>{
  const [projectMap] = useAtom(projectMapState);
  const [inventoryMap] = useAtom(inventoryMapState);
  const [teamMember] = useAtom(teamMemberState);
  const [divisionMap] = useAtom(divisionMapState);
  const [productMap] = useAtom(productMapState);
  const [app,setApp] = useAtom(appState);

  function fullName(x){
   if(teamMember.has(x)) var {firstName,lastName} = teamMember.get(x)
    return `${firstName} ${lastName}`
  }

  function Members(x,y){
    if(x) return Object.assign({},...x.flatMap(({id},i)=>({[`${y} (${i+1})`]:fullName(id)})))
     else return []
  }

useEffect(()=>{
 if(app.get("search")){
 var search = app.get("search")
 var omit = ['projID','_id','avatar']

 setApp(p=>{
  p.set('searchResult', groupByKey([
    ...Array.from([...teamMember],([k,{division,firstName,lastName,email}])=>({_id:k,catagory: ['Clients','Partners','Sites'].includes(division) ? division: 'Team', fullName:`${firstName} ${lastName}`, firstName: firstName, lastName: lastName, email: email,'Full Name':`${firstName} ${lastName}` })),
    ...Array.from([...projectMap],([k,{DivisionID,PoNumber,ProjectTitle,ProjectDesc,ProjectState,SalesState}])=>(
      {_id:k,
        catagory:'Project',
        projID:{ProjectID:k,DivisionID,modelType:divisionMap.get(DivisionID)?.teamName},
        'Job Number':PoNumber,
        'Full Name':ProjectTitle,
        Description:ProjectDesc,
        'Project Manager': ProjectState?.projManager && fullName(ProjectState.projManager),
        'Project Lead': ProjectState?.teamLead && fullName(ProjectState.teamLead),
         ...Members(ProjectState?.Members,'Team Member'),
         ...Members(SalesState?.partnerID, 'Partner'),
         ...Members(SalesState?.clientID, 'Client'),
      })),
    ...Array.from([...inventoryMap],([k,{Status,PurchaseOrder,projectStagingZone,ProductItems,OrderNumber,TrackingNumber,stockShelf,ProjectID}])=>({_id:k,catagory:'Inventory',avatar:productMap.get(ProductItems?.[0]?.id)?.IMAGE_SPOTLIGHT,PoNumber:PurchaseOrder,fullName: productMap.get(ProductItems?.[0]?.id)?.DISPLAY_NAME ,projectStagingZone,Status,TrackingNumber,aisles:stockShelf?.aisles,shelf:stockShelf?.shelf,OrderNumber,ProjectOrderNumber:projectMap.get(ProjectID?.[0])?.PoNumber,ProjectTitle:projectMap.get(ProjectID?.[0])?.ProjectTitle  })),
    ...Array.from([...productMap],([k,{IMAGE_SPOTLIGHT,PART_NUMBER,DISPLAY_NAME,MANUFACTURER,SN}])=>({_id:k,catagory:'Product',avatar:IMAGE_SPOTLIGHT,PoNumber:PART_NUMBER,fullName:DISPLAY_NAME,email:MANUFACTURER,SN}))
  ].filter(x=> Object.entries(x).filter(([k])=>!omit.includes(k)).some(([k,v])=> v && v?.toLowerCase()?.includes(search?.toLowerCase())))
   .flatMap(x=>({...x,relevance: Object.entries(x).filter(([k])=>!omit.includes(k)).find(([,v])=> v?.toLowerCase()?.includes(search?.toLowerCase())) })) ,'catagory')
 );
 return new Map(p);
})
 }else if(app.has("searchResult")) setApp(p=>{
  p.delete('searchResult');
  return new Map(p);
})
},[app.get("search"),projectMap,inventoryMap,teamMember,divisionMap,productMap]); 

  return null
};