import React, { useEffect, useRef } from "react";
import useState from 'react-usestateref';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import { styled } from '@mui/material/styles';
import {Box, Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Paper, Divider, IconButton, Chip, Card, CardHeader, CardContent,CardActions,Slide } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TaskManager from '../FileManager/TaskManager';


import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
dayjs.extend(isYesterday)
dayjs.extend(isToday)

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const EODReport=({That,globalWrn,globalState,viewData,dynamicComponent,handlePopupClose,formatter,useIsMobile,checker,dbUpdateValidate})=>{
    const mainState = useState(Object)
    const [state, setState, stateRef] = mainState
    const outputState = useState([])
    const [, setOutput, outputRef] = outputState
    const [, setEOD, eodRef] = useState(new Map())
    const [, setEODVal, eodValRef] = useState(new Map())
    const [popupData, setPopup] = useAtom(globalState.popupState)
    const [projectMap] = useAtom(globalState.projectMapState)
    const [teamMember] = useAtom(globalState.teamMemberState)
    const [UserMap] = useAtom(globalState.userMapState)
    const containerRef = useRef(null)
    const {projID,emailable,subset} = viewData
    if(projID) var { ProjectID, DivisionID, modelType } = projID
    const ProjectData = projectMap.has(ProjectID) ? projectMap.get(ProjectID) : undefined

    const [appSocket] = useAtom(globalState.appSocketState);
    const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);

    const [expanded, setExpanded,expandedRef] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

    var isMobile = useIsMobile(560)

    useEffect(()=>{
      var socketQueryIO = appSocketQuery.get('socketQueryIO');
      if(ProjectData?.SalesState?.SiteID?.length>0) socketQueryIO.emit('queryDB',{model:'Projects',modelType,query: { 'SalesState.SiteID' : { $in : ProjectData.SalesState.SiteID },'_id' : { $nin : [ProjectID] } } },(x)=>{
        var filtered = x.flatMap(({_id,ProjectTitle})=>({primary:ProjectTitle,actionID:{ProjectID:_id,modelType,DivisionID} }))
       if(filtered.length>0) setState(p=>({...p,...{ 
        headerDropDwn: [<Divider variant="middle" sx={{width:'43%'}}/>,dynamicComponent('DropDown','elements',{state:'options',label:'EOD Report', list:filtered, replace:true},mainState,[({options})=>
          setState(p=>({...p,...{
            headerTitle2: <Typography variant="body1" aria-hidden sx={{textAlign:'center',color:'#121212',backgroundColor:'#555'}}>{options}</Typography>,
            cardContent2: dynamicComponent('EODReport','elements',{state:"EODReport", emailable:true,subset:true, projID: filtered.find(({primary})=>primary===options).actionID },That)
            }}))
         ])]
        }})) 
       }) 

    },[ProjectData])

    useEffect(()=>{
     // console.log('ProjectData',ProjectData)
      if(ProjectData) var {ProjectTitle,ProjectDesc,PoNumber} = ProjectData

      setState(p=>({...p,...{
      cardDetails: <Card sx={{flex:.7}}>
      <CardHeader
        title={ProjectTitle}
        subheader={'Other Notes'}
        titleTypographyProps={{ align: 'center' }}
        action={<EngineeringIcon /> }
        subheaderTypographyProps={{
          align: 'center',
        }}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[700],
        }}
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection:'column',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
          <Typography component="h2" variant="body1" color="text.primary">
            PO# {PoNumber}
          </Typography>
          <Typography variant="body2" aria-hidden>Started: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.startDate).format("MMM D 'YY")} - Ends On: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.endDate).format("MMM D 'YY")}</Typography>
          <Typography variant="body2" aria-hidden>Day Starts: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.startDate).format('h:mm a')} - Day Ends: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.endDate).format('h:mm a')}</Typography>
        </Box>
        <Typography variant="subtitle2" aria-hidden>Job Details: <pre style={{ fontFamily: 'inherit',textAlign:'left',lineHeight:1,whiteSpace:'pre-line' }}>{ProjectDesc}</pre></Typography>
      </CardContent>
      <CardActions sx={{display:'flex',justifyContent:'space-evenly'}}>
        { stateRef.current.headerDropDwn }
      </CardActions>
      {stateRef.current.headerTitle2}
      <Box sx={{padding:'4% 2%',backgroundColor:'#555555'}}>{ stateRef.current.cardContent2 }</Box>
    </Card>,
    detailsBttn: dynamicComponent('DefaultButton','elements',{state:'dtails',label:'More Info',type:'button',startIcon:<ReadMoreIcon/>,full:true},That,[()=> setState(p=>({...p,...{detailsToggle: !p.detailsToggle}}))]),
    }}))

    if(popupData.open&&!subset) setPopup(p=>({...p,...{
          helper: {
            label: 'More Info.',
            action: ()=> setState(p=>({...p,...{detailsToggle: !p.detailsToggle}}))
          }
        }}))
    },[stateRef.current.headerDropDwn,stateRef.current.cardContent2])

    function submitAction(){
      console.log('submitAction',outputRef.current)      
      Promise.all(outputRef.current.flatMap((x)=>{ 
      var arrayFilters =  [{"el._id": {$in: [x.reportID]}}]
      var output = {"$push": {'ProjectState.EODLogg.$[el].revisions':x}}

      dbUpdateValidate({model:'Projects',modelType,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
        if(success){ 
          globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
          setEODVal(p=>{p.delete(x.reportID);return new Map(p)})
        }if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
        })
        return 
      }))
      .then(()=>setOutput([]))
    }

    function submitReformat(){  
      var reportID = outputRef.current.find(({reportID})=>reportID).reportID
      var arrayFilters =  [{"el._id": {$in: [reportID]}}]
      var output = {'ProjectState.EODLogg.$[el].revisions':outputRef.current}

      dbUpdateValidate({model:'Projects',modelType,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
        if(success){ 
          globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
          setEODVal(p=>{p.delete(reportID);return new Map(p)})
        }if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
        })

        setOutput([])
    }

    useEffect(()=>{
      if(outputRef.current.length>0){ 
        if(popupData.open&&!subset) setPopup(p=>({...p,...{
          submitLabel: 'Revise',
          handleSubmit: submitAction
        }}))
        else setState(p=>({...p,...{
          submitBttn: dynamicComponent('DefaultButton','elements',{state:'submit',label:'Save',type:'button',startIcon:<SaveAsIcon/>,full:true},That,[submitAction])
        }}))
      }
      else if(outputRef.current.length === 0 && popupData.handleSubmit)
      setPopup(p=>({...p,...{
        handleSubmit: undefined
      }}))
    },[outputRef.current.length])

    const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
function latestState(x){
  return outputRef.current[x] || simp(ProjectData,x)
}


     function objectFormat(k,v,id){
     return v.flatMap(({ammount,type,userFilter},i2)=>{
      if(userFilter) return
    // if(latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions?.[k]?.find(x=>x.type===type)) ammount = latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions?.[k]?.find(x=>x.type===type).ammount
    
    if(latestState('ProjectState.EODLogg')?.find(({_id})=>id===_id)?.revisions?.some(x=>Object.keys(x).includes(type))){
      ammount = latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions.reverse().find(x=>Object.keys(x).includes(type))[type]
      var {user,date} = latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions.find(x=>Object.keys(x).includes(type))
      var {firstName,lastName} = teamMember.get(user)
     }

     if(eodValRef.current.has(id+'_'+type)) return dynamicComponent('Listable','elements',{state:'EODInventory',label:'',color:'#f58426',items:[{id:'LabelValue',loc:'elements',viewData:{state:'inventory',label:'Costs',type:'number'}}],defaultValue:[{inventory:{value:ammount,label:type}}],disableAdd:true },mainState,[({EODInventory})=>{
     if(EODInventory[0]?.inventory && v[i2]?.ammount !== EODInventory[0].inventory.value){
      var {label,value} = EODInventory[0].inventory

      if(Object.keys(outputRef.current).length===0) setOutput(ProjectData.ProjectState.EODLogg.map(x=> x._id===id ? ({...x, revisions:{[k]:x?.[k]?.map(y=> y.type===label ? ({...y,ammount:value}):y)}}) : x))
      else setOutput(outputRef.current.map(x=> x._id===id ? ({...x,revisions:{[k]:x?.[k]?.map(y=> y.type===label ? ({...y,ammount:value}) : y)}}) : x))   
      }
     }])



     if (ammount !== null && ammount !== undefined)
     return <Stack spacing={2} direction="row" sx={user ? { backgroundColor:'#ff9d15',padding:'1%',borderRadius:'4px',justifyContent:'space-between'} : {justifyContent:'space-between' }}>
        <Item key={'stack'+type+ammount+i2}>
          <Typography>{type}</Typography>
        </Item>
        <Item key={'stack'+type+i2}>
          <Typography> + {ammount} units </Typography>
        </Item>
        <Box sx={{alignContent:'right'}}>
      <IconButton sx={{display:'flex', justifyContent:'space-between'}} onClick={()=> setEODVal(p=>{p.set(id+'_'+type,ammount);return new Map(p)})}><BorderColorIcon/></IconButton>
      </Box>
      {user&&date && <Typography variant="caption" sx={{color:'#fff'}}> Revised: {firstName} {lastName} - {dayjs(date).format('MMM Do YY - h:mma')}</Typography> }
      </Stack>
    })
    }

    function stringFormat(k,v,id){
     // if(latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions?.[k]) v = latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions?.[k]
      if(latestState('ProjectState.EODLogg')?.find(({_id})=>id===_id)?.revisions?.some(x=>Object.keys(x).includes(k))){
         v = latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions?.reverse().find(x=>Object.keys(x).includes(k))[k]
         console.log("Revisions are:", v)
         var {user,date} = latestState('ProjectState.EODLogg').find(({_id})=>id===_id)?.revisions.find(x=>Object.keys(x).includes(k))
         if (teamMember.get(user)) var {firstName,lastName} = teamMember.get(user)
      }

      return <Stack spacing={2} direction="row" sx={ user ? { backgroundColor:'#ff9d15',padding:'1%',borderRadius:'4px',justifyContent:'space-between'} : {justifyContent:'space-between' }}>
      <Item sx={{flex:1}}>{ eodValRef.current.has(id+'_'+k) ?
       dynamicComponent('InputField','elements',{state:'name',label:k,type:'text',defaultValue:v,multiline:true},mainState, [({name})=>{
        var newItems = {date:new Date(),user:UserMap.get('avatar'),reportID:id,[k]:name}
       if(name!==v) setOutput(outputRef.current ? [...outputRef.current.filter(x=>{ 
         if(x.reportID===id&&Object.keys(x).includes(k)) return false
         return true}),newItems] : [newItems])
      }])
      : <Box sx={{display:'flex',justifyContent:'space-between'}}>
      <Typography>{k.toUpperCase()}: <pre style={{ fontFamily: 'inherit',textAlign:'left',lineHeight:1,whiteSpace:'pre-line' }}>{v}</pre></Typography>
      <IconButton onClick={()=>setEODVal(p=>{p.set(id+'_'+k,v);return new Map(p)})}><BorderColorIcon/></IconButton>
      </Box> }
      {user&&date && <Typography variant="caption" sx={{color:'#fff'}}> Revised: {firstName} {lastName} - {dayjs(date).format('MMM Do YY - h:mma')}</Typography> }</Item>
      </Stack>
    }

    useEffect(()=>{
      if(latestState('ProjectState.EODLogg')) latestState('ProjectState.EODLogg').flatMap((x)=>{
       var items = Object.entries(x)
       if(!items.some(([k])=>k==='notes')) items.push(['notes','Missing'])
       if(!items.some(([k])=>k==='techNotes')) items.push(['techNotes','Missing'])

       items.filter(([k])=>['user'].includes(k)).flatMap(([k,v])=>{
        setState(p=>({...p,...{
        [v+'avatar']: dynamicComponent('AvatarBubble','elements',{state:'user',label:'missing', style:{width:'30px',height:'30px'},userID: v },mainState)
       }}))
       })

      Promise.all(
        items.filter(([k])=>!['stats','_id','user','date'].includes(k)).sort((a,b)=>{ 
          if(a.constructor===Array && b.constructor===String) return 1
          if(b.constructor===Array && a.constructor===String ) return -1
          return 0
          }).flatMap(async([k,v])=>{
              if(k==='revisions'&&v.constructor!==Array){
                setOutput(Object.entries(v).filter(([k,v])=>!['_id','date'].includes(k)).filter(([k,v])=>!v.length<=0).flatMap(([k,v])=>({date:new Date(),user:UserMap.get('avatar'),reportID:x._id,[k]:v})))
                submitReformat()
                return <Chip icon={<RotateLeftIcon />} color="info" label={'These Revisions are currently being updated to a new format. Please refresh the Portal/App.'} />
              }
              if(k==='WarnedDate') return <Chip icon={<ReportProblemIcon />} color="error" label={'Warned On: '+dayjs(v).format('ddd MMM D YYYY @ h:mma')} />
              var item = []
              if(v.constructor === Array && v.length>0) item = await objectFormat(k,v,x._id)
              if(v.constructor === String) item = await stringFormat(k,v,x._id)
              return item
            }) 
          ).then(y=>{
        setEOD(p => { p.set(x._id, <Stack spacing={2} divider={<Divider flexItem />}>{[].concat(...y)}</Stack> ); return new Map(p) })
       })
    })
    },[latestState('ProjectState.EODLogg'), eodValRef.current])

  return <Box sx={{display:'flex',flexDirection: isMobile?'column':'row', gap:'.5%',padding:'.5%'}} ref={containerRef}> { stateRef.current.detailsToggle && <Slide direction={isMobile?"down":'right'} in={stateRef.current.detailsToggle} container={containerRef.current}>{stateRef.current.cardDetails}</Slide>}
    { latestState('ProjectState.EODLogg') ? <Box sx={{flex:1,overflow:'auto'}}>{ latestState('ProjectState.EODLogg').flatMap(({user,date,_id,revisions,WarnedDate},i)=>
    <Accordion expanded={expandedRef.current === `panel${i}`} onChange={handleChange(`panel${i}`)} key={'Accordion'+_id+i} sx={{flexDirection:'column'}}>
    <AccordionSummary expandIcon={<ExpandMoreIcon/>} >
      <Box sx={{display:'flex',gap:2,alignItems:'center'}}>
      {user && stateRef.current[user+'avatar']}
      <Box sx={{display:'flex',flexDirection:'column'}} >
      <Typography variant="body2">
        EOD - {dayjs(date).format("ddd MMM D 'YY")}
      </Typography>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>{teamMember.get(user)?.firstName} {teamMember.get(user)?.lastName}</Typography>
      </Box>
      { revisions && revisions?.length > 0 && <Chip label="REVISED" size="small" variant="outlined" sx={{backgroundColor:'#ff9d15'}} />}
      { emailable && ProjectData?.SalesState?.clientID?.length>0 && (dayjs(date).isYesterday()||dayjs(date).isToday()) && dynamicComponent('DefaultButton','elements',{state:'dtails',label:'Email Out',type:'button',startIcon:<ReadMoreIcon/>,full:true},That,[()=> console.log('Emailer',ProjectData?.SalesState?.clientID)])}
      </Box>
    </AccordionSummary>
    <AccordionDetails>
    { eodRef.current.has(_id) && eodRef.current.get(_id) }
    </AccordionDetails>
  </Accordion>
    )}
    {!popupData.open && subset && <Box sx={{position:'sticky',bottom:0,display:'flex',gap:'1%'}}>{stateRef.current.detailsBttn}{stateRef.current.submitBttn}</Box> }
    </Box> : <Typography variant="h7" sx={{m:"auto",textShadow:'1px 1px #2e2e2e'}}>No EOD Reports yet!</Typography> }
    <TaskManager That={mainState}/>
    </Box>
  }

  export default EODReport;