import React, { useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { styled } from '@mui/material/styles';
import { Avatar,Paper,Divider,Box,Drawer,Grid,Checkbox,InputLabel,MenuItem,FormControl,Button,Chip,Typography,Accordion,AccordionSummary,AccordionDetails,List,ListItemText,ListItemIcon,ListItem,IconButton,InputBase,ListSubheader,ListItemButton,Collapse,Card,CardContent,CardMedia,ToggleButton,ToggleButtonGroup,ListItemAvatar} from '@mui/material';


const Item = styled(Paper)(({ theme })=>({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    color: theme.palette.text.secondary,
  }));

  const ItemSecondary = styled(Paper)(({ theme })=>({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    alignItems: 'center',
    padding: '1%',
    display: 'flex',
    flexWrap: 'nowrap',
    height: '100%',
    color: theme.palette.text.secondary,
  }));

const TransferRequest=({viewData,globalState,useIsMobile,dynamicComponent,action})=>{
 const {totalAmount, divName, displayName, state, InventoryID, ProjectID} = viewData
  const outputState = useState(Object)
  const [, setState, stateRef] = useState(Object)
  const [, setPopup2] = useAtom(globalState.popupState2)
  const [, setOutput, outputRef] = outputState
  const [divisionMap] = useAtom(globalState.divisionMapState)

  const useAndMobile = useIsMobile(560)

  
    useEffect(()=>{
      console.log("Output:", InventoryID, outputRef.current) 
      action.forEach((x)=> x('Frank'))
        if(outputRef.current?.QTYtoTransfer > 0) action.forEach((x)=> x({
          InventoryID,
          transfer: outputRef.current.QTYtoTransfer})); 
    },[outputRef.current])

  useEffect(()=>{
    setState(p=>({...p,...{
      totalAmm: totalAmount
    }}))
  },[])

  useEffect(()=>{
    setState(p=>({...p,...{
      QTYButt: dynamicComponent('LabelValue','elements',{state:'QTYtoTransfer',label:'QTY To Transfer',type:'number',Max:totalAmount,labelEditable:false},outputState,[({QTYtoTransfer})=>setOutput(p=>({...p,...{QTYtoTransfer:QTYtoTransfer.value}}))])
    }}))
  },[outputRef.current.QTYtoTransfer])

    useEffect(()=>{
        setState(p=>({...p,...{
            unitTotal: <Typography sx={{padding:'2%', color:'lawngreen'}}>
            {outputRef.current?.QTYtoTransfer ? `${stateRef.current.totalAmm - outputRef.current?.QTYtoTransfer} Units Total` : `${stateRef.current.totalAmm} Units Total`}
            </Typography>
        }}))
    },[outputRef.current?.QTYtoTransfer, stateRef.current.totalAmm])

    
    return <Box>
            <Grid  item xs={12} md={ useAndMobile ? 12 : 4 }>
              <ItemSecondary>
                <Box sx={{justifyContent:'center', width:'100%'}}>
                    {totalAmount > 0 ?
                  stateRef.current.unitTotal
                  : 
                  <Typography sx={{padding:'2%', color:'red'}}>
                  {`${totalAmount} Units Total`}
                  </Typography>
                    }
                </Box>
              </ItemSecondary>
              <Item>
                <Box sx={{width:'100%'}}>
                  {stateRef.current.QTYButt}
                </Box>
              </Item>
            </Grid>
          </Box>
}
export default TransferRequest